<!--
  1. Component to link the invoice detail
  2. Main purpose of it that when admin clicks on the invoice id, it should
     redirect to invoice details.
 -->
<template>
    <div class="item-actions">
        <a :href="'payout/details/'+rowData.id" target="_self">{{rowData.invoice_id}}</a>
    </div>
</template>

<script>
export default {
    name: "BillDetailLink",
    props: {
        rowData: {
            type: Object,
            required: true
        },
    },
}
</script>
