<template>
  <div class="fee-container">
    <label class="switch-label mt-2">Latest Invoice</label>
    <div class="fee-container__fee-content">
      <template v-if="activeView === 'read'">
        <span class="m-2">{{ latestInvoiceNumber }}</span>
        <img
          class="cursor-pointer"
          src="/icons/start_a_conversation_ic.svg"
          alt="edit"
          @click="editInvoiceData">
      </template>
      <div
        v-else
        class="flex-display flex-row mt-2">
        <input
          v-model="latestInvoiceNumber"
          class="form-control"
          type="number">
        <button
          class="btn btn-primary ml-2"
          @click="updateInvoiceData">Update</button>
        <button
          class="btn confirmation-delete ml-2"
          @click="cancelInvoiceData">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TherapistLatestInvoice',
    props: {
        therapistId: {
            type: Number,
            required: true
        },
        latestInvoice: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            activeView: 'read',
            latestInvoiceNumber: this.latestInvoice,
            oldLatestInvoiceNumber: ''
        }
    },
    methods: {
        editInvoiceData () {
            this.activeView = 'edit'
            this.oldLatestInvoiceNumber = this.latestInvoiceNumber
        },
        cancelInvoiceData () {
            this.activeView = 'read'
            this.latestInvoiceNumber = this.oldLatestInvoiceNumber
        },
        updateInvoiceData () {
            let loader = this.$loading.show();
            this.$http.put(this.url + "therapist/invoice-data/update", {
                therapist_id: this.therapistId,
                latest_invoice: this.latestInvoiceNumber
            }).then(response => {
                loader.hide();
                this.activeView = "read";
                this.$emit("latestInvoiceUpdated", this.latestInvoiceNumber);
                this.$toastr("success", response.data.message, "");
            }).catch(error => {
                loader.hide()
                this.$toastr("error", error.response.data.message, "");
            })
        }
    }
}
</script>

<style scoped>

</style>
