<template>
  <div>
    <h1 class="mb-3 fs-16 bold-font grey-900">Review payment method</h1>
    <div class="bookingFlow__flex align-center justify-space-between">

      <div class="bookingFlow__flex align-center">
        <img :src="img" alt="cardImg" class="height-36">
        <div class="pl-3">
          <p class="mb-0 fs-16 grey-900">{{ name }}</p>
          <p  class="fs-12 grey-900 mb-0">{{description}}</p>
        </div>
      </div>
      <img v-if="planType === planTypeInsurance" src="https://assets.myonlinetherapy.com/images/icons/booking/booking/close_ic.svg" @click="showInsuranceExpireModal" alt="cardImg" class="width-22 height-22 pointer">

    </div>
    <pop-up :visible="insuranceExpireDialogue" @hide="insuranceExpireDialogue = false" :width="'397'" :style-classes="'width-22 height-22'">
      <template v-slot:header>
        <p class="grey-900 bold-font fs-20 lh-28 text-left">Are you sure?</p>
      </template>
      <template v-slot:main>
        <p class="grey-900 fs-16 lh-20 text-left">
          By removing insurance, we will be expiring {{ client.first_name }}'s insurance plan and transitioning {{ client.first_name }} onto a self-paying payment plan
        </p>
      </template>
      <template v-slot:footer>
        <button @click="expireClientInsurance" class="d-inline-block white--text bg-blue-500 width-95 height-44 bold-font fs-16">Go ahead</button>
      </template>
    </pop-up>

  </div>
</template>

<script>
import PopUp from "../components/popUp";
import TherapyHelper from "@/helpers/app/therapyHelper";
export default {
  name: "reviewPaymentMethod",
  components: {PopUp},
  props: {
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
    },
    planType: {
      required: true,
    },
    client: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      img: '',
      insuranceExpireDialogue: false,
      planTypeInsurance:TherapyHelper.planTypeInsurance,
    }
  },
  created() {
    this.getCardIcon(this.name);
  },
  methods:{
    getCardIcon(name)
    {
      switch (name.toLowerCase()) {
        case 'mastercard':
          this.img = 'https://assets.myonlinetherapy.com/images/icons/card/mastercard.png';
          break;
        case 'visa':
          this.img = 'https://assets.myonlinetherapy.com/images/icons/card/visa.png';
          break;
        default:
          this.img = 'https://assets.myonlinetherapy.com/images/icons/booking/booking/cc_default_ic.svg';
          break;
      }
    },
    showInsuranceExpireModal(){
      this.insuranceExpireDialogue = true;
    },
    expireClientInsurance(){
      let loader = this.$loading.show();
      this.$http.put(this.url + 'therapy/plan/leave', {
        client_id: this.client.id,
        timezone: "europe/london",
        device_type: this.deviceType}).then(response => {
        this.$toastr("success", response.data.message);
        this.insuranceExpireDialogue = false;
        location.reload();
      }).catch( error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    }
  }
}
</script>
