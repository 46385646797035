<template>
    <div>
        <div class="toggle-switch mt-2">
            <label class="switch-label">Indemnity coverage expiry date</label>
            <div class="flex-row flex-display justify-content-end align-items-center">
                <p class="m-1 pl-2" v-if="indemnityCoverage">{{ indemnityCoverage.indemnity_insurance_expiry }}</p>
                <img src="/icons/start_a_conversation_ic.svg" alt="Add date" class="ml-2 cursor-pointer"
                     @click="$emit('dateClicked')">
            </div>
        </div>
        <div class="toggle-switch mt-2 mb-2">
            <label class="switch-label">Indemnity region coverage</label>
            <div class="flex-row flex-display justify-content-end align-items-center max-width-210">
                <p class="m-1 pl-2" v-if="indemnityCoverage">
                    {{ indemnityCoverage.selected_insurance_regions }}
                </p>
                <img src="/icons/start_a_conversation_ic.svg" alt="Add regions" class="ml-2 cursor-pointer"
                     @click="$emit('regionClicked')">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TherapistIndemnityCoverage',
    props: {
        indemnityCoverage: {
            required: true
        }
    },
}
</script>
<style scoped>
.max-width-210 {
    max-width: 210px !important;
}
</style>

