<template>
  <!-- details modal -->
  <v-dialog v-model="show" persistent max-width="400px" min-height="263px" v-else>
    <v-card>
      <v-card-title>
        <div class="flex-display flex-column w-100">
          <div class="flex-display flex-row w-100 justify-end">
            <img class="pointer" width="36" height="36" alt="mot" src="/icons/close_ic.svg" @click.stop="hide"/>
          </div>
          <div class="flex-display flex-row w-100 justify-center">
            <h1 class="model-heading text-center">{{ modalTitle }}</h1>
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="flex-display flex-column justify-start">
          <div class="flex-display flex-column justify-space-between m-b-10">
            <label class="item-label col-4 m-0 p-0 m-b-5" id="finalizeDateLabel" for="finalizeDate">Finalize Date
              <v-tooltip max-width="235px" content-class="text-left opacity-1 p-3 bg-blue-200" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"><img  width="16" height="16" alt="info" src="/icons/info.png"></span>
                </template>
                <span><strong>Finalize Date</strong><br>
                The date draft items are finalised and an invoice or a payout is created based on the net balance.
                </span>
              </v-tooltip>

            </label>

            <date-picker class="p-0"
                         id="finalizeDate"
                         :date="finalize_date"
                         :org="true"
                         :isMaxDateEnable="false"
                         placeholder=""
                         v-on:valueUpdated="updateFinalizeDate"
                         v-on:saveDate="updateFinalizeDate"

            ></date-picker>
          </div>
          <div class="flex-display flex-column justify-space-between m-b-10">
            <label class="item-label col-4 m-0 p-0 m-b-5" for="dueDate">Pay run date
              <v-tooltip max-width="235px" content-class="text-left opacity-1 p-3 bg-blue-200" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"><img width="16" height="16" alt="info" src="/icons/info.png"></span>
                </template>
                <span><strong>Payment Terms</strong><br>
                  The number of days a therapist has to pay an invoice in full e.g.
                  Setting a payment term of net 30 days allows the therapist 30 days to pay off an invoice.
                </span>
              </v-tooltip>
            </label>
            <date-picker class="p-0"
                         id="dueDate"
                         :date="pay_run_date"
                         :org="true"
                         :isMaxDateEnable="false"
                         placeholder=""
                         v-on:valueUpdated="updatePayRunDate"
                         v-on:saveDate="updatePayRunDate"
            ></date-picker>
          </div>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <button class="btn modal-action-button btn-cancel" @click.stop="hide">Cancel</button>
        <button class="btn modal-action-button disable-class" :disabled="disableSubmit" @click="submit()">{{
            actionButtonTitle
          }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Confirmation from "../common/Confirmation";
import DatePicker from "@/components/common/DatePicker";

export default {
  name: "FinaliseDraftItem",
  components: {Confirmation, DatePicker},
  props: {
    visible: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      modalTitle: 'Finalise draft item',
      actionButtonTitle: 'Submit',
      finalize_date: '',
      pay_run_date: '',
      disableSubmit: true
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  created() {
    this.allowSubmit();
  },
  methods: {
    hide() {
      this.$emit('hide')
    },
    updateFinalizeDate(date) {
      this.finalize_date = date;
    },
    updatePayRunDate(date) {
      this.pay_run_date = date;
    },
    allowSubmit() {
      if (this.finalize_date !== '' && this.pay_run_date !== '') {
        return this.disableSubmit = false;
      }
    },
    submit(event) {
      this.$emit('submitFinalizeDraft', this.finalize_date, this.pay_run_date);
    },


  },
  watch: {
    'finalize_date': function () {
      this.allowSubmit();
    },
    'pay_run_date': function () {
      this.allowSubmit();
    }
  },
}
</script>
<style scoped>
.btn-cancel {
  color: #308BC3 !important;
  background-color: #ffffff !important;
  border: 1px solid #308BC3 !important;
}

.disable-class {
  background-color: #3A9DCE !important;

}
</style>
