<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
              <div class="search-bar-container">
                <input type="text" class="w-100 search-container" id="search" v-model="keyword" v-on:keyup="keywordUpdated" v-on:keyup.enter="search" placeholder="Search for a client">
                <span class="submit">
                    </span>
              </div>
              <template >
                <div class="users-list" id="clients_list">
                    <div v-for="(client, $index) in clients" :key="$index">
                        <div class="user-tile">
                            <div class="user-tile__user_img">
                                <img :src="client.profile_picture !== '' ? client.profile_picture : defaultImage"/>
                            </div>
                            <div class="user-tile__user_detail col-md-7">
                                <p class="user-tile__user_detail__header word-break">{{ client.full_name }}</p>
                                <p class="user-tile__user_detail__sub-header"></p>
                                <p class="user-tile__user_detail__text word-break">{{ client.email }}</p>
                            </div>
                            <div class="user-tile__actions">
                                <span class="user-tile__actions___status" v-if="selectedClients.indexOf(client.id) > -1">Connected</span>
                                <button class="btn user-tile__actions___connect" @click="addClient(client.id)" v-else>Connect</button>
                            </div>
                        </div>
                    </div>
                  <infinite-loading :identifier="infiniteId"  @infinite="infiniteHandler"></infinite-loading>
                </div>
              </template>
            </v-card-text>

            <v-card-actions class="flex-display flex-row">

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import InfiniteLoading from 'vue-infinite-loading';
    export default {
        name: "AddClient",
        components: {
          InfiniteLoading
        },
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            selectedClients: {
                type: Array,
                required: true
            },
            therapist_id: {
              required: true,
              type: String
            }
        },
        data: () => ({
            modal_title : 'Add a Client',
            actionButtonTitle:'Apply',
            defaultImage: '/img/default_user_img.svg',
            clients: [],
            keyword: '',
            showLoadMore: true,
            pageNumber:1,
            infiniteId: +new Date(),
        }),
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide(){
                this.$emit('hide')
            },
            addClient(client_id){
                this.$emit('addClient', client_id);
                this.$emit('hide');
            },
            keywordUpdated () {
              if (this.keyword === '') {
                this.refreshClients()
              }
            },
            search () {
                this.refreshClients();
            },
            refreshClients() {
              this.clients = [];
              this.pageNumber = 1;
              this.infiniteId += 1;
            },
            infiniteHandler($state) {
              this.$http.get(this.url + "therapist/clients", {
                params: {
                  therapist_id : this.therapist_id,
                  page: this.pageNumber,
                  keyword: this.keyword
                }
              }).then(response => {
                if (response.data.clients.data.length > 0) {
                  this.pageNumber += 1;
                  this.clients.push(...response.data.clients.data);
                    $state.loaded();
                }
                if (!response.data.has_more) {
                    $state.complete();
                }
              }).catch(error => {
                this.$toastr("error", error.response.data.message, "");
              })
            },
        }
    }
</script>
<style scoped>
.word-break {
  word-break: break-all;
}
</style>
