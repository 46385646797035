<template>
    <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{header_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click="hide"/>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <!-- title and type -->
                    <v-layout wrap align-center class="justify-space-between">
                        <form id="session_package_form" class="w-100 flex-display flex-row flex-wrap flex-justify-between ">
                            <!-- Session Type -->
                            <div class="form-group w-100">
                                <label class="form-label">Session Type</label>
                                <select name="type" class="input-select" v-model="form.session_type_id">
                                    <option value="" disabled>Select Session Type</option>
                                    <template v-for="sessionType in sessionTypes">
                                        <option :value="sessionType.id">{{sessionType.title}}</option>
                                    </template>
                                </select>
                            </div>
                            <!-- Supported Psychologists -->
<!--                            <div class="form-group w-48 flex-justify-between">
                                <div>
                                    <label class="form-label">Supported Psychologists</label>
                                    <select v-model="form.therapist_support" class="input-select">
                                        <option value="" disabled>Select Psychologist</option>
                                        <option value="all">All</option>
                                        <option value="selected">Selected</option>
                                        <option value="none">None</option>
                                    </select>
                                </div>
                                <div v-show=" ( form.therapist_support === 'selected' )">
                                    <label class="form-label">
                                        Allowed Psychologists <br>
                                        <small>
                                            (Only selected psychologists can offer package)
                                        </small>
                                    </label>
                                    <multi-select v-model="form.selected_therapists"
                                                  :options="psychologists"
                                                  :searchable=true
                                                  :multiple=true
                                                  :taggable=true
                                                  :close-on-select="false"
                                                  :clear-on-select="false"
                                                  label="name"
                                                  track-by="id"
                                                  placeholder="Select Psychologist">
                                    </multi-select>
                                </div>
                            </div>-->
                            <!-- Package Title -->
                            <div class="form-group w-48">
                                <label class="form-label">Title</label>
                                <input type="text" name="title" v-model="form.title" class="input-text" placeholder="e.g. Live Chat (30 Min)"/>
                            </div>
                            <!-- Package Code -->
                            <div class="form-group w-48">
                                <label class="form-label">Short Code</label>
                                <input type="text" name="title" v-model="form.code" class="input-text" placeholder="e.g PKG-LC-30"/>
                            </div>
                            <!-- Sessions Allowed -->
                            <div class="form-group w-48">
                                <label class="form-label">Sessions</label>
                                <input name="price" type="text" v-model="form.sessions" class="input-text" placeholder="e.g. 10"/>
                            </div>
                            <!-- Discount Percentage -->
                            <div class="form-group w-48">
                                <label class="form-label">Discount(%)</label>
                                <input name="administrative_fee" type="text" v-model="form.discount" class="input-text" placeholder="e.g. 10"/>
                            </div>
                            <!-- Configurations -->
                            <div class="w-100">
                                <label class="form-label">Configurations</label>
                                <div class="border-form-group w-100 px-3 py-3 flex-display flex-row flex-justify-between">
                                    <div>
                                        <label class="switch-label pr-3">Status</label>
                                        <label class="switch">
                                            <input type="checkbox" name="is_card_only" v-model="form.status" true-value="1" false-value="0">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </v-layout>
                </v-container>
            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button class="btn modal-action-button" @click="submitPackage" >{{action_button_title}}</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import MultiSelect from 'vue-multiselect'
    export default {
        name: "addSessionPackage",
        props: {
            visible: {
                required: true,
                type: Boolean,
            },
            action: {
                required: true,
                type: String
            },
            sessionTypes: {
                required: true,
                type: Array
            },
            psychologists: {
                required: true,
                type: Array
            },
            value: {
                type: Object,
                default: null
            },
        },
        data: () => ({
            header_title: 'Add Session Package',
            action_button_title: 'Add',
            form : {
                id: null,
                session_type_id: '',
                title: '',
                code: '',
                therapist_support: '',
                therapist_ids: '',
                sessions: null,
                discount: null,
                status: 1,
                selected_therapists: []
            },
            isFirstChange: true
        }),
        components:{MultiSelect},
        computed: {
            show: {
                get() {
                    return this.visible
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        } ,
        created () {
            if ( this.action === 'update' ) {
                this.header_title =  'Update Session Package';
                this.action_button_title = 'Update';
                this.form = {...this.value};
            }
        },
        methods: {
            hide() {
                this.$emit('hide');
            },
            submitPackage() {
                this.$emit('submitPackage', this.form, this.action);
            },
            parseSelectedUsers(data) {
                let _ids = [];
                for( let i=0;i < data.length; i++ ) {
                    _ids.push(data[i].id);
                }
                return _ids.join(',');
            },
        },
        watch: {
            'form.session_type_id': function (val) {
                const index = this.sessionTypes.findIndex(x => x.id === val);
                if ( !this.value || this.value.session_type_id !== val || !this.isFirstChange ) {
                    this.form.title = this.sessionTypes[index].name;
                    this.form.code = 'PKG-' +
                        this.sessionTypes[index].title.split(' ')
                            .map(i => ( isNaN( i.charAt(0) ) ? i.charAt(0) : null)).join('') +
                        '-' + this.sessionTypes[index].duration;
                }
                else {
                    this.isFirstChange = false;
                }
            },
            'form.selected_therapists': function(val) {
                this.form.therapist_ids = this.parseSelectedUsers(val);
            },
            'form.sessions': function (val) {
                this.form.sessions = val.replace(/[^0-9]+/, '');
            },
            'form.discount': function (val) {
                this.form.discount = val.replace(/[^0-9]+/, '');
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .form-group {
        margin: 5px 0 !important;
    }
</style>