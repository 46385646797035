<template>
    <div v-if="showBanner" @click="$emit('banner-clicked')" class="pointer flex-display flex-row banner-width py-3 px-3" :class="backgroundColor">
        <img class="height-24 width-24" :src="bannerImage" alt="exclamation-mark">
        <div :class="backgroundColor" class="flex-display flex-column justify-start w-100">
            <p v-if="bannerHeading !== ''" class="fs-16 lh-20 m-l-11 mb-0 font-semi-bold" :class="textColor">
                {{ bannerHeading }}
            </p>
            <template v-if="bannerHeading === ''">
                <p class="fs-16 lh-20 m-l-11 mb-0 mt-1 font-semi-bold" :class="textColor">
                    {{ bannerText }}
                </p>
            </template>
            <template v-else>
                <p class="fs-16 lh-20 m-l-11 mb-0 mt-1" :class="textColor">
                    {{ bannerText }}
                </p>
            </template>
        </div>
        <img v-if="isDismissible"
             @click="$emit('dismiss')"
             class="pointer ml-2 width-12 height-12"
             width="12"
             height="12"
             src="https://assets.myonlinetherapy.com/images/icons/client-details/close-yellow.png"
             alt="myonlinetherapy"/>
    </div>
</template>

<script>
    export default {
        name: "clinicalAssessmentBanner",
        props: {
            showBanner:{
                default: false
            },
            bannerText: {
                default: "",
            },
            bannerHeading: {
                default: "",
            },
            type: {
                default: "",
            },
            isDismissible: {
                default: false
            }
        },
        computed: {
            bannerImage() {
                let img = "";
                switch (this.type) {
                    case "info":
                        img = "https://assets.myonlinetherapy.com/images/icons/client-details/blue-exclamation.png";
                        break;
                    case "warning":
                        img = "https://assets.myonlinetherapy.com/images/icons/client-details/yellow-exclamation.png";
                        break;
                    case "error":
                        img = "https://assets.myonlinetherapy.com/images/icons/client-details/red-exclamation.png";
                        break;
                }
                return img;
            },
            backgroundColor() {
                let color = "";
                switch (this.type) {
                    case "info":
                        color = "bg-blue-100";
                        break;
                    case "warning":
                        color = "bg-yellow-100";
                        break;
                    case "error":
                        color = "bg-red-100";
                        break;
                }
                return color;
            },
            textColor() {
                let color = "";
                switch (this.type) {
                    case "info":
                        color = "blue-500";
                        break;
                    case "warning":
                        color = "yellow-500";
                        break;
                    case "error":
                        color = "red-500";
                        break;
                }
                return color;
            },
        }
    }
</script>
<style scoped>
.banner-width{
  width: auto !important;
}
</style>