<template>
    <div class="fee-container mt-0">
        <div class="fee-container__fee-content">
            <template v-if="activeView === 'read'">
              <p>
                <img class="mr-3" alt="mobile" src="/icons/mobile_number_ic.svg"/>
                <a :href="'tel:'+phone"><span class="mr-1">{{client.country_code}}</span>{{ phone }}</a>
                <img class="cursor-pointer ml-3 " src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editphone">
              </p>
            </template>
            <div class="flex-display flex-row mb-2" v-else>
                <label class="form-label">{{client.country_code}}</label>
                  <input id="phone" v-model="phone" @keypress="isNumber($event)"
                         type="tel" class="form-control"/>
               <button class="btn btn-primary ml-2" @click="updateClientPhone">Update</button>
                <button class="btn confirmation-delete ml-2" @click="cancelPhone">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import numberHelper from "@/helpers/numberHelper";
    export default {
        name: 'ClientPhone',
        props: {
            client: {
                required: true
            },
        },
        data() {
            return {
                activeView: 'read',
                phone: this.client.phone_number,
                oldphone : '',
            }
        },
        methods: {
          isNumber: numberHelper.isNumber,
          removeLeadingZero(phone) {
            this.phone = numberHelper.removeCharactorAtFirstPlace(phone, "0");
          },
          editphone() {
                this.activeView = 'edit';
                this.oldphone = this.phone
          },
          cancelPhone() {
            this.activeView = 'read';
            this.phone = this.oldphone
          },
          updateClientPhone() {
            this.$emit('updatePhone', this.phone, 'phone')
            this.activeView = 'read';

          },
      },
      watch: {
        phone() {
          this.removeLeadingZero(this.phone);
        }
      }
    }
</script>