<template>
    <div class="calendar-weekly">
        <div class="calendar-header">
            <div class="title-cell">
                <p>{{headerTitle}}</p>
            </div>
            <template v-for="date in timeSpan">
                <div class="date-cell" :class="{'today': isToday(date.date), 'holiday': isWeekend(date.date)}">
                    <p>{{date.formatted_date}}</p>
                </div>
            </template>
        </div>
        <div class="calendar-body">
            <div class="calendar-row first">
                <div class="title-cell">
                    <p>{{firstRowTitle}}</p>
                </div>
                <template v-for="date in timeSpan">
                    <div v-if="date.date" class="date-cell" :class="{'today': isToday(date.date), 'holiday': isWeekend(date.date)}">
                        <p v-if="date.slots_count">{{date.slots_count}}</p>
                    </div>
                </template>
            </div>
            <template v-for="therapist in schedule">
                <div class="calendar-row">
                    <div class="title-cell">
                        <p>{{therapist.full_name}} <br>
                        <a href="#" @click.prevent="manageAvailability(therapist.id)">Manage Availability</a>
                        <span class="leave-check" v-if="therapist.activeLeave">On Leave</span></p>
                    </div>
                    <template v-for="(slots, date) in therapist.availabilty">
                        <div class="date-cell" :class="{'fill': slots.length > 0, 'today': isToday(date), 'holiday': isWeekend(date) ,'leave': isOnLeave(date,therapist.therapistLeave)}">
                            <div class="slot-actions"></div>
                            <template v-for="slot in slots">
                                <p>{{customDateFormat(slot.start_time, 'HH:mm')}} - {{customDateFormat(slot.end_time, 'HH:mm')}} {{slotPostfix(slot)}}</p>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import timeHelper from "../../../helpers/timeHelper";
    import moment from "moment";
    export default {
        name: 'WeeklyCalendar',
        props: {
            timeSpan: {
                required: true,
                type: Array
            },
            schedule: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                headerTitle: 'Therapists',
                firstRowTitle: 'All Psychologists'
            }
        },
        methods: {
            manageAvailability(therapistId) {
                try {
                    const admin = JSON.parse(localStorage.getItem('user'));
                    const url = process.env.VUE_APP_API_PATH + 'admin-impersonation/impersonate';
                    const jwtToken = localStorage.getItem('token');

                    const headers = {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    };

                    const payload = {
                        Therapist_Id: therapistId,
                        Admin_Id: admin.id,
                        Admin_Token: admin.impersonation_token
                    };

                    this.$http.post(url, payload, { headers })
                        .then(response => {
                            console.log('Impersonation successful:', response);
                            window.open(process.env.VUE_APP_MYOT_WEB_APP_ENDPOINT + '/therapists/admin-impersonation/availability', '_blank');
                            this.$toastr("success", 'Impersonation Successful', "");
                        })
                        .catch(error => {
                            console.error('An error occurred while impersonating:', error.message);
                            this.$toastr("error", 'There was an error while trying to impersonate the therapist. Please try again or contact support if the issue persists.', "");
                        });
                } catch (error) {
                    console.error('An error occurred:', error.message);
                    this.$toastr("error", 'There was an error while trying to impersonate the therapist. Please try again or contact support if the issue persists.', "");
                }
            },
            isToday(date) {
                return timeHelper.isToday(date);
            },
            isWeekend(date) {
                return timeHelper.isWeekend(date);
            },
            slotPostfix(slot) {
                if( slot.is_booked === 1 ) {
                    return '(B)';
                }
                else if(slot.is_reserved === 1) {
                    return '(R)';
                }
                return '';
            },
            isOnLeave(date,leaveDetails) {
              if (leaveDetails){
                return  timeHelper.isOnLeave(date,leaveDetails.start_date,leaveDetails.end_date);
              }
            },
            formatDateTime(datetime) {
            return timeHelper.formatDateTime(datetime, 'ddd Do MMM')
            },
            isOnLeaveLabelCheck(leaveDetails){
              if (leaveDetails) {

                let today = new Date();
                today.setHours(0, 0, 0, 0);
                let start = new Date(leaveDetails.start_date);
                let end = new Date(leaveDetails.end_date);
                let todayDate = moment(today, "DD/MM/YYYY");
                let startDate = moment(start, "DD/MM/YYYY");
                let endDate = moment(end, "DD/MM/YYYY");
                return todayDate.isBetween(startDate, endDate, null, '[]');
              }
            }
        }
    }
</script>

<style scoped>
.leave-check{
  font-size: 13px;
  font-style: italic;
  font-weight: 700;
  line-height: 13px;
}
</style>
