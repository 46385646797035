<template>
    <div class="fee-container">
        <label class="switch-label mt-2">{{ name }}</label>
        <div class="fee-container__fee-content">
            <template v-if="activeView === 'read'">
                <span class="m-2">{{payoutAmount}}</span>
                <img class="cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editFee">
            </template>
            <template v-else>
                <input class="form-control" type="number" step="0.01" @input="validateUpto2Decimals()" v-model="payoutAmount" />
                <span class="m-2">  </span>
                <button class="btn btn-primary" @click="updateFee">Update</button>
              <button class="btn confirmation-delete ml-2" @click="cancelPayout">Cancel</button>

            </template>
        </div>
    </div>
</template>

<script>
    import regexHelper from "../../../helpers/regexHelper";
    import numberHelper from "@/helpers/numberHelper";
    export default {
        name: 'TherapistPayoutFee',
        props: {
            therapistId: {
                type: Number,
                required: true
            },
            fee: {
                type: Number,
                required: true
            },
            name: {
              type: String,
              required: true
            }
        },
        data() {
            return {
                activeView: 'read',
                payoutAmount: this.fee,
                oldPayoutAmount : '',

            }
        },
        methods: {
            editFee() {
                this.activeView = 'edit';
                this.oldPayoutAmount = this.payoutAmount
            },
            cancelPayout() {
              this.activeView = 'read';
              this.payoutAmount = this.oldPayoutAmount
            },
            updateFee() {
              let loader = this.$loading.show();
              this.payoutAmount = this.payoutAmount === '' ? 0 : this.payoutAmount;
              let formData = {};
              if (this.name === '30 min payout') {
                formData = {
                  therapist_id: this.therapistId,
                  therapy_fee_30_min: this.payoutAmount
                };
              } else {
                formData = {
                  therapist_id: this.therapistId,
                  therapy_fee_50_min: this.payoutAmount
                };
              }
              this.$http.put(this.url + "therapist/payout-fee/update", formData
              ).then(response => {
                loader.hide();
                this.activeView = "read";
                this.$emit("feeUpdated", this.payoutAmount);
                this.$toastr("success", response.data.message, "");
              }).catch(error => {
                loader.hide();
                this.$toastr("error", error.response.data.message, "");
              });
            },
          validateUpto2Decimals(){
            this.payoutAmount = numberHelper.allowUptoTwoDecimalPoint(this.payoutAmount);
          }
        },
        watch: {
            'payoutAmount': function(value) {
              this.payoutAmount = regexHelper.filterNumericAndDecimalsOnly(value);
            }
        }
    }
</script>