<template>
  <div class="add-member-modal">
    <form>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">First Name</label>
            <input
              v-model="form.first_name"
              class="input-text"
              type="text"
              placeholder="Enter first name">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">Last Name</label>
            <input
              v-model="form.last_name"
              class="input-text"
              type="text"
              placeholder="Enter last name">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">Date of Birth</label>
            <date-picker
              :date="form.dob"
              class="p-0"
              @valueUpdated="dobUpdated"/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">Post Code</label>
            <input
              v-model="form.address_postal_code"
              class="input-text"
              type="text"
              placeholder="Enter Post Code">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">House Address</label>
            <input
              v-model="form.address_line1"
              class="input-text"
              type="text"
              placeholder="Enter House Address">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">City/Town</label>
            <input
              v-model="form.address_city"
              class="input-text"
              type="text"
              placeholder="Enter City/Town">
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-12">
          <label class="form-label">Document Front</label>
          <div class="file-upload">
            <div
              v-if="front_url === ''"
              class="file-upload-text">
              <input
                id="front"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                class="d-none"
                @change="onFileChange">
              <img
                src="/img/upload_img_ic.svg"
                alt="myonline therapy"
                class="mb-2">
              <a @click="takeInput('front')"> click here</a> to browse from your computer
            </div>
            <div
              v-else
              class="file-upload-preview">
              <img
                :src="front_url"
                class="file-upload-preview__img" >
              <img
                class="file-upload-preview__delete-img"
                src="/img/delete_img_ic.svg"
                alt="myonline therapy"
                @click="reset('front')">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1">
          <label class="form-label">Document Back</label>
          <div class="file-upload">
            <div
              v-if="back_url === ''"
              class="file-upload-text">
              <input
                id="back"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                class="d-none"
                @change="onFileChange">
              <img
                src="/img/upload_img_ic.svg"
                alt="myonline therapy"
                class="mb-2">
              <a @click="takeInput('back')"> click here</a> to browse from your computer
            </div>
            <div
              v-else
              class="file-upload-preview">
              <img
                :src="back_url"
                class="file-upload-preview__img" >
              <img
                class="file-upload-preview__delete-img"
                src="/img/delete_img_ic.svg"
                alt="myonline therapy"
                @click="reset('back')">
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</template>
<script>
import DatePicker from '../../../common/DatePicker'
export default {
  name: 'AccountDetails',
  components: {
    DatePicker
  },
  props: {
    promoData: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    form: {
      first_name: '',
      last_name: '',
      address_line1: '',
      address_city: '',
      address_postal_code: '',
      dob: '',
        document_front: '',
        document_back: '',
      is_verified: false
    },
    front_url: '',
    back_url: '',
    currentFile: ''
  }),
  watch: {

  },
  created () {
    this.form = this.promoData
  },
  methods: {
    dobUpdated (date) {
      this.$emit('dobUpdated', date)
    },
    takeInput (inputId) {
      $('#' + inputId).trigger('click')
      this.currentFile = inputId
    },
    onFileChange (e) {
      const file = e.target.files[0]
      if (this.currentFile === 'front') {
        this.form.document_front = file
        this.front_url = URL.createObjectURL(file)
      } else {
        this.form.document_back = file
        this.back_url = URL.createObjectURL(file)
      }
    },
    reset (type) {
      if (type === 'front') {
        this.form.document_front = this.front_url = ''
        $('#front').val('')
      } else {
        this.form.document_back = this.back_url = ''
        $('#back').val('')
      }
    }
  }
}
</script>
<style scoped>

</style>
