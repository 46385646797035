<template>
  <div class="flex-display flex-row mb-3">
    <div class="flex-display flex-row w-100 align-items-center">
      <template v-if="activeView === 'read'">
        <div class="flex-display flex-row justify-content-space-between w-100">
          <div class="flex-display flex-column w-100 justify-content-center">
            <label class="switch-label m-0">Welcome video url</label>
          </div>
          <div class="flex-row flex-display justify-content-end w-100 align-items-center">
            <p class="m-0 pl-5 word-break">
              {{videoUrl}}
            </p>
            <img class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="edit">
          </div>
        </div>
      </template>
      <template v-else>
        <label class="switch-label m-0">Welcome video url</label>
        <input class="form-control ml-2 mr-1" v-model="videoUrl"/>
        <button class="btn" :disabled="disable" @click="updateVideoUrl">Update</button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TherapistVideo",
  props: {
    user_id: {
      required: true,
    },
    video: {
      required: true
    },
  },
  data() {
    return {
      activeView: "read",
      videoUrl: this.video,
      disable: true,
    }
  },
  methods: {
    edit() {
      this.activeView = "edit";
    },
    updateVideoUrl() {
      let loader = this.$loading.show();
      this.$http.put(this.url+"therapist/video/update", {
        therapist_id: this.user_id,
        video_url: this.videoUrl
      }).then(response => {
        loader.hide();
        this.activeView = "read";
        this.$toastr("success", response.data.message,"");
        this.$emit('update_therapist_profile', response.data.therapist_profile);
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message,"");
      });
    }
  },
    watch: {
        videoUrl(value) {
            value ? this.disable = false : this.disable = true;
        }
    }
}
</script>