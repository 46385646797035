<template>
  <div class="mt-5">
  <p class="bold-font w-100 mb-1 flex-display align-center mb-4 heading">
    {{ title }}</p>
  <div class="booking-details flex-display flex-row w-100 padding-left-40">
    <div class="flex-display flex-column w-50">
      <div class="detail-item mb-3 authorised-session align-center" v-if="!authorisedSession">
        <label class="item-label font-default fs-14 mb-0">Authorised: </label>

        <span class="item-value font-weight-bold pl-2 font-default fs-14"> {{ sessions.authorized }}</span>
          <div class="width-290">
              <span v-if="authorisedSessionEnable"
                    class="d-block mb-2">

                <span class="pl-2 pointer cursor-pointer">
                    <img src="/icons/planDetail/edit.svg" @click="authorisedModal" alt="myonlinetherapy" class="enable">
                    <div v-if="authorisedSessionEnable && authorisedSessionModal" class="mt-2 request-popover vue-popover dropdown-position-bottom">
                      <div class="flex-column">
                        <p class="blue-500 fs-14 mb-0">
                          {{ authorisedSessionMessage }}
                        </p>
                        <button class="got-it-btn fs-14 font-weight-bold mt-2" @click="authorisedSessionModal = false">Got it</button>
                      </div>
                    </div>
                </span>

              </span>
              <span v-else class="pl-2 pointer cursor-pointer" @click="editAuthorisedSession">
                  <img src="/icons/planDetail/edit.svg" alt="myonlinetherapy" class="disabled">
              </span>
          </div>
      </div>
      <div class="flex-display flex-row align-center mb-2" v-else>
        <label class="item-label fs-15 font-default fs-14">Authorised: </label>
        <div class="width-250 flex-display flex-row align-center width-206">
          <input type="text" class="input-select w-100 bg-white ml-2" v-model="sessions.authorized">

          <span class="pl-2 mb-2 save-btn font-semi-bold cursor-pointer" @click="updateAuthorisedSession">Save</span>
        </div>
      </div>
      <p class="detail-item">
        <label class="item-label font-default fs-14">Scheduled: </label>
        <span class="item-value font-weight-bold font-default fs-14"> {{ sessions.scheduled }}</span>
      </p>
      <p class="detail-item">
        <label class="item-label font-default fs-14">Remaining: </label>
        <span class="item-value font-weight-bold font-default fs-14"> {{ sessions.remaining }}</span>
      </p>
    </div>
    <div class="flex-display flex-column w-50">
      <div class="flex-display flex-column px-3 w-100">
        <p class="detail-item">
          <label class="item-label font-default fs-14">Completed:  </label>
          <span class="item-value font-weight-bold font-default fs-14"> {{ sessions.completed }} </span>
        </p>
        <p class="detail-item">
          <label class="item-label font-default fs-14">Incomplete: </label>
          <span class="item-value font-weight-bold font-default fs-14"> {{ sessions.incomplete }}</span>
        </p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Sessions",
  props: {
    sessions: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    planId:{
      type: Number,
      required:false
    },
    authorisedSessionMessage: {
      type: String
    },
    authorisedSessionEnable: {
      type: Boolean
    }
  },
  data() {
    return {
        authorisedSession: false,
        authorisedSessionModal: false
    }
  },
  created() {
    this.authorisedSessionModal = this.authorisedSessionEnable;
    this.authorisedSessionModal = this.authorisedSessionEnable;
  },
  methods: {
    authorisedModal() {
      this.authorisedSessionModal = true;
    },
    onClose() {
      this.$refs.popover.$emit('close')
    },
    editAuthorisedSession() {
      this.authorisedSession = this.authorisedSession === false;
    },
    updateAuthorisedSession() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "client/update-authorized-sessions", {
        plan_id: this.planId,
        authorized_sessions: this.sessions.authorized,
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit('get-plan-details');
        this.authorisedSession = this.authorisedSession !== true;
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
        this.authorisedSession = this.authorisedSession === false;
      });
    },

  }
}
</script>

<style scoped>
.heading {
  font-size: 18px !important;
  color: #283D47 !important;
  line-height: 18px;
}
.booking-details .detail-item {
  color: #283D47 !important;
}
.booking-details .detail-item .item-label {
  color: #283D47;
}
.save-btn {
  color: #3C9DD8 !important;
}
.authorised-session {
  display: flex;
}
.got-it-btn{
  float: right;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #2493C4;
}
.item-label{
  line-height: 14px !important;
  letter-spacing: .3px !important;
}
.item-value{
  line-height: 14px !important;
  letter-spacing: .3px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
