<template>
    <v-dialog v-model="show" persistent max-width="566px" min-height="569px" v-else>
        <v-card>
            <!-- Title -->
            <v-card-title class="pl-5 pt-5 pb-0 pr-0">
                <h1 class="model-heading">Indemnity insurance expiry date</h1>
                <img alt="close" class="close-model pt-4 pr-4" src="/icons/close_ic.svg" @click.stop="hide"/>
                <div class="divider"></div>
            </v-card-title>
            <!-- Content -->
            <v-card-text class="pl-5 pr-5 pb-0">
                <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                >
                    <div class="form-group flex-display flex-row width-200 align-center indemnity-expiry-date mb-0">
                        <date-picker class="p-0"
                                     placeholder=""
                                     id="indemnityExpiryDate"
                                     :org="true"
                                     :isMaxDateEnable="false"
                                     :date="indemnityExpiryDate"
                                     v-on:valueUpdated="updateIndemnityExpiryDate"
                        ></date-picker>
                    </div>
                </form>
            </v-card-text>
            <v-card-actions class="flex-display flex-row pb-5 pr-4">
                <v-spacer></v-spacer>
                <button class="btn btn-save pt-12 pr-12 mr-3" @click.stop="hide">{{ cancelButtonTitle }}</button>
                <button class="btn leave-submit" :disabled="isDisabled" @click="submitDate">{{ actionButtonTitle }}</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from '../../common/DatePicker'

export default {
    name: 'IndemnityCoverageDateModal',
    components: {
        DatePicker,
    },
    props: {
        userId: {
            required: true,
        },
        visible: {
            required: true,
            type: Boolean,
        },
        expiry_date: {
            required: false,
            default: new Date().toISOString().slice(0, 10),
        }
    },
    mounted() {
        if (this.expiry_date) {
            this.indemnityExpiryDate = this.expiry_date;
        }
    },
    data: () => ({
        modalTitle: 'Schedule leave',
        actionButtonTitle: 'Save',
        cancelButtonTitle: 'Cancel',
        disabled: false,
        expiryDate: false,
        indemnityExpiryDate: null,
    }),
    computed: {
        isDisabled() {
            return !this.indemnityExpiryDate;
        },

        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            },
        },
    },
    methods: {
        updateIndemnityExpiryDate(date) {
            this.indemnityExpiryDate = date;
        },
        submitDate() {
            let loader = this.$loading.show();
            this.$http.put(this.url + "therapist/indemnity-insurance/add", {
                therapist_id: this.userId,
                expiry_date: this.indemnityExpiryDate,
            }).then((response) => {
                this.$toastr("success", response.data.message, "");
                loader.hide();
                this.hide();
                this.$emit("refreshIndemnity");
            }).catch(error => {
                this.$toastr("error", error.response.data.message, "");
                loader.hide();
            })
        },

        hide() {
            this.$emit('hide')
        }
    },
}
</script>
<style scoped>
.btn.btn-save {
    width: auto;
    color: #308BC3 !important;
    background-color: #ffffff !important;
    border: 1px solid #308BC3 !important;
}

.btn.leave-submit {
    padding: 12px 24px;
    width: auto;
    height: auto;
    background: #2493C4;
}

.divider {
    position: static;
    width: 486px;
    height: 1px;
    left: 0;
    top: 48px;
    background: #F1F1F1;
    margin: 16px 0;
}

.leave-delete {
    color: #C50909;
    font-weight: 700;
}

.leave-text {
    color: #4A4A4A !important;
}

.v-text-field input {
    border: 1px solid #4A4A4A !important;
    padding: 0 6px !important;
    outline: none !important;
}

::placeholder {
    color: #4A4A4A !important;
}

.v-menu__content--fixed {
    top: 490px !important;
}

td button:disabled {
    background-color: #fff !important;
}
</style>
