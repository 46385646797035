<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            lazy
            full-width
            width="290px"
    >
        <template v-slot:activator="{ on }">
            <input v-if="org" v-model="date"
                   readonly
                   v-on="on"
                   :placeholder="placeholder"
                   class="input-text w-100"/>
            <v-text-field v-else
                          v-model="date"
                          readonly
                          v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker v-if="isMaxDateEnable" v-model="selectedDate" :header-color="primary" scrollable :max="nowDate">
            <v-spacer></v-spacer>
            <img v-if="enableReset"
                 @click="selectedDate=''"
                 src="/icons/delete_ic.png"
                 alt="clear"
                 width="20px"
                 class="pointer ml-2 mt--3"/>
            <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
        </v-date-picker>
        <v-date-picker v-else v-model="selectedDate" :header-color="primary" scrollable>
              <v-spacer></v-spacer>
              <img v-if="enableReset"
                   @click="selectedDate=''"
                   src="/icons/delete_ic.png"
                   alt="clear"
                   width="20px"
                   class="pointer ml-2 mt--3"/>
              <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="saveDate">OK</v-btn>
          </v-date-picker>
    </v-dialog>
</template>
<script>
    export default {
        name: "DatePicker",
        props: {
            date: {
                type: String,
                required: true
            },
            org: {
                default: false,
            },
            isMaxDateEnable: {
                default: true,
            },
            placeholder: {
                default: "Date",
            },
            enableReset: {
              default: false
            }
        },
        data() {
            return {
                modal: false,
                selectedDate: this.date,
                primary: '#1867c0',
                nowDate: new Date().toISOString().slice(0,10),
            }
        },
        methods: {
            valueUpdated() {
                this.$emit('valueUpdated', this.selectedDate);
            },
          saveDate(){
            this.$refs.dialog.save(this.selectedDate);
            this.$emit('saveDate', this.selectedDate,true);

          }
        },
        watch: {
            'selectedDate': function (value) {
                this.valueUpdated();
            }
        }
    }
</script>
<style scoped>
    table tbody tr{
        border: none !important;
    }
</style>
