let therapyHelper = {

    /* Session Statuses */
    sessionStatusReserved: 'reserved',
    sessionStatusBooked: 'scheduled',
    sessionStatusRescheduleRequest: 'reschedule request',
    sessionStatusRescheduled: 'rescheduled',
    sessionStatusCancellationRequest: 'cancellation request',
    sessionStatusCancelled: 'cancelled',
    sessionStatusComplete: 'complete',
    sessionStatusIncomplete: 'incomplete',
    sessionStatusSubscribed: 'subscribed',
    sessionStatusExpired: 'expired',

    /* Who Gets Paid */
    paidNA: 'N/A',
    paidThroughMOT: 'Through MOT',
    paidDirect: 'Direct',

    /* Invoice Statuses */
    invoiceStatusDraft: 'Draft',
    invoiceStatusDecisionRequired: 'Decision required',
    invoiceStatusInformationMissing: 'Information missing',
    invoiceStatusNew: 'New',
    invoiceStatusCancelled: 'Cancelled',
    invoiceStatusClaimWithOrg: 'Claim with org',
    invoiceStatusClientPaymentOutstanding: 'Client payment outstanding',
    invoiceStatusPaid: 'Paid',
    invoiceStatusWriteOffPayToPsychologist: 'Write-off (pay to psychologist)',
    invoiceStatusWriteOffClientOutstanding: 'Write-off (client outstanding)',

    /* Claim Statuses */
    claimStatusNA: 'N/A',
    claimStatusClientToPay: 'Client to pay',
    claimStatusPartiallyPaid: 'Partially paid',
    claimStatusPaidInFull: 'Paid in full',
    claimStatusRejected: 'Rejected',
    claimStatusRejectedPending: 'Rejected (pending)',

    /* Client Collection Statuses */
    collectionStatusNA: 'N/A',
    collectionStatusAmountOutstanding: 'Amount outstanding',
    collectionStatusPaidByClient: 'Paid by client',
    collectionStatusClientBadDebt: 'Client bad debt',

    /* Payment Statuses */
    paymentStatusNA: 'N/A',
    paymentStatusReadyToPayout: 'Ready to payout / settle',
    paymentStatusInExternalSystem: 'In external system',
    paymentStatusPaidToPsychologist: 'Paid to psychologist',
    paymentStatusInvoiceSent: 'Invoice sent',
    paymentStatusInvoiceSettled: 'Invoice settled',

    /* Session Type Methods */
    sessionTypeMethodVideo: 'video',
    sessionTypeMethodLiveChat: 'live_chat',
    sessionTypeMethodCoaching: 'daily_coaching',

    /*Payment Plan Types */
    planTypeSelfPay: "self_pay",
    planTypeInsurance: "insurance",
    planTypeNonInsurance: "non_insurance",
    planTypePackage: "package",
    planTypeSelfPayDiscounted: "self_pay_discounted",

    getSessionStatusList () {
        return [
            this.sessionStatusReserved,
            this.sessionStatusBooked,
            this.sessionStatusRescheduleRequest,
            this.sessionStatusRescheduled,
            this.sessionStatusCancellationRequest,
            this.sessionStatusCancelled,
            this.sessionStatusComplete,
            this.sessionStatusIncomplete,
            this.sessionStatusSubscribed,
            this.sessionStatusExpired
        ]
    },
    getReasonCodes() {
        return [
            'Client missed session',
            'Therapist missed session',
            'Platform issue'
        ]
    },
    getWhoGetPaidStatusList () {
        return [
            this.paidDirect,
            this.paidThroughMOT
        ]
    },
    getInvoiceStatusList () {
        return [
            this.invoiceStatusDraft,
            this.invoiceStatusNew,
            this.invoiceStatusDecisionRequired,
            this.invoiceStatusInformationMissing,
            this.invoiceStatusCancelled,
            this.invoiceStatusClaimWithOrg,
            this.invoiceStatusClientPaymentOutstanding,
            this.invoiceStatusPaid,
            this.invoiceStatusWriteOffPayToPsychologist,
            this.invoiceStatusWriteOffClientOutstanding,
        ]
    },
    getClaimStatusList () {
        return [
            this.claimStatusNA,
            this.claimStatusClientToPay,
            this.claimStatusPaidInFull,
            this.claimStatusPartiallyPaid,
            this.claimStatusRejected,
            this.claimStatusRejectedPending
        ]
    },
    getCollectionStatusList () {
        return [
            this.collectionStatusAmountOutstanding,
            this.collectionStatusPaidByClient,
            this.collectionStatusClientBadDebt
        ]
    }
}

export default therapyHelper
