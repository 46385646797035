<template>
  <v-dialog v-model="show" persistent max-width="1092px" min-height="500px">
    <v-card v-if="isEverythingReady">
      <!-- Title -->
      <v-card-title class="p-0">
        <h1 class="model-heading font-default plan-title mx-5 py-5">Plan ID:
          <span class="plan-title font-weight-bold">{{ rowData.id }}</span>
        </h1>
        <img class="close-model mx-5 py-4" src="/icons/close_ic.svg" slot="mot"
             alt="close" @click="hideModal"/>
      </v-card-title>
      <div class="mx-5 mb-5">
        <v-tabs class="plan" :class="[showTab === true ? 'therapy': 'self-care']" >
          <v-tab
                 v-for="tab of tabs"
                 class="w-50 plan-details"
                 :class="[tab.key,showTab === true ? 'd-block': 'd-none self-care']"
                 :key="tab.key"
          >
                  <span class="bg-red-100"
                        v-if="
                            (tab.key === 'details') ||
                            (tab.key === 'linked_sessions')
                        "
                  >&nbsp;</span>
            &nbsp; {{ tab.text }}
          </v-tab>
          <v-tab-item key="details">
            <therapy-plan-detail :plan="plan"
                                 :plan-id="rowData.id"
                                 v-on:get-plan-details="getPlanDetail"
                                 v-on:refresh-therapy-plan="refreshGetTherapyPlan"
                                 v-on:get-additional-session-request="getAdditionalSessionRequest"
                                 :defaultPlan="defaultPlan"
            ></therapy-plan-detail>
            <template v-if="showTab">
              <sessions :sessions="sessions"
                        :plan-id="rowData.id"
                        v-on:get-plan-details="getPlanDetail"
                        :authorisedSessionEnable="authorisedSessionEnable"
                        :authorisedSessionMessage="authorisedSessionMessage"
                        :title="'Sessions'">

              </sessions>
            </template>
            <template v-if="additionalRequestEnabled">
              <additional-session-request :title="'Additional session requests'"
                                          :additional-request-data="additionalRequestData"
                                          :additional-request-allowed="additionalRequestAllowed"
                                          :voucher-id="rowData.id"
                                          :user-voucher-id="rowData.user_voucher_id"
                                          :user_id="user_id"
                                          :currentDate="currentDate"
                                          :sessions="sessions"
                                          v-on:get-plan-session-detail="getPlanSessionDetail"
                                          v-on:get-additional-session-request="getAdditionalSessionRequest"
                                          :plan="plan"
                                          :additionalSessionBanner="additionalSessionBanner"
                                          :isRequestAllowedOnPlan="isRequestAllowedOnPlan"
              ></additional-session-request>
            </template>
          </v-tab-item>
          <v-tab-item key="linked_sessions">
            <linked-sessions :plan-id="rowData.id"></linked-sessions>
          </v-tab-item>
        </v-tabs>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import LinkedSessions from "@/components/main/planDetails/LinkedSessions";
import TherapyPlanDetail from "@/components/main/planDetails/TherapyPlanDetail";
import Sessions from "@/components/main/planDetails/Sessions";
import AdditionalSessionRequest
  from "@/components/main/planDetails/AdditionalSessionRequest";

export default {
  name: "PlanDetail",
  components: {
    AdditionalSessionRequest,
    Sessions,
    TherapyPlanDetail,
    LinkedSessions
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    rowData: {},
    user_id: {
      type: String
    }
  },
  data() {
    return {
      isEverythingReady: true,
      tabs: [
        {'key': 'details', 'text': 'DETAILS'},
        {'key': 'linked_sessions', 'text': 'LINKED SESSIONS'},
      ],
      plan: {},
      sessions: {},
      additionalRequestData: [],
      showTab: true,
      textForAdditionalSessions: "",
      additionalRequestEnabled: true,
      defaultPlan: false,
      currentDate: null,
      additionalSessionBanner: "",
      authorisedSessionEnable: false,
      authorisedSessionMessage: "",
      isRequestAllowedOnPlan: true,
      additionalRequestAllowed: true,

    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    }
  },
  created() {
    this.getPlanDetail();
    this.getPlanSessionDetail();
    this.getAdditionalSessionRequest();
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    getPlanDetail() {
      let loader = this.$loading.show()
      this.isEverythingReady = false
      this.$http.get(this.url + 'client/plan-details', {
        params: {
          plan_id: this.rowData.id,
        }
      }).then(response => {
        this.plan = response.data;
        this.showTab = this.plan.plan_type === 'therapy';
        this.defaultPlan = response.default_plan;
        this.isEverythingReady = true;
        loader.hide();

      }).catch(error => {
        loader.hide()
        this.isEverythingReady = true
        this.hideModal();
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },

    getPlanSessionDetail() {
      this.isEverythingReady = false
      this.$http.get(this.url + 'client/plan-sessions-count', {
        params: {
          plan_id: this.rowData.id,
        }
      }).then(response => {
        this.sessions = response.data;
        this.isEverythingReady = true
      }).catch(error => {
        this.hideModal();
        this.isEverythingReady = true
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },

    getAdditionalSessionRequest() {
      this.isEverythingReady = false
      this.$http.get(this.url + 'client/get-plan-session-requests', {
        params: {
          plan_id: this.rowData.id,
        }
      }).then(response => {
        this.additionalRequestData = response.data.additional_sessions;
        this.currentDate = response.data.current_date;
        this.additionalRequestEnabled = response.data.additional_request_enabled;
        this.additionalRequestAllowed = response.data.additional_request_allowed;
        this.isRequestAllowedOnPlan = response.data.is_request_allowed_on_plan;
        this.additionalSessionBanner = response.data.additional_session_banner;
        this.authorisedSessionEnable = response.data.authorised_request_status.authorised_session_enable;
        this.authorisedSessionMessage = response.data.authorised_request_status.authorised_session_message;
        this.isEverythingReady = true;
      }).catch(error => {
        this.isEverythingReady = true
        this.hideModal();
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },
  }
}
</script>

<style>
.self-care .v-tabs__wrapper {
  display: none;
}
.plan .v-tabs__wrapper {
  border-bottom: 1px solid #E9E9E9;
}

.plan-details {
  width: 195px !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding-bottom: 26px;
  border-bottom: 4px solid #E9E9E9;
}

.plan .v-tabs__slider-wrapper {
  width: 195px !important;
}

.plan .accent {
  background-color: #3A9DCE !important;
  border-color: #3A9DCE !important;
  border-bottom: 4px solid #3A9DCE;
}

.plan-title {
  color: #283D47 !important;
  font-size: 22px !important;
  line-height: 22px !important;
  font-weight: normal !important;
}

.plan-details .v-tabs__item--active {
  color: #3A9DCE !important;

}

</style>
