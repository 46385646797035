<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{modalTitle}}</h1>
        <img class="close-model" alt="close-icon" src="/icons/close_ic.svg" @click="hide"/>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <!-- title and type -->
          <v-layout wrap align-center class="justify-space-between">
            <form id="sessionTypeForm" class="w-100 flex-display flex-row flex-wrap flex-justify-between ">
              <input type="hidden" v-model="form.id" name="id"/>
              <input type="hidden" :value="actionType" name="form_type"/>

              <div class="form-group w-48">
                <label class="form-label">Session Title</label>
                <input name="title" v-model="form.title" class="input-text py-3" placeholder="Enter session title..">
              </div>

              <div class="form-group w-48">
                <label class="form-label">Use</label>
                <select name="use" class="input-select" v-model="form.use">
                  <option value="" disabled selected>Select Use</option>
                  <option value="initial">Initial</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="both">Both</option>
                  <option value="none">None</option>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Price</label>
                <input name="price" type="number" v-model="form.price" class="input-text" placeholder="e.g. 50"/>
              </div>

              <div class="form-group w-48 flex-justify-between">
                <label class="form-label">Duration (Minutes)</label>
                <input type="number" name="duration" v-model="form.duration" class="input-text" placeholder="e.g. 50"/>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Advance Notice(hours)</label>
                <input name="advance_notice_period" v-model="form.advance_notice_period" type="number" class="input-text" placeholder="e.g. 20"/>
              </div>

              <div class="form-group w-48 flex-justify-between">
                <label class="form-label mb-2">
                  Method
                  <select v-model="form.method" name="method" class="input-select">
                    <option value=""  disabled>Select Method&#45;&#45;</option>
                    <option value="video">Video</option>
                    <option value="live_chat">Live Chat</option>
                    <option value="daily_coaching">Daily Coaching</option>
                  </select>
                </label>
              </div>


              <div class="form-group w-48 flex-justify-between">
                <label class="form-label">
                  Type Of Therapy
                  <select v-model="form.type" name="type" class="input-select">
                    <option value=""  disabled>Select Type&#45;&#45;</option>
                    <option value="individual">Individual</option>
                    <option value="couples">Couples</option>
                    <option value="family">Family</option>
                  </select>
                </label>
              </div>
              <div class="form-group w-100">
                <textarea name="description" v-model="form.description" class="input-textArea py-3" rows="2" placeholder="Enter session description.."></textarea>
              </div>

              <!-- Charge setting -->
              <div class="w-100">
                <label class="form-label">Charge Settings</label>
                <div class="border-form-group w-100 px-3 py-3 flex-display flex-row flex-justify-between">
                  <div>
                    <label class="switch-label pr-3">Is Insurance Accepted</label>
                    <label class="switch">
                      <input type="checkbox" name="insurance_accepted" v-model="form.insurance_accepted" true-value="1" false-value="0">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Other Setting -->
              <div class="w-100 mt-1">
                <label class="form-label">Other Setting</label>
                <div class="border-form-group w-100 px-3 py-3">
                  <div class="flex-display flex-wrap justify-space-between">

                    <div>
                      <label class="switch-label pr-2 pl-2">Status</label>
                      <label class="switch">
                        <input type="checkbox" name="status" v-model="form.status" true-value="1" false-value="0">
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div>
                      <label class="switch-label pr-2 pl-2">Is Initial Assessment</label>
                      <label class="switch">
                        <input type="checkbox" name="is_initial_assessment" v-model="form.is_initial_assessment" true-value="1" false-value="0">
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div>
                      <label class="switch-label pr-2 pl-2">Default Active For Client</label>
                      <label class="switch">
                        <input type="checkbox" name="default_active" v-model="form.default_active" true-value="1" false-value="0">
                        <span class="slider round"></span>
                      </label>
                    </div>

                    <div>
                      <label class="switch-label pr-2 pl-2">Therapist controls: client booking permissions</label>
                      <label class="switch">
                        <input type="checkbox" name="default_active" v-model="form.manage_client_permission" true-value="1" false-value="0">
                        <span class="slider round"></span>
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitSessionType" >{{actionButtonTitle}}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Multiselect from "vue-multiselect"

export default {
  name: "addSessionTypeV2",
  components: {
    Multiselect
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: String
    },
    sessionTypes: {
      required: true,
      type: Array
    },
    value: null,
  },
  data() {
    return {
      modalTitle: "",
      actionButtonTitle: "",
      form: {
        id: "",
        title: "",
        description: "",
        use: "",
        duration: "",
        duration_type: "minutes",
        advance_notice_period: "",
        price: "",
        is_initial_assessment: 0,
        status: 1,
        insurance_accepted: 0,
        method: "",
        type: "",
        default_active: 0,
        manage_client_permission: 0,
      },
      errors: null
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      },
    },
  },
  created() {
    if (this.actionType === "add") {
      this.modalTitle = "Add Session Type";
      this.actionButtonTitle = "Add";
    } else {
      this.modalTitle = "Update Session Type";
      this.actionButtonTitle = "Update";
      this.form.id = this.value.id;
      this.editSessionType();
    }
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    submitSessionType() {
      let loader = this.$loading.show();
      this.$http({
        method: "post",
        url: this.url + "session-types/v2",
        data: this.form
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message);
        this.$emit("submitSessionType");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
    editSessionType() {
      let loader = this.$loading.show();
      this.$http(this.url + 'session-types/' + this.value.id + '/v2', {
        params: {
          id: this.value.id
        }
      }).then(response => {
        this.actionType = "edit";
        this.form = response.data.session;
        loader.hide();
      }).catch(error => {
        this.$toastr("error", error.response.data.message);
        loader.hide();
      });
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-group {
  margin: 5px 0 !important;
}
</style>
