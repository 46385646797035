<template>
  <div class="details-section client-top-section">
    <div class="header">
      <p class="title">{{ this.sectionTitle }}</p>
    </div>
    <div v-if="userPackagePermissions.length > 0" class="flex-display flex-column pa-3 w-100">
      <div class="toggle-switch ma-1"
           v-for="(packagePermission, index) in userPackagePermissions"
           :key="packagePermission.id">
        <template>
          <label class="switch-label w-170"> {{ packagePermission.title }} - {{ packagePermission.code }}</label>
          <label class="switch float-right">
            <input type="checkbox" v-on:change="changePackagePermission($event, packagePermission.id)"
                   v-model="packagePermission.is_supported" name="session_package_permission" true-value="1" false-value="0"
            >
            <span class="slider round"></span>
          </label>

        </template>
      </div>
    </div>
    <div v-else>
      <p class="mt-2 text-center">There are no session package yet for this User.</p>
    </div>
  </div>

</template>

<script>
export default {
  name: "sessionPackagePermissions",
  props: {
    userPackagePermissions: {
      required: true,
      type: Array
    },
    userId: {
      required: true,
    },
    sectionTitle: {
      type: String,
      default: "Therapy session packages",
    },
    userType: {
      type: String,
      default: ''
    }
  },

  methods: {
    changePackagePermission (event, sessionPackageId) {
      let loader = this.$loading.show()
      this.$http.put(this.url + "package-permissions", {
        user_id: this.userId,
        status: event.target.checked == true ? 1 : 0,
        session_package_id: sessionPackageId
      }).then(response => {
        loader.hide()
        this.$toastr("success", response.data.message, "")
      }).catch(error => {
        loader.hide()
        this.$toastr("error", error.response.data.message, "")
      })
    },
  }
}
</script>

<style scoped>
.input-text-payout {
  border: 1px solid #D4D4D4;
  border-radius: 3px;
  padding: 0 10px;
}
.w-170 {
  width: 170px !important;
}
</style>