<template>
  <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" alt="myonlinetherapy" src="/icons/close_ic.svg"
             @click.stop="hide"/>
      </v-card-title>

      <v-card-text>
        <div class="add-member-modal">
          <form>

            <div class="form-group">
              <label class="form-label">Code</label>
              <input class="input-text" v-model="confirmation_code"
                     placeholder="Enter Code">
            </div>
          </form>
          <div class="mt-2 text-center mb-2">
            <a class="pointer" @click="sendCode('phone')">Re-send Code</a>
            <p class="text-center text-dark m-1">OR</p>
            <a class="pointer" @click="sendCode('email')">Send By Email</a>
          </div>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <div v-if="actionType !== 'login'">
        <button class="btn" :disabled="( this.confirmation_code === '' )"
                @click="verifyMobile">Confirm Number
        </button>
        </div>
        <div v-else>
          <button class="btn" :disabled="( this.confirmation_code === '' )"
                  @click="verifyByLogin">Confirm Number
          </button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "confirmationCode",
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    user: {
      type: Object,
    },
    actionType: {
      type: String,
    }
  },
  data() {
    return {
      modalTitle: "Confirmation Code",
      code: null,
      disableAddButton: true,
      phone: this.user.phone,
      email: this.user.email,
      country_code: this.user.country_code,
      region: "",
      disable: true,
      error: "",
      code_sent: false,
      confirmation_code: "",
      send_by: "mobile",
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      },
    },
  },
  methods: {
    hide() {
      this.$emit("hide")
    },
    sendCode(send_by) {
      let loader = this.$loading.show()
      this.$http.put(this.url + "confirmation-code", {
        phone: this.phone,
        country_code: this.country_code,
        email: this.user.email,
        timezone: this.timezone,
        send_to: send_by,
        device_type: "web",
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, '');
        this.code_sent = true;
        this.modalTitle = "Confirmation Code"
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
    verifyMobile() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "mobile-verify-code", {
        user_id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        password: this.user.password,
        password_confirmation: this.user.confirmPassword,
        phone: this.user.phone,
        email: this.user.email,
        country_code: this.country_code,
        code: this.confirmation_code,
        timezone: this.timezone,
        device_type: "web",
        type: "phone",
        token: this.$route.query.token
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, '');
        this.$router.push("/login");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
    verifyByLogin() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "verify-by-code", {
        user: this.user,
        user_id: this.user.id,
        phone: this.user.phone,
        email: this.user.email,
        country_code: this.country_code,
        code: this.confirmation_code,
        timezone: this.timezone,
        device_type: "web",
        type: "phone"
      }).then(response => {
        loader.hide();
        localStorage.setItem("token",response.data.access_token);
        localStorage.setItem("password_expire_date",response.data.password_expire_date);
        localStorage.setItem("sb_access_token",response.data.sb_access_token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        this.setHeaderToken(response.data.access_token);
        if ( response.data.user.user_type === "APP_ADMIN" ) {
          this.$router.push("dashboard");
        }
        else {
          this.$router.push("clients");
        }

      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
  },
}
</script>

<style scoped>
.close-model {
  top: 10px !important;
}
</style>
