<template>
  <div class="mb-3">
    <div class="flex-display flex-row justify-space-between align-center">
      <label class="light-label org-label form-label m-r-8">{{ index+1 }}) </label>
      <label class="org-label form-label pt-0 m-r-8">Service Name</label>
      <input class="input-text width-200 border-radius-8"
             :class="{ 'error-border': showNameError }"
             type="text"
             placeholder="Name"
             :disabled="shouldDisable"
             v-model="charge.name"/>

      <label class="org-label form-label pt-0 m-r-8 m-l-25">Service Price</label>
      <input class="input-text width-65 border-radius-8"
             type="text"
             placeholder="£"
             :disabled="shouldDisable"
             :class="{ 'error-border': showPriceError }"
             v-model="charge.price"/>
      <div class="flex-display flex-row align-center m-l-18">
        <img v-if="showDeleteIcon"
             @click="$emit('delete-service-charge', index)"
             src="/assets/images/delete_ic.png"
             class="pointer"
             width="20"
             height="20"
             alt="MyOnlineTherapy">
      </div>
    </div>
  </div>
</template>

<script>
import regexHelper from "@/helpers/regexHelper";
import numberHelper from "@/helpers/numberHelper";

export default {
  name: "ServiceCharge",
  props: {
    charge: {
      required: true,
    },
    index: {
      default: 0,
    },
    showServiceChargesErrors: {
      default: false
    },
    showDeleteIcon: {
      default: false
    },
    shouldDisable: {
      default: false,
    }
  },
  computed: {
    showPriceError() {
      return this.showServiceChargesErrors && this.isEmpty(this.charge.price);
    },
    showNameError() {
      return this.showServiceChargesErrors && this.isEmpty(this.charge.name)
    },
  },
  methods: {
    isEmpty(value) {
      return numberHelper.isEmpty(value);
    },

  },
  watch: {
    'charge.price': function (newVal, oldVal) {
      newVal = regexHelper.filterNumericAndDecimalsOnly(newVal);
      if (newVal.includes('.')) {
        this.charge.price = newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2)
      }
    }
  }
}
</script>

<style scoped>
  .border-radius-8 {
    border-radius: 8px !important;
  }
</style>
