<template>
    <div class="multi-select-filter">
        <div class="dropdown" :id="id">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{selected.length > 0 ? 'Selected' : allItemsLabel}} <img src="/icons/dropdown_ic.svg">
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="menu-content">
                    <div class="menu-body">
                        <div class="search-container">
                            <div class="search-action" @click="searchItem">
                                <img src="/icons/search_ic.svg">
                            </div>
                            <input class="search-input"
                                   type="text"
                                   :placeholder="search.placeholder"
                                   v-model="search.searchQuery"
                                   v-on:keyup.enter="searchItem"
                            >
                        </div>
                        <div class="items-list" id="list_items" v-on:scroll="loadMore">
                            <a class="dropdown-item"
                               :key="'all'"
                               @click="clearSelection">
                                <label class="checkbox-container">
                                    {{allItemsLabel}}
                                </label>
                            </a>
                            <a class="dropdown-item"
                               v-for="(item,index) in items"
                               :key="index"
                               >
                                <label class="checkbox-container">
                                    <span>{{item.name}}</span>
                                    <input type="checkbox" :value="item.id" v-model="selectedItems">
                                    <span class="check-mark"></span>
                                </label>
                            </a>
                        </div>
                    </div>
                    <div class="menu-footer">
                        <button class="app-btn-delete mr-4" @click="clearSelection">Clear</button>
                        <button class="app-btn app-btn-primary" @click="applyFilter">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import stringHelper from '../../helpers/stringHelper';
    import arrayHelper from "../../helpers/arrayHelper";
    export default {
        name: 'MultiSelectFilter',
        props: {
            id: {
                type: String,
                required: true
            },
            allItemsLabel: {
                type: String,
                default: 'All'
            },
            items: {
                type: Array,
                required: true,
                default: []
            },
            selected: {
                type: Array,
                required: true,
                default: []
            }
        },
        mounted() {
            let id = this.id;
            $('.dropdown#' + id + ' .dropdown-menu').on('click', function (event) {
                event.stopPropagation();
            });
        },
        data() {
            return {
                search: {
                    placeholder: 'Search by name',
                    searchQuery: ''
                },
                selectedItems: !this.empty(this.selected) ? [...this.selected] : [],
            }
        },
        methods: {
            searchItem() {
                this.$emit('search', this.search.searchQuery);
            },
            clearSelection() {
                this.selectedItems = [];
            },
            applyFilter() {
                let selected = this.selectedItems;
                let id = this.id;
                $('.dropdown#' + id + ' .dropdown-menu').removeClass('show');
                this.$emit('apply', selected);
            },
            empty(string) {
                return stringHelper.empty(string);
            },
            loadMore() {
                const self = this;
                let container = document.getElementById('list_items');
                if ( ( container.clientHeight + container.scrollTop ) >= container.scrollHeight ) {
                    self.$emit('loadMore');
                }
            }
        }
    }
</script>

<style scoped>
    .dropdown-toggle::after {
        display:none;
    }
</style>
