<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading pt-4 pl-4">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text class="pt-0">
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="collectionForm"
            >
              <!-- Goal title -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Title</span>
                  <input class="input-text"
                         type="text"
                         name="name"
                         v-model="collection.name"
                         placeholder="Enter Title"
                  />
                </label>
              </div>

              <!-- Goal Description -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Description (90 character max)</span>
                  <input class="input-text"
                         type="text"
                         name="description"
                         v-model="collection.description"
                         placeholder="Enter Title"
                         maxlength=90
                  />
                </label>
              </div>

              <!--Intro audio title-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio title</span>
                  <input class="input-text"
                         type="text"
                         name="audio_title"
                         v-model="collection.intro.title"
                         placeholder="Enter audio title"
                  />
                </label>
              </div>

              <!--Intro audio description-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio description</span>
                  <input class="input-text"
                         type="text"
                         name="audio_description"
                         v-model="collection.intro.bottom_desc"
                         placeholder="Enter audio description"
                  />
                </label>
              </div>

              <!--Intro audio (portrait) -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio (portrait)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="collection.intro.portrait_url"
                         placeholder="Enter audio link"
                  />
                </label>
              </div>

              <!-- Intro audio (landscape)-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio (landscape)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="collection.intro.landscape_url"
                         placeholder="Enter audio link"
                  />
                </label>
              </div>

              <!--Intro video title-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video title</span>
                  <input class="input-text"
                         type="text"
                         name="video_title"
                         v-model="collection.how_to.title"
                         placeholder="Enter video title"
                  />
                </label>
              </div>

              <!--Intro video description-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video description</span>
                  <input class="input-text"
                         type="text"
                         name="video_description"
                         v-model="collection.how_to.bottom_desc"
                         placeholder="Enter video description"
                  />
                </label>
              </div>

              <!--Intro video thumbnail-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video thumbnail link</span>
                  <input class="input-text"
                         type="text"
                         name="video_thumbnail_link"
                         v-model="collection.how_to.thumbnail_url"
                         placeholder="Enter video Thumbnail link"
                  />
                </label>
              </div>


              <!--video (landscape)-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video (landscape)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="collection.how_to.video_url"
                         placeholder="Enter video link"
                  />
                </label>
              </div>

              <!--Keywords-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Keywords</span>
                  <keywords-component :keywords="collection.keywords"
                                      v-on:update="updateKeywords"
                  ></keywords-component>
                </label>
              </div>

              <!-- Select Skills for the collection -->

              <v-expansion-panel  v-model="panel" expand>
                <v-expansion-panel-content>
                  <template v-slot:actions>
                    <v-icon color="white">mdi-menu-down</v-icon>
                  </template>
                  <template v-slot:header>
                    <div class="neutral-dark-blue pointer fs-14 lh-14 font-weight-bold pb-2">Getting started</div>
                  </template>
                  <article>
                    <span class="form-label pt-2 pb-2">Skills</span>
                    <label class="neutral-dark-blue fs-14 lh-14 font-weight-bold pb-2 w-100">Simple select / dropdown</label>
                    <div class="form-group width-280 left ">
                      <multi-select v-model="collection.getting_started_skills"
                                    :options="skills"
                                    :allow-empty="false"
                                    :multiple="true"
                                    :taggable="false"
                                    :min="1"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select exercise"
                                    label="name"
                                    track-by="id"
                                    class="w-100">
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                    values.length
                                  }} options selected</span></template>
                      </multi-select>
                    </div>
                    <draggable class="w-48 right"
                               :list="collection.getting_started_skills">
                      <div class="keyword w-100" v-for="(skill,index) in collection.getting_started_skills" :key="skill.id">
                        {{ index + 1 }}
                        <i class="fa fa-arrows handle pt-2 pb-2"></i>
                        {{ skill.name }}
                      </div>
                    </draggable>
                  </article>
                </v-expansion-panel-content>

                <!-- Going deeper -->

                <v-expansion-panel-content>
                  <template v-slot:actions>
                    <v-icon color="white">mdi-menu-down</v-icon>
                  </template>
                  <template v-slot:header>
                    <div class="neutral-dark-blue pointer fs-14 lh-14 font-weight-bold pb-2">Going deeper</div>
                  </template>
                  <article>
                    <!-- Beginner skills -->
                    <span class="form-label pt-2 pb-2">Skills</span>
                    <label class="neutral-dark-blue fs-14 lh-14 font-weight-bold pb-2 w-100">Simple select / dropdown</label>
                    <div class="form-group w-48 left">
                      <multi-select v-model="collection.going_deeper_skills"
                                    :options="skills"
                                    :allow-empty="false"
                                    :multiple="true"
                                    :taggable="false"
                                    :min="1"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    placeholder="Select exercise"
                                    label="name"
                                    track-by="id"
                                    class="w-100">
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                    values.length
                                  }} options selected</span></template>
                      </multi-select>
                    </div>
                    <draggable class="w-48 right"
                               :list="collection.going_deeper_skills">
                      <div class="keyword w-100" v-for="(skill,index) in collection.going_deeper_skills" :key="skill.id">
                        {{ index + 1 }}
                        <i class="fa fa-arrows handle pt-2 pb-2"></i>
                        {{ skill.name }}
                      </div>
                    </draggable>
                  </article>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Status Toggle -->
              <div class="mt-4 w-48">
                <span class="form-label pt-2 pb-2">Status</span>
                <label class="switch-label font-weight-bold pr-3 fs-13 lh-13">Inactive/Active</label>
                <label class="switch m-l-93">
                  <input type="checkbox"
                         name="status"
                         v-model="collection.status"
                         :true-value="'active'"
                         :false-value="'inactive'"
                  />
                  <span class="slider round"></span>
                </label>
              </div>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row m-t-25">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button width-118 height-41" @click="submitCollection">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MultiSelect from "vue-multiselect"
import KeywordsComponent from "../common/KeywordsComponent"
import draggable from "vuedraggable"
import arrayHelper from "@/helpers/arrayHelper";

export default {
  name: "addCollection",
  components: {
    MultiSelect,
    KeywordsComponent,
    draggable,
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    actionType: {
      required: true,
      type: String
    },
    collections: {
      required: true,
      type: Array
    },
    skills: {
      required: true,
      type: Array
    },
    value: null
  },
  data: () => ({
    modelHeader: "",
    modalTitle: "",
    actionButtonTitle: "",
    panel: [true, false],
    collection: {
      name: "",
      status: "",
      intro: {
        title:"",
        bottom_desc:"",
        portrait_url: "",
        landscape_url: "",
      },
      how_to: {
        title:"",
        bottom_desc:"",
        video_url: "",
        thumbnail_url: "",
      },
      getting_started_skills: [],
      going_deeper_skills: [],
      visible: 0,
      description: "",
      keywords: [],
    }
  }),
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit("close")
        }
      },
    }
  },
  created () {
    if (this.actionType === "add") {
      this.modalTitle = "Add Collection"
      this.actionButtonTitle = "Save"
    } else {
      this.modalTitle = "Update Collection"
      this.actionButtonTitle = "Update"
    }


    if (this.value.id) {
      this.collection = {
        ...this.value,
        getting_started_skills: [...this.value.getting_started_skills],
        going_deeper_skills: [...this.value.going_deeper_skills],
      }


    }


  },
  methods: {
    hide () {
      this.$emit("hide")
    },
    submitCollection () {
      if (!this.validateSubmitRequest()) {
        return false;
      }

      let loader = this.$loading.show();

      this.$http.put(this.url + 'self_care/collection', {
        ...this.collection,
        getting_started_skills: this.collection.getting_started_skills.length > 0 ? arrayHelper.pluck(this.collection.getting_started_skills, 'id').toString() : null,
        going_deeper_skills: this.collection.going_deeper_skills.length > 0 ? arrayHelper.pluck(this.collection.going_deeper_skills, 'id').toString() : null
      }).then(response => {
        loader.hide();
        this.$toastr('success', response.data.message, '');
        this.$emit('submitCollection', response.data.collection);
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });

    },
    validateSubmitRequest () {
      if (this.collection.name === "") {
        this.showAlert("Please enter title.")
        return false
      }

      if (this.collection.description === "") {
        this.showAlert("Please enter description.")
        return false
      }

      if (this.collection.status === "") {
        this.showAlert("Please select status.")
        return false
      }

      if (this.collection.getting_started_skills.length <= 0 && this.collection.going_deeper_skills.length <= 0) {
        this.showAlert("Please add skills")
        return false
      }
      return true
    },
    showAlert (msg) {
      this.$toastr("error", msg)
    },
    updateKeywords (keywords) {
      this.collection.keywords = keywords
    },

  }
}
</script>
<style>

.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__header {
  padding: 0 !important;
  border-bottom: 1px solid #E4E4E4;
}
.v-expansion-panel__container {
  border-top: none;
}
.v-expansion-panel__header__icon .v-icon {
  background: #526670;
  border-radius: 50%;
}
</style>
