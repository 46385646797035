<template>
    <div class="tab_content clinical-assessment-main pa-3">
        <template v-if="clientTreatmentPlan">
            <div class="flex-display flex-row m-t-20 m-b-20 w-100">
                <div class="width-150 m-r-15">
                    <p class="width-150 fs-14 lh-20 text-gray mb-0 font-medium">Issue</p>
                </div>
                <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                    {{ clientTreatmentPlan.issues }}
                </p>
            </div>

            <div class="flex-display flex-row m-b-20 w-100">
                <div class="width-150 m-r-15">
                    <p class="width-150 fs-14 lh-20 text-gray mb-0 font-medium">Severity</p>
                </div>
                <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                    {{ clientTreatmentPlan.severity }}
                </p>
            </div>

            <div class="flex-display flex-row m-b-20 w-100" v-if="clientTreatmentPlan.is_icd_10_code">
                <div class="width-150 m-r-15">
                    <p class="width-150 fs-14 lh-20 text-gray mb-0 font-medium">ICD-10</p>
                </div>
                <template v-if="clientTreatmentPlan.icd_10_code !== ''">
                    <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                        {{ clientTreatmentPlan.icd_10_code }}
                    </p>
                </template>
                <template v-else>
                    <div @click="$emit('open-assessment-form', true)" class="pointer flex-display flex-row align-center">
                        <img class="height-24 width-24" src="https://assets.myonlinetherapy.com/images/icons/client-details/red-exclamation.png" alt="MyOnileTherapy"/>
                        <p class="mb-0 font-semi-bold red-500 m-l-12 break-all">Please enter an ICD-10 code</p>
                    </div>
                </template>
            </div>

            <div class="flex-display flex-row m-b-20 w-100">
                <div class="width-150 m-r-15">
                    <p class="width-150 fs-14 lh-20 text-gray mb-0 font-medium">No. of recommended sessions</p>
                </div>
                <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                    {{ clientTreatmentPlan.recommended_session_count }} <span class="font-regular">({{clientTreatmentPlan.recommended_session_text}})</span>
                </p>
            </div>

            <div class="flex-display flex-row m-b-20 w-100">
                <div class="width-150 m-r-15">
                    <p class="width-150 fs-14 lh-20 text-gray mb-0 font-medium">Proposal</p>
                </div>
                <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                    {{ clientTreatmentPlan.proposal }}
                </p>
            </div>

            <div class="m-b-20" v-if="isReviewAllowed && therapyOverviewWarning && therapyOverviewWarning.show_warning">
                <clinical-assessment-banner :type="'error'"
                                            :show-banner="true"
                                            :banner-text="therapyOverviewWarning.text"
                                            v-on:banner-clicked="warningClicked">
                </clinical-assessment-banner>
            </div>

        </template>
        <template v-else-if="isReviewAllowed">
        </template>
        <template v-else>
            <div class="w-100 review-height flex-display flex-row justify-center align-center">
                <clinical-assessment-banner :type="'info'"
                                            :show-banner="true"
                                            banner-text="Client has not completed first session yet.">
                </clinical-assessment-banner>
            </div>
        </template>
    </div>
</template>

<script>
import warningComponent from "@/components/common/warningComponent";
import clinicalAssessmentBanner from "@/components/people/client/clinicalAssessment/banner/clinicalAssessmentBanner";

export default {
    name: "treatmentPlan",
    props: {
        clientTreatmentPlan: {
            required: true,
        },
        isReviewAllowed: {
            default: true
        },
        therapyOverviewWarning: {
            required: true
        },
        clientId: {
            default: "",
        }
    },
    components: {
        warningComponent,
        clinicalAssessmentBanner,
    },
    methods: {
        warningClicked() {
            this.$emit('open-assessment-form', true);
            this.dismissTreatmentOverviewWarning();
        },
        dismissTreatmentOverviewWarning() {
            let loader = this.$loading.show();
            let formData = {};
            formData = {
              client_id: this.clientId,
              for: 'clients',
              device_type: this.deviceType,
              timezone: this.timezone,
            };
          this.$http.put(this.url + 'client/dismiss/treatment_overview_warning',formData
            ).then(response => {
                loader.hide();
                this.therapyOverviewWarning = response.data.therapy_overview_warning;
            }).catch(error => {
                loader.hide()
            });
        },
    }
}
</script>