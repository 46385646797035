<template>
    <div class="details-section introduction client-top-section">
      <div v-if="user.type === 'client'" class="header">
        <p class="title">{{ this.sectionTitle }}</p>
      </div>
      <div class="flex-display flex-column pa-3 w-100">
        <div class="text-center">
            <slot name="profile-image"></slot>
            <slot name="therapist-name"></slot>
        </div>
        <div class="text-center">
                <span >({{ user.username }})</span>
        </div>
        <div class="issue-send-bird-token">
            <a :href="false" @click="reissueSendBirdToken(user.id)">Reissue send-bird token</a>
        </div>
        <div class="mt-4">
            <slot name="therapist-designation"></slot>
            <slot name="client-therapy-status"></slot>
            <slot name="therapist-title"></slot>
            <slot name="therapist-gender"></slot>
            <slot name="setting-toggles"></slot>
            <slot name="fee-percentage"></slot>
            <slot name="xero-contact"></slot>
            <slot name="invoice-data"></slot>
            <slot name="therapist-bio"></slot>
            <slot name="email"></slot>
            <slot name="therapistVideo"></slot>
            <slot name="therapistVideoDuration"></slot>
            <slot name="therapistVideoMode"></slot>
            <slot name="therapistVideoStatus"></slot>
            <slot name="phone"></slot>
            <slot name="indemnityCoverage"></slot>
            <div class="flex-display flex-row justify-content-space-between w-100">
              <div class="flex-display flex-column justify-content-center w-100">
                <label class="switch-label m-0 color-fade">Date registered:</label>
              </div>
              <div class="flex-row flex-display justify-content-end w-100 align-items-center">
                <p class="m-0">
                  {{ user.registered_date }}
                </p>
              </div>
            </div>
          <p>
            <a v-if="user.type === 'therapist'" @click="showConfirmation">Unlink Stripe account</a>
          </p>
          <!-- delete dialog -->
          <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
              Are you sure you want to unlink the therapist’s Stripe account?
            </template>
            <template slot="desc">
              This should only be done if a therapist moves to a new country. Please ask the therapist to add their new bank details asap as if not done so, self-paying bookings will not be paid out to the therapist.
            </template>
            <template slot="actions">
              <a class="cancel-model" @click="hideConfirmation">No</a>
              <button class="btn modal-action-button confirmation-delete" @click="unlinkStripeAccount">Yes</button>
            </template>
          </confirmation>
          <slot name="client-risk"></slot>
          <slot name="client-region"></slot>
          <slot name="client-gender"></slot>
          <slot name="client-dob"></slot>

          <p v-if="user.type === 'client'" >
            <a @click="showNextToKin">Next of kin and GP contact details</a>
            <img class="cursor-pointer ml-3 " src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editNextToKin">
          </p>
        </div>
      </div>
        <!-- Add Issue -->
        <add-review v-if="addAssessmentDialog"
                    :visible="addAssessmentDialog"
                    :client="user"
                    v-on:addReview="addReview"
                    v-on:hide="hideAssessmentModal"
        >
        </add-review>
      <add-client-next-to-kin v-if="nextToKinDialog"
                              :visible="nextToKinDialog"
                              :isEditable="isEditable"
                              :client="user"
                              v-on:hide="hideNextToKinModal">

      </add-client-next-to-kin>
      </div>
</template>

<script>
    import AddReview from "./actions/AddReview";
    import Confirmation from "@/components/common/Confirmation";
    import AddClientNextToKin from "@/components/people/actions/AddClientNextToKin";
    export default {
        name: 'Introduction',
        components: {AddClientNextToKin, AddReview,Confirmation},
        props: {
            user: {
                type: Object,
            }
        },
        data() {
            return {
                defaultImage: '/img/default_user_img.svg',
                addAssessmentDialog: false,
                deleteDialog: false,
                sectionTitle: 'Summary and contact info',
                nextToKinDialog: false,
                isEditable: false,

            }
        },
      methods: {
            showAssessmentModal() {
                this.addAssessmentDialog = true;
            },
            hideAssessmentModal() {
                this.addAssessmentDialog = false;
            },
            showNextToKin() {
                this.nextToKinDialog = true;
                this.isEditable = false;
            },
            editNextToKin() {
                this.nextToKinDialog = true;
                this.isEditable = true;
            },
            hideNextToKinModal() {
                this.nextToKinDialog = false;
            },
            addReview(initial_assessment) {
                this.addAssessmentDialog = false;
                this.$emit('addReview', initial_assessment);
            },
            formatIssues(issues) {
                const self = this;
                let formattedIssues = [];
                let issuesArray = issues.split(',');
                issuesArray.forEach(function(issue) {
                    formattedIssues.push(self.capitalizeText(issue));
                });
                let issuesString = formattedIssues.join(', ');
                return issuesString.length > 30 ? issuesString.substring(0, 30) + '...' : issuesString;
            },
            capitalizeText(text) {
                if (typeof text !== 'string') return '';
                return text.charAt(0).toUpperCase() + text.slice(1)
            },
            reissueSendBirdToken(userId) {
                let loader = this.$loading.show();
                let self = this;
                this.$http.put(this.url+'send_bird_token/issue', {
                    user_id: userId,
                    device_type: self.deviceType,
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                });
            },
          unlinkStripeAccount(){
            let loader = this.$loading.show()
            this.$http.put(this.url + "therapist/unlink-stripe-account", {
              therapist_id: this.user.id,
            }).then(response => {
              loader.hide()
              this.hideConfirmation();
              this.$toastr("success", response.data.message, "");
            }).catch(error => {
              loader.hide()
              this.$toastr("error", error.response.data.message, "");
            })
          },
          hideConfirmation() {
            this.deleteDialog = false;
          },
          showConfirmation() {
            this.deleteDialog = true;
          },
        }
    }
</script>
