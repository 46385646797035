<template>
  <div class="flex-display flex-column gray-box ml-3 p-3 width-450 h-100 overflow-y-auto">
    <template v-if="collectionActions.length > 0">
        <p class="detail-item text-dark">
            COLLECTION ACTIONS
        </p>
        <template v-for="collectionAction in collectionActions">
            <p class="detail-item">
                <label class="item-label">{{ collectionAction.date }}: </label>
                <span class="item-value"> {{ collectionAction.action }}</span>
            </p>
        </template>
    </template>
    <template v-else>
        <div class="flex-display flex-column justify-center align-center h-100 w-100">
            <label class="item-label">No collection actions yet!</label>
        </div>
    </template>

  </div>
</template>

<script>
export default {
    name: "CollectionActions",
    props: {
        collectionActions: {
            default: null
        }
    }
}
</script>

<style scoped>
    .gray-box {
        background: #F2F2F2 !important;
        border-radius: 10px !important;
    }
    .item-label {
        color: #949494;
        font-family: MetropolisRegular;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 17px;
    }
</style>
