<template>
  <v-toolbar
    id="core-toolbar"
    flat
    prominent
    style="background: white;box-shadow: 0 0 4px 0 rgba(0,0,0,0.3) !important;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="tertiary--text font-weight-light"
      >
        <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <v-menu
          bottom
          left
          content-class="dropdown-menu"
          offset-y
          transition="slide-y-transition">
          <div
            v-ripple
            slot="activator"
            class="toolbar-items"
          >
            <v-badge
              color="error"
              overlap
            >
              <v-icon color="tertiary">mdi-account</v-icon>
            </v-badge>
          </div>
          <v-card>
            <v-list dense>
              <v-list-tile @click="profile">
                <v-list-tile-title>
                  Profile
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-list dense>
              <v-list-tile @click="logout">
                  <v-list-tile-title>
                    Logout
                  </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

import {
  mapMutations
} from 'vuex'

export default {
  data: () => ({
    title: null,
    responsive: false,
    responsiveInput: false
  }),

  watch: {
    '$route' (val) {
      this.title = val.name
    }
  },

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    logout() {
      this.$http.post(this.url+'logout').then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("password_expire_date");
        localStorage.removeItem("sb_access_token");
        localStorage.removeItem("user");
        this.$router.push('/login');
      });
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
    profile() {
      const path = '/profile'
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style>
  .v-menu__content {
    max-width: 20% !important;
  }
  #core-toolbar a {
    text-decoration: none;
  }
</style>
