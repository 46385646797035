<template>
  <div class="position-relative">
    <div class="d-flex justify-space-between align-center">
      <p class="mb-0 fs-16 bold-font grey-900">{{ name }}</p>
      <div
          class="clientInitial width-40 height-40 flex-row align-center justify-center bookingFlow__rounded-full bg-grey-600 position-absolute tw-th-right-0">
        <p class="text-center ma-auto fs-16 lh-20 mb-0 m-t-11 bold-font white--text">
          {{ nameInitials }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import StringHelper from "@/helpers/stringHelper";

export default {
  name: "clientInfoHeader",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name:"",
      nameInitials:""
    }
  },
  created() {
    this.name = this.client.full_name;
    this.nameInitials = StringHelper.userNameInitials(this.client);
  },
}
</script>
