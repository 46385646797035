<template>
    <div class="w-100">
        <div class="main-container mb-3">
            <div class="row mx-0">
                <div class="col-xs-6">
                    <label class="org-label switch-label pr-3 ml-5">Subscription or licence:</label>
                </div>
                <div class="col-xs-6" >
                    <select v-model="plan.billing_type" class="input-select" name="subscription_type"
                    >
                        <option :value="'license'">License</option>
                        <option :value="'subscription'">Subscription</option>
                    </select>
                </div>
            </div>
        </div>

        <template v-if="plan.billing_type === 'subscription'">
          <div class="main-container w-100">
            <div class="form-group w-100 flex-display flex-row">
              <label class="org-label form-label pt-0 w-auto white-space-pre">
                Subscription:
              </label>
              <select v-model="plan.pricing_id"
                      class="input-select m-l-12"
                      name="subscription">
                <template v-for="pricingPlan in plans[0].pricing">
                  <option :value="pricingPlan.id">{{ pricingPlan.title }}</option>
                </template>
              </select>
            </div>
          </div>

          <template v-for="pricingItem in pricingItems">
            <div class="main-container w-100">
              <div class="form-group w-100 flex-display flex-row align-center">
                <label class="org-label form-label pt-0 w-auto white-space-pre">
                  {{ capitalizeString(pricingItem.billing) }}:
                </label>
                <p class="mb-0 m-l-12">£ {{ pricingItem.discount_price }}</p>
              </div>
            </div>
          </template>
        </template>

    </div>
</template>

<script>

import StringHelper from '@/helpers/stringHelper'

export default {
    name: 'selfCarePlan',
    props: {
        selfCarePlan: {
            default: null
        },
        plans: {
            default: null
        },
    },
    created () {
        this.plan = {
            ...this.selfCarePlan
        }
    },

    data() {
        return {
            plan: null,
            pricingItems: null
        }
    },
  methods: {
    capitalizeString: StringHelper.capitalizeText,
    pricingModelChanged(val) {
      let pricingModel = this.plans[0].pricing.filter((p) => {
        return p.id === val;
      });
      this.pricingItems = pricingModel[0].self_care_pricing_items;
    }
  },
  watch: {
    'plan.billing_type': function(value) {
      this.$emit('planChanged', this.plan)

    },
    'plan.pricing_id': function (value) {
      this.pricingModelChanged(value);
      this.$emit('planChanged', this.plan)
    },
    selfCarePlan:{
      handler: function(val, oldVal){
        this.plan = {...val}
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
