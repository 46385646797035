<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Availability
            </p>
            <!--<a class="create-new" href="">New Slot</a>-->
        </div>
        <div class="content">

        </div>
    </div>
</template>

<script>
    export default {
        name: 'Availability'
    }
</script>