<template>
    <!-- delete dialog -->
    <confirmation v-if="deleteDialog" :visible="deleteDialog">
        <template slot="title">
            Are you sure?
        </template>
        <template slot="desc">
            This session will be cancelled. This will trigger a full refund to the client.
        </template>
        <template slot="actions">
            <a class="cancel-model" @click="hideCancelConfirmation">No</a>
            <button class="btn modal-action-button confirmation-delete" @click="cancelSession('approve')">Yes</button>
        </template>
    </confirmation>

    <!-- Save confirmation dialog -->
    <confirmation v-else-if="saveDialog" :visible="saveDialog">
        <template slot="title">
            Are you sure?
        </template>
        <template slot="desc">
            You wish to refund the client £{{ refundAmount }}?
        </template>
        <template slot="actions">
            <a class="cancel-model" @click="saveDialog = false">No</a>
            <button class="btn modal-action-button confirmation-delete" @click="saveSessionStatus()">Yes</button>
        </template>
    </confirmation>

    <!-- details modal -->
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px" v-else>
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modalTitle}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="closeModalOnCancel"/>
            </v-card-title>

            <v-card-text>

                <div class="booking-details">
                    <div class="detail-item">
                        <label class="item-label">Session types:</label>
                        <span class="item-value"> {{ rowData.session_type_v2.formatted_name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Client:</label>
                        <span class="item-value"> {{ rowData.client.full_name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Therapist:</label>
                        <span class="item-value"> {{ rowData.therapist.full_name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Session time:</label>
                        <span class="item-value"> {{ customDateFormat(rowData.slot.start_time, 'DD/MM/YYYY hh:mm A') }}</span>
                    </div>

                    <div class="detail-item" v-if="rowData.session_status === 'reschedule request' && rowData.reschedule_request">
                        <label class="item-label">Reschedule at:</label>
                        <span class="item-value"> {{ customDateFormat(rowData.reschedule_request[0].rescheduledAt, 'DD/MM/YYYY hh:mm A') }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Session price:</label>
                        <span class="item-value"> {{ rowData.formatted_price }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Payment method:</label>
                        <span class="item-value"> {{ rowData.payment_type }}</span>
                    </div>

                    <div class="detail-item">
                      <label class="item-label">Plan:</label>
                      <span class="item-value"> {{ rowData.org_plan_name }}</span>
                    </div>

                    <div class="detail-item flex-view mt-0 mb-2">
                      <label class="item-label col-4 m-0 p-0" for="sessionStatus">Session Status: </label>

                        <template v-if="rowData.session_status === 'cancelled'">
                            <span class="item-value" :class="{'highlighted': isTherapistRescheduleRequest(rowData)}"> {{ rowData.session_status }}</span>
                        </template>
                        <template v-else-if="reasonCodeExistsAlready && rowData.session_status === 'incomplete'">
                            <span class="item-value"> {{ rowData.session_status }}</span>
                        </template>
                        <template v-else>
                            <select class="input-select col-8"
                                    :class="{'highlighted': isTherapistRescheduleRequest(rowData)}"
                                    id="sessionStatus"
                                    name="sessionStatus"
                                    v-model="rowData.session_status"
                                    v-on:change="changeSessionStatus(rowData.session_status)"
                            >
                                <template v-for="status in sessionStatusValues">
                                    <option :value="status" :disabled="disableSessionStatus(status)" :hidden="disableSessionStatus(status)">{{ status }}</option>
                                </template>
                            </select>
                        </template>

                    </div>

                    <template v-if="rowData.session_status === 'incomplete'">
                        <div class="detail-item flex-view mb-2">
                            <label class="item-label col-4 m-0 p-0" for="reasonCode">Reason code: </label>
                            <template v-if="reasonCodeExistsAlready">
                                <span class="item-value"> {{ rowData.reason_code }}</span>
                            </template>
                            <template v-else>
                                <select class="input-select col-8"
                                        id="reasonCodes"
                                        name="reasonCode"
                                        v-model="rowData.reason_code"
                                        v-on:change="sessionStatusFlag = false"
                                >
                                    <template v-for="code in reasonCodesValues">
                                        <option :value="code">{{ code }}</option>
                                    </template>
                                </select>
                            </template>
                        </div>
                        <div v-if="checkRefund"
                             class="detail-item flex-view">
                            <label class="item-label col-4 m-0 p-0">Client refund: </label>
                            <template v-if="reasonCodeExistsAlready">
                                <span class="item-value"> {{ rowData.formatted_refund_amount }}</span>
                            </template>
                            <template v-else>
                                <input class="input-text col-8" type="number" name="refundAmount" v-model="refundAmount" />
                            </template>
                        </div>
                    </template>

                    <div class="detail-item" v-if="rowData.session_type_v2.method === methodVideo">
                        <a class="pointer" @click="openCallLogs">View Call Logs</a>
                    </div>


                </div>

            </v-card-text>

            <v-card-actions class="flex-display flex-row" v-if="(['scheduled', 'rescheduled', 'complete', 'subscribed', 'expired'].includes(rowData.session_status) || (rowData.session_status === 'incomplete' && rescheduleAndCancellationAllowed)) && sessionStatusFlag">
                <v-spacer></v-spacer>
                <button class="btn modal-action-button confirmation-delete" @click="showCancelConfirmation">Cancel Session</button>
                <button class="btn modal-action-button" @click="rescheduleSession" v-if="!['subscribed', 'expired'].includes(rowData.session_status)">Reschedule Session</button>
            </v-card-actions>
            <v-card-actions class="flex-display flex-row" v-else-if="['cancellation request', 'reschedule request'].includes(rowData.session_status) && sessionStatusFlag">
                <v-spacer></v-spacer>
                <button class="btn modal-action-button confirmation-delete" @click="submit('disapprove')">Disapprove</button>
                <button class="btn modal-action-button" @click="submit('approve')">{{actionButtonTitle}}</button>
            </v-card-actions>
            <v-card-actions class="flex-display flex-row" v-else-if="['reserved','scheduled', 'rescheduled', 'complete', 'incomplete', 'subscribed', 'expired', 'reschedule request', 'cancellation request','cancelled'].includes(sessionStatus) && !sessionStatusFlag">
                <v-spacer></v-spacer>
                <button v-if="rowData.session_status === 'incomplete' && checkRefund" class="btn modal-action-button" :disabled="disableSave()" @click="saveDialog = true">Save</button>
                <button v-else class="btn modal-action-button" :disabled="disableSave()" @click="saveSessionStatus()">Save</button>
                <button class="btn modal-action-button confirmation-delete" @click.stop="closeModalOnCancel">Cancel</button>
            </v-card-actions>
            <v-card-actions class="booking-details flex-display flex-row" v-else-if="rowData.session_status === 'incomplete' && !rescheduleAndCancellationAllowed && sessionStatusFlag">
                <v-spacer></v-spacer>
              <div class="detail-item">
                <label class="item-label">Note: this session can not be rescheduled or cancelled because money has already been refunded to the client and deducted from the therapist.</label>
              </div>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>
<script>
    import Confirmation from "../common/Confirmation";
    import therapyHelper from "@/helpers/app/therapyHelper";
    import routeHelper from "@/helpers/app/routeHelper";
    import numberHelper from '../../helpers/numberHelper'
    import stringHelper from '../../helpers/stringHelper'
    export default {
        name: "BookingDetail",
        components: { Confirmation },
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                modalTitle : 'Booking Detail',
                actionButtonTitle:'Approve',
                deleteDialog: false,
                editUrl: '',
                sessionStatusValues: therapyHelper.getSessionStatusList(),
                reasonCodesValues: therapyHelper.getReasonCodes(),
                sessionStatus:'',
                reasonCode:'',
                sessionStatusFlag: true,
                refundAmount: 0,
                saveDialog: false,
                reasonCodeExistsAlready: false,
                methodVideo: therapyHelper.sessionTypeMethodVideo,
                rescheduleAndCancellationAllowed: false,
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
            checkRefund() {
                return this.rowData && (
                    this.rowData.payment_type === 'card' ||
                    this.rowData.payment_type === 'in_app_purchase'
                ) && this.rowData.reason_code === 'Platform issue';
            },
            reasonCodeAdded() {
                return !stringHelper.empty(this.rowData.reason_code);
            }
        },
      created() {
          this.sessionStatus = this.rowData.session_status;
          this.reasonCode = this.rowData.reason_code;
          this.reasonCodeExistsAlready = !stringHelper.empty(this.rowData.reason_code);
          this.getBookingRelatedFlag();
      },
      methods:{
            hide(){
              this.$emit('hide')
            },
            showCancelConfirmation() {
                this.deleteDialog = true;
            },
            hideCancelConfirmation() {
                this.deleteDialog = false;
            },
            getBookingRelatedFlag() {
              let loader = this.$loading.show();
              this.$http.get(this.url + 'client/booking-details', {
                params: {
                  id: this.rowData.id
                }
              }).then(response => {

                this.rescheduleAndCancellationAllowed = response.data.is_admin_allowed_to_reschedule_and_cancel;
                loader.hide();
              }).catch(error => {
                loader.hide();
              });
            },
            cancelSession (action) {
                let loader = this.$loading.show();
                this.$http.put(this.url + routeHelper.cancelSessionApi, {
                    booking_id: this.rowData.id,
                    action: action,
                    user_type: 'admin',
                    timezone: this.timezone,
                    device_type: this.deviceType
                }).then(response => {
                  this.$toastr('success',response.data.message,'');
                  if (action === "approve") {
                    this.trackSessionCancellation(response.data.booking_id);
                  }
                  this.$events.fire('vuetable:reload');
                  this.$events.fire('session-request-responded');
                  this.$emit('refreshAfterCancel');
                  this.$emit('hide');
                  loader.hide();
                  this.$router.go();
                }).catch(error => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message,'');
                });
            },

            trackSessionCancellation(bookingId) {

              this.$http.get(this.url+'track_booking_event', {
                params: {
                  booking_id: bookingId
                }
              }).then(response => {

                let bookingData = response.data.data;

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                  'ecommerce': {
                    'refund': {
                      'actionField': {
                        'id': bookingData.transaction_id.toString(),   // Transaction ID. Required for purchases and refunds.
                      }
                    }
                  }
                });

                console.log("GTM event is done");


              }).catch(error => {
                console.log(error);
              });
            },

            rescheduleSession() {
                this.$emit('reschedule', this.rowData);
            },
            submit(action) {
                if ( this.rowData.session_status === 'cancellation request' ) {
                  action === 'approve' ? this.showCancelConfirmation() : this.cancelSession('disapprove');
                }
                else if ( this.rowData.session_status === 'reschedule request' ) {
                    this.respondRescheduleRequest(action);
                }
            },
            respondRescheduleRequest (action) {
                let self = this;
                let loader = self.$loading.show();
                self.$http.put(self.url + routeHelper.rescheduleSessionApi, {
                    booking_id: self.rowData.id,
                    action: action,
                    user_type: 'admin',
                    timezone: self.timezone,
                    device_type: self.deviceType
                }).then((response) => {
                    self.$toastr('success',response.data.message,'');
                    self.$events.fire('vuetable:reload');
                    self.$emit('hide');
                    loader.hide();
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, '');
                })
            },
            isTherapistRescheduleRequest(session) {
                const action = session.sessionStatusAndSetBy.split('|');
                return (session.session_status === 'reschedule request' && action[1] == 'therapist');
            },
            openCallLogs() {
              console.log('event triggered')
                this.$emit('openCallLogs')
            },
            changeSessionStatus(status) {
                this.sessionStatusFlag = false;
            },
            saveSessionStatus() {
              let loader = this.$loading.show();
                this.$http.put(this.url + routeHelper.sessionStatusUpdateApi, {
                    booking_id: this.rowData.id,
                    session_status: this.rowData.session_status,
                    reason_code: this.rowData.reason_code,
                    refund_amount: this.refundAmount,
                    user_type: this.userType,
                    timezone: this.timezone,
                    device_type: this.deviceType
                }).then((response) => {
                    this.$toastr('success', response.data.message, '')
                    this.$events.fire('vuetable:reload')
                    this.$emit('refreshAfterSave')
                    this.$emit('refreshAfterCancel')
                    this.$emit('hide')
                    loader.hide()
                }).catch((error) => {
                    loader.hide()
                    this.$toastr('error', error.response.data.message, '')
                })
            },
            closeModalOnCancel(){
              this.rowData.session_status = this.sessionStatus ;
              this.rowData.reason_code = this.reasonCode;
              this.$emit('hide')
            },
            disableSessionStatus(status){
              return status === 'cancelled' || status === 'cancellation request' || status === 'reschedule request' || status === 'rescheduled';
            },
            disableSave() {
                let disableButton = false;
                // Enable the save button
                // if session status is incomplete
                // And reason code is Platform issue
                // And Refund amount is greater than zero and less that or equal to session price
                if (this.rowData.session_status === 'incomplete') {
                    if (
                        this.checkRefund &&
                        this.rowData.reason_code === 'Platform issue'
                    ) {
                        disableButton = this.refundAmount <= 0 || this.refundAmount > parseFloat(this.rowData.price);
                    } else if(stringHelper.empty(this.rowData.reason_code)) {
                        // Enable the save button if a reason code is not empty and have value other than "Platform issue"
                        disableButton = true;
                    }
                }
                return disableButton;
            }
      },
        watch: {
            refundAmount(newVal, oldVal) {
                this.refundAmount = numberHelper.allowUptoTwoDecimalPoint(newVal);
            }
        },
    }
</script>
<style scoped>
    .pointer {
        color: #3a9dce !important;
    }
    .pointer:hover {
        color: #0056b3 !important;
    }
</style>
