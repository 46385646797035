<template>
  <div v-else class="bookingFlow__BoxWidth bookingFlow__w-60 ma-auto">
    <div class="bookingFlow__boxShadow flex-column  justify-start bg-white bookingFlow__padding">
      <client-info-header
          :client="client"
      ></client-info-header>
      <divider></divider>
      <h1 class="grey-900 bold-font lh-24 fs-16 ls-3 text-left ma-0 mb-3">
        Choose session type</h1>
      <div class="flex-column">
        <div class="mb-3 bookingFlow__sessionType" v-for="(sessionType, index) in sessionTypes">
          <div class="bookingFlow__flex align-center width-370 height-67 bookingFlow__w-60 bg-white pl-3"
               :class="{'opacity-5' : selectedSessionId !== sessionType.id}"
               :id="'session-selected-'+sessionType.id">
            <div class="bookingFlow__flex align-center">
              <label>
                <input type="radio" :id="'session_checked'+sessionType.id" :value="sessionType.id"
                       v-model="selectedSessionId" @input="update(sessionType, $event.target.value)"
                       class="option-input radio"/>
              </label>
              <span class="font-default mt-1 ml-3">{{ sessionType.formatted_title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="booking_button ma-auto m-t-80">
      <button
          class="d-inline-block white--text bg-blue-500  w-100 height-44 bold-font fs-16"
          :disabled="disabled" @click="continueBtn" :class="disabled ? 'opacity-5': 'bg-blue-500'">
        Confirm session type
      </button>
    </div>
  </div>

</template>

<script>
import ClientInfoHeader from "../components/clientInfoHeader";
import Divider from "../components/divider";
import MultiSelect from "vue-multiselect";

export default {
  name: "sessionType",
  components: {Divider, ClientInfoHeader, MultiSelect},
  props: {
    for: {
      type: String,
      default: "booking"
    },
    client: {
      type: Object,
      required: true
    },
    therapist: {
      required: true
    },
    sessionType:{
      required: false
    },
  },
  data() {
    return {
      sessionTypes: [],
      selectedSessionId: null,
      selectedSessionType: null,
      disabled: false,
    }
  },
  created() {
    this.selectedSessionId = this.sessionType.id;
    this.selectedSessionType = this.sessionType;
    this.getSessionTypes();
  },
  methods: {
    getSessionTypes() {
      this.errorMessage = '';
      let loader = this.$loading.show();
      this.$http.get( this.url+ 'therapy/session-types', {
        params: {
          for: this.for,
          client_id: this.client.id,
          user_id: this.therapist.id,
          timezone: "europe/london",
          device_type: this.deviceType
        }
      }).then(response => {
        this.sessionTypes = response.data.session_types;
        if (this.sessionTypes.length > 0) {
          if(!this.selectedSessionId){
            this.selectedSessionId = this.sessionTypes[0].id;
            this.selectedSessionType = this.sessionTypes[0];
          }
        }
        loader.hide();
      }).catch( error => {
        loader.hide();
        this.errorMessage = error.response.data.message;
        this.$toastr("error", this.errorMessage);

      })
    },

    continueBtn() {
      this.$emit("getSessionTypeId", this.selectedSessionType);
      this.$emit("increment");
    },
    update(key, value) {
      const booking_button = $('.booking_button');
      booking_button.hide();
      let selected_session_type = $("#session_checked"+value);
      let selected_session = $("#session-selected-"+value);
      if (selected_session_type.prop("checked") === true) {
        this.selectedSessionType = key;
        booking_button.show();
        $(".session-selected").addClass("opacity-5");
        selected_session.removeClass("opacity-5");
      }
    },
  },
  watch: {
    selectedSessionId() {
      this.disabled = !this.selectedSessionId;
    }
  },

}
</script>

