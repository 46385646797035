<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <div class="flex-display flex-row justify-content-space-between w-100">
                <div class="flex-display flex-column justify-content-center">
                    <label class="switch-label m-0">Gender</label>
                </div>
                <div class="flex-row flex-display justify-content-end w-100 align-items-center filters">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{selected}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item"
                               v-for="(gender,index) in genders"
                               :key="index"
                               @click="updateProfile(gender)" >{{gender.name}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "TherapistGender",
        props: {
            gender: {
                type: String,
            }
        },
        data() {
            return {
                selected: '',
                therapistGender: this.gender,
                genders:[
                    {name: 'Male', value: 'male'},
                    {name: 'Female', value: 'female'},
                ],
            }
        },
        mounted() {
            this.selected = this.gender.charAt(0).toUpperCase() + this.gender.slice(1);
        },
        methods: {
            updateProfile(gender) {
                this.selected = gender.name;
                this.$emit('updateProfile', gender.value, 'gender');
            },
        },
    }
</script>