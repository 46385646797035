<template>
  <div class="bookingFlow__flex py-3">
    <div>
      <img class="width-16" :src="alertIcon" alt="mot">
    </div>
    <div class="ml-2">
      <p class="fs-12 mb-0" v-html="msgText"
         :class="type === 'error' ? 'red-300' : 'grey-700'"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "alertBox",
  props: {
    type: {
      type: String,
      default: "error"
    },
    msgText: {
      type: String,
      required: true
    }
  },
  computed: {
    alertIcon: function () {
      return this.type === "error" ? "https://assets.care.myonlinetherapy.com/images/icons/booking/booking/warning_ic.svg" : "https://assets.myonlinetherapy.com/images/icons/booking/booking/info_grey_ic.svg";
    }
  }
}
</script>