<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400px"
    min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modal_title }}</h1>
        <img
          class="close-model"
          src="/icons/close_ic.svg"
          @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <template v-if="view === 1">
          <account-details
            :promo-data="form"
            @dobUpdated="dobUpdated"/>
        </template>
        <template v-else-if="view === 2">
          <bank-details
            :action="action"
            :promo-data="form"
            @decrement="decrement"/>
        </template>
        <template v-else>
          <promo-details
            :promo-data="form"
            @decrement="decrement"/>
        </template>
      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer/>
        <a
          class="cancel-model"
          @click="hide">Cancel</a>
        <button
          class="btn modal-action-button"
          @click="increment">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import AccountDetails from './views/PromoterAccountDetails'
import BankDetails from './views/PromoterBankDetails'
import PromoDetails from './views/PromoterPromoDetails'
export default {
  name: 'AddPromoter',
  components: { AccountDetails, BankDetails, PromoDetails },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    action: {
      default: 'add'
    },
    promoter: {
      default: null
    }
  },
  data: () => ({
    view: 1,
    modal_title: 'Account Details',
    actionButtonTitle: 'Next',
    form: {},
    resetForm: {
      first_name: '',
      last_name: '',
      dob: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      address_line1: '',
      address_city: '',
      address_postal_code: '',
      account_number: '',
      sort_code: '',
      bank: '',
      token: null,
      promo_code: null,
      sessions: null,
      discount: null,
      commission: null
    }
  }),
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  watch: {
    'form.discount': function (value) {
      if (value > 100) {
        this.form.discount = 100
      }
    },
    'form.commission': function (value) {
      if (value > 100) {
        this.form.commission = 100
      }
    }
  },
  created () {
    this.form = this.resetForm
    if (this.action === 'edit') {
      if (this.form) {
        this.form.first_name = this.promoter.first_name
        this.form.last_name = this.promoter.last_name
        this.form.name = this.promoter.name
        this.form.address_line1 = ' '
        this.form.address_city = ' '
        this.form.address_postal_code = ' '
        this.form.account_number = this.promoter.account_detail.account_number
        this.form.sort_code = this.promoter.account_detail.sort_code
        this.form.bank = this.promoter.account_detail.bank
        this.form.promo_code = this.promoter.promo_code.code
        this.form.sessions = this.promoter.promo_code.sessions
        this.form.discount = this.promoter.promo_code.discount
        this.form.commission = this.promoter.promo_code.commission
      }
      this.increment()
    }
  },
  methods: {
    hide () {
      this.$emit('hide')
    },
    increment () {
      if (this.validateForm()) {
        if (this.view < 3) {
          this.view++
          this.setViewData()
        } else {
          this.action === 'add' ? this.addPromoter() : this.updatePromoter()
        }
      }
    },
    decrement () {
      this.view--
      this.setViewData()
    },
    setViewData () {
      if (this.view === 1) {
        this.modal_title = 'Account Details'
        this.actionButtonTitle = 'Next'
      } else if (this.view === 2) {
        this.modal_title = 'Bank Details'
        this.actionButtonTitle = 'Next'
      } else {
        this.modal_title = 'Promotion Details'
        this.actionButtonTitle = this.action === 'add' ? 'Create' : 'Update'
      }
    },
    validateForm () {
      if (this.view === 1) {
        if (this.action === 'add') {
          if (!this.form.first_name || this.form.first_name === '') {
            this.$toastr('error', 'Please enter your first name.', '')
            return false
          }
          if (!this.form.last_name || this.form.last_name === '') {
            this.$toastr('error', 'Please enter your last name.', '')
            return false
          }
          if (!this.form.document_front || this.form.document_front === '') {
            this.$toastr('error', 'Please enter front of identity documents.', '')
            return false
          }
          if (!this.form.document_back || this.form.document_back === '') {
            this.$toastr('error', 'Please enter front of back documents.', '')
            return false
          }
        }
        if (!this.form.dob || this.form.dob === '') {
          this.$toastr('error', 'Please enter your date of birth.', '')
          return false
        }
        if (!this.form.address_line1 || this.form.address_line1 === '') {
          this.$toastr('error', 'Please enter your address.', '')
          return false
        }
        if (!this.form.address_city || this.form.address_city === '') {
          this.$toastr('error', 'Please enter your city/town.', '')
          return false
        }
        if (!this.form.address_postal_code || this.form.address_postal_code === '') {
          this.$toastr('error', 'Please enter your post code.', '')
          return false
        }
      } else if (this.view === 2) {
        if (this.action === 'edit') {
          if (!this.form.first_name || this.form.first_name === '') {
            this.$toastr('error', 'Please enter your first name.', '')
            return false
          }
          if (!this.form.last_name || this.form.last_name === '') {
            this.$toastr('error', 'Please enter your last name.', '')
            return false
          }
        }
        if (!this.form.account_number || this.form.account_number === '') {
          this.$toastr('error', 'Please enter account number.', '')
          return false
        }
        if (!this.form.sort_code || this.form.sort_code === '') {
          this.$toastr('error', 'Please enter sort code.', '')
          return false
        }
        if (!this.form.bank || this.form.bank === '') {
          this.$toastr('error', 'Please enter bank name.', '')
          return false
        }
      } else if (this.view === 3) {
        if (!this.form.promo_code || this.form.promo_code === '') {
          this.$toastr('error', 'Please enter promo code.', '')
          return false
        }
        if (!this.form.sessions || this.form.sessions === '' || this.form.sessions == 0) {
          this.$toastr('error', 'No. of sessions should be at least 1', '')
          return false
        }
        if (!this.form.discount || this.form.discount === '' || this.form.discount == 0) {
          this.$toastr('error', 'Discount should be at least 1%', '')
          return false
        }
        if (!this.form.commission || this.form.commission === '') {
            this.form.commission = 0;
        }
      }
      return true
    },
    addPromoter () {
      this.$emit('addPromoter', this.form)
    },
    updatePromoter () {
      this.$emit('updatePromoter', this.promoter.id, this.form)
    },
    dobUpdated (date) {
      this.form.dob = date
    }
  }
}
</script>
<style scoped>

</style>
