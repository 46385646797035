<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <template v-if="activeView === 'read'">
                <div class="flex-display flex-row justify-content-space-between w-100">
                    <div class="flex-display flex-column justify-content-center">
                        <label class="switch-label m-0">Designation</label>
                    </div>
                    <div class="flex-row flex-display justify-content-end w-100 align-items-center">
                        <p class="m-0 pl-5">
                            {{therapistDesignation}}
                        </p>
                        <img class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editDesignation">
                    </div>
                </div>

            </template>
            <template v-else>
                <label class="switch-label m-0">Designation</label>
                <input class="form-control ml-2 mr-1" v-model="therapistDesignation"/>
                <button class="btn" @click="updateProfile">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TherapistDesignation',
        props: {
            designation: {
                type: String,
            }
        },
        data() {
            return {
                activeView: 'read',
                therapistDesignation: this.designation,
            }
        },
        methods: {
            editDesignation() {
                this.activeView = 'edit';
            },
            updateProfile() {
                this.$emit('updateProfile', this.therapistDesignation, 'designation');
                this.activeView = 'read';
            }
        },
    }
</script>