<template>
  <div class="flex-display flex-row mb-3">
    <div class="flex-display flex-row w-100 align-items-center">
      <template v-if="activeView === 'read'">
        <div class="flex-display flex-row justify-content-space-between w-100">
          <div class="flex-display flex-column justify-content-center w-100">
            <label class="switch-label m-0 color-fade">Region:</label>
          </div>
          <div class="flex-row flex-display justify-content-end w-100 align-items-center">
            <p class="m-0">
              {{ clientRegion }}
            </p>
            <img
                class="ml-2 cursor-pointer"
                src="/icons/start_a_conversation_ic.svg"
                alt="edit"
                @click="editRegion">
          </div>
        </div>

      </template>
      <template v-else>
        <div class="flex-row flex-display justify-content-end w-100 align-items-center filters">
          <div class="flex-display flex-column justify-content-center w-100">
            <label class="switch-label m-0 color-fade">Region:</label>
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
              {{ clientRegion }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item"
                 v-for="(region,index) in regions"
                 :key="index"
                 @click="updateRegion(region)">{{ region.name }}
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientRegion',
  props: {
    user: {},
    regions: {},
  },
  data() {
    return {
      activeView: 'read',
      clientRegion: this.user.region,
    }
  },
  methods: {
    editRegion() {
      this.activeView = 'edit'
    },
    updateRegion(region) {
      this.clientRegion = region.name;
      this.$emit('updateRegion', this.clientRegion, 'region')
      this.activeView = 'read'
    },
  }
}
</script>
