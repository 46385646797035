<template>
  <v-layout wrap align-center class="justify-space-between" :class="plan.plan_type === 'therapy' ? 'mt-5': 'mt-2'">
    <div class="w-100">
      <div id="grey-header" class="w-100 therapy-detail min-height-263px border-gray-400 padding-left-40 py-5" >
        <div class="booking-details flex-display flex-row w-100">
          <div class="flex-display flex-column w-50">
            <p class="detail-item">
              <label class="item-label fs-14 font-default">Plan type: </label>
              <span class="item-value font-weight-bold fs-14 font-default"> {{ capitalizeString(plan.plan_type) }} </span>
            </p>
            <p class="detail-item">
              <label class="item-label fs-14 font-default">Plan name: </label>
              <span class="item-value font-weight-bold fs-14 font-default"> {{ capitalizeString(plan.plan_name) }}</span>
            </p>
            <p class="detail-item">
              <label class="item-label fs-14 font-default">Voucher type: </label>
              <span class="item-value font-weight-bold fs-14 font-default"> {{ capitalizeString(plan.voucher_type) }}</span>
            </p>
            <p class="detail-item">
              <label class="item-label fs-14 font-default">Activation date: </label>
              <span class="item-value font-weight-bold fs-14 font-default"> {{ plan.activation_date }}</span>
            </p>

            <p class="detail-item mb-0" v-if="!statusEdit">
              <label class="item-label fs-14 font-default mb-0">Status: </label>
              <span class="item-value font-weight-bold fs-14 font-default"> {{ plan.status }}</span>
              <span class="pl-2" @click="convertEditable">
                <img src="/icons/planDetail/edit.svg" alt="myonlinetherapy">
              </span>
            </p>

            <div class="flex-display flex-row align-center" v-else>
              <label class="item-label fs-14 font-default mb-0">Status: </label>
              <div class="width-250 flex-display flex-row align-center width-206">
                <select
                    id="planStatus"
                    v-model="plan.status"
                    class="input-select w-100 bg-white ml-2"
                    name="planStatus"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>

                <span class="pl-2 mb-2 save-btn font-semi-bold cursor-pointer" @click="changePlanStatus">Save</span>
              </div>
            </div>
          </div>

          <div class="flex-display flex-column w-50">
            <div class="flex-display flex-column px-3 w-100">
              <p class="detail-item">
                <label class="item-label fs-14 font-default">Organisation:  </label>
                <span class="item-value font-weight-bold fs-14 font-default"> {{ capitalizeString(plan.organization) }} </span>
              </p>
              <p class="detail-item">
                <label class="item-label fs-14 font-default">Voucher code: </label>
                <span class="item-value font-weight-bold fs-14 font-default"> {{ plan.voucher_code }}</span>
              </p>
              <p class="detail-item">
                <label class="item-label fs-14 font-default">Membership number: </label>
                <span class="item-value font-weight-bold fs-14 font-default"> {{ plan.membership_number }}</span>
              </p>
              <p class="detail-item">
                <label class="item-label fs-14 font-default">Expiry date:  </label>
                <span class="item-value font-weight-bold fs-14 font-default">{{ plan.expiry_date }}</span>
              </p>
              <p class="detail-item mb-0" v-if="!reasonCodeEdit">
                <label class="item-label fs-14 font-default mb-0">Inactive reason: </label>
                <span class="item-value font-weight-bold fs-14 font-default"> {{ plan.inactive_reason }}</span>
                <span class="pl-2 pointer" @click="convertReasonCode"><img src="/icons/planDetail/edit.svg" alt="myonlinetherapy"></span>
              </p>
              <div class="flex-display flex-row align-center" v-else>
                <label class="item-label fs-14 font-default mb-0">Inactive reason: </label>
                <div class="width-250 flex-display flex-row align-center width-206 mb-0">
                  <select
                      id="reasonCode"
                      v-model="plan.inactive_reason"
                      class="input-select w-100 bg-white ml-2"
                      name="reasonCode"
                  >
                    <option value="voucher_expired">Voucher expired</option>
                    <option value="policy_expired">Policy expired</option>
                    <option value="complete">Complete</option>
                    <option value="plan_change">Plan change</option>
                  </select>

                  <span class="pl-2 mb-2 save-btn font-semi-bold cursor-pointer" @click="changeInactiveReasonCode">Save</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import stringHelper from "@/helpers/stringHelper";

export default {
  name: "TherapyPlanDetail",
  props: {
    plan: {
      type: Object,
      required: true
    },
    planId:{
      type: Number,
      required:false
    },
    defaultPlan: {
      type: Boolean
    }
  },
  data() {
    return {
      status: "",
      statusEdit: false,
      reasonCodeEdit: false
    }
  },
  methods: {
    capitalizeString: stringHelper.capitalizeText,
    convertEditable() {
      this.statusEdit = this.statusEdit === false;
    },
    convertReasonCode() {
      this.reasonCodeEdit = this.reasonCodeEdit === false;
    },
    changePlanStatus() {
      if(this.plan.status === 'inactive'){
        this.plan.inactive_reason = 'plan_change'
      }else if(this.plan.status === 'active'){
        this.plan.inactive_reason = 'active'
      }
      let loader = this.$loading.show();
      this.$http.put(this.url + "update-therapy-plan-status", {
        id: this.planId,
        reason_code: this.plan.inactive_reason,
        plan_status: this.plan.status,

      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit('get-plan-details');
        this.$emit('refresh-therapy-plan');
        this.statusEdit = this.statusEdit !== true;
        this.reasonCodeEdit = this.reasonCodeEdit !== true;
        this.$emit('get-additional-session-request')
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
    });
    },
    changeInactiveReasonCode() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "client/update-inactive-reason-code", {
        plan_id: this.planId,
        reason_code: this.plan.inactive_reason,
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit('get-plan-details');
        this.$emit('refresh-therapy-plan');
        this.statusEdit = this.statusEdit !== true;
        this.reasonCodeEdit = this.reasonCodeEdit !== true;
        this.$emit('get-additional-session-request')
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
  }
}
</script>

<style scoped>
.therapy-detail {
  background: #F7F7F7;
  border: 1px solid #E9E9E9;
}
.booking-details .detail-item {
  color: #283D47 !important;
}
.booking-details .detail-item .item-label {
  color: #283D47;
  font-size: 14px;
}
.save-btn {
  color: #3C9DD8 !important;
}
.item-label {
  font-size: 14px;
}
.padding-left-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
