<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Clients
            </p>
            <a class="create-new" href="#" @click="showClientModal()">New Client</a>
        </div>
        <div class="content">
            <div id="configuration">
                <div class="item-container border-0"
                     v-for="(client, index) in clients"
                     :key="index"
                >
                    <p class="item-text">{{ client.full_name }}</p>
                    <div class="item-actions">
                        <img class="cursor-pointer" src="/icons/delete_payout_ic.svg" @click="deleteConfirmation(client.id)"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Client -->
        <add-client v-if="addClientDialog"
                    :visible="addClientDialog"
                    :selectedClients="getSelectedClientsIds(clients)"
                    v-on:addClient="addClient"
                    v-on:hide="hideClientModal"
                    :therapist_id = "user_id"
        >
        </add-client>

        <!-- delete dialog -->
        <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
                Are you sure?
            </template>
            <template slot="desc">
                This Client will be disconnected. This action cannot be undone.
            </template>
            <template slot="actions">
                <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                <button class="btn modal-action-button confirmation-delete" @click="removeClient">Delete</button>
            </template>
        </confirmation>
    </div>
</template>

<script>
    import AddClient from './actions/AddClient.vue';
    import Confirmation from '../common/Confirmation.vue';
    export default {
        name: 'Clients',
        components: {
            AddClient,
            Confirmation
        },
        props: {
            user_id: {
                type: [Number, String],
                required: true
            },
            clients: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                addClientDialog: false,
                deleteDialog: false,
                activeClient: '',
            }
        },
        methods: {
            deleteConfirmation(client_id) {
                this.deleteDialog = true;
                this.activeClient =  client_id;
            },
            hideConfirmation() {
                this.deleteDialog = false;
                this.activeClient = '';
            },
            removeClient() {
                this.deleteDialog = false;
                let self = this;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'therapist_connection', {
                    params: {
                        client_id: self.activeClient,
                        therapist_id: self.user_id,
                        user_type: 'therapist'
                    }
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                    self.$emit('updateClients', response.data.connections);
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                });
            },
            showClientModal() {
                this.addClientDialog = true;
            },
            hideClientModal() {
                this.addClientDialog  = false;
            },
            addClient(client_id) {
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+'therapist_connection', {
                    client_id: client_id,
                    therapist_id: self.user_id,
                    user_type: 'therapist'
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                    self.$emit('updateClients', response.data.connections);
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                });
            },
            getSelectedClientsIds(clients) {
                let selectedIds = clients.map( function(obj){
                    return obj.id;
                });
                return selectedIds;
            },
        }
    }
</script>