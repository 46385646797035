<template>
  <!-- details modal -->
  <v-dialog
    v-else
    v-model="show"
    persistent
    max-width="400px"
    min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img
          class="close-model"
          src="/icons/close_ic.svg"
          alt="mot"
          @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <vue-json-pretty
          :path="'res'"
          :data="rowData.response"
          @click="handleClick"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
    import VueJsonPretty from 'vue-json-pretty'
    export default {
        name: 'EmailDetail',
      components: {
        VueJsonPretty
      },
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                modalTitle: 'Mandrill Response'
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        methods: {
            hide () {
                this.$emit('hide')
            },
          handleClick () {

          }
        }
    }
</script>
