<template>
    <div class="dropdown" :class="dropdownClass">
        <div class="text" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="isDisabled ? 'disabled':''">
            <slot name="selected" />
        </div>
        <ul class="dropdown-menu" aria-labelledby="dLabel">
            <slot name="options" />
        </ul>
    </div>
</template>

<script>
    export default {
        props: [
            'dropdownClass',
            'isDisabled'
        ]
    }
</script>

<style>
    .dropdown {
        cursor: pointer;
        display: inline-block;
    }

    .dropdown-menu {
        margin: 0 auto;
        right: 0;
        padding: 0;
        min-width: 110%;
        width: auto;
    }

    .dropdown-item {
        text-align: center;
    }
</style>
