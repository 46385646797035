/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NProgress from 'nprogress';
import moment from 'moment';

// Routes
import paths from './paths'
import authHelper from "@/helpers/app/authHelper";
import userRoles from '../helpers/app/userRoleHelper'
import SignUp from '../views/SignUp';
import toastr from 'jquery/external/sizzle/dist/sizzle'
function route (path, view, name,meta) {
  return {
    name: name || view,
    meta,
    path,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle)
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([
    { path: '*', redirect: '/login' }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
    let isPasswordExpired = false;
    if (localStorage.getItem('password_expire_date')) {
        let startDate = moment(localStorage.getItem('password_expire_date'));
        let endDate = moment();

        if (endDate.diff(startDate, 'days') >= authHelper.adminPasswordValidity) {
            isPasswordExpired = true;
        }
    }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({ path: '/login',  params: { nextUrl: to.fullPath } })
    } else {
        if (isPasswordExpired && to.path !== "/expired-password-change") {
            if (from.path === "/expired-password-change") {
                next(false);
            } else {
                next({ path: '/expired-password-change'});
            }
        } else {
            next();
        }
    }
  } else if(to.matched.some(record => !record.meta.requiresAuth)) {
      if (localStorage.getItem('token') == null) {
          next()
      } else {
          var user = JSON.parse(localStorage.getItem('user'))
          if (user) {
              next({
                  path: '/dashboard'
              })
          }
      }
  } else {
    next()
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

Vue.use(Meta);

export default router
