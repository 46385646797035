<template>
  <div
    :class="'recipient-' + type + ' ' + ( type !== 'support-team' ? 'pointer ' : '' ) + ( remove_border ? 'border-0' : '' )"
    class="recipient "
    @click="openConversation">
    <div class="profile-image-container col-md-3">
      <span
        v-if="unreadMessage"
        class="slickdot slickdot-recipient-unread-message"/>
      <v-lazy-image
        v-if="profile_image"
        :src="profile_image"
        :src-placeholder="defaultUserImage"
        class="profile-image"
      />
      <img
        v-else
        :src="defaultUserImage"
        class="profile-image"
        alt="MOT">
    </div>
    <div class="profile-info-container col-md-9">
      <p
        :class="{'deleted': profile_header === 'Deleted User'}"
        class="profile-heading">
        {{ profile_header }}
        <span
          v-if="header_time !== ''"
          class="header-small">{{ getDateOnly(header_time) }} | {{ getTimeOnly(header_time) }}</span>
      </p>
      <p
        :class="{'deleted': profile_header === 'Deleted User'}"
        class="profile-sub-heading">{{ sanitizeText(profile_sub_header) }}</p>
      <p
        v-if="profile_text !== ''"
        class="profile-text">{{ profile_text }}</p>
    </div>
  </div>
</template>

<script>
    import sanitizeHtml from 'sanitize-html'
    export default {
        props: [
            'user_id',
            'defaultUserImage',
            'recipient',
            'type',
            'profile_image',
            'profile_header',
            'header_time',
            'profile_sub_header',
            'profile_text',
            'unreadMessage',
            'remove_border'
        ],
        data: function () {
            return {}
        },
        methods: {
            openConversation: function () {
                this.$emit('openConversation', this.recipient)
            },
            getDateOnly: function (timestamp) {
                return this.formatDate(timestamp)
            },
            getTimeOnly: function (timestamp) {
                return this.formatTime(timestamp)
            },
            sanitizeText (text) {
                return sanitizeHtml(text)
            }
        }
    }
</script>
<style scoped>
    .deleted {
        color: #A1A1A1 !important;
    }
</style>
