<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ headerTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="MyOnlineTherapy" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="w-100 flex-display flex-row flex-wrap flex-justify-between " id="pricingForm">
              <!-- Discount title -->

              <div class="w-100">
                <label class="w-100">
                  <span class="form-label">Discount Title</span>
                  <input type="text" name="discount_title" v-model="form.discount_title" class="input-text" placeholder="Enter title"/>
                </label>
              </div>

              <!-- Discount Description -->
              <div class="w-100">
                <label class="w-100">
                  <span class="form-label">Discount Description</span>
                  <textarea class="input-textArea form-group__input form-group__input--inputTextArea"
                            name="description"
                            v-model="form.discount_description"
                            placeholder="Enter Description..."
                            rows="4"
                  ></textarea>
                </label>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Pricing</label>
                <select :disabled="action === 'add'" name="use" class="input-select" v-model="form.pricing_id">
                  <option value="" disabled selected>Select Pricing</option>
                  <template v-for="p in pricing">
                    <option :value="p.id">{{ p.title }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Billing type</label>
                <select name="use" class="input-select" v-model="form.billing">
                  <option value="monthly">Monthly</option>
                  <option value="annual">Annual</option>
                </select>

              </div>

              <div class="form-group w-48">
                <label class="form-label">Currency</label>
                <select name="use" class="input-select" v-model="form.currency_id">
                  <option value="" disabled selected>Select Currency</option>
                  <template v-for="currency in currencies">
                    <option :value="currency.id">{{ currency.title }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Price</label>
                <input name="price" type="number" v-model="form.price" class="input-text" placeholder="e.g. 50"/>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Discounted Price</label>
                <input name="price" type="number" v-model="form.discount_price" class="input-text" placeholder="e.g. 50"/>
              </div>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitSelfCarePricingItem">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddSelfcarePricingItems",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    action: {
      required: true,
      default: "add"
    },
    value: {
      type: Object,
      default: null
    },
    preSelectedPricingId: {
      default: 0
    },
  },
  data () {
    return {
      isReady: false,
      pricing: null,
      currencies: null,
      headerTitle: 'Add Selfcare Pricing Item',
      actionButtonTitle: 'Add',
      form: {
        id: null,
        pricing_id: "",
        currency_id: "",
        discount_title: "",
        discount_description: "",
        billing: "monthly",
        price: 0.00,
        discount_price: 0.00,
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  created () {
    this.getSelfCarePricingItemFormData();
    if ( this.action === 'update' ) {
      this.headerTitle =  'Update Selfcare Pricing Item';
      this.actionButtonTitle = 'Update';
      this.form = {...this.value};
    }
  },
  methods: {
    getSelfCarePricingItemFormData() {
      let loader = this.$loading.show();
      this.disableActions = true;
      this.$http.get(this.url+'data/self-care-pricing-items', {
      }).then((response) => {
        loader.hide();
        this.pricing = response.data.pricing;
        this.currencies = response.data.currencies;
        this.form.pricing_id = this.preSelectedPricingId;
        this.isReady = true;
      }).catch((error) => {
        loader.hide();
        this.isReady = true;
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    hide() {
      this.$emit('hide');
    },
    validateSubmitRequest() {
      if (!this.form.pricing_id) {
        this.showAlert('Please select a pricing.');
        return false;
      }
      if (!this.form.currency_id) {
        this.showAlert('Please select a currency.');
        return false;
      }
      if (this.form.discount_title === '') {
        this.showAlert('Please enter discount title.');
        return false;
      }
      if (this.form.discount_description === '') {
        this.showAlert('Please enter a discount description.');
        return false;
      }
      if (this.form.price <= 0) {
        this.showAlert('Please enter price greater than zero.');
        return false;
      }
      if (this.form.discount_price <= 0) {
        this.showAlert('Please enter discount price greater than zero.');
        return false;
      }
      return true;
    },
    submitSelfCarePricingItem() {
      if (this.validateSubmitRequest()) {
        this.$emit('submitSelfCarePricingItem', this.form, this.action);
      }
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    }
  }
}
</script>
