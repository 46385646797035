<template>
  <div class="flex-row justify-start pointer" @click="$emit('warning-clicked')">
    <p v-if="labelText !== ''" class="light-grey mb-0">{{labelText}} </p>
    <img class="m-l-2 width-20 height-20" src="/icons/information-red.png" width="20" height="20" alt="myonlinetherapy"/>
    <p class="warning-color font-weight-medium m-l-5 mb-0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "warningComponent",
  props: {
    text: {
      required: true
    },
    labelText: {
      required: false,
      default: ""
    }
  }
}
</script>
<style>
.light-grey{
  color: #757575 !important;
}
.warning-color{
  color: #FF3A30 !important;
}
</style>