import moment from 'moment';
let timeHelper = {
    isToday(date) {
        let today = new Date();
        let selected = new Date(date);
        let todayDate = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
        let selectedDate = selected.getFullYear() + '-' + selected.getMonth() + '-' + selected.getDate();
        return todayDate === selectedDate;
    },
    isWeekend(date) {
        return ([0,6].indexOf(new Date(date).getDay()) !== -1)
    },
    firstDayOfCurrentWeek() {
        const today = moment();
        return today.startOf('week').add(1, 'day').format('YYYY-MM-DD');
    },
    lastDayOfCurrentWeek() {
        const today = moment();
        return today.endOf('week').add(1, 'day').format('YYYY-MM-DD');
    },
    addWeek(date, week) {
        return moment(date).add(week, 'week').format('YYYY-MM-DD');
    },
    formatDateTime(datetime, format) {
        return moment(datetime).format(format);
    },
    isOnLeave(date,leaveStartDate,leaveEndDate){
        let selected = new Date(date);
        selected.setHours(0,0,0,0)
        let start = new Date(leaveStartDate);
        let end = new Date(leaveEndDate);
        let selectedDate =moment(selected,"DD/MM/YYYY");
        let startDate = moment(start,"DD/MM/YYYY");
        let endDate = moment(end,"DD/MM/YYYY");
        return selectedDate.isBetween(startDate,endDate,null, '[]');

    }
};

export default timeHelper;