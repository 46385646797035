<template>
    <div class="filters">

        <create-custom-time-filters v-if="customTimeFilterModal"
                                    :visible="customTimeFilterModal"
                                    v-on:apply-custom-time-filter="applyCustomTimeFilter"
                                    v-on:hide="hideCustomTimeFilter"
        ></create-custom-time-filters>

        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{selected}}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item"
                   v-for="(filter,index) in filters"
                   :key="index"
                   @click="selectFilter(filter)" >{{filter.name}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateCustomTimeFilters from "./CreateCustomTimeFilters";
    export default {
        components: { CreateCustomTimeFilters },
        data () {
            return {
                selected:'',
                customTimeFilterModal: false,
                selectedFilterData: null
            }
        },
        props: {
            filters: {
                required: true
            },
            source: {
                type: String,
                default: null
            }
        },
        created(){
            let self = this;
             this.filters.filter(function(filter) {
                return filter.default === true;
            }).map(function (filter) {
                self.selected = filter.name;
                 return filter.name;
            });
        },
        methods: {
            selectFilter(filter) {
                if ( filter.name.toLowerCase() === 'custom' ) {
                    this.selectedFilterData = {
                        ...filter
                    };
                    this.showCustomTimeFilter();
                }
                else {
                    this.selected = filter.name;
                    this.$events.fire('filter-set', {
                        ...filter,
                        source: this.source
                    });
                }
            },
            showCustomTimeFilter() {
                this.customTimeFilterModal = true;
            },
            hideCustomTimeFilter() {
                this.customTimeFilterModal = false;
            },
            applyCustomTimeFilter(value1, value2) {
                const self = this;
                self.selectedFilterData.name = value1 + ' - ' + value2;
                self.selectedFilterData.constraints.value1 = value1;
                self.selectedFilterData.constraints.value2 = value2;
                if ( self.filters[0].constraints.type !== 'Date' ) {
                    self.filters.unshift(self.selectedFilterData);
                }
                else {
                    self.filters[0] = self.selectedFilterData;
                }
                self.selected = self.selectedFilterData.name;
                self.$events.fire('filter-set', self.selectedFilterData);
                self.hideCustomTimeFilter();
            }
        },
    }
</script>
<style>
    .dt-options-item {
        padding-left: 20px !important;
    }
    .selected-filter {
        left: 5px;
    }
    .dropdown-menu {
      max-height: 300px;
      overflow-y: auto;
    }
</style>
