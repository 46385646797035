<template>
    <div class="tab_content">
        <template v-if="displayCentralWarning">
            <div class="w-100 review-height flex-display flex-row justify-center align-center">
                <clinical-assessment-banner :type="getWarningType"
                                            :show-banner="true"
                                            :bannerHeading="outcomeMeasureBanner.heading"
                                            :banner-text="outcomeMeasureBanner.text">
                </clinical-assessment-banner>
            </div>
        </template>
        <template v-else-if="showClientOutcomeMeasure && isEverythingReady">
            <div class="flex-display flex-column pa-20 m-t-24 w-100 clinical-assessment-main pa-3">
                <clinical-assessment-banner :is-dismissible="true"
                                            :type="getWarningType"
                                            :show-banner="displayComponentWarning"
                                            :bannerHeading="outcomeMeasureBanner.heading"
                                            :banner-text="outcomeMeasureBanner.text"
                                            v-on:dismiss="outcomeMeasureBanner.show_warning = false">
                </clinical-assessment-banner>
                <!-- Goals Section -->
                <div class="flex-display flex-column mt-1">
                    <h1 class="fs-18 lh-22 m-b-20 grey-900">
                        {{ goalTitle }}
                    </h1>
                    <div class="flex-display flex-column m-b-12">
                        <p class="fs-14 lh-18 text-gray mb-1">
                            {{ goalInitialQuestions[0].question_title }}
                        </p>
                        <p class="fs-14 lh-18 mb-0 grey-900">
                            {{ goalInitialQuestions[0].answer }}
                        </p>
                    </div>
                    <div class="flex-display flex-column m-b-20">
                        <p class="fs-14 lh-18 text-gray mb-1">
                            {{ goalInitialQuestions[1].question_title }}
                        </p>
                        <div class="flex-column flex-row mt-3">
                            <div class="flex-display">
                                <p class="fs-14 lh-18 text-gray width-130">Initial score</p>
                              <p class="fs-14 lh-18 grey-900 bold-font">{{ goalInitialScore }}</p>
                            </div>
                            <div class="flex-display">
                              <p class="fs-14 lh-18 text-gray mb-0 width-130">Final score</p>
                              <p class="fs-14 lh-18 grey-900 bold-font mb-0">{{ goalFinalScore }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gray-hr"></div>

                <!-- Gad Section -->
                <div class="flex-display flex-column">
                    <h1 class="fs-18 lh-22 m-b-18 grey-900">
                        {{ gadTitle }}
                    </h1>
                    <div class="flex-display flex-column m-b-20">
                        <p class="fs-14 lh-18 text-gray mb-1 ">
                            Over the last two weeks, how often have you been bothered by any of the following problems?
                        </p>
                        <div class="flex-display flex-column w-100">
                          <div class="flex-display flex-row">
                            <p class="fs-14 lh-18 text-gray m-b-12 width-150" :class="isInitialPending ? '' : 'm-t-14'">Initial score</p>
                            <template v-if="isInitialPending">
                              <p class="fs-14 lh-18 grey-900 m-b-12 bold-font width-65">
                                {{ gadInitialScore }}
                              </p>
                            </template>
                            <template v-else>
                              <v-expansion-panel>
                                <v-expansion-panel-content>
                                  <template v-slot:header>
                                    <p class="fs-14 lh-18 grey-900 mb-0 bold-font">
                                      {{ gadInitialScore }}</p>
                                  </template>
                                  <v-card>
                                    <v-card-text>
                                      <div class="flex-display flex-column width-510">
                                        <template v-for="(question, index) in gadInitialQuestions">
                                          <div class="flex-display flex-row w-100">
                                            <p class="w-75 fs-14 lh-18 text-gray mb-0">
                                              {{ question.question_title }}
                                            </p>
                                            <p class="ml-2 w-25 fs-14 lh-18 grey-900 bold-font mb-0">
                                              {{ question.answer }}
                                            </p>
                                          </div>
                                          <div class="my-2"
                                               :class="[index <= 5 ? 'height-1 bg-grey-100' : 'height-2 bg-blue-900']"></div>
                                        </template>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </template>
                          </div>

                          <div class="flex-display flex-row">
                            <p class="fs-14 lh-18 text-gray mb-0 width-150" :class="outcomeMeasure.final_assessment.gad7.questions.length <= 0 ? '' : 'm-t-14'">Final score</p>
                            <template v-if="outcomeMeasure.final_assessment.gad7.questions.length <= 0">
                              <p class="fs-14 lh-18 grey-900 mb-0 bold-font width-65">
                                {{ gadFinalScore }}
                              </p>
                            </template>
                            <template v-else>
                              <v-expansion-panel>
                                <v-expansion-panel-content>
                                  <template v-slot:header>
                                    <p class="fs-14 lh-18 grey-900 mb-0 bold-font">
                                      {{ gadFinalScore }}</p>
                                  </template>
                                  <v-card>
                                    <v-card-text>
                                      <div class="flex-display flex-column width-510">
                                        <template v-for="(question, index) in gadFinalQuestions">
                                          <div class="flex-display flex-row w-100">
                                            <p class="w-75 fs-14 lh-18 text-gray mb-0">
                                              {{ question.question_title }}
                                            </p>
                                            <p class="ml-2 w-25 fs-14 lh-18 grey-900 bold-font mb-0">
                                              {{ question.answer }}
                                            </p>
                                          </div>
                                          <div class="my-2"
                                               :class="[index <= 5 ? 'height-1 bg-grey-100' : 'height-2 bg-blue-900']"></div>
                                        </template>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </template>
                          </div>

                        </div>
                    </div>
                </div>

                <div class="gray-hr"></div>

                <!-- PHQ Section -->
                <div class="flex-display flex-column">
                    <h1 class="fs-18 lh-22 m-b-20 grey-900">
                        {{ phqTitle }}
                    </h1>
                    <div class="flex-display flex-column m-b-20">
                      <p class="fs-14 lh-18 text-gray mb-1">
                            Over the last two weeks, how often have you been bothered by any of the following problems?
                        </p>
                        <div class="flex-display flex-column w-100">
                          <div class="flex-display flex-row">
                            <p class="fs-14 lh-18 text-gray m-b-12 width-150" :class="isInitialPending ? '' : 'm-t-14'">Initial score</p>
                            <template v-if="isInitialPending">
                              <p class="fs-14 lh-18 grey-900 m-b-12 bold-font width-65">
                                {{ phqInitialScore }}
                              </p>
                            </template>
                            <template v-else>
                              <v-expansion-panel>
                                <v-expansion-panel-content>
                                  <template v-slot:header>
                                    <p class="fs-14 lh-18 grey-900 mb-0 bold-font">
                                      {{ phqInitialScore }}</p>
                                  </template>
                                  <v-card>
                                    <v-card-text>
                                      <div class="flex-display flex-column width-510">
                                        <template v-for="(question, index) in phqInitialQuestions">
                                          <div class="flex-display flex-row w-100">
                                            <p class="w-75 fs-14 lh-18 text-gray mb-0">
                                              {{ question.question_title }}
                                            </p>
                                            <p class="ml-2 w-25 fs-14 lh-18 grey-900 bold-font mb-0">
                                              {{ question.answer }}
                                            </p>
                                          </div>
                                          <div class="my-2"
                                               :class="[index <= 7 ? 'height-1 bg-grey-100' : 'height-2 bg-blue-900']"></div>
                                        </template>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </v-expansion-panel>

                            </template>
                          </div>
                          <div class="flex-display flex-row">
                            <p class="fs-14 lh-18 text-gray mb-0 width-150" :class="outcomeMeasure.final_assessment.phq9.questions.length <= 0 ? '' : 'm-t-14'">Final score</p>
                            <template v-if="outcomeMeasure.final_assessment.phq9.questions.length <= 0">
                              <p class="fs-14 lh-18 grey-900 mb-0 bold-font width-65">
                                {{ phqFinalScore }}
                              </p>
                            </template>
                            <template v-else>
                              <v-expansion-panel>
                                <v-expansion-panel-content>
                                  <template v-slot:header>
                                    <p class="fs-14 lh-18 grey-900 mb-0 bold-font">
                                      {{ phqFinalScore }}</p>
                                  </template>
                                  <v-card>
                                    <v-card-text>
                                      <div class="flex-display flex-column width-510">
                                        <template v-for="(question, index) in phqFinalQuestions">
                                          <div class="flex-display flex-row w-100">
                                            <p class="w-75 fs-14 lh-18 text-gray mb-0">
                                              {{ question.question_title }}
                                            </p>
                                            <p class="ml-2 w-25 fs-14 lh-18 grey-900 bold-font mb-0">
                                              {{ question.answer }}
                                            </p>
                                          </div>
                                          <div class="my-2"
                                               :class="[index <= 7 ? 'height-1 bg-grey-100' : 'height-2 bg-blue-900']"></div>
                                        </template>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-expansion-panel-content>
                              </v-expansion-panel>

                            </template>
                          </div>
                        </div>

                    </div>
                </div>

            </div>
        </template>

    </div>
</template>

<script>
import clinicalAssessmentBanner from "@/components/people/client/clinicalAssessment/banner/clinicalAssessmentBanner";
export default {
    name: "outcomeMeasures",
    props: {
        clientId: {
          required: true
        },
    },
    components: {
        clinicalAssessmentBanner
    },
    data() {
        return {
          outcomeMeasure: null,
          showClientOutcomeMeasure: false,
          outcomeMeasureBanner: {
            heading: "",
            text: "",
            type: "",
            color: "",
            show_warning: false,
          },
            isInitialPending: true,
            isFinalPending: true,

            goalInitialQuestions: [],
            goalFinalQuestions: [],
            gadInitialQuestions: [],
            gadFinalQuestions: [],
            phqInitialQuestions: [],
            phqFinalQuestions: [],

            goalInitialScore: "N/A",
            goalFinalScore: "N/A",
            gadInitialScore: "N/A",
            gadFinalScore: "N/A",
            phqInitialScore: "N/A",
            phqFinalScore: "N/A",

            goalTitle: "",
            gadTitle: "",
            phqTitle: "",

            isEverythingReady: false
        }
    },
    created() {
      this.getOutComeMeasure();
    },
    computed: {
    displayCentralWarning() {
      return this.outcomeMeasureBanner.show_warning &&
          (
              this.outcomeMeasureBanner.type === "not_applicable" ||
              this.outcomeMeasureBanner.type === "not_completed"
          );
    },
    displayComponentWarning() {
      return this.outcomeMeasureBanner.show_warning &&
          (
              this.outcomeMeasureBanner.type === "initial" ||
              this.outcomeMeasureBanner.type === "final"
          );
    },
    getWarningType() {
      let bannerType = "";
      if (this.outcomeMeasureBanner.type === "not_applicable") {
        bannerType = "info";
      } else if (this.outcomeMeasureBanner.type === "not_completed") {
        bannerType = "error";
      } else {
        bannerType = "warning";
      }
      return bannerType;
    }

  },
    methods: {
        getOutComeMeasure() {
          this.$http.get(this.url + "client/outcome-measure", {
            params: {
              timezone: this.timezone,
              device_type: this.deviceType,
              client_id: this.clientId
            }
          }).then(response => {
            this.outcomeMeasure = response.data.outcome_measure;
            this.showClientOutcomeMeasure = response.data.show_outcome_measure;
            this.outcomeMeasureBanner = response.data.outcome_measure_banner;
            if (this.showClientOutcomeMeasure){
              this.initializeQuestions();
              this.initializeScores();
              this.initializeTitles();
            }
            this.isEverythingReady = true;

          }).catch(error => {
            this.$toastr("error", error.response.data.message, "");
          });
        },
        initializeQuestions() {
            if (this.outcomeMeasure) {
                this.isInitialPending = this.outcomeMeasure.is_initial_assessment_pending;
                this.isFinalPending = this.outcomeMeasure.is_final_assessment_pending;

                this.goalInitialQuestions = this.outcomeMeasure.initial_assessment.client_goals.questions;
                this.goalFinalQuestions = this.outcomeMeasure.final_assessment.client_goals.questions;

                this.gadInitialQuestions = this.outcomeMeasure.initial_assessment.gad7.questions;
                this.gadFinalQuestions = this.outcomeMeasure.final_assessment.gad7.questions;

                this.phqInitialQuestions = this.outcomeMeasure.initial_assessment.phq9.questions;
                this.phqFinalQuestions = this.outcomeMeasure.final_assessment.phq9.questions;
            }
        },
        initializeScores() {
            if (!this.isInitialPending) {
                let goalInitialScoreQuestion = this.getGoalScoreQuestion(this.goalInitialQuestions, 'initial');
                this.goalInitialScore = goalInitialScoreQuestion.length > 0
                    ? goalInitialScoreQuestion[0].answer
                    : "N/A";
                this.gadInitialScore = this.outcomeMeasure.initial_assessment.gad7.score;
                this.phqInitialScore = this.outcomeMeasure.initial_assessment.phq9.score;
            }
            if (!this.isFinalPending) {
                let goalFinalScoreQuestion = this.getGoalScoreQuestion(this.goalFinalQuestions, 'final');
                this.goalFinalScore = goalFinalScoreQuestion.length > 0
                    ? goalFinalScoreQuestion[0].answer
                    : "N/A";
                this.gadFinalScore = this.outcomeMeasure.final_assessment.gad7.questions.length > 0
                    ? this.outcomeMeasure.final_assessment.gad7.score
                    : "N/A";
                this.phqFinalScore = this.outcomeMeasure.final_assessment.phq9.questions.length > 0
                    ? this.outcomeMeasure.final_assessment.phq9.score
                    : "N/A";
            }
        },
        initializeTitles() {
            this.goalTitle = this.outcomeMeasure.initial_assessment.client_goals.title;
            this.gadTitle = this.outcomeMeasure.initial_assessment.gad7.title;
            this.phqTitle = this.outcomeMeasure.initial_assessment.phq9.title;
        },
        getGoalScoreQuestion(questions, type) {
            return questions.filter((question) => {
                return question.type === type && question.is_score_question === true;
            });
        }
    }
}
</script>


