<template>
    <div>
        <div class="datatable-wrapper w-100">
            <vue-table ref="vuetable"
                    :api-mode="false"
                    :css="css.table"
                    :fields="tableFields"
                    :data="tableData"
                    >
            </vue-table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import momentTimezone from 'moment-timezone'
    import VueTable from 'vuetable-2/src/components/Vuetable'
    import RecordDetails from './RecordDetails.vue';
    import Vue from 'vue'
    import SwitchVitalityHealthPlan from '@/components/common/dataTable/users/SwitchVitalityHealthPlan'
    import stringHelper from '@/helpers/stringHelper'
    Vue.component('record-details', Vue.extend(RecordDetails), {detailEvent: 'open-booking-detail'});
    Vue.component('switch-plans', SwitchVitalityHealthPlan);


    export default {
        name: "DataTableNoApi",
        props: {
            fields: {
                type: Array,
                required: true
            },
            data:{
                type: Array,
                required: true
            },
            isDetail: {
                type: Boolean,
                default: false
            },
            doSwitchPlans: {
              type: Boolean,
              default: false
            }
        },
        components:{
            VueTable,
        },
        data() {
            return {
                tableFields: [],
                tableData: [],
                css:{
                    table: {
                        tableClass: 'table',
                        ascendingIcon: 'glyphicon glyphicon-chevron-up',
                        descendingIcon: 'glyphicon glyphicon-chevron-down'
                    },
                    paginationCss: {
                        wrapperClass: 'pagination floated right',
                        activeClass: 'active',
                        disabledClass: 'disabled',
                        pageClass: 'item',
                        linkClass: 'icon item',
                        paginationClass: 'ui bottom attached segment grid',
                        paginationInfoClass: 'left floated left aligned six wide column',
                        dropdownClass: 'ui search dropdown',
                        icons: {
                            prev: 'fa fa-chevron-left',
                            next: 'fa fa-chevron-right',
                        }
                    },
                },
            };
        },
        created() {
            this.tableData = this.data;
            this.tableFields = this.fields;
            if (this.isDetail) {
                this.tableFields.push({
                    name: '__component:record-details',
                    titleClass: 'title-class',
                    dataClass: 'data-class p-0 pt-2'
                })
            }
            if (this.doSwitchPlans) {
              this.tableFields.push({
                name: '__component:switch-plans',
                titleClass: 'title-class',
                dataClass: 'data-class p-0 pt-2'
              })
            }
        },
        methods: {
            formatDate(time, format) {
                if (stringHelper.empty(time)) {
                    return '';
                } else {
                    if (time.indexOf(' ') !== -1) {
                        const utcTime = time.replace(' ', 'T') + 'Z'
                        return moment(utcTime).tz(moment.tz.guess()).format(format)
                    } else {
                        return moment(time).tz(moment.tz.guess()).format(format)
                    }
                }
            },
            formatStatus(status) {
                status = status.split('|');
                if ( status[0].toLowerCase() === 'reschedule request' && status[1].toLowerCase() === 'therapist' ) {
                    return '<span class="text-warning"><span class="slickdot slickdot-nav-unread-message m-0"></span> '+ status[0] +'</span>';
                }
                else {
                    return status[0];
                }
            },
            unparameterize(parameter) {
                if( parameter === '' || parameter === null ) {
                    return '';
                }
                let frags = parameter.split('_');
                for (let i=0; i<frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            },
        }
    }
</script>

<style scoped>

</style>
