<template>
  <div class="conversation-container chat-messages-container">
    <div class="container-header">
      <div class="header-content col-md-12">
        <div class="profile-info-container">
          <p class="profile-heading">{{ messagesHeader }}</p>
        </div>
      </div>
    </div>
    <div class="container-content">
      <div
        id="messages_container"
        class="messages-container"
        @scroll="scrollHandler">
        <div id="messages_history">
          <template v-for="(message, index) in messages">
            <div
              v-if="sentByMe(message.sender)"
              :id="'outgoing_msg_' + message.messageId"
              class="message outgoing-message">
              <div class="profile-image-container">
                <img
                  :src="message.sender.profileUrl ? parseProfileImageUrl(message.sender.profileUrl) : defaultUserImage"
                  class="profile-image"
                  alt="MyOnlineTherapy">
              </div>
              <div class="message-container">
                <p class="message-text">{{ message.message }}</p>
                <p class="message-time">
                  {{ getTimeOnly(message.createdAt) }} | {{ getDateOnly(message.createdAt) }}
                  <img :src="isMessageUnread(message) ? messageUnreadIcon : messageReadIcon">
                </p>
              </div>
            </div>
            <div
              v-else
              class="message incoming-message">
              <div class="profile-image-container">
                <img
                  :src="message.sender.profileUrl ? parseProfileImageUrl(message.sender.profileUrl) : defaultUserImage"
                  class="profile-image"
                  alt="MyOnlineTherapy">
              </div>
              <div class="message-container">
                <p class="message-text">{{ message.message }}</p>
                <p class="message-time">{{ getTimeOnly(message.createdAt) }} | {{ getDateOnly(message.createdAt) }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="new-message">
        <div
          id="message-type"
          class="message to typing"/>
        <div class="write-message-input">
          <div id="hidden_content"/>
          <div class="text-area-container">
            <textarea
              id="new_message"
              v-model="message"
              placeholder="Type here…"/>
          </div>
          <img
            :src="sendMessageImage"
            :disabled="disableSendButton"
            class="send-message-button pointer"
            @click="sendMessage">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import sanitizeHtml from 'sanitize-html'
    export default {
        props: [
            'user_id',
            'profileUrl',
            'defaultUserImage',
            'sendMessageIcon',
            'sendMessageIconDisabled',
            'messageReadIcon',
            'messageUnreadIcon',
            'activeChannel',
            'messages',
            'messagesHeader'
        ],
        data: function () {
            return {
                loggedInUserImage: this.profileUrl,
                sendMessageImage: '',
                message: '',
                disableSendButton: true
            }
        },
        watch: {
            'messages': function (value) {
                this.setSendButtonStatus(value)
                this.callTypingIndicator()
                let content = sanitizeHtml(this.message)
                $('#hidden_content').empty().append(content)
                const height = $('#hidden_content').height()
                if (height > 24) {
                    $('.text-area-container').height(height)
                }
            }
        },
        mounted: function () {
            const self = this
            // send message on enter key press
            document.getElementById('new_message').addEventListener('keyup', function (event) {
                // Cancel the default action, if needed
                event.preventDefault()
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    // Trigger the button element with a click
                    self.sendMessage()
                }
            })
        },
        created: function () {
            this.sendMessageImage = this.sendMessageIconDisabled
        },
        methods: {
            newConversation: function () {
                this.$emit('newConversation', this.recipient)
            },
            sentByMe: function (sender) {
                const prefix = this.environmentPrefix
                return sender.userId === prefix + this.user_id
            },
            sendMessage: function () {
                let message = sanitizeHtml(this.message)
                this.message = ''
                this.$emit('sendMessage', message)
            },
            callTypingIndicator: function () {
                clearTimeout(this.timeout)
                const self = this
                this.activeChannel.startTyping()
                this.timeout = setTimeout(function () {
                    console.log('stop typing')
                    self.activeChannel.endTyping()
                }, 500)
            },
            setSendButtonStatus (value) {
                if (value.length > 0) {
                    this.sendMessageImage = this.sendMessageIcon
                    this.disableSendButton = false
                } else {
                    this.sendMessageImage = this.sendMessageIconDisabled
                    this.disableSendButton = true
                }
            },
            getDateOnly: function (timestamp) {
                return this.formatDate(timestamp)
            },
            getTimeOnly: function (timestamp) {
               return this.formatTime(timestamp)
            },
            isMessageUnread: function (message) {
                return this.activeChannel.getReadReceipt(message) > 0
            },
            scrollHandler: function () {
                let container = document.getElementById('messages_container')
                if (parseInt(container.scrollTop) === 0) {
                    this.$emit('loadMore')
                }
            },
            parseProfileImageUrl (url) {
              let parseResult = new DOMParser().parseFromString(url, 'text/html')
              return parseResult.documentElement.textContent
            }
        }
    }
</script>
