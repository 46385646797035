<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="400px"
      min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modal_title }}</h1>
        <img
            class="close-model"
            src="/icons/close_ic.svg"
            @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <form class="d-flex flex-column" style="gap: 1em;">
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Email</label>
              <input
                  v-model="form.email"
                  class="input-text"
                  type="text"
                  placeholder="Enter Email">
            </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                <label class="form-label">First Name</label>
                <input
                    v-model="form.first_name"
                    class="input-text"
                    type="text"
                    placeholder="Enter first name">
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <label class="form-label">Last Name</label>
                <input
                    v-model="form.last_name"
                    class="input-text"
                    type="text"
                    placeholder="Enter last name">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <label class="form-label">Date of Birth</label>
                <date-picker
                    :date="form.date_of_birth"
                    class="p-0"
                    @valueUpdated="dobUpdated"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <label class="form-label">Gender</label>
                <select name="gender" class="input-select" v-model="form.gender">
                  <option value="" disabled selected>Select a Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="Non binary">Non binary</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <label class="form-label">Therapy Plan</label>
              <select name="plan" class="input-select" v-model="form.plan_id">
                <option selected></option>
                <option v-for="plan in therapyPlans" :value="plan.plan_id">{{plan.name}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Organisation</label>
              <select name="org" class="input-select" v-model="form.org_id">
                <option v-for="org in organisations" :value="org.org_id">{{org.name}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label">Self Care Plan (Optional)</label>
              <select name="plan" class="input-select" v-model="form.self_care_plan_id">
                <option selected></option>
                <option v-for="plan in selfCarePlans" :value="plan.plan_id">{{plan.name}}</option>
              </select>
            </div>
          </div>
        </form>
      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer/>
        <a
            class="cancel-model"
            @click="hide">Cancel</a>
        <button
            class="btn modal-action-button" :disabled="creatingClient"
            @click="addNewClient">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'
import DatePicker from "@/components/common/DatePicker.vue";
export default {
  name: 'AddNewClient',
  components: {DatePicker},
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    plans: {
      required: true,
      type: Array
    },
    organisations: {
      required: true,
      type: Array
    },
  },
  data: () => ({
    modal_title: 'Add New Client',
    actionButtonTitle: 'Create',
    selfCarePlans: [],
    therapyPlans: [],
    form: {
      first_name: null,
      last_name: null,
      date_of_birth: null,
      gender: null,
      plan_id: null,
      org_id: null,
      email: null,
      self_care_plan_id: null
    },
    creatingClient: false,
    resetForm: {
      first_name: '',
      last_name: '',
      date_of_birth: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      gender: '',
      plan_id: null,
      org_id: null,
      email: null,
      self_care_plan_id: null
    }
  }),
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  created () {
    this.selfCarePlans = this.plans.filter(p => p.plan_type === 'self-care')
    this.therapyPlans = this.plans.filter(p => p.plan_type === 'therapy')
    this.form = this.resetForm
  },
  methods: {
    hide () {
      this.$emit('hide')
    },
    validateForm() {

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.form.email || this.form.email === '' || !emailRegex.test(this.form.email)) {
        this.$toastr('error', 'Please enter a valid email address.', '')
        return false
      }

      if (!this.form.first_name || this.form.first_name === '') {
        this.$toastr('error', 'Please enter client first name.', '')
        return false
      }
      if (!this.form.last_name || this.form.last_name === '') {
        this.$toastr('error', 'Please enter client last name.', '')
        return false
      }

      if (!this.form.date_of_birth || this.form.date_of_birth === '') {
        this.$toastr('error', 'Please enter client date of birth.', '')
        return false
      }

      if (!this.form.plan_id || this.form.plan_id === '') {
        this.$toastr('error', 'Please select a therapy plan.', '')
        return false
      }

      if (!this.form.org_id || this.form.org_id === '') {
        this.$toastr('error', 'Please select an organisation.', '')
        return false
      }

      return true
    },
    addNewClient () {
      this.creatingClient = true;
      try{
        if(this.validateForm()){
          this.$emit('addNewClient', this.form)
        }
      } finally {
        this.creatingClient = false;
      }

    },
    dobUpdated (date) {
      this.form.date_of_birth = date
    }
  },
  watch: {
    plans(){
      this.selfCarePlans = this.plans.filter(p => p.plan_type === 'self-care')
      this.therapyPlans = this.plans.filter(p => p.plan_type === 'therapy')
    }
  }
}
</script>
<style scoped>

</style>
