<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row">
            <template v-if="activeView === 'read'">
                <p class="mb-0">
                    <img class="mr-3" src="/icons/email_ic.svg" alt="MyOnlineTherapy"/>
                    <a :href="'mailto:'+therapistEmail">{{ therapistEmail }}</a>
                </p>
                <img v-if="userType === 'therapist'" class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editEmail">
            </template>
            <template v-else>
                <input v-bind:class="{'form-control mr-1': true, 'input-error mr-1': isEmailNotValid}" v-model="therapistEmail"/>
                <button class="btn" @click="updateProfile" :disabled="disable">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TherapistEmail',
        props: {
            email: {
                type: String,
                required: true
            },
            userType: {
              type: String,
              default: 'therapist'
            }
        },
        data() {
            return {
                activeView: 'read',
                therapistEmail: this.email,
                isEmailNotValid: false,
                disable: true,
            }
        },
        methods: {
            editEmail() {
                this.activeView = 'edit';
            },
            updateProfile() {
                this.$emit('updateProfile', this.therapistEmail, 'email');
                this.activeView = 'read';
            }
        },
        watch: {
            'therapistEmail': function(value) {
                let emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                if (value !== this.email && emailReg.test(value)) {
                    this.isEmailNotValid = false;
                    this.disable = false;
                } else {
                    this.disable = true;
                    this.isEmailNotValid = true;
                }
            }
        }
    }
</script>
