<template>
    <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
        <v-card>
            <!-- Title -->
            <v-card-title>
                <h1 class="model-heading">{{modalTitle}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click="hide"/>
            </v-card-title>

            <!-- Content -->
            <v-card-text>
                <v-container fluid>
                    <v-layout wrap align-center class="justify-space-between">
                        <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                              id="interviewForm"
                        >
                            <input type="hidden" v-model="interview.id" name="id"/>
                            <input type="hidden" :value="actionType" name="form_type"/>
                            <!-- Interview therapist -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Therapist</span>
                                    <select class="input-select form-group__input"
                                            name="therapist"
                                            v-model="interview.user_id"
                                    >
                                        <option :value="null" disabled selected>Select Therapist</option>
                                        <template v-for="therapist in therapists">
                                            <option :value="therapist.id">{{therapist.name}}</option>
                                        </template>
                                    </select>
                                </label>
                            </div>
                            <!-- Interview Code -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Code</span>
                                    <input class="input-text form-group__input"
                                           type="text"
                                           name="name"
                                           v-model="interview.key"
                                           placeholder="Enter Code"
                                    />
                                </label>
                            </div>
                            <!-- Interview title -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Title</span>
                                    <input class="input-text form-group__input"
                                           type="text"
                                           name="name"
                                           v-model="interview.name"
                                           placeholder="Enter Title"
                                    />
                                </label>
                            </div>
                            <!-- Interview duration -->
                            <div class="w-48">
                                <span class="form-label w-100">Duration (seconds)</span>
                                <div class="flex-display justify-space-between">
                                    <div class="form-group w-48">
                                        <label>
                                            <input class="input-text form-group__input"
                                                   type="text"
                                                   name="duration"
                                                   v-model="interview.duration_count"
                                                   placeholder="Enter Duration"
                                            />
                                        </label>
                                    </div>
                                    <div class="form-group w-48">
                                        <label>
                                            <select class="input-select form-group__input" name="durationUnit"
                                                    v-model="interview.duration_unit">
                                                <option value="" disabled selected>Select Unit</option>
                                                <option value="seconds">Seconds</option>
                                                <option value="minutes">Minutes</option>
                                                <option value="hours">Hours</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Interview status -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Status</span>
                                    <select class="input-select form-group__input" name="status" v-model="interview.status">
                                        <option value="" disabled selected>Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">In Active</option>
                                    </select>
                                </label>
                            </div>
                            <!-- Empty div for keep align -->
                            <div class="form-group w-48"></div>

                            <!-- Interview portrait file -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Portrait File</span>
                                    <!-- URl -->
                                    <span>
                                        <input class="input-text form-group__input"
                                               type="text"
                                               name="therapy"
                                               v-model="interview.url"
                                               placeholder="Enter URL"
                                        />
                                    </span>
                                </label>
                            </div>
                            <!-- Interview landscape file -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Landscape File</span>
                                    <!-- URl -->
                                    <span>
                                        <input class="input-text form-group__input"
                                               type="text"
                                               name="therapy"
                                               v-model="interview.web_url"
                                               placeholder="Enter URL"
                                        />
                                    </span>
                                </label>
                            </div>
                            <!-- Interview portrait thumbnail -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Portrait Thumbnail</span>
                                    <!-- URl -->
                                    <span>
                                        <input class="input-text form-group__input"
                                               type="text"
                                               name="therapy"
                                               v-model="interview.thumbnail_url"
                                               placeholder="Enter URL"
                                        />
                                    </span>
                                </label>
                            </div>
                            <!-- Interview landscape thumbnail -->
                            <div class="form-group w-48">
                                <label>
                                    <span class="form-label">Landscape Thumbnail</span>
                                    <!-- URl -->
                                    <span>
                                        <input class="input-text form-group__input"
                                               type="text"
                                               name="therapy"
                                               v-model="interview.web_thumbnail_url"
                                               placeholder="Enter URL"
                                        />
                                    </span>
                                </label>
                            </div>

                            <!-- Order Controls -->
                            <div class="w-100 mb-4">
                                <label class="form-label">Order</label>
                                <div class="border-form-group flex-display flex-wrap justify-space-between w-100 p-3">
                                    <template v-for="screen in screens">
                                        <div class="form-group w-48">
                                            <label>
                                                <span class="form-label">{{screen.name}}</span>
                                                <select class="input-select form-group__input"
                                                        name="therapist"
                                                        v-model="interview.order[screen.id]"
                                                >
                                                    <option :value="null" disabled selected>Select Order</option>
                                                    <option :value="0">N/A</option>
                                                    <template v-for="n in total">
                                                        <option :value="n">{{n}}</option>
                                                    </template>
                                                </select>
                                            </label>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <!-- Visibility Controls -->
                            <div class="w-100">
                                <label class="form-label">Visibility</label>
                                <div class="border-form-group flex-display flex-wrap justify-space-between w-100 p-3">
                                    <template v-for="screen in screens">
                                        <div class="form-group w-48">
                                            <label class="switch-label pr-3">{{screen.name}}</label>
                                            <label class="switch">
                                                <input type="checkbox"
                                                       :name="screen.id"
                                                       v-model="interview.visibility[screen.id]"
                                                       :true-value="true"
                                                       :false-value="false"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </form>
                    </v-layout>
                </v-container>
            </v-card-text>

            <!-- Actions -->
            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button class="btn modal-action-button" @click="submitInterview" :disabled="!isSubmitAllowed">
                    {{actionButtonTitle}}
                </button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "addInterview",
        props: {
            visible: {
                required: true,
                type: Boolean,
            },
            actionType: {
                required: true,
                type: String
            },
            therapists: {
                required: true,
                type: Array
            },
            screens: {
                required: true,
                type: Array
            },
            total: {
                required: true,
                type: Number
            },
            value: null,
        },
        data: () => ({
            modelHeader: '',
            modalTitle: '',
            actionButtonTitle: '',
            interview: null
        }),
        computed: {
            show: {
                get() {
                    return this.visible
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
            isSubmitAllowed: {
                get() {
                    return this.dataNotMissing() && this.dataUpdated();
                }
            }
        },
        created() {
            if (this.actionType === 'add') {
                this.modalTitle = 'Add Video';
                this.actionButtonTitle = 'Add';
            } else {
                this.modalTitle = 'Update Video';
                this.actionButtonTitle = 'Update';
            }
            this.interview = {
                ...this.value,
                order: {
                    ...this.value.order
                },
                visibility: {
                    ...this.value.visibility
                }
            };
        },
        methods: {
            hide() {
                this.$emit('hide');
            },
            validateSubmitRequest() {
                if (this.interview.name === '') {
                    this.showAlert('Please enter title.');
                    return false;
                }

                if (this.interview.status === '') {
                    this.showAlert('Please select status.');
                    return false;
                }
                return true;
            },
            submitInterview() {
                if (this.validateSubmitRequest()) {
                    this.$emit('submitInterview', this.interview);
                }
            },
            dataNotMissing() {
                return this.interview.user_id &&
                    this.interview.key !== '' &&
                    this.interview.name !== '' &&
                    this.interview.duration_count !== '' &&
                    this.interview.duration_unit !== '' &&
                    this.interview.url !== '' &&
                    this.interview.web_url !== '' &&
                    this.interview.thumbnail_url !== '' &&
                    this.interview.web_thumbnail_url !== '' &&
                    this.interview.status !== '';
            },
            dataUpdated() {
                return this.interview.user_id !== this.value.user_id ||
                    this.interview.key !== this.value.key ||
                    this.interview.name !== this.value.name ||
                    this.interview.duration_count !== this.value.duration_count ||
                    this.interview.duration_unit !== this.value.duration_unit ||
                    this.interview.url !== this.value.url ||
                    this.interview.web_url !== this.value.web_url ||
                    this.interview.thumbnail_url !== this.value.thumbnail_url ||
                    this.interview.web_thumbnail_url !== this.value.web_thumbnail_url ||
                    this.interview.order.homeTabInitialSessionCompleted !== this.value.order.homeTabInitialSessionCompleted ||
                    this.interview.order.homeTabInitialSessionNotCompleted !== this.value.order.homeTabInitialSessionNotCompleted ||
                    this.interview.order.discoverTab !== this.value.order.discoverTab ||
                    this.interview.visibility.homeTabInitialSessionCompleted !== this.value.visibility.homeTabInitialSessionCompleted ||
                    this.interview.visibility.homeTabInitialSessionNotCompleted !== this.value.visibility.homeTabInitialSessionNotCompleted ||
                    this.interview.visibility.discoverTab !== this.value.visibility.discoverTab ||
                    this.interview.status !== this.value.status;
            },
            showAlert(msg) {
                this.$toastr('error', msg);
            }
        }
    }
</script>
<style scoped>

</style>
