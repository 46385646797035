<template>
  <v-dialog v-model="show" persistent :max-width="width" min-height="248px" :border-radius="borderRadius">
    <v-card :class="borderRadius">
      <v-card-title class="d-block right pt-3 pr-4 pb-0 w-100 ">
        <img class="pointer right" :class="styleClasses" @click.stop="$emit('hide')" src="https://assets.myonlinetherapy.com/images/icons/booking/booking/close_ic.svg" alt="close"/>
      </v-card-title>
      <v-card-text class="flex-column justify-left align-center pt-0 px-4 ">
        <div>
          <slot name="header"></slot>
        </div>
        <div class="">
          <slot name="main"></slot>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end px-4 pb-4">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "popUp",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    width: {
      type: String,
      default: ""
    },
    actionButtonText: {
      type: String,
      default: "Okay"
    },
    referencePage: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: String,
      default: "tw-th-rounded-md"
    },
    actionButtonStyleClasses: {
      type: String,
      default: "tw-th-w-[160px]"
    },
    styleClasses: {
      type: String,
      default: "tw-th-text-popUp",
    },
    btnIsTrue: {
      type: Boolean,
      default: true
    },
    backArrow: {
      type:Boolean,
      default: false
    }
  },
  computed: {

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  data() {
    return {
    }
  },
}
</script>
<style>
.v-dialog {
  border-radius:12px !important;
}
</style>
