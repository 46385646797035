<template>
    <v-navigation-drawer
            id="app-drawer"
            v-model="inputValue"
            app
            floating
            persistent
            mobile-break-point="991"
            width="260"
    >
        <v-layout
                class="fill-height"
                tag="v-list"
                column
        >
            <v-list-tile avatar>
                <v-list-tile-avatar
                        color="white"
                >
                    <v-img
                            :src="logo"
                            height="34"
                            contain
                    />
                </v-list-tile-avatar>
                <v-list-tile-title class="title" style="color: white">
                    My Online Therapy
                </v-list-tile-title>
            </v-list-tile>
            <v-divider/>
            <!--<v-list-tile
              v-if="responsive"
            >
              <v-text-field
                class="purple-input search-input"
                label="Search..."
                color="purple"
              />
            </v-list-tile>-->
            <v-list-tile
                    v-for="(link, i) in links"
                    :key="i"
                    :to="link.to"
                    :active-class="color"
                    avatar
                    class="v-list-item"
                    :class="(link.category) ? 'navigation-section-title' : 'normal-section-title'"
            >
                <v-list-tile-action v-if="link.icon">
                    <img :src="'/icons/sidebar/'+link.icon" alt="sidebarIcon"/>
                </v-list-tile-action>
                <v-list-tile-title
                        v-text="link.text"
                />
                <message-notification v-if="link.text === 'Chat'"></message-notification>
                <booking-notification v-if="link.text === 'Bookings'"></booking-notification>
            </v-list-tile>

        </v-layout>
    </v-navigation-drawer>
</template>

<script>
    // Utilities
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import MessageNotification from "../messaging/messageNotification";
    import BookingNotification from "../main/BookingNotification";
    import userRoles from '../../helpers/app/userRoleHelper';

    export default {
        components: {BookingNotification, MessageNotification},
        data: () => ({
            logo: './img/logo.png',
            links: [
                {
                    to: '',
                    text: 'MAIN',
                    category: true
                },
                {
                    to: '/dashboard',
                    icon: 'dashboard.svg',
                    text: 'Dashboard'
                },
                {
                    to: '/schedule',
                    icon: 'schedule.svg',
                    text: 'Schedule'
                },
                {
                    to: '/bookings',
                    icon: 'bookings.svg',
                    text: 'Bookings'
                },
                {
                    to: '/subscriptions',
                    icon: 'bookings.svg',
                    text: 'Self-care'
                },
                {
                    to: '/therapyPlanActions',
                    icon: 'bookings.svg',
                    text: 'Therapy Plan Actions'
                },
                {
                    to: '',
                    text: 'PEOPLE',
                    category: true
                },
                {
                    to: '/clients',
                    icon: 'clients.svg',
                    text: 'Clients'
                },
                {
                    to: '/therapists',
                    icon: 'clients.svg',
                    text: 'Therapists'
                },
                {
                    to: '/promoters',
                    icon: 'clients.svg',
                    text: 'Promoters'
                },
                {
                    to: '',
                    text: 'TRANSACTIONS',
                    category: true
                },
                {
                    to: '/insurance',
                    icon: 'insurance.svg',
                    text: 'Invoices'
                },
                {
                    to: '/Transactions',
                    icon: 'transactions.svg',
                    text: 'Therapy Transactions'
                },
                {
                  to: '/SelfCareTransactions',
                  icon: 'transactions.svg',
                  text: 'Self-care Transactions'
                },
                {
                    to: '/Transfers',
                    icon: 'transactions.svg',
                    text: 'Promoter Transfers'
                },
                {
                    to: '',
                    text: 'SETTLING PAYMENTS',
                    category: true
                },
                {
                    to: '/drafts',
                    icon: 'transactions.svg',
                    text: 'Draft Payouts & Invoices'
                },
                {
                    to: '/Invoices',
                    icon: 'transactions.svg',
                    text: 'Therapist Invoices'
                },
                /*{
                  to: '/payouts',
                  icon: 'payouts.svg',
                  text: 'Payouts'
                },*/
                {
                    to: '/payouts',
                    icon: 'transactions.svg',
                    text: 'Therapist Payouts'
                 },
                {
                    to: '',
                    text: 'MESSAGING',
                    category: true
                },
                {
                    to: '/chat',
                    icon: 'chat.svg',
                    text: 'Chat'
                },
                {
                    to: '/notifications',
                    icon: 'notifications.svg',
                    text: 'Notifications'
                },
                {
                    to: '/emails',
                    icon: 'notifications.svg',
                    text: 'Email Logs'
                },
                {
                    to: '/notificationLogs',
                    icon: 'notifications.svg',
                    text: 'Notification Logs'
                },
                {
                    to: '',
                    text: 'CONFIGURE',
                    category: true
                },
                {
                    to: '/pricing',
                    icon: 'transactions.svg',
                    text: 'Pricing'
                },
                {
                    to: '/org_console',
                    icon: 'bookings.svg',
                    text: 'Org Console'
                },
                {
                    to: '/issues',
                    icon: 'issues.svg',
                    text: 'Issues'
                },
                {
                    to: '/treatments',
                    icon: 'treatments.svg',
                    text: 'Treatments'
                },
                {
                    to: '/sessionTypes',
                    icon: 'session-types.png',
                    text: 'Session Types'
                },
                {
                    to: '/sessionPackages',
                    icon: 'session-packages.png',
                    text: 'Session Packages'
                },
                {
                    to: '/selfCareCollection',
                    icon: 'self-care-goals.png',
                    text: 'Self Care Collection'
                },
                {
                    to: '/selfCareCourses',
                    icon: 'self-care-goals.png',
                    text: 'Courses'
                },
                {
                    to: '/selfCareSkills',
                    icon: 'self-care-skills.png',
                    text: 'Self Care Skills'
                },
                {
                    to: '/pgInterviews',
                    icon: 'pro-green-icon.png',
                    text: 'PG Interviews'
                },
                {
                    to: '',
                    text: 'ACCOUNT',
                    category: true
                },
                {
                    to: '/Team',
                    icon: 'team.svg',
                    text: 'Team'
                }
            ],
            responsive: false
        }),
        computed: {
            ...mapState('app', ['image', 'color']),
            inputValue: {
                get() {
                    return this.$store.state.app.drawer
                },
                set(val) {
                    this.setDrawer(val)
                }
            },
            items() {
                return this.$t('Layout.View.items')
            },
            sidebarOverlayGradiant() {
                return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`
            }
        },
        mounted() {
            this.onResponsiveInverted()
            window.addEventListener('resize', this.onResponsiveInverted)
        },
        created() {
            const user = JSON.parse(localStorage.getItem('user'));
            if ([userRoles.support].includes(user.user_type)) {
              this.links = [
                  {
                    to: '',
                    text: 'MAIN',
                    category: true
                  },
                  {
                    to: '/dashboard',
                    icon: 'dashboard.svg',
                    text: 'Dashboard'
                  },
                  {
                    to: '/schedule',
                    icon: 'schedule.svg',
                    text: 'Schedule'
                  },
                  {
                    to: '/bookings',
                    icon: 'bookings.svg',
                    text: 'Bookings'
                  },
                  {
                    to: '/subscriptions',
                    icon: 'bookings.svg',
                    text: 'Self-care'
                  },
                  {
                    to: '/therapyPlanActions',
                    icon: 'bookings.svg',
                    text: 'Therapy Plan Actions'
                  },
                  {
                    to: '',
                    text: 'PEOPLE',
                    category: true
                  },
                  {
                    to: '/clients',
                    icon: 'clients.svg',
                    text: 'Clients'
                  },
                  {
                    to: '/therapists',
                    icon: 'clients.svg',
                    text: 'Therapists'
                  },
                  {
                    to: '/promoters',
                    icon: 'clients.svg',
                    text: 'Promoters'
                  },
                  {
                    to: '',
                    text: 'TRANSACTIONS',
                    category: true
                  },
                  {
                    to: '/insurance',
                    icon: 'insurance.svg',
                    text: 'Invoices'
                  },
                  {
                    to: '/Transactions',
                    icon: 'transactions.svg',
                    text: 'Transactions'
                  },
                  {
                    to: '/Transfers',
                    icon: 'transactions.svg',
                    text: 'Promoter Transfers'
                  },
                  {
                    to: '',
                    text: 'SETTLING PAYMENTS',
                    category: true
                  },
                  {
                    to: '/drafts',
                    icon: 'transactions.svg',
                    text: 'Draft Payouts & Invoices'
                  },
                  {
                    to: '/Invoices',
                    icon: 'transactions.svg',
                    text: 'Therapist Invoices'
                  },
                  {
                    to: '/payouts',
                    icon: 'transactions.svg',
                    text: 'Therapist Payouts'
                  },
                  {
                    to: '',
                    text: 'MESSAGING',
                    category: true
                  },
                  {
                    to: '/chat',
                    icon: 'chat.svg',
                    text: 'Chat'
                  },
                  {
                    to: '/emails',
                    icon: 'notifications.svg',
                    text: 'Email Logs'
                  },
                  {
                    to: '/notificationLogs',
                    icon: 'notifications.svg',
                    text: 'Notification Logs'
                  },
                ]
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResponsiveInverted)
        },
        methods: {
            ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
            onResponsiveInverted() {
                if (window.innerWidth < 991) {
                    this.responsive = true
                } else {
                    this.responsive = false
                }
            }
        }
    }
</script>

<style lang="scss">
    #app-drawer {
        .v-list__tile {
            border-radius: 4px;

            &--buy {
                margin-top: auto;
                margin-bottom: 17px;
            }
        }

        .v-image__image--contain {
            top: 9px;
            height: 60%;
        }

        .search-input {
            margin-bottom: 30px !important;
            padding-left: 15px;
            padding-right: 15px;
        }

        div.v-responsive.v-image > div.v-responsive__content {
            overflow-y: auto;
        }
    }
</style>
