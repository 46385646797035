<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <div class="flex-display flex-row justify-content-space-between w-100">
                <div class="flex-display flex-column justify-content-center">
                    <label class="switch-label m-0 color-fade">Address:</label>
                </div>
                <div class="flex-row flex-display justify-content-end w-100 align-items-center">
                    <span class="pointer">{{clientHouseAddress}} {{clientCity}} {{clientPostalCode}}</span>
                    <img class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" v-if="isEditable" @click="showAddressModal=true">
                </div>
            </div>
        </div>
        <add-client-address :visible="showAddressModal"
                            v-on:addAddress="addAddress"
                            :client-house-address="clientHouseAddress"
                            :client-city="clientCity"
                            :client-postal-code="clientPostalCode"
                            v-on:hide="showAddressModal=false">
        </add-client-address>
    </div>
</template>

<script>
    import AddClientAddress from "./actions/AddClientAddress";
    export default {
        name: "ClientAddress",
        props: {
            user: {}
        },
        components: {
            AddClientAddress
        },
        data() {
            return {
                clientHouseAddress: this.getHouseAddress(),
                clientCity: this.getCity(),
                clientPostalCode: this.getPostalCode(),
                showAddressModal: false,
                isEditable: false,
            }
        },
        mounted() {
            this.isAddressEditable();
        },
        methods: {
            isAddressEditable() {
                if (this.clientHouseAddress === "" || this.clientCity === "" || this.clientPostalCode === "") {
                    this.isEditable = true;
                } else {
                    this.isEditable = false;
                }
            },
            getHouseAddress() {
                return this.user.insurance ? this.user.insurance.house_address : "";
            },
            getCity() {
                return this.user.insurance ? this.user.insurance.city : "";
            },
            getPostalCode() {
                return this.user.insurance ? this.user.insurance.postal_code : "";
            },
            addAddress(data) {
                this.clientHouseAddress = data.house_address;
                this.clientCity = data.city;
                this.clientPostalCode = data.postal_code;
                this.$emit("updateAddress", {
                    "house_address": data.house_address,
                    "city": data.city,
                    "postal_code": data.postal_code,
                }, "address");
                this.isAddressEditable();
            }
        },
    }
</script>