<template>
    <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
        <v-card>
            <!-- Title -->
            <v-card-title>
                <h1 class="model-heading">Add Category</h1>
                <img class="close-model" src="/icons/close_ic.svg" alt="MyOnlineTherapy" @click="hide"/>
            </v-card-title>

            <!-- Content -->
            <v-card-text>
                <v-container fluid>
                    <v-layout wrap align-center class="justify-space-between">
                        <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                              id="skillCategoryForm"
                        >
                            <!-- Category title -->
                            <div class="form-group w-100">
                                <label>
                                    <span class="form-label">Title</span>
                                    <textarea class="input-textArea form-group__input form-group__input--inputTextArea"
                                              name="description"
                                              v-model="category"
                                              placeholder="Enter Title..."
                                              rows="2"
                                    ></textarea>
                                </label>
                            </div>
                        </form>
                    </v-layout>
                </v-container>
            </v-card-text>

            <!-- Actions -->
            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button class="btn modal-action-button" @click="submitSkillCategory">Add</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "AddSkillCategory",
        props: {
            visible: {
                required: true,
                type: Boolean,
            },
        },
        computed: {
            show: {
                get() {
                    return this.visible
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        data: () => ({
            category: null,
        }),
        methods: {
            hide() {
                this.$emit('hide');
            },
            validateSubmitRequest() {
                if (this.category === '') {
                    this.showAlert('Please enter title.');
                    return false;
                }
                return true;
            },
            submitSkillCategory() {
                if (this.validateSubmitRequest()) {
                    this.$emit('submitSkillCategory', this.category);
                }
            },
            showAlert(msg) {
                this.$toastr('error', msg);
            }
        }
    }
</script>