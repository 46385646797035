<template>
  <v-dialog v-model="show" persistent max-width="566px" min-height="569px" v-else>
    <v-card>
      <!-- Title -->
      <v-card-title class="pl-5 pt-5 pb-0 pr-0">
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img alt="close" class="close-model pt-4 pr-4" src="/icons/close_ic.svg" @click.stop="hide"/>
        <div class="divider"></div>
      </v-card-title>
      <!-- Content -->
      <v-card-text class="pl-5 pt-3 pr-5 pb-0">
        <label class="app-label gray-900 font-weight-medium">Which regions does your indemnity insurance cover?</label>
        <div class="mt-4">
          <div v-for="insuranceRegion in insuranceRegions" class="man-cell field">
            <label>
              <input type="checkbox" @change="allowSave($event)" :value="insuranceRegion.id"
                     v-model="insuranceRegion.is_selected">
              <div class="man-circle">
                <img class="circle-check m-l-3 width-18 height-12 check-image" alt="checkbox"
                     src="/assets/images/blackcheckmark.png">
              </div>
            </label>
            <span class="text region-label">{{ insuranceRegion.name }}</span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="flex-display flex-row pb-5 pr-4">
        <v-spacer></v-spacer>
        <button class="btn btn-save  pt-12 pr-12 mr-3" @click.stop="hide">{{ cancelButtonTitle }}</button>
        <button class="btn leave-submit" :disabled="isDisable" @click="addIndemnityInsuranceRegions">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'IndemnityCoverageRegionModal',
  props: {
    userId: {
      required: true,
    },
    visible: {
      required: true,
      type: Boolean,
    },
    insurance_regions: {
      required: true,
      default: []
    }
  },
  data: () => ({
    modalTitle: 'Regions covered by indemnity insurance',
    actionButtonTitle: 'Save',
    cancelButtonTitle: 'Cancel',
    isDisable: true,
    selectedRegions: [],
    existingRegionIds: [],
    insuranceRegions: [],
  }),
  created() {
    this.initializeCloneOfRegions();
    this.getSelectedIds(true);
  },
  computed: {

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    initializeCloneOfRegions() {
      this.insurance_regions.forEach((insurance_region, index) => {
        this.insuranceRegions[index] = {...insurance_region};
      });
    },
    getSelectedIds(existingRegionCheck = false) {
      this.selectedRegions = [];
      if (this.insuranceRegions.length > 0) {
        this.insuranceRegions.filter((insurance_region) => {
          if (insurance_region.is_selected) {
            this.selectedRegions.push(insurance_region.id);
          }
        });
        if (existingRegionCheck) {
          this.existingRegionIds = [
            ...this.selectedRegions
          ];
        }
      }
    },
    addIndemnityInsuranceRegions() {
      let loader = this.$loading.show();
      this.getSelectedIds();
      this.$http.put(this.url + "therapist/indemnity-insurance/add", {
        therapist_id: this.userId,
        insurance_regions: this.selectedRegions.toString(),
      }).then((response) => {
        loader.hide();
        this.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("refreshIndemnity")

      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
        loader.hide();
      })
    },
    allowSave(el) {
      this.getSelectedIds();
      if (this.selectedRegions.length !== 0) {
        this.isDisable = false;
      }
      if (
          this.selectedRegions.length === this.existingRegionIds.length &&
          this.existingRegionIds.every((val, index) => val === this.selectedRegions[index])
      ) {
        this.isDisable = true;
      }
    },

    hide() {
      this.$emit('hide')
    }
  },
  watch: {
    selectedRegions() {
      if (this.selectedRegions.length === 0) {
        this.isDisable = true;
      }
    }
  }
}
</script>
<style scoped>
.man-cell .man-circle {
  border-radius: 0px !important;
}

.man-cell input[type="checkbox"]:checked ~ .man-circle {
  background-color: rgb(206, 212, 218) !important;
}

.check-image {
  display: none;
}

.man-cell input[type="checkbox"]:checked ~ .man-circle .check-image {
  display: inline-block !important;
}

.btn.btn-save {
  width: auto;
  color: #308BC3 !important;
  background-color: #ffffff !important;
  border: 1px solid #308BC3 !important;
}

.btn.leave-submit {
  padding: 12px 24px;
  width: auto;
  height: auto;
  background: #2493C4;
}

.divider {
  position: static;
  width: 486px;
  height: 1px;
  left: 0;
  top: 48px;
  background: #F1F1F1;
  margin: 16px 0;
}

.leave-delete {
  color: #C50909;
  font-weight: 700;
}

.leave-text {
  color: #4A4A4A !important;
}

.v-text-field input {
  border: 1px solid #4A4A4A !important;
  padding: 0 6px !important;
  outline: none !important;
}

::placeholder {
  color: #4A4A4A !important;
}

.v-menu__content--fixed {
  top: 490px !important;
}

td button:disabled {
  background-color: #fff !important;
}

.app-label {
  font-size: 17px !important;
  color: #2D2929 !important;
}

.region-label {
  font-size: 14px !important;
  color: #4A4A4A !important;
}
</style>
