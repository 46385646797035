<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Subscription
            </p>
        </div>
        <div class="content">
            <div id="configuration" class="pa-3">
                <template v-if="subscription">
                    <p class="font-weight-bold">Subscription type</p>
                    <template v-if="subscription.free_trial_active">
                        <p>{{subscription.type}}</p>
                        <p>{{subscription.trial_days}}-day free trial ({{subscription.trial_days_left}} days remaining)</p>
                    </template>
                    <template v-else>
                        <p>{{subscription.name}}</p>
                    </template>

                    <p class="font-weight-bold">Price</p>
                    <p>{{subscription.formatted_price}}</p>

                    <p class="font-weight-bold">Activation date</p>
                    <p>{{subscription.activation_date}}</p>

                    <p class="font-weight-bold">Renewal date</p>
                    <p>{{subscription.renewal_date}}</p>

                    <p class="font-weight-bold">Revenue</p>
                    <p>£{{subscription.revenue}}</p>

                    <p class="font-weight-bold">Status</p>
                    <p>{{subscription.status}}</p>

                    <p class="font-weight-bold">Cancellation date</p>
                    <p>{{subscription.cancellation_date ? subscription.cancellation_date : 'N/A'}}</p>

                </template>
                <template v-else>
                    <p class="font-weight-bold">Client has no active subscription right now.</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Subscription",
        props: {
            subscription:{}
        }
    }
</script>

<style scoped>

</style>