<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="skillForm"
            >
              <input type="hidden" v-model="skill.id" name="id"/>
              <input type="hidden" :value="actionType" name="form_type"/>
              <!-- Skill title -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Title</span>
                  <input class="input-text form-group__input"
                         type="text"
                         name="name"
                         v-model="skill.name"
                         placeholder="Enter Title"
                  />
                </label>
              </div>
              <!-- Skill duration -->
              <div class="w-48">
                <span class="form-label w-100">Duration</span>
                <div class="flex-display justify-space-between">
                  <div class="form-group w-48">
                    <label>
                      <input class="input-text form-group__input"
                             type="text"
                             name="duration"
                             v-model="skill.duration_count"
                             placeholder="Enter Duration"
                      />
                    </label>
                  </div>
                  <div class="form-group w-48">
                    <label>
                      <select class="input-select form-group__input" name="durationUnit"
                              v-model="skill.duration_unit">
                        <option value="" disabled selected>Select Unit</option>
                        <option value="seconds">Seconds</option>
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Skill therapist -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Therapist</span>
                  <select class="input-select form-group__input"
                          name="therapist"
                          v-model="skill.user_id"
                  >
                    <option :value="null" disabled selected>Select Therapist</option>
                    <template v-for="therapist in therapists">
                      <option :value="therapist.id">{{ therapist.name }}</option>
                    </template>
                  </select>
                </label>
              </div>
              <!-- Skill therapy -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Therapy</span>
                  <select class="input-select form-group__input"
                          name="therapist"
                          v-model="skill.therapy_code"
                  >
                    <option value="" selected>Select Therapy</option>
                    <template v-for="therapy in therapies">
                      <option :value="therapy.code">{{ therapy.code }}</option>
                    </template>
                  </select>
                </label>
              </div>
              <!-- Skill Category -->
              <div class="form-group w-33">
                <label>
                  <span class="form-label">Category</span>
                  <select class="input-select form-group__input"
                          name="category_id"
                          v-model="skill.category_id">
                    <option value="" disabled hidden>Select Category</option>
                    <template v-for="category in categories">
                      <option :value="category.id">{{ category.name }}</option>
                    </template>
                  </select>
                </label>
              </div>

              <!-- skill orientation -->
              <div class="form-group w-33">
                <label>
                  <span class="form-label">Orientation</span>
                  <select class="input-select form-group__input"
                          v-model="skill.orientation">
                    <option value="" disabled selected>Select Orientation</option>
                    <option value="portrait">Portrait</option>
                    <option value="landscape">Landscape</option>
                  </select>
                </label>
              </div>

              <!-- Excercise Type -->
              <div class="form-group w-33">
                <label>
                  <span class="form-label">Exercise Type</span>
                  <select class="input-select form-group__input"
                          v-model="skill.exercise_type">
                    <option value="">Select Exercise Type</option>
                    <option value="collection">Collection</option>
                    <option value="course">Course</option>
                  </select>
                </label>
              </div>
              <!-- Skill description-->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">Description</span>
                  <textarea class="input-textArea form-group__input form-group__input--inputTextArea"
                            name="description"
                            v-model="skill.description"
                            placeholder="Enter description..."
                            rows="2"
                  ></textarea>
                </label>
              </div>
              <!-- Skill end description -->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">End Description</span>
                  <keywords-component :keywords="skill.end_description"
                                      :placeholder="'Enter description'"
                                      v-on:update="updateEndDescription"
                  ></keywords-component>
                </label>
              </div>
              <!-- Skill portrait file -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Portrait File</span>
                  <span>
                    <input class="input-text form-group__input"
                           type="text"
                           name="therapy"
                           v-model="skill.url"
                           placeholder="Enter URL"
                    />
                  </span>
                </label>
              </div>
              <!-- Skill landscape file -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Landscape File</span>
                  <span>
                    <input class="input-text form-group__input"
                           type="text"
                           name="therapy"
                           v-model="skill.web_url"
                           placeholder="Enter URL"
                    />
                  </span>
                </label>
              </div>
              <!-- related skill -->
              <div class="form-group w-48">
                <span class="form-label">Related Skills</span>
                <multi-select v-model="skill.related_skills"
                              class="mt-2" :options="activeSkills" :limit="3"
                              :allow-empty="false" :multiple="true" :taggable="true"
                              label="name" track-by="id" placeholder="Select Related Skill"
                ></multi-select>
              </div>
              <!-- Skill requirements -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Requirements</span>
                  <multi-select v-model="skill.requirements"
                                class="mt-2" :options="requirements" :limit="3"
                                :allow-empty="false" :multiple="true" :taggable="true"
                                label="title" track-by="id" placeholder="Select Requirements"
                  ></multi-select>
                </label>
              </div>
              <!--keywords-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Keywords</span>
                  <keywords-component :keywords="skill.keywords"
                                      v-on:update="updateKeywords"
                  ></keywords-component>
                </label>
              </div>
              <!-- skill orientation -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Type</span>
                  <select class="input-select form-group__input"
                          v-model="skill.type">
                    <option value="" disabled selected>Select Type</option>
                    <option value="Audio">Audio</option>
                    <option value="Video">Video</option>
                    <option value="Animation">Animation</option>
                  </select>
                </label>
              </div>



              <!-- Settings -->
              <div class="w-100 mt-1 mb-3">
                <label class="form-label">Settings</label>
                <div class="border-form-group w-100 px-3 py-3 flex-display flex-row flex-justify-between">
                  <!-- Is Introductory -->
                  <div class="mt-1 w-48">
                    <label class="switch-label pr-3">Is Introductory</label>
                    <label class="switch">
                      <input type="checkbox"
                             v-model="skill.is_introductory"
                             :true-value="'1'"
                             :false-value="'0'"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- Is Locked -->
                  <div class="mt-1 w-48">
                    <label class="switch-label pr-3">Is Locked</label>
                    <label class="switch">
                      <input type="checkbox"
                             v-model="skill.is_locked"
                             :true-value="'1'"
                             :false-value="'0'"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- Status -->
                  <div class="mt-1 w-48">
                    <label class="switch-label pr-3">Status</label>
                    <label class="switch">
                      <input type="checkbox"
                             v-model="skill.status"
                             :true-value="'active'"
                             :false-value="'inactive'"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitSkill">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import MultiSelect from "vue-multiselect";
import KeywordsComponent from "../common/KeywordsComponent";

export default {
  name: "addSkill",
  components: {
    vueDropzone: vue2Dropzone,
    MultiSelect,
    KeywordsComponent
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: String
    },
    skills: {
      required: true,
      type: Array,
      default: []
    },
    therapists: {
      required: true,
      type: Array
    },
    therapies: {
      required: true,
      type: Array
    },
    categories: {
      required: true,
      type: Array
    },
    requirements: {
      required: true,
      type: Array
    },
    signedUrl: '',
    value: null,
  },
  data: () => ({
    modelHeader: '',
    modalTitle: '',
    actionButtonTitle: '',
    skill: null
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
    activeSkills: function () {
      return this.skills.filter(skill => {
        return skill.status === 'active'
      });
    }
  },
  created() {
    if (this.actionType === 'add') {
      this.modalTitle = 'Add Skill';
      this.actionButtonTitle = 'Add';
    } else {
      this.modalTitle = 'Update Skill';
      this.actionButtonTitle = 'Update';
    }
    this.skill = {
      ...this.value,
      category_id: this.value.category_id && this.value.category_id > 0 ? this.value.category_id : ''
    };

  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    validateSubmitRequest() {
      if (this.skill.name === '') {
        this.showAlert('Please enter title.');
        return false;
      }

      if (this.skill.status === '') {
        this.showAlert('Please select status.');
        return false;
      }
      return true;
    },
    submitSkill() {
      if (this.validateSubmitRequest()) {
        this.$emit('submitSkill', this.skill);
      }
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    },
    updateKeywords(keywords) {
      this.skill.keywords = keywords;
    },
    updateEndDescription(endDescription) {
      this.skill.end_description = endDescription;
    }
  }
}
</script>
<style scoped>
</style>
