<template>
  <div class="booking-filters flex-display">
    <div class="w-100px ml-3">
      <date-picker
        :date="userFilters.date"
        class="p-0"
        @valueUpdated="dateUpdated"/>

    </div>
    <div
      v-if="!userFilters.allPast"
      class="w-100px ml-3">
      <input
        v-model="userFilters.days"
        class="input-text"
        type="text"
        placeholder="Enter days">
    </div>
    <div class="ml-3 mt-2">
      <label class="analytics-header__heading">
        <input
          v-model="userFilters.allPast"
          :true-value="true"
          :false-value="false"
          type="checkbox"
          @click="toggleAll">
        All Past
      </label>
    </div>
    <div class="w-100px ml-3">
      <button
        class="btn btn-primary"
        @click="applyFilters">Apply
      </button>
    </div>
  </div>
</template>

<script>
    import Filters from '../common/dataTable/filters/Filters'
    import DatePicker from '../common/DatePicker'

    export default {
        name: 'DashboardUserFilters',
        components: { Filters, DatePicker },
        props: {
            filters: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                userFilters: this.filters
            }
        },
        mounted () {
            this.$events.listen('filter-set', e => this.applyFilters(e))
        },
        methods: {
            dateUpdated (date) {
                this.userFilters.date = date
            },
            applyFilters () {
                this.$emit('applyUserFilters', this.userFilters)
            },
            toggleAll () {
                console.log(this.allPast)
            }
        }
    }
</script>
<style scoped>
    .w-100px {
        width: 100px;
    }

    .booking-filters input {
        text-align: center !important;
    }
    .analytics-header__heading {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }
</style>
