<template>
  <v-dialog v-model="show" persistent max-width="600px" min-height="500px">
    <v-card v-if="isEverythingReady">
      <!-- Title -->
      <v-card-title class="p-0">
        <img class="model-heading font-default plan-title close-model mx-5 py-4" src="/icons/close_ic.svg" slot="mot" alt="close" @click="hideModal"/>
      </v-card-title>
      <v-card-text class="p-0">
        <div class="additional-form mb-5">
          <h1 class="additional-title bold-font mb-5">Additional session request: # {{ requestNumber }}</h1>

        <p class="detail-item my-3">
          <label class="item-label font-default font-weight-regular font-size-14">Date requested: </label>
          <span class="item-value font-semi-bold font-default font-size-14"> {{ requestDate }}  </span>
        </p>

        <div class="flex-display flex-row align-center mt-3">
          <label class="item-label font-size-14 font-default mb-0">Request status: </label>
          <div class="width-273 flex-display flex-row align-center ml-2">
            <select class="input-select"
                    :disabled="disabled || disableInputFields"
                    :class="disableInputFields ? 'disabled-input' :''"
                    name="status"
                    required
                    v-popover:requestStatusInputs.right
                    v-model="request_status"
            >
              <option value="" class="grey-600">Select request status</option>
              <option value="N/A">Not required</option>
              <option :value="requestPending">{{ requestPending }}</option>
              <option :value="requestSubmitted">{{ requestSubmitted }}</option>
              <option :value="requestApproved">{{ requestApproved }}</option>
              <option :value="requestPartiallyApproved">{{ requestPartiallyApproved }}</option>
              <option :value="requestRejected">{{ requestRejected }}</option>
            </select>

          </div>
        </div>
        <div class="detail-item" v-if="request_status === requestPending">
          <img class="width-16 height-16"  src="https://assets.myonlinetherapy.com/images/icons/booking/booking/warning_ic.svg" alt="mot">
          <span class="item-value font-regular fs-13 red-300 "> Submit request to Vitality and update status  </span>
        </div>

        <div class="flex-display flex-row align-center my-3">
          <label class="item-label font-size-14 font-default mb-0">Number of sessions requested:</label>
          <div class="width-174 flex-display flex-row align-center">
            <input class="input-select w-100 bg-white ml-2"
                   type="text"
                   name="name"
                   :class="disableInputFields ? 'disabled-input' :''"
                   :disabled="disableInputFields"
                   v-model="requested_sessions"
            />
          </div>
        </div>

        <div class="flex-display flex-row align-center my-3" v-if="request_status === requestPartiallyApproved  && formType === 'update'">
          <label class="item-label font-size-14 font-default mb-0">Number of partially approved sessions:</label>
          <div class="width-184 flex-display flex-row align-center">
            <input class="input-select w-100 bg-white ml-2"
                   type="text"
                   name="name"
                   v-model="approvedSessions"
                   :class="disableInputFields ? 'disabled-input' :''"
                   :disabled="disableInputFields"
            />
          </div>
        </div>
        <div class="mt-4 font-regular font-size-14">
          <p class="item-label font-default mb-2">Recommendation:</p>
          <textarea class="width-384 height-375 mt-1 recommendation input-textArea form-group__input"
                    type="text"
                    name="name"
                    rows="4"
                    v-popover:requestSessionInputs.right
                    v-model="details"
                    :disabled="disableInputFields"
                    :class="disableInputFields ? 'disabled-input' : ''"
          />
        </div>

      </div>
      </v-card-text>
      <v-card-actions class="mb-5 d-block">
        <v-spacer></v-spacer>
        <div class="flex-display justify-center align-center">
          <a class="cancel-model" @click="hideModal">Cancel</a>
          <button class="btn modal-action-button" :disabled="disableInputFields && formType !== 'add'" @click="creatAdditionalSessionRequest">Save</button>
        </div>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import planHelper from "@/helpers/app/partner/planHelper";

export default {
  name: "AdditionalSessionRequestModal",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    formType: {
      type: String,
    },
    additionalSessionRequest: {
      type: Object,
      required: false
    },
    user_id: {
      type: String
    },
    voucherId: {
      type: Number
    },
    userVoucherId: {
      type: Number
    },
    plan: {
      type: Object
    },
    requestNumber: {
      type: Number
    },
    requestDate: {
      type: String
    },
    sessions: {
      type: Object
    },
    isAllowedOnPlanStatusRequest: {
      type: Boolean
    },
    isRequestAllowedOnPlan: {
      type: Boolean
    },
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    }
  },
  created() {
    if(this.formType === "add") {
      this.request_status =planHelper.requestPending;
      this.requestId = "";
      this.disabled = true;
    }else{
      this.requestId = this.additionalSessionRequest.id;
      this.disabled = false;

      if (! this.isRequestAllowedOnPlan &
          this.additionalSessionRequest.request_status.includes(planHelper.requestPartiallyApproved) &
          this.additionalSessionRequest.request_status.includes(planHelper.requestApproved) &
          this.additionalSessionRequest.request_status.includes('Rejected')
      ) {
        this.disableInputFields = true;
      }
    }
  },
  data() {
    return {
      isEverythingReady: true,
      date_requested: this.additionalSessionRequest.date_requested,
      id: this.requestNumber,
      request_number: this.additionalSessionRequest.request_number,
      request_status: this.additionalSessionRequest.request_status,
      requested_sessions: this.additionalSessionRequest.requested_sessions,
      approvedSessions: this.additionalSessionRequest.approved_sessions,
      details: this.additionalSessionRequest.details,
      requestId:"",
      requestPending: planHelper.requestPending,
      requestSubmitted: planHelper.requestSubmitted,
      requestApproved: planHelper.requestApproved,
      requestPartiallyApproved: planHelper.requestPartiallyApproved,
      requestRejected: planHelper.requestRejected,
      sessionRequest: null,
      popoverText: "",
      disabled: false,
      requestApprovedMessage: "",
      totalAuthorizedSessions: 0,
      disableInputFields: false,
    }
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    creatAdditionalSessionRequest() {
      if (this.request_status === planHelper.requestApproved){
        this.totalAuthorizedSessions = parseInt(this.sessions.authorized) + parseInt(this.requested_sessions);
      } else if (this.request_status === planHelper.requestPartiallyApproved) {
        this.totalAuthorizedSessions = parseInt(this.sessions.authorized) + parseInt(this.approvedSessions);
      } else {
        this.totalAuthorizedSessions = parseInt(this.sessions.authorized);
      }
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapy_plan_actions", {
        id: this.voucherId,
        plan_id : this.voucherId,
        request_action : this.formType,
        request_id: this.requestId,
        status: this.request_status,
        requested_sessions: this.requested_sessions,
        authorized_sessions: this.totalAuthorizedSessions,
        total_authorized_sessions: this.totalAuthorizedSessions,
        approved_sessions: this.approvedSessions,
        request_detail: this.details,
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.requestApprovedMessage = response.data.message;

        this.$emit('authorised-success-message',this.requestApprovedMessage)
        this.$emit('refresh-get-plan-details');
        this.$emit('refresh-get-plan-session-detail');
        this.$emit('refresh-get-additional-session-request');
        this.hideModal();
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
  },

}
</script>

<style scoped>
.font-size-14 {
  font-size: 14px;
}
.recommendation {
  border: 1px solid #D4D4D4;
  border-radius: 4px;
}
.additional-form {
  margin-top: 89px;
  margin-left: 95px;
  margin-right: 100px;
}
.additional-title {
  font-size: 16px;
  line-height: 16px;
  color: #283D47;
}
.disabled-input {
  background: #F1F1F1 !important;
}
</style>
