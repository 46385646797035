<template>
  <div
    id="chat_list_container"
    class="conversation-container h-100 chat-list-container"
    @scroll="scrollFunction">
    <div class="container-header">
      <div class="header-content col-md-12">
        <span class="content-text float-left">Start a conversation</span>
        <img
          :src="newConversationImage"
          class="float-right pointer"
          @click.stop="dialog=true">
      </div>
    </div>
    <div class="chat-filtration-toggle">
      <div class="toggle-switch">
        <label class="switch-label">Unread Messages Only</label>
        <label class="switch">
          <input
            v-model="unread_message_only"
            type="checkbox"
            name="is_30_min_session_allowed"
            true-value="1"
            false-value="0"
            @change="toggleUpdated">
          <span class="slider round"/>
        </label>
      </div>
    </div>
    <!--        <div class="search-bar-container">
            <input type="text" class="search-container" id="chat_list_search" v-model="keyword" v-on:keyup="keywordUpdated" placeholder="Type user's name to search">
            <span class="submit" @click="keywordUpdated"></span>
        </div>-->
    <div class="container-content">
      <template v-if="channels.length > 0">
        <div v-for="(channel, index) in channels">
          <recipient-container
            v-if="channel.members.length > 0"
            :channel="channel"
            :user_id="user_id"
            :default-user-image="defaultUserImage"
            @openConversation="openConversation"
          />
        </div>
      </template>
      <template v-else>
        <div class="pt-5">No recent conversation found.</div>
      </template>
    </div>

    <!-- Create New Conversation Modal -->
    <vue-dialog
      :visible="dialog"
      :user_id="user_id"
      :default-user-image="defaultUserImage"
      :all-users="allUsers"
      :search="search"
      @close="dialog=false"
      @newConversation="newConversation"
      @scroll="loadMoreUsers"
      @searchUsers="searchUsers"
    />
  </div>
</template>

<script>
    import vueDialog from './newConversationModal.vue'
    import Recipient from './recipient.vue'
    import recipientContainer from './recipientContainer'
    const user_type = 'app_admin'

    export default {
        components: { Recipient, vueDialog, recipientContainer },
        props: ['user_id', 'defaultUserImage', 'newConversationImage', 'allUsers', 'channels', 'search', 'chatSearchKeyword', 'unread_only'],
        data: function () {
            return {
                dialog: false,
                'default_sub_header': user_type === 'therapist' ? 'Your Connected Client' : 'Your connected Therapist',
                unread_message_only: 1,
                keyword: ''
            }
        },
        created: function () {
            this.unread_message_only = this.unread_only
            this.keyword = this.chatSearchKeyword
        },
        methods: {
            newConversation: function (data) {
                this.$emit('newConversation', data)
            },
            openConversation: function (data) {
                this.$emit('openConversation', data)
            },
            scrollFunction () {
                this.$emit('scroll')
            },
            hideModal () {
                this.dialog = false
            },
            loadMoreUsers () {
                this.$emit('loadMoreUsers')
            },
            searchUsers (search) {
                this.$emit('searchUsers', search)
            },
            toggleUpdated () {
                this.$emit('toggleUpdated', this.unread_message_only)
            },
            keywordUpdated () {
                if (this.keyword === '' || event.keyCode === 13) {
                    this.$emit('searchConversations', this.keyword)
                }
            }
        }

    }
</script>
<style scoped>
    .chat-filtration-toggle {
        border-top: 1px solid #F2F2F2;
        padding: 10px;
    }
    .chat-filtration-toggle .toggle-switch {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .chat-filtration-toggle .toggle-switch label {
        margin: 0 !important;
    }
    .search-bar-container {
        border-top: 1px solid #F2F2F2;
        padding: 5px 10px;
    }
    .search-bar-container input {
        margin: 0 !important;
        width: 100%;
    }
</style>
