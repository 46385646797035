<template>
  <v-dialog
    v-model="show"
    max-width="500px"
    min-height="388px">
    <v-card class="new-conversation-modal">
      <v-card-title class="modal_title position-relative">
        <p class="model-heading">Select Recipient</p>
        <img
          class="close-model"
          src="/icons/close_ic.svg"
          @click.stop="show=false">
      </v-card-title>

      <v-card-text>

        <div class="search-bar-container">
          <input
            id="search"
            v-model="keyword"
            type="text"
            class="search-container"
            placeholder="Type user's name or email to search"
            @keyup="keywordUpdated">
          <span
            class="submit"
            @click="searchUsers">
            <!--<p>Search</p>-->
            <!--<img src="/assets/images/search(popup)_ic.svg" alt="Search"/>-->
          </span>
        </div>
        <div
          id="recipients_container"
          class="recipients-container"
          @scroll="scrollFunction">
          <div v-for="(recipient, index) in allUsers">
            <recipient
              :default-user-image="defaultUserImage"
              :recipient="recipient"
              :type="recipient.type"
              :profile_image="isEmpty(recipient.profile_picture) ? defaultUserImage : recipient.profile_picture"
              :profile_header="recipient.name"
              :header_time="''"
              :profile_sub_header="recipient.profile_sub_header"
              :profile_text="recipient.type"
              :unread-message="false"
              :remove_border="false"
              :user_id="user_id"
              @openConversation="newConversation"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

    import Recipient from './recipient.vue'
    export default {
        components: { Recipient },
        props: ['visible', 'user_id', 'defaultUserImage', 'allUsers', 'search'],
        data: function () {
            return {
                keyword: this.search
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        created: function () {},
        methods: {
            newConversation (data) {
                this.$emit('newConversation', data)
            },
            scrollFunction () {
                this.$emit('scroll')
            },
            searchUsers () {
                this.$emit('searchUsers', this.keyword)
            },
            keywordUpdated (event) {
                if (this.keyword === '' || event.keyCode === 13) {
                    this.searchUsers()
                }
            }
        }
    }
</script>
