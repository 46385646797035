<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" alt="myonline therapy" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
                <div class="client-detail-link">
                    <p class="detail-item">
                      <a class="pointer" :href="'/client/details/' + rowData.client.id" target="_blank">View Client Details </a>
                    </p>

                  <p class="detail-item">
                    <span :class="{ 'slickdot-red':  (rowData.insurance ? !rowData.insurance.gender : true) }"></span>
                    <label class="item-label">Sex:</label>
                    <span class="item-value" v-if="rowData.insurance"> {{ rowData.insurance.gender }} </span>
                  </p>
                  <p class="detail-item">
                    <span :class="{ 'slickdot-red':  (rowData.insurance ? !rowData.insurance.date_of_birth : true) }"></span>
                        <label class="item-label">Date of Birth:</label>
                        <span class="item-value" v-if="rowData.insurance"> {{ formatDate(rowData.insurance.date_of_birth) }} </span>
                    </p>

                    <p class="detail-item">
                      <span :class="{ 'slickdot-red': (rowData.insurance ? !rowData.insurance.membership_number : true) }"></span>
                        <label class="item-label">Membership number:</label>
                        <span class="item-value" v-if="rowData.insurance"> {{ rowData.insurance.membership_number }} </span>
                    </p>

                    <p class="detail-item">
                      <span :class="{ 'slickdot-red':  (rowData.insurance ? !rowData.insurance.house_address : true) }"></span>
                        <label class="item-label">Address:</label>
                        <span class="item-value" v-if="rowData.insurance"> {{ rowData.insurance.house_address }} </span>
                    </p>

                    <p class="detail-item">
                      <span :class="{ 'slickdot-red':  (rowData.insurance ? !rowData.insurance.country : true) }"></span>
                        <label class="item-label">Country:</label>
                        <span class="item-value" v-if="rowData.insurance"> {{ getCountryNameFromCountryCode(rowData.insurance.country, countries) }} </span>
                    </p>

                    <p class="detail-item">
                      <span :class="{ 'slickdot-red':  (rowData.insurance ? !rowData.insurance.postal_code : true) }"></span>
                        <label class="item-label">Postcode:</label>
                        <span class="item-value" v-if="rowData.insurance"> {{ rowData.insurance.postal_code }} </span>
                    </p>

                  <p class="detail-item">
                    <span :class="{ 'slickdot-red':  !rowData.client.phone }"></span>
                    <label class="item-label">Phone:</label>
                    <span class="item-value"> {{ rowData.client.phone }} </span>
                  </p>

                  <p class="detail-item" v-if="rowData.client.email">
                    <label class="item-label">Email:</label>
                    <span class="item-value"> {{ rowData.client.email }} </span>
                  </p>

                  <p class="detail-item" v-if="icd10Enable" >
                    <span :class="{ 'slickdot-red':  (rowData.client.initial_assessment ? !rowData.client.initial_assessment.icd_10_code : true) }"></span>
                        <label class="item-label">ICD10 code:</label>
                        <span class="item-value"> {{ rowData.client.initial_assessment ? rowData.client.initial_assessment.icd_10_code : '' }} </span>
                    </p>
                    <p class="detail-item">
                      <span :class="{ 'slickdot-red':  !rowData.insurance_company }"></span>
                        <label class="item-label">Insurer:</label>
                        <span class="item-value"> {{ rowData.insurance_company }}</span>
                    </p>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import DatePicker from '../common/DatePicker.vue';
    import countryHelper from "@/helpers/countryHelper";
    import moment from "moment";

    export default {
        name: "InsuranceClientDetail",
        components: {DatePicker},
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            },
        },
        data () {
          return {
                modal_title : 'Client Detail',
                actionButtonTitle:'Save',
                icd10Enable : this.rowData.is_icd_enabled,
                countries: [],
            }
        },
        created(){
            this.modal_title = this.rowData.client.full_name + ' details';
            this.getCountries();
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            }
        },
        methods:{
            hide(){
                this.$emit('hide')
            },
            formatDate(date) {
              if (date && date !== '0000-00-00') {
               return moment(String(date)).format('DD/MM/YYYY');
              }
              return "";
            },
            getCountryNameFromCountryCode(code, countries) {
              return countryHelper.getCountryNameFromCountryCode(code, countries);
            },
            getCountries() {
              let loader = this.$loading.show();
              this.$http.get(this.url + "countries", {
              }).then((response) => {
                loader.hide();
                response.data.forEach((item) => {
                  let name = item.name === "United Kingdom of Great Britain and Northern Ireland" ? "United Kingdom" : item.name;
                  let value = item.name === "United Kingdom of Great Britain and Northern Ireland" ? "GB" : item.alpha2Code;
                  let data = { "value": value, "text": name };
                  this.countries.push(data);
                });
              }).catch((error) => {
                loader.hide();
              })
          },
        },
    }
</script>
<style scoped>

</style>
