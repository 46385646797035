<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Clinical assessments
            </p>
        </div>
        <div class="content">
            <div id="configuration">
              <v-tabs class="secondary-tabs">
                <v-tab key="treatment-plan">Treatment Overview</v-tab>
                <v-tab key="online-assessment">Online assessment</v-tab>
                <v-tab key="outcome-measures">Outcome measures</v-tab>
                <v-tab-item key="treatment-plan">
                  <treatment-plan
                                  :isReviewAllowed="isReviewAllowed"
                                  :client-treatment-plan="treatmentPlan"
                                  :clientId="clientId"
                                  :therapy-overview-warning="therapyOverviewWarning"
                                  @open-assessment-form="openAssessmentForm">
                  </treatment-plan>
                </v-tab-item>
                <v-tab-item key="online-assessment">
                  <online-assessment
                                     :clientId="clientId"
                  >
                  </online-assessment>
                </v-tab-item>
                <v-tab-item key="outcome-measures">
                  <outcome-measures
                                    :outcomeMeasureBanner="outcomeMeasureBanner"
                                    :show-client-outcome-measure="showOutcomeMeasure"
                                    :client-outcome-measure="outcomeMeasures"
                                    :clientId="clientId"

                  >
                  </outcome-measures>
                </v-tab-item>
              </v-tabs>

            </div>
        </div>
    </div>
</template>

<script>
    import TreatmentPlan from "@/components/people/client/clinicalAssessment/treatmentPlan";
    import OnlineAssessment from "@/components/people/client/clinicalAssessment/onlineAssessment";
    import OutcomeMeasures from "@/components/people/client/clinicalAssessment/outcomeMeasures";
    export default {
        name: 'clinicalAssessment',
      components: {OutcomeMeasures, OnlineAssessment, TreatmentPlan},
      props: {
          clientId: {
            required: true
          },
        },
        data() {
          return {
            treatmentPlan: null,
            isReviewAllowed: false,
            outcomeMeasures: null,
            showOutcomeMeasure: false,
            therapyOverviewWarning: {
              show_warning: false,
              text: "",
            },
            outcomeMeasureBanner: {
              heading: "",
              text: "",
              type: "",
              color: "",
              show_warning: false,
            }
          }
        },
        created() {
          this.getTreatmentPlan();
        },
        methods: {
          openAssessmentForm() {
            this.$emit('openAssessmentForm');
          },

           getTreatmentPlan() {
             this.$http.get(this.url + "client/treatment-plan", {
              params: {
                timezone: this.timezone,
                device_type: this.deviceType,
                client_id: this.clientId
              }
            }).then(response => {
              this.treatmentPlan = response.data.initial_assessment;
              this.isReviewAllowed = response.data.is_review_allowed;
              this.therapyOverviewWarning = response.data.therapy_overview_warning;
            }).catch(error => {
              this.$toastr("error", error.response.data.message, "");
            });
          },

        },

    }
</script>