<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <template v-if="activeView === 'read'">
                <div class="flex-display flex-row justify-content-space-between w-100">
                    <div class="flex-display flex-column w-100 justify-content-center">
                        <label class="switch-label m-0">Welcome video duration</label>
                    </div>
                    <div class="flex-row flex-display justify-content-end w-100 align-items-center">
                        <p class="m-0 pl-5 word-break" v-if="videoDuration">
                            {{videoDuration}} minute
                        </p>
                        <img class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="edit">
                    </div>
                </div>
            </template>
            <template v-else>
                <label class="switch-label m-0">Welcome video duration</label>
                <input type="number" class="form-control ml-2 mr-1" v-model="videoDuration"/>
                <button class="btn" :disabled="disable" @click="updateVideoDuration">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TherapistVideoDuration",
        props: {
            user_id: {
                required: true,
            },
            duration: {
                required: true
            },
        },
        data() {
            return {
                activeView: "read",
                videoDuration: this.duration,
                disable: true,
            }
        },
        methods: {
            edit() {
                this.activeView = "edit";
            },
            updateVideoDuration() {
                let loader = this.$loading.show();
                this.$http.put(this.url+"therapist/video/update", {
                    therapist_id: this.user_id,
                    video_duration: this.videoDuration
                }).then(response => {
                    loader.hide();
                    this.activeView = "read";
                    this.$toastr("success", response.data.message,"");
                }).catch(error => {
                    loader.hide();
                    this.$toastr("error", error.response.data.message,"");
                });
            }
        },
        watch: {
            videoDuration(value) {
                value ? this.disable = false : this.disable = true;
            }
        }
    }
</script>