<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Issues
            </p>
            <a class="create-new" href="#" @click="showIssueModal()">New Issue</a>
        </div>
        <div class="content">
            <div id="configuration">
                <div class="item-container border-0"
                     v-for="(issue, index) in selectedIssues"
                     :key="index"
                >
                    <p class="item-text">{{ issue }}</p>
                    <div class="item-actions">
                        <img class="cursor-pointer" src="/icons/delete_payout_ic.svg" @click="deleteConfirmation(issue)"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Issue -->
        <add-issue v-if="addIssueDialog"
                   :visible="addIssueDialog"
                   :issues="issues"
                   :selectedIssues="selectedIssues"
                   v-on:addIssue="addIssue"
                   v-on:hide="hideIssueModal"
        >
        </add-issue>

        <!-- delete dialog -->
        <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
                Are you sure?
            </template>
            <template slot="desc">
                This Issue will be deleted. This action cannot be undone.
            </template>
            <template slot="actions">
                <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                <button class="btn modal-action-button confirmation-delete" @click="deleteIssue">Delete</button>
            </template>
        </confirmation>
    </div>
</template>

<script>
    import AddIssue from './actions/AddIssue.vue';
    import Confirmation from '../common/Confirmation.vue';
    export default {
        name: 'Issues',
        components: {
            AddIssue,
            Confirmation
        },
        props: {
            user_id: {
                type: [Number, String],
                required: true
            },
            issues: {
                type: Array,
                required: true
            },
            selectedIssues: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                addIssueDialog: false,
                deleteDialog: false,
                allSelectedIssue: [],
                activeIssue: ''
            }
        },
        created() {
            this.allSelectedIssues = this.selectedIssues;
        },
        methods: {
            deleteConfirmation(issue) {
                this.deleteDialog = true;
                this.activeIssue =  issue;
            },
            hideConfirmation () {
                this.deleteDialog = false;
                this.activeIssue = '';
            },
            deleteIssue(){
                this.allSelectedIssues = this.removeArrayValue(this.activeIssue, this.allSelectedIssues);
                this.updateIssues();
                this.deleteDialog = false;
            },
            showIssueModal(){
                this.addIssueDialog = true;
            },
            hideIssueModal(){
                this.addIssueDialog  = false;
            },
            addIssue(issues){
                this.allSelectedIssues = issues;
                this.updateIssues();
            },
            updateIssues() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+'update_user_issues', {
                    user_id: this.user_id,
                    issues: this.allSelectedIssues.join(','),
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                    self.$emit('updateIssues', response.data.user.issues);
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                })
            },
            removeArrayValue(value, array) {
                const index = array.indexOf(value);
                if (index > -1) {
                    array.splice(index, 1);
                }
                return array;
            }
        }
    }
</script>