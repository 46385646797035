import { render, staticRenderFns } from "./TherapistName.vue?vue&type=template&id=0613d0e9&"
import script from "./TherapistName.vue?vue&type=script&lang=js&"
export * from "./TherapistName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports