<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>

                <div class="booking-details">
                    <div class="detail-item">
                        <div class="toggle-switch">
                            <label class="switch-label">Enable: &nbsp;</label>
                            <label class="switch">
                                <input type="checkbox" name="is_30_min_session_allowed" v-model="active" true-value="1" false-value="0" v-on:change="updateStatus">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Name:</label>
                        <span class="item-value"> {{ rowData.name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Code:</label>
                        <span class="item-value"> {{ rowData.promo_code.code }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Sessions:</label>
                        <span class="item-value"> {{ rowData.promo_code.sessions }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Discount:</label>
                        <span class="item-value"> {{ rowData.promo_code.discount }}%</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Commission:</label>
                        <span class="item-value"> {{ rowData.promo_code.commission }}%</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Times Used:</label>
                        <span class="item-value"> {{ rowData.promo_code.times_used }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Account Number:</label>
                        <span class="item-value"> {{ rowData.account_detail.account_number }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Sort Code:</label>
                        <span class="item-value"> {{ rowData.account_detail.sort_code }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Bank:</label>
                        <span class="item-value"> {{ rowData.account_detail.bank }}</span>
                    </div>
                </div>

            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <button class="btn modal-action-button confirmation-delete" @click="deletePromoter">Remove Promoter</button>
                <button class="btn modal-action-button" @click="editPromoter">Edit</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "PromoterDetail",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                modal_title : 'Promoter Detail',
                editUrl: '',
                active: this.rowData.status === 'active' ? 1 : 0
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide () {
                this.$emit('hide')
            },
            editPromoter () {
                this.$emit('edit');
            },
            deletePromoter () {
                this.$emit('delete');
            },
            updateStatus () {
                this.$emit('updateStatus', this.rowData.id, this.active);
            }
        },
        watch: {

        },
    }
</script>
<style scoped>

</style>