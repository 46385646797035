<template>
  <div v-if="switchPlan" class="user-progress">
    <div class="item-actions">
      <button @click="changePlan" class="btn-edit">{{ btnText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchVitalityHealthPlan',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data () {
    return {
      switchPlan: false,
    }
  },
  computed: {
    btnText: function () {
      if (this.rowData.plan_config_name === 'Vitality Core Plan') {
        this.switchToPlan = 'vitality-health-standard-plan';
        return 'Switch to Mental Health plan';
      }
      if (this.rowData.plan_config_name === 'Vitality Mental Health Plan') {
        this.switchToPlan = 'vitality-health-core-plan';
        return 'Switch to Core plan';
      }
    }
  },
  created() {
    if ( this.rowData.detailEvent ) {
      this.detailEvent = this.rowData.detailEvent;
    }
    if (this.rowData.plan_config_name === 'Vitality Mental Health Plan' ||
        this.rowData.plan_config_name === 'Vitality Core Plan')
    {
      this.switchPlan = true;
    }

  },
  methods: {
    changePlan () {
      this.$events.fire('switch-plan', this.rowData, this.switchToPlan)
    },
  },
}
</script>

<style scoped>

</style>
