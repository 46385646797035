<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <template v-if="activeView === 'read'">
                <div class="flex-display flex-row justify-content-space-between w-100">
                    <div class="flex-display flex-column justify-content-center">
                        <label class="switch-label bio-label m-0">Short Bio</label>
                    </div>
                    <div class="flex-row flex-display justify-content-end w-100 align-items-center">
                        <p class="m-0 pl-5 word-break">
                            {{therapistBio}}
                        </p>
                        <img class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editBio">
                    </div>
                </div>

            </template>
            <template v-else>
                <label class="switch-label bio-label m-0">Short Bio</label>
                <textarea v-model="therapistBio" class="form-control ml-2 mr-1" rows="4"></textarea>
                <button class="btn" @click="updateProfile">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TherapistBio',
        props: {
            bio: {
                type: String,
            }
        },
        data() {
            return {
                activeView: 'read',
                therapistBio: this.bio,
            }
        },
        methods: {
            editBio() {
                this.activeView = 'edit';
            },
            updateProfile() {
                this.$emit('updateProfile', this.therapistBio, 'short_bio');
                this.activeView = 'read';
            }
        },
    }
</script>
<style scoped>
    .bio-label {
        width: 65px !important;
    }
    .word-break {
        word-break: break-all;
        white-space: pre-line;
        text-align: left;
    }
</style>