<template>
    <div class="add-member-modal">
        <span @click="decrement" v-if="action === 'add'">
            <img src="/icons/back_ic.svg"/>
        </span>
        <form>
            <template v-if="action === 'edit'">
                <div class="form-group">
                    <label class="form-label">First Name</label>
                    <input v-model="form.first_name" class="input-text" type="text" placeholder="Enter first name"/>
                </div>
                <div class="form-group">
                    <label class="form-label">Last Name</label>
                    <input v-model="form.last_name" class="input-text" type="text" placeholder="Enter last name"/>
                </div>
            </template>
            <div class="form-group">
                <label class="form-label">Account Number</label>
                <input v-model="form.account_number" class="input-text" type="text" placeholder="Enter Bank Account Number"/>
            </div>
            <div class="form-group">
                <label class="form-label">Sort Code</label>
                <input v-model="form.sort_code" class="input-text" type="text" placeholder="Enter Bank Sort Code"/>
            </div>
            <div class="form-group">
                <label class="form-label">Bank/Building Society</label>
                <input v-model="form.bank" class="input-text" type="text" placeholder="Enter name of bank"/>
            </div>
        </form>
    </div>
</template>
<script>
    export default {
        name: "BankDetails",
        props: {
            promoData: {
                required: true,
                type: Object
            },
            action: {
                default: 'add'
            }
        },
        data: () => ({
            form: null
        }),
        created () {
            this.form = this.promoData;
        },
        methods:{
            decrement() {
                this.$emit('decrement');
            }
        },
        watch: {

        }
    }
</script>
<style scoped>

</style>