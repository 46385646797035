<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="mot" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="requirementForm"
            >
              <input type="hidden" v-model="requirement.id" name="id"/>
              <input type="hidden" :value="actionType" name="form_type"/>
              <!-- Requirement title -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Title</span>
                  <input class="input-text form-group__input"
                         type="text"
                         name="name"
                         v-model="requirement.title"
                         placeholder="Enter title"
                  />
                </label>
              </div>

              <!-- Requirement detail -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Detail</span>
                  <input class="input-text form-group__input"
                         type="text"
                         name="name"
                         v-model="requirement.detail"
                         placeholder="Enter detail"
                  />
                </label>
              </div>

              <!-- Requirement image -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Image</span>
                  <input class="input-text form-group__input"
                         type="text"
                         name="name"
                         v-model="requirement.image"
                         placeholder="Enter image"
                  />
                </label>
              </div>
            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitRequirement">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: "addRequirement",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: String
    },
    value: null,
  },
  data: () => ({
    modelHeader: '',
    modalTitle: '',
    actionButtonTitle: '',
    requirement: null
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    }
  },
  created() {
    if (this.actionType === 'add') {
      this.modalTitle = 'Add Requirement';
      this.actionButtonTitle = 'Add';
    } else {
      this.modalTitle = 'Update Requirement';
      this.actionButtonTitle = 'Update';
    }
    this.requirement = {
      ...this.value
    };

  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    validateSubmitRequest() {
      if (this.requirement.title === '') {
        this.showAlert('Please enter title.');
        return false;
      }
      return true;
    },
    submitRequirement() {
      if (this.validateSubmitRequest()) {
        this.$emit('submitRequirement', this.requirement);
      }
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    },
  }
}
</script>
