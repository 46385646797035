<template>
    <div class="bookingFlow__BoxWidth bookingFlow__w-80 ma-auto">
      <div class="bookingFlow__boxShadow flex-column  justify-start bg-white bookingFlow__padding">
        <div class="form-group" id="client_selection">
          <label class="form-label">Client</label>
          <div class="flex-display flex-row justify-space-between align-center bordered-container" v-if="preSelectedClient">
            <p class="mb-0">{{ preSelectedClient.full_name }}</p>
            <img @click="getAllClients" src="/icons/close_ic.svg" class="pointer width-20 height-20" alt="close">
          </div>
          <multi-select v-else
                        v-model="selectedClient"
                        :options="clients"
                        :allow-empty="false"
                        :show-labels="false"
                        :internal-search="false"
                        label="full_name"
                        track-by="id"
                        placeholder="Select client"
                        @search-change="searchClient"
                        :disabled="action === 'reschedule'"
          ></multi-select>
        </div>
        <div v-if="selectedTherapist" class="form-group" id="therapist_selection">
          <label class="form-label">Therapist</label>
          <p class="mb-0 bordered-container">{{ selectedTherapist.full_name }}</p>
        </div>
      </div>
      <div class="ma-auto m-t-80">
        <button
            class="d-inline-block white--text bg-blue-500  w-100 height-44 bold-font fs-16"
            :disabled="btnDisabled" @click="continueBtn" :class="disabled ? 'opacity-5': 'bg-blue-500'">
          Next
        </button>
      </div>
    </div>
</template>

<script>
import Back from "@/components/main/addBooking/components/back";
import MultiSelect from "vue-multiselect";

export default {
  name: "AddClientAndTherapist",
  components: {Back,MultiSelect},
  props: {
    action: {
      type: String,
      required: true
    },
    clients: {
      type: Array
    },
    preSelectedClient: {
      type: Object,
      default: null
    },
    client: {
      type: Object,
      default: null
    },
    therapist: {
      type: Object,
      default: null
    },
  },
    data() {
        return {
          disabled: true,
          selectedClient: null,
          selectedTherapist: null,
          clientUserName: "",
          typingTimer: 0,
          doneTypingInterval: 500,
        }
  },

  mounted: function() {
    const self = this;
    const client_selector = '#client_selection .multiselect .multiselect__content-wrapper';
    const therapist_selector = '#therapist_selection .multiselect .multiselect__content-wrapper';
    $(client_selector).scroll(function(){
      self.scrollFunction('client', client_selector);
    });
    $(therapist_selector).scroll(function(){
      self.scrollFunction('therapist', therapist_selector);
    });
    if (this.client) {
      this.selectedClient = this.client;
    }
    if (this.therapist) {
      this.selectedTherapist = this.therapist;
    }
  },
  computed: {
    btnDisabled: function () {
      this.disabled = !(this.selectedTherapist && this.selectedClient);
    }
  },
  methods: {
    scrollFunction(type, selector) {
      const container = document.querySelector(selector);
      if ( ( container.clientHeight + container.scrollTop + 5 ) >= ( container.scrollHeight ) ) {
        this.$emit('scroll', type);
      }
    },
    searchClient(query) {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        this.$emit('search', query, 'client');
      }, this.doneTypingInterval);
    },
    continueBtn() {
      this.$emit("increment");
    },
    updateTherapistOnClientSelection(showToast = false) {
      if (this.selectedClient.connected_therapist) {
        this.selectedTherapist = {...this.selectedClient.connected_therapist};
      } else {
        this.selectedTherapist = null;
        if (showToast && this.selectedClient) {
          this.$toastr('error','This client has no connected therapist','');
        }
        this.disabled = true;
      }
      this.$emit('clientSelected', this.selectedClient);
      this.clientUserName = this.selectedClient.username;
    },
    getAllClients() {
      this.selectedClient = null;
      this.selectedTherapist = null;
      this.$emit("getAllClients");
    },
  },
  watch: {
    'selectedClient': function () {
      if (this.selectedClient) {
        this.updateTherapistOnClientSelection(true);
      }
    },
    'selectedTherapist' : function () {
      if ( this.selectedTherapist ) {
        this.$emit('therapistSelected', this.selectedTherapist);
      }
    },
    'preSelectedClient' : function () {
      if (this.preSelectedClient) {
        this.selectedClient = {
          ...this.preSelectedClient
        };
      }
      if (this.selectedClient) {
        this.updateTherapistOnClientSelection();
      }
    },
  }
}

</script>

<style scoped>
 .bordered-container {
   border: 1px solid #D4D4D4;
   padding-left: 12px;
   padding-right: 5px;
   padding-top: 5px;
   padding-bottom: 5px;
 }
</style>
