<template>
  <div class="mt-5">
      <p class="bold-font w-100 mb-1 flex-display align-center mb-4 heading">
      {{ title }}</p>
    <div class="additional-session-request">

      <div class="padding-left-40 ">
        <div class="divider w-100">
          <div class="width-290">
              <span v-if="!additionalRequestAllowed"
                    class="d-block mb-2"
                    v-popover:requestSessions.right
                    id="requestSessions"
                    :id="!additionalRequestAllowed ? 'requestSessions' : ''">
                <a class="font-semi-bold create-request fs-14 font-default">Create a new request</a>
              </span>
            <span v-else>
                <a v-if="isRequestAllowedOnPlan" class="font-semi-bold create-request fs-14 mb-2 d-block" @click="createNewRequest()">Create a new request</a>
                <a v-else class="font-semi-bold create-request fs-14 mb-2 fs-14 font-default">Create a new request</a>
              </span>
            <popover name="requestSessions" class="request-popover" event="hover">
              <div class="flex-column">
                <p class="blue-500 fs-14 mb-0">
                  {{ additionalSessionBanner}}
                </p>
              </div>
            </popover>
          </div>
        </div>

      </div>

      <div class="mt-4 padding-left-40" v-if="additionalRequestData.length > 0">
        <div v-for="additionalRequest in additionalRequestData"
        :key="additionalRequest.id" class="divider mb-4">
          <p class="detail-item">
            <label class="item-label font-default font-weight-regular">Request number: </label>
            <span class="item-value font-semi-bold font-default"> {{ additionalRequest.request_number }} </span>
          </p>
          <p class="detail-item">
            <label class="item-label font-weight-regular font-default">Date requested: </label>
            <span class="item-value font-semi-bold font-default"> {{ additionalRequest.date_requested }}  </span>
          </p>
          <div class="detail-item">
            <label class="item-label font-weight-regular font-default mb-4">Request status:  </label>
            <span class="item-value font-semi-bold font-default"> {{ additionalRequest.request_status }}
              <span v-if="additionalRequest.request_status === pendingRequest" class="pl-1">
                <img class="width-16 height-16"  src="https://assets.myonlinetherapy.com/images/icons/booking/booking/warning_ic.svg" alt="mot">
                <span class="item-value font-regular fs-13 red-300 pl-2 font-default"> Submit request to Vitality and update status  </span>
              </span>
            </span>


          </div>
          <p class="detail-item">
            <label class="item-label font-weight-regular font-default">Number of sessions requested: </label>
            <span class="item-value font-semi-bold font-default"> {{ additionalRequest.requested_sessions }} </span>
          </p>
          <p class="create-request cursor-pointer fs-14 font-default mb-0 pt-1" @click="editAdditionalRequest(additionalRequest)">View or edit request</p>
        </div>
      </div>


    </div>
    <additional-session-request-modal v-if="additionalRequestDialogue"
                                      :visible="additionalRequestDialogue"
                                      v-on:hide="additionalRequestDialogue = false"
                                      :form-type="formType"
                                      :additionalSessionRequest="additionalSessionRequest"
                                      v-on:refresh-get-plan-session-detail="refreshGetPlanSessionDetail"
                                      v-on:refresh-get-additional-session-request="RefreshGetAdditionalSessionRequest"
                                      :user_id="user_id"
                                      :voucher-id="voucherId"
                                      :user-voucher-id="userVoucherId"
                                      :requestNumber="requestNumber"
                                      :plan="plan"
                                      :requestDate="requestDate"
                                      :additionalSessionBanner="additionalSessionBanner"
                                      :sessions="sessions"
                                      :isAllowedOnPlanStatusRequest="isRequestAllowedOnPlanStatus"
                                      :isRequestAllowedOnPlan="isRequestAllowedOnPlan"
                                      :sessionsAuthorizedOnPlan="sessionsAuthorizedOnPlan"

                                      >
    </additional-session-request-modal>
  </div>
</template>

<script>
import AdditionalSessionRequestModal
  from "@/components/main/planDetails/AdditionalSessionRequestModal";
import planHelper from '@/helpers/app/partner/planHelper'
export default {
  name: "AdditionalSessionRequest",
  components: {
    AdditionalSessionRequestModal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    additionalRequestData: {
      type: Array,
    },
    additionalRequestAllowed: {
      type: Boolean,
    },
    user_id: {
      type: String,
    },
    voucherId: {
      type: Number
    },
    userVoucherId: {
      type: Number
    },
    plan: {
      type: Object,
    },
    currentDate: {
      required:false
    },
    additionalSessionBanner: {
      type: String
    },
    sessions: {
      type: Object,
    },
    isRequestAllowedOnPlan: {
      type: Boolean
    },
  },
  data() {
    return {
      disableCreateRequestButton: false,
      pendingRequest: planHelper.requestPending,
      additionalRequestDialogue: false,
      additionalSessionRequest: {},
      formType: "add",
      requestPending: planHelper.requestPending,
      requestNumber: 0,
      requestDate: null,
      isRequestAllowedOnPlanStatus: false,
    }
  },
  methods:{
    refreshGetPlanSessionDetail() {
      this.$emit('get-plan-session-detail');
    },
    RefreshGetAdditionalSessionRequest() {
      this.$emit('get-additional-session-request');
    },
    createNewRequest() {
      this.requestNumber = this.additionalRequestData.length + 1;
      this.requestDate = this.currentDate;
      this.additionalSessionRequest = {}
      this.additionalRequestDialogue = true;
      this.formType = "add";
      this.isRequestAllowedOnPlanStatus = true;
    },
    editAdditionalRequest(additionalSessionData){
      this.requestNumber = additionalSessionData.request_number;
      this.requestDate = additionalSessionData.date_requested;
      this.additionalSessionRequest = additionalSessionData
      this.additionalRequestDialogue = true;
      this.formType = "update";
      this.isRequestAllowedOnPlanStatus = this.isRequestAllowedOnPlan;
    }
  }
}
</script>

<style scoped>
.item-label{
  line-height: 17px !important;
  letter-spacing: .3px !important;
  font-size:14px !important;
}
.item-value{
  line-height: 17px !important;
  letter-spacing: .3px !important;
  font-size:14px !important;
}
.heading {
  font-size: 18px;
  color: #283D47;
  line-height: 18px;
}
.create-request {
  color: #3C9DD8 !important;
  line-height: 14px;
  font-weight: 600 !important;
}
.divider {
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 10px;
}
.padding-left-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
</style>
