<template>
  <v-dialog
    v-model="show"
    :class="'overflow-scroll'"
    persistent
    min-height="263px">
    <v-card
      v-if="isEverythingReady"
      :class="'invoice-popup-width'">
      <v-card-title>
        <h1 class="model-heading ml-2">{{ modal_title }}</h1>
        <img
          alt="close"
          class="close-model mr-2"
          src="/icons/close_ic.svg"
          @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <div class="booking-details flex-display flex-row w-100">
          <!-- Invoice Details -->
          <invoice-details
            :invoice="claim"
            :is-service-charges-invoice="isServiceChargesInvoice"
            :is-bulk-invoicing-enabled="isBulkInvoicingEnabled"
            @claimUpdated="claimChanged"/>

          <div class="flex-display flex-column p-l-34 pr-3 border-right-1px">

            <!-- Claim Workflow -->
            <claim-work-flow
              :invoice="claim"
              :show-amount-paid-by-org-error="showAmountPaidByOrgError"
              :remittance-form-editable="remittanceFormEditable"
              :claim-status-editable="claimStatusEditable"
              :third-party-liability-editable="thirdPartyLiabilityEditable"
              @claimUpdated="claimChanged"/>

            <!-- Payout Workflow -->
            <payout-work-flow :claim="claim"/>
          </div>

          <div class="flex-display flex-column">

            <!-- Collection Workflow -->
            <collection-work-flow
              :invoice="claim"
              :collection-status-editable="collectionStatusEditable"
              @claimUpdated="claimChanged"/>

            <!-- Collection Action -->
            <collection-actions :collection-actions="claim.collection_actions"></collection-actions>

            <!-- Action Buttons -->
            <div class="flex-display flex-row w-100 justify-end align-center mt-4 pr-3">
              <v-spacer/>
              <a
                class="cancel-model"
                @click="hide">Cancel</a>
              <button
                :disabled="disableSave"
                class="btn modal-action-button"
                @click="submit">
                {{ actionButtonTitle }}
              </button>
            </div>
          </div>
        </div>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>
<script>
import DatePicker from '../../common/DatePicker.vue'
import therapyHelper from '@/helpers/app/therapyHelper'
import stringHelper from '@/helpers/stringHelper'
import regexHelper from '@/helpers/regexHelper'
import moment from 'moment'
import invoiceDetails from './invoiceDetails'
import claimWorkFlow from './claimWorkFlow'
import payoutWorkFlow from './payoutWorkFlow'
import collectionWorkFlow from './collectionWorkFlow'
import collectionActions from './collectionActions'

export default {
    name: 'Index',
    components: {
        DatePicker,
        invoiceDetails,
        claimWorkFlow,
        payoutWorkFlow,
        collectionWorkFlow,
        collectionActions
    },
    props: {
        visible: {
            required: true,
            type: Boolean
        },
        rowData: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isBulkInvoicingEnabled: this.rowData.enable_bulk_invoicing,
            isServiceChargesInvoice: this.checkIsServiceChargesInvoice(),
            modal_title: this.getModalTitle(this.rowData),
            actionButtonTitle: 'Save',
            isEverythingReady: false,
            claim: null,
            requiresAmountPaidByOrg: false,
            showAmountPaidByOrgError: false,
            remittanceFormEditable: false,
            claimStatusEditable: false,
            collectionStatusEditable: false,
            thirdPartyLiabilityEditable: false
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
        disableSave: function () {
            return this.showAmountPaidByOrgError
        }
    },
    watch: {
        'claim.invoice_status': function (value) {
            this.invoiceStatusUpdated()
        },
        'claim.claim_status': function (value) {
            this.claimStatusUpdated()
        },
        'claim.collection_status': function (value) {
            this.collectionStatusUpdated()
        },
        'claim.payment_status': function (value) {
            this.paymentStatusUpdated()
        },
        'claim.client_copayment': function (value) {
            this.outstandingAmountUpdated()
        },
        'claim.amount_paid_by_org': function (newVal, oldVal) {
            if (newVal) {
                newVal = regexHelper.filterNumericAndDecimalsOnly(newVal)
                if (newVal.includes('.')) {
                    this.claim.amount_paid_by_org = newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2)
                }
                if (parseFloat(this.claim.amount_paid_by_org) > parseFloat(this.claim.price)) {
                    this.claim.amount_paid_by_org = this.claim.price
                }
            }
            this.amountPaidByOrgUpdated()
        },
        'claim.remittance_id': function (value) {
            this.claim.remittance_id = regexHelper.filterAlphaNumeric(value)
        },
        'claim.who_get_paid': function (value) {
            this.updateFieldsVisibility()
        }
    },
    created () {
        this.initializeClaim()
        this.updateClaimStatuses()
        this.isEverythingReady = true
    },
    methods: {
        hide () {
            this.$emit('hide')
        },
        getModalTitle (rowData) {
            return 'Invoice Id: ' + this.rowData.invoice_id
        },
        initializeClaim () {
            this.claim = {
                ...this.rowData,
                collection_status: this.rowData.collection_status ? this.rowData.collection_status : therapyHelper.collectionStatusNA,
                charge_client_outstanding_amount: false,
                remittance_date: this.rowData.remittance_date ? this.formatDate(this.rowData.remittance_date) : '',
                payment_date: this.rowData.payment_date ? this.formatDate(this.rowData.payment_date) : '',
                payment_status: this.rowData.therapist_fte_status ? therapyHelper.paymentStatusNA : this.rowData.payment_status
            }
        },
        updateClaimStatuses () {
            //this.invoiceStatusUpdated()
            //this.claimStatusUpdated()
            //this.collectionStatusUpdated()
            this.updateFieldsVisibility()
        },
        claimChanged (claim) {
            this.claim = claim
        },
        clearPaymentDate () {
            this.claim.payment_date = ''
        },
        clearRemittanceDate () {
            this.claim.remittance_date = ''
        },
        resetFlagsForAmount () {
            this.claim.client_copayment = this.rowData.client_copayment
            this.claim.amount_paid_by_org = this.rowData.amount_paid_by_org
            this.thirdPartyLiabilityEditable = false
            this.requiresAmountPaidByOrg = false
            this.showAmountPaidByOrgError = false
        },
        updateFieldsVisibility () {
            this.manageCollectionStatusesVisibility()
            this.manageRemittanceFieldsVisibility()
        },
        manageCollectionStatusesVisibility () {
            // Client Collection Status
            this.collectionStatusEditable = this.claim.collection_status !== therapyHelper.collectionStatusNA && this.claim.collection_status !== therapyHelper.collectionStatusPaidByClient

            // Amount paid by org and client amount outstanding
            if (this.claim.claim_status === therapyHelper.claimStatusPartiallyPaid) {
                this.thirdPartyLiabilityEditable = true
            }
        },
        manageRemittanceFieldsVisibility () {
            /* Remittance Data Handling */
            let claimStatuses = [
                therapyHelper.claimStatusPartiallyPaid,
                therapyHelper.claimStatusClientToPay,
                therapyHelper.claimStatusRejected,
                therapyHelper.claimStatusRejectedPending,
                therapyHelper.claimStatusPaidInFull
            ]
            this.remittanceFormEditable = claimStatuses.includes(this.claim.claim_status)
        },
        invoiceStatusUpdated () {
            console.log('Invoice Status: ' + this.claim.invoice_status)
            // reset claim status if invoice is submitted to 3rd party again
            if (this.rowData.invoice_status !== therapyHelper.invoiceStatusClaimWithOrg &&
                this.claim.invoice_status === therapyHelper.invoiceStatusClaimWithOrg
            ) {
                this.claim.claim_status = therapyHelper.claimStatusNA
            }

            this.updateFieldsVisibility()

            // if claim is not valid, amount is outstanding from client
            if (this.claim.invoice_status === therapyHelper.invoiceStatusClientPaymentOutstanding &&
                (
                    stringHelper.empty(this.claim.claim_status) ||
                    this.claim.claim_status === therapyHelper.claimStatusNA
                )
            ) {
                this.claim.collection_status = therapyHelper.collectionStatusAmountOutstanding
            }

            if (this.claim.invoice_status === therapyHelper.invoiceStatusWriteOffClientOutstanding) {
                this.claim.collection_status = therapyHelper.collectionStatusClientBadDebt
            }

            this.claimStatusEditable = [
                therapyHelper.invoiceStatusClientPaymentOutstanding,
                therapyHelper.invoiceStatusClaimWithOrg,
                therapyHelper.invoiceStatusCancelled,
                therapyHelper.invoiceStatusPaid
            ].includes(this.claim.invoice_status)

            // set payment status based on invoice status
            if (
                ![
                    therapyHelper.paymentStatusInExternalSystem,
                    therapyHelper.paymentStatusPaidToPsychologist,
                    therapyHelper.paymentStatusInvoiceSent,
                    therapyHelper.paymentStatusInvoiceSettled
                ].includes(this.claim.payment_status)
            ) {
                this.claim.payment_status = [
                    therapyHelper.invoiceStatusPaid,
                    therapyHelper.invoiceStatusWriteOffPayToPsychologist
                ].includes(this.claim.invoice_status) ? therapyHelper.paymentStatusReadyToPayout : ''
            }

            this.updateClientAmountOutstanding()
            this.updateInvoiceOutstandingAmount()
        },
        claimStatusUpdated () {
            console.log('Claim Status: ' + this.claim.claim_status)
            this.updateFieldsVisibility()
            this.claim.amount_paid_by_org = this.rowData.amount_paid_by_org
            if (this.claim.claim_status !== this.rowData.claim_status) {
                this.claim.collection_status = therapyHelper.collectionStatusNA
                //this.claim.payment_status = null
                switch (this.claim.claim_status) {
                    case therapyHelper.claimStatusNA:
                        this.resetFlagsForAmount()
                        break
                    case therapyHelper.claimStatusClientToPay:
                        this.thirdPartyLiabilityEditable = false
                        this.claim.invoice_status = therapyHelper.invoiceStatusClientPaymentOutstanding
                        this.claim.collection_status = therapyHelper.collectionStatusAmountOutstanding
                        this.claim.client_copayment = this.claim.price
                        this.claim.amount_paid_by_org = 0
                        this.requiresAmountPaidByOrg = false
                        this.showErrorForPaidByOrgAmount()
                        break
                    case therapyHelper.claimStatusPartiallyPaid:
                        this.thirdPartyLiabilityEditable = true
                        this.claim.invoice_status = therapyHelper.invoiceStatusClientPaymentOutstanding
                        this.claim.collection_status = therapyHelper.collectionStatusAmountOutstanding
                        this.requiresAmountPaidByOrg = true
                        this.showErrorForPaidByOrgAmount()
                        break
                    case therapyHelper.claimStatusPaidInFull:
                        this.thirdPartyLiabilityEditable = false
                        this.claim.invoice_status = therapyHelper.invoiceStatusPaid
                        this.claim.payment_status = therapyHelper.paymentStatusReadyToPayout
                        this.claim.client_copayment = 0
                        this.claim.amount_paid_by_org = this.claim.price
                        this.requiresAmountPaidByOrg = false
                        this.showErrorForPaidByOrgAmount()
                        break
                    case therapyHelper.claimStatusRejected:
                        this.thirdPartyLiabilityEditable = false
                        this.claim.invoice_status = therapyHelper.invoiceStatusClientPaymentOutstanding
                        this.claim.collection_status = therapyHelper.collectionStatusAmountOutstanding
                        this.claim.client_copayment = null
                        this.claim.amount_paid_by_org = null
                        this.requiresAmountPaidByOrg = false
                        this.showErrorForPaidByOrgAmount()
                        break
                    case therapyHelper.claimStatusRejectedPending:
                        this.resetFlagsForAmount()
                        this.claim.invoice_status = therapyHelper.invoiceStatusDecisionRequired
                        this.claim.client_copayment = null
                        this.claim.amount_paid_by_org = null
                        break
                    default:
                        break
                }
                this.updateClientAmountOutstanding()
            }
        },
        collectionStatusUpdated () {
            console.log('Collection Status: ' + this.claim.collection_status)
            if (this.claim.collection_status !== this.rowData.collection_status) {
                this.updateFieldsVisibility()
                this.collectionStatusEditable = this.claim.collection_status !== therapyHelper.collectionStatusNA && this.claim.collection_status !== therapyHelper.collectionStatusPaidByClient

                if (this.claim.collection_status === therapyHelper.collectionStatusAmountOutstanding &&
                    this.claim.invoice_status !== therapyHelper.invoiceStatusClientPaymentOutstanding
                ) {
                    this.claim.invoice_status = therapyHelper.invoiceStatusClientPaymentOutstanding
                }

                if (this.claim.collection_status === therapyHelper.collectionStatusClientBadDebt &&
                    this.claim.invoice_status !== therapyHelper.invoiceStatusWriteOffClientOutstanding
                ) {
                    this.claim.invoice_status = therapyHelper.invoiceStatusWriteOffClientOutstanding
                }

                if (this.claim.collection_status === therapyHelper.collectionStatusPaidByClient &&
                    this.claim.invoice_status !== therapyHelper.invoiceStatusPaid
                ) {
                    this.claim.invoice_status = therapyHelper.invoiceStatusPaid
                }

                this.updateClientAmountOutstanding()
            }
        },
        paymentStatusUpdated () {
            console.log('Payment Status: ' + this.claim.payment_status)
            if (this.rowData.payment_status !== this.claim.payment_status) {
                this.claim.payment_date = ''
            }
        },
        outstandingAmountUpdated () {
            if (
                [
                    therapyHelper.claimStatusClientToPay, therapyHelper.claimStatusRejected, therapyHelper.claimStatusPartiallyPaid
                ].includes(this.claim.status) && parseInt(this.claim.client_copayment) <= 0
            ) {
                this.claim.client_copayment = ''
            }
            if (parseFloat(this.claim.client_copayment) > parseFloat(this.claim.price)) {
                this.claim.client_copayment = this.claim.price.toString()
            }
            if (!stringHelper.empty(this.claim.client_copayment)) {
                this.claim.client_copayment = this.claim.client_copayment.toString()
                this.claim.client_copayment = regexHelper.filterNumericAndDecimalsOnly(this.claim.client_copayment)
            }
        },
        amountPaidByOrgUpdated () {
            this.showErrorForPaidByOrgAmount()
            if (this.claim.amount_paid_by_org) {
                this.claim.client_copayment = this.claim.price - this.claim.amount_paid_by_org
                if (this.claim.claim_status === therapyHelper.claimStatusPartiallyPaid) {
                    this.claim.client_amount_outstanding = this.claim.client_copayment
                    if (this.claim.invoice_status === therapyHelper.invoiceStatusClientPaymentOutstanding) {
                        this.claim.claim_amount_outstanding = this.claim.client_amount_outstanding
                    }
                }
            }
        },
        updateInvoiceOutstandingAmount () {
            switch (this.claim.invoice_status) {
                case therapyHelper.invoiceStatusDraft:
                case therapyHelper.invoiceStatusCancelled:
                    this.claim.claim_amount_outstanding = null
                    break
                case therapyHelper.invoiceStatusNew:
                case therapyHelper.invoiceStatusInformationMissing:
                case therapyHelper.invoiceStatusDecisionRequired:
                case therapyHelper.invoiceStatusClaimWithOrg:
                case therapyHelper.invoiceStatusWriteOffPayToPsychologist:
                    this.claim.claim_amount_outstanding = this.claim.price
                    break
                case therapyHelper.invoiceStatusPaid:
                    this.claim.claim_amount_outstanding = 0
                    break
                case therapyHelper.invoiceStatusClientPaymentOutstanding:
                case therapyHelper.invoiceStatusWriteOffClientOutstanding:
                    this.claim.claim_amount_outstanding = this.claim.client_amount_outstanding
                    break
                default:
                    break
            }
        },
        updateClientAmountOutstanding () {
            if (this.claim.invoice_status === therapyHelper.invoiceStatusClientPaymentOutstanding) {
                this.claim.client_amount_outstanding = this.claim.price
            }
            if (this.claim.claim_status === therapyHelper.claimStatusRejected) {
                this.claim.client_amount_outstanding = this.claim.price
            }
            if ([therapyHelper.claimStatusClientToPay, therapyHelper.claimStatusPartiallyPaid].includes(this.claim.claim_status)) {
                this.claim.client_amount_outstanding = this.claim.client_copayment
            }
            if (this.claim.collection_status === therapyHelper.collectionStatusPaidByClient) {
                this.claim.client_amount_outstanding = 0
            }
        },
        checkIsServiceChargesInvoice () {
            return !!(this.rowData.booking === null &&
                !this.rowData.enable_bulk_invoicing &&
                this.rowData.enable_service_charges)
        },
        showErrorForPaidByOrgAmount () {
            this.showAmountPaidByOrgError = this.requiresAmountPaidByOrg && stringHelper.empty(this.claim.amount_paid_by_org)
        },
        formatDate (date) {
            let d, month, day, year
            d = new Date(date)
            month = '' + (d.getMonth() + 1)
            day = '' + d.getDate()
            year = d.getFullYear()

            if (month.length < 2) month = '0' + month
            if (day.length < 2) day = '0' + day

            return year + '-' + month + '-' + day
        },
        validatesSubmit () {
            if (this.claim.invoice_status === therapyHelper.invoiceStatusCancelled &&
                this.claim.collection_status !== therapyHelper.collectionStatusNA &&
                (
                    !stringHelper.empty(this.claim.claim_status) ||
                    !stringHelper.empty(this.claim.collection_status) ||
                    !stringHelper.empty(this.claim.payment_status)
                )
            ) {
                this.$toastr('error', 'Invoice status cannot be updated to cancelled', '')
                return false
            }
            return true
        },
        submit () {
            let isValidated = this.validatesSubmit()
            if (isValidated) {
                let loader = this.$loading.show()
                this.$http.put(this.url + 'insurance/claim/edit', {
                    id: this.claim.id,
                    claim_status: this.claim.claim_status === 'N/A' ? null : this.claim.claim_status,
                    client_copayment: this.claim.client_copayment,
                    payment_status: this.claim.payment_status,
                    payment_id: this.claim.payment_id,
                    payment_date: this.claim.payment_date,
                    who_get_paid: this.claim.who_get_paid,
                    charge_client_outstanding_amount: this.claim.charge_client_outstanding_amount ? 'true' : 'false',
                    remittance_id: this.claim.remittance_id,
                    remittance_date: this.claim.remittance_date,
                    remittance_method: this.claim.remittance_method,
                    invoice_status: this.claim.invoice_status,
                    collection_status: this.claim.collection_status === therapyHelper.collectionStatusNA ? null : this.claim.collection_status,
                    amount_paid_by_org: this.claim.amount_paid_by_org,
                    client_amount_outstanding: this.claim.client_amount_outstanding
                }).then((response) => {
                    this.$toastr('success', response.data.message, '')
                    this.$events.fire('vuetable:reload')
                    //this.$emit('hide')
                    loader.hide()
                }).catch((error) => {
                    loader.hide()
                    let errorMessage = error.response.data.message ? error.response.data.message : ''
                    this.$toastr('error', errorMessage, '')
                })
            }
        }
    }
}
</script>
