<template>
  <div
    id="activitySection"
    class="details-section client-top-section">
    <div class="header">
      <p class="title">Activity</p>
    </div>
    <div class="client-top-section-content">
      <template v-if="activities.length > 0">
        <div
          v-for="(activity,index) in activities"
          :key="index"
          class="row ml-0 mr-0 activity-row">
          <div class="col-md-4">
            <p>{{ activity.date }}</p>
          </div>
          <div class="col-md-6">
            <p
              :class="index === 0 ? 'active-row' : ''"
              class="description">
              {{ activity.description }}
              <a
                v-if="activity.booking"
                :href="'/bookings?booking_id='+activity.booking.id"
                target="_blank">({{ activity.booking.id }})</a>
            </p>
          </div>
          <div class="col-md-2">
            <p class="text-right">{{ activity.action_by }}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="mt-2 text-center">There is no activity yet.</p>
      </template>

    </div>
  </div>
</template>

<script>
    export default {
        name: 'Activity',
        props: {
          activities: {
              default: null
          }
        }
    }
</script>
<style scoped>
   .activity-row {
       height: 40px;
   }
   .activity-row p {
       height: 16px;
       color: #949494;
       font-family: metropolisRegular;
       font-size: 13px;
       letter-spacing: 0.3px;
       line-height: 16px;
       margin-bottom: 0;
       padding: 20px 0;
   }
   .activity-row .description::before {
       content:"\A";
       border-radius: 50%;
       border: 1px solid #308BC3;
       display: inline-block;
       height: 5px;
       width: 5px;
       margin-right: 10px;
   }
   .active-row::before {
       content:"\A";
       border-radius: 50%;
       border: 1px solid #308BC3;
       display: inline-block;
       height: 5px;
       width: 5px;
       background-color: #308BC3;
   }
   .client-top-section-content {
       height: 100%;
   }
</style>
