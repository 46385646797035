<template>
  <div class="my-5">
    <table class="table">
      <thead>
      <tr>
        <th scope="" class="item-heading">Session ID</th>
        <th scope="" class="item-heading">Booking date</th>
        <th scope="" class="item-heading">Session date</th>
        <th scope="" class="item-heading">Therapist</th>
        <th scope="" class="item-heading">Session type</th>
        <th scope="" class="item-heading">Initial / Ongoing</th>
        <th scope="" class="item-heading">Session Status</th>
      </tr>
      </thead>
      <tbody>
      <template v-if="linkedSessions.length > 0">
        <tr v-for="linkedSession in linkedSessions">
          <td class="item-text text-capitalize fs-14 cursor-pointer session" @click="bookingDetail(linkedSession)">{{ linkedSession.session_id }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.booking_date }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.session_date }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.therapist }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.session_type }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.initial_ongoing }}</td>
          <td class="item-text text-capitalize fs-14">{{ linkedSession.session_status }}</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="8" class="empty-result">No Data Available</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "LinkedSessions",
  props: {
    planId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      linkedSessions: [],
      isEverythingReady: true,
    }
  },
  created() {
    this.getLinkedSessions();
  },
  methods: {
    getLinkedSessions() {
      let loader = this.$loading.show()
      this.isEverythingReady = false
      this.$http.get(this.url + 'client/get-linked-sessions', {
        params: {
          plan_id: this.planId,
        }
      }).then(response  => {
        this.linkedSessions = response.data;
        loader.hide();
        this.isEverythingReady = true;

      }).catch(error => {
        loader.hide()
        this.isEverythingReady = true
        this.hideModal();
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },
  }
}
</script>

<style scoped>
.session {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
   color: #3C9DD8 !important;
}
.empty-result {
  text-align: center;
  border-bottom: 1px solid rgba(0,0,0,.06);
}
.item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #283D47;
  padding-top: 12px;
  padding-bottom: 15px;
}
.item-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #283D47;
  padding-bottom: 8px;
}
</style>
