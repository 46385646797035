<template>
    <div class="add-member-modal">
        <span @click="decrement">
            <img src="/icons/back_ic.svg"/>
        </span>
        <form>
            <div class="form-group">
                <label class="form-label">Code</label>
                <input v-model="form.promo_code" class="input-text" type="text" placeholder="Create\ Code"/>
            </div>
            <div class="form-group">
                <label class="form-label">How many sessions?</label>
                <input v-model="form.sessions" class="input-text" type="number" placeholder="Enter value"/>
            </div>
            <div class="form-group">
                <label class="form-label">Discount (%)</label>
                <input v-model="form.discount" class="input-text" type="number" min="0" max="100" placeholder="Enter value"/>
            </div>
            <div class="form-group">
                <label class="form-label">Commission (%)</label>
                <input v-model="form.commission" class="input-text" type="number" min="0" max="100" placeholder="Enter value"/>
            </div>
        </form>
    </div>
</template>
<script>
    export default {
        name: "PromoDetails",
        props: {
            promoData: {
                required: true,
                type: Object
            }
        },
        data: () => ({
            form: ''
        }),
        created () {
            this.form = this.promoData;
        },
        methods:{
            decrement() {
                this.$emit('decrement');
            }
        },
        watch: {

        }
    }
</script>
<style scoped>

</style>