<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                {{ headerTitle }}
            </p>
            <slot name="data-table-actions"></slot>
        </div>
        <div class="content">
            <data-table v-if="areBookingsLoaded"
                        :isDetail="isDetail"
                        :fields="bookings.fields"
                        :data="bookings.data"
            ></data-table>
          <infinite-loading :identifier="infiniteId"  @infinite="infiniteHandler">
            <span slot="no-results"></span>
          </infinite-loading>
        </div>

        <add-booking v-if="addBookingDialog"
                     :visible="addBookingDialog"
                     :action="this.action"
                     :booking="this.selectedBooking"
                     v-on:addBooking="addBooking"
                     v-on:reschedule="rescheduleSession"
                     v-on:hide="addBookingDialog=false"
        >
        </add-booking>

         <!-- Booking Details Modal -->
        <booking-detail v-if="bookingDetailDialog"
                        :visible="bookingDetailDialog"
                        :rowData="selectedBooking"
                        v-on:reschedule="showRescheduleModal"
                        v-on:refreshAfterCancel="refreshAfterCancel"
                        v-on:refreshAfterSave="refreshBookingData"
                        v-on:openCallLogs="openCallLogs"
                        v-on:hide="hideDetailModal"
        >
        </booking-detail>

        <!-- Booking Call Logs -->
        <booking-call-logs v-if="callLogsDialog"
                           :visible="callLogsDialog"
                           :rowData="selectedBooking"
                           v-on:hide="closeCallLogs"
        >
        </booking-call-logs>
    </div>
</template>

<script>
    import DataTable from "../common/dataTable/DataTableDataMode.vue";
    import BookingDetail from "./BookingDetail";
    import BookingCallLogs from "./BookingCallLogs";
    import AddBooking from "../main/AddBooking";
    import routeHelper from "@/helpers/app/routeHelper";
    import InfiniteLoading from 'vue-infinite-loading';
    export default {
        name: 'Bookings',
        components: {
            DataTable,
            BookingDetail,
            BookingCallLogs,
            AddBooking,
            InfiniteLoading
        },
        props: {
            headerTitle: {
                type: String,
                required: true
            },
            isDetail: {
                type: Boolean,
                default: false
            },
            user_id: {
              required: true,
            },
            userType:{
              required: true,
              type: String
            }
        },
        data() {
            return {
                bookingDetailDialog: false,
                callLogsDialog: false,
                selectedBooking: null,
                addBookingDialog: false,
                bookings: {
                  fields: [],
                  data: []
                },
                pageNumber: 1,
                areBookingsLoaded: false,
                infiniteId: +new Date(),
                bookingUrl: null
            }
        },
        created () {
          this.bookingUrl = this.userType === "client" ? "client/bookings" : "therapist/bookings";
        },
      events: {
            'open-booking-details': function(data) {
                this.selectedBooking = data;
                this.bookingDetailDialog = true;
            },
        },
      methods: {
            hideDetailModal() {
                this.bookingDetailDialog = false;
            },
            openCallLogs () {
                this.bookingDetailDialog = false
                this.callLogsDialog = true
            },
            closeCallLogs () {
                this.callLogsDialog = false
                this.bookingDetailDialog = true
            },
             hideBookingModal() {
                this.addBookingDialog = false;
            },
            showBookingModal() {
                this.addBookingDialog = true;
            },
            showRescheduleModal(session) {
                this.selectedBooking = session;
                this.action = 'reschedule';
                this.showBookingModal();
            },

            refreshAfterCancel () {
              this.pageNumber = 1;
              this.bookings.data = [];
              this.infiniteId += 1;
              this.$emit('refreshBookings');
            },

            refreshBookingData () {
              this.pageNumber = 1;
              this.bookings.data = [];
              this.infiniteId += 1;
              this.$emit('refreshBookings');
            },
            rescheduleSession(booking_data) {
                let loader = this.$loading.show();
                this.$http.put(this.url + routeHelper.rescheduleSessionApi, {
                booking_id: booking_data.id,
                rescheduled_slot_id: booking_data.therapist_slot_id,
                action: 'request',
                timezone: this.timezone,
                device_type: this.deviceType
            }).then(response => {
                loader.hide();
                this.hideBookingModal();
                this.hideDetailModal();
                this.$events.fire('vuetable:reload');
                this.pageNumber = 1;
                this.bookings.data = [];
                this.infiniteId += 1;
                this.$toastr('success',response.data.message,'');
                this.$emit('refreshBookings');
                this.$router.go();
            }).catch(error => {
                loader.hide();
                this.$toastr('error', error.response.data.message,'');
            });
            },
            infiniteHandler($state) {
              this.$http.get(this.url + this.bookingUrl, {
                params: {
                  user_id: this.user_id,
                  timezone: this.timezone,
                  for: "for-profile",
                  page: this.pageNumber,
                }
              }).then(response => {
                this.bookings.fields = response.data.bookings.fields;
                if (response.data.bookings.data.length > 0) {
                  this.pageNumber += 1;
                  this.bookings.data.push(...response.data.bookings.data);
                  $state.loaded();
                } else {
                  $state.complete();
                }
                this.areBookingsLoaded = true;
              }).catch(error => {
                this.$toastr('error', error.response.data.message, 'Error')
              })
            },
        }
    }
</script>
