<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                {{ headerTitle }}
            </p>
            <slot name="data-table-actions"></slot>
        </div>
        <div class="content">
            <data-table :isDetail="isDetail"
                        :fields="fields"
                        :data="data"
            ></data-table>
        </div>

        <!-- Transaction Details Modal -->
        <transaction-details v-if="transactionDetailDialog"
                        :visible="transactionDetailDialog"
                        :rowData="selectedTransaction"
                        v-on:hide="hideDetailModal"
        >
        </transaction-details>
    </div>
</template>

<script>
    import TransactionDetails from "../transactions/TransactionDetail.vue";
    import DataTable from "../common/dataTable/DataTableDataMode.vue";
    export default {
        name: 'Transactions',
        components: {
            DataTable,
            TransactionDetails
        },
        props: {
            headerTitle: {
                type: String,
                required: true
            },
            fields: {
                type: Array,
                required: true
            },
            data: {
                type: Array,
                required: true
            },
            isDetail: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                transactionDetailDialog: false,
                selectedTransaction: null
            }
        },
        events: {
            'open-transaction-detail': function(data) {
                this.selectedTransaction = data;
                this.transactionDetailDialog = true;
            },
        },
        methods: {
            hideDetailModal() {
                this.transactionDetailDialog = false;
            }
        }
    }
</script>