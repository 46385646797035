<template>
  <span>
    <span
      v-if="unreadMessageCount > 0"
      class="slickdot slickdot-nav-unread-message"/>
  </span>
</template>

<script>

    import * as SendBird from 'sendbird'

    const send_bird_id = process.env.VUE_APP_SENDBIRD_APP_ID
    const sb = new SendBird({ appId: send_bird_id })
    const ChannelHandler = new sb.ChannelHandler()
    export default {
        data: function () {
            return {
                userId: '0',
                fullName: '',
                username: '',
                profileUrl: '',
                channels: [],
                connectedUsers: [],
                unreadMessageCount: 0
            }
        },

        mounted: function () {
            // if ( window.location.href.indexOf("messages") === -1 ) {
                const self = this
                let unique_identifier = self.environmentPrefix + self.userId
                if (self.activeChannel) {
                    unique_identifier = self.activeChannel.name
                }
                ChannelHandler.onMessageReceived = function (channel, message) {
                    sb.getTotalUnreadMessageCount(function (messages) {
                        self.playAudio()
                        self.unreadMessageCount = messages
                    })
                }

                sb.addChannelHandler(unique_identifier, ChannelHandler)
            // }
        },
        created: function () {
            this.getUserDataForChat()
        },
        methods: {
            getUserDataForChat: function () {
                const self = this
                let loader = this.$loading.show()
                this.$http.get(this.url + 'chat/user_data', {
                    params: {
                        skip: this.skip,
                        take: this.take,
                        keyword: this.keyword,
                        type: 'therapist'
                    }
                }).then(function (response) {
                    loader.hide()
                    self.userId = String(response.data.user.id)
                    self.fullName = response.data.user.name
                    self.username = response.data.user.username
                    self.profileUrl = response.data.user.profile_picture
                    self.initializeSendBird()
                }).catch(function (error) {
                    loader.hide()
                    self.$toastr('error', error.response.data.message, 'Error')
                })
            },
            initializeSendBird: function () {
                const self = this
                let prefix = self.environmentPrefix

                const sbAccessToken = localStorage.getItem('sb_access_token')
                sb.connect(prefix + self.userId, sbAccessToken, function (user, error) {
                    if (error) {
                        return
                    }

                    sb.updateCurrentUserInfo(self.fullName, self.profileUrl, function (response, error) {
                        if (error) {
                            return
                        }

                        sb.getTotalUnreadMessageCount(function (messages) {
                            if (messages > 0) {
                                // if ( window.location.href.indexOf("home") > -1 ) {
                                    self.playAudio()
                                // }
                            }
                            self.unreadMessageCount = messages
                        })
                    })
                })
            },
            playAudio () {
                // playAudio();
            }
        }

    }
</script>
