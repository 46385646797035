<template>
  <div v-if="clientCurrentTherapyStatus" class="dropdown mb-3 d-block">
    <div @click="toggleDropDownMenu" :class="styleTherapyStatusDropDown"  class="dropbtn flex-row align-items-center justify-space-between">
      <div v-if="clientCurrentTherapyStatus">{{clientCurrentTherapyStatus.name}}</div>
      <div v-else>Loading</div>
      <div id="chevron" class="align-items-center justify-space-center">
         <span class="ml-3">
            <img :src="chevronIcon" alt="myonlinetherapy"/>
         </span>
      </div>
    </div>

    <div v-if="toggleTherapyStatusDropdown" id="myDropdown" class="dropdown-content">
      <a @click="updateTherapyStatus(therapyStatus.id)" v-for="therapyStatus in allowedTherapyStatuses">{{therapyStatus.name}}</a>
    </div>

  </div>

</template>

<script>
export default {
  name: "therapyStatusesDropDown",
  props: {
    client: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      toggleTherapyStatusDropdown: false,
      clientCurrentTherapyStatus: null,
      allowedTherapyStatuses: [],
      urlEndPoint: "client/therapy-status"
    }
  },
  computed: {
    chevronIcon: function () {
      return this.toggleTherapyStatusDropdown ? "/icons/dropdowns/chevron-up.png" : "/icons/dropdowns/chevron-bottom.png";
    },
    styleTherapyStatusDropDown: function () {
      return this.allowedTherapyStatuses.length > 0 ? "" : "bg-grey-300 cursor-disable";
    }
  },
  created() {
    this.getClientTherapyStatus();
  },
  methods: {
    getClientTherapyStatus() {
      this.$http.get(this.url + this.urlEndPoint, {
        params: {
          client_id: this.client.id
        }
      }).then((response) => {
        this.clientCurrentTherapyStatus = response.data.therapy_status;
        this.allowedTherapyStatuses = response.data.allowed_therapy_statues;
      });
    },

    updateTherapyStatus(therapyStatusId) {
      let formData = {
        client_id : this.client.id,
        therapy_status_id: therapyStatusId
      }
      let loader = this.$loading.show();
      this.$http.put(this.url + this.urlEndPoint, formData).then((response) => {
        loader.hide();
        this.clientCurrentTherapyStatus = response.data.therapy_status;
        this.allowedTherapyStatuses = response.data.allowed_therapy_statues;
        this.toggleTherapyStatusDropdown = false; // hide the drop down too
        this.$toastr("success", response.data.message, "");
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    toggleDropDownMenu() {
      if (this.allowedTherapyStatuses.length > 0) {
        this.toggleTherapyStatusDropdown = !this.toggleTherapyStatusDropdown;
      }

    },
  }
}
</script>

<style scoped>
.dropbtn {
  display: flex;
  padding: 8px 16px;
  max-width: 528px;
  height: 36px;
  background: #FFFFFF;
  color: #4A4A4A;
  border: 1px solid #D4D4D4;
  box-sizing: border-box;
  border-radius: 4px;
}

#chevron {
  display: flex;
  width: 35px;
  height: 34px;
  border-left: 1px solid #D4D4D4;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 528px;
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;

}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  color: #4A4A4A !important;
}

.dropdown-content a:hover {
  background: #D4D4D4;
}


</style>