<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="400px"
    min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modal_title }}</h1>
        <img
          class="close-model"
          src="/icons/close_ic.svg"
          @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <div class="add-member-modal">
          <form>
            <div class="form-group">
              <label class="form-label">Notification Title</label>
              <input
                v-model="title"
                class="input-text"
                type="text"
                placeholder="Enter notification title">
            </div>
            <div class="form-group">
              <label class="form-label">Notification Text</label>
              <textarea
                v-model="notification"
                class="input-text"
                placeholder="Enter notification text"
                rows="4"/>
            </div>
            <div class="form-group mb-0">
              <label class="form-label">Filters</label>
              <div>
                <label>
                  <input
                    v-model="ios"
                    type="checkbox">
                  iOS Users
                </label>
              </div>
              <div>
                <label>
                  <input
                    v-model="android"
                    type="checkbox">
                  Android Users
                </label>
              </div>
            </div>
          </form>

        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer/>
        <a
          class="cancel-model"
          @click="hide">Cancel</a>
        <button
          class="btn modal-action-button"
          @click="sendNotification">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
    export default {
        name: 'NewNotification',
        props: {
            visible: {
                required: true,
                type: Boolean
            }
        },
        data: () => ({
            modal_title: 'New notification',
            actionButtonTitle: 'Send Notification',
            title: '',
            notification: '',
            filters: '',
            ios: 0,
            android: 0
        }),
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        methods: {
            hide () {
                this.$emit('hide')
            },
            sendNotification () {
                let filters = []
                if (this.ios) {
                    filters.push('ios')
                }
                if (this.android) {
                    filters.push('android')
                }
                this.$emit('sendNotification', this.title, this.notification, filters)
                this.$emit('hide')
            }
        }
    }
</script>
<style scoped>

</style>
