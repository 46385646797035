let stringHelper = {
    empty(value) {
        return !(value && value !== '' && value !== 0);
    },
    parseForUrl(value) {
        return value.replace(/ /g,"_");
    },
    userNameInitials(client) {
        return client.first_name.charAt(0).toUpperCase()+""+client.last_name.charAt(0).toUpperCase();
    },
    capitalizeText(text) {
        return typeof text !== "string" ? "" : text.charAt(0).toUpperCase() + text.slice(1);
    },
};

export default stringHelper;
