<template>
  <div>
    <div class="d-flex flex-row justify-space-between align-center">
      <div class="mr-4">
        <label>
          <span class="org-label form-label pt-0">Service Name</span>
          <div class="flex-row flex-display justify-start align-center">
            <label class="light-label org-label form-label pr-1">{{ index+1 }}) </label>
            <input class="input-text border-radius-8"
                   :class="{ 'error-border': showNameError }"
                   type="text"
                   :disabled="shouldDisable"
                   v-model="charge.name"
            />
          </div>
        </label>
      </div>
      <div class="mr-3">
        <label class="width-100">
          <span class="org-label form-label pt-0">Service Price</span>
          <div class="flex-row flex-display justify-start align-center">
            <label class="light-label org-label form-label pr-1">£ </label>
            <input class="input-text width-65 border-radius-8"
                   type="text"
                   :disabled="shouldDisable"
                   :class="{ 'error-border': showPriceError }"
                   v-model="charge.price"
            />
          </div>
        </label>
      </div>
      <div class="pt-3">
        <img v-if="showDeleteIcon" @click="$emit('delete-service-charge', index)" src="/assets/images/delete_ic.png" class="pointer mt-2" width="14" height="14" alt="MyOnlineTherapy">
      </div>
    </div>
  </div>
</template>

<script>
import regexHelper from "@/helpers/regexHelper";
import numberHelper from "@/helpers/numberHelper";

export default {
  name: "ServiceCharge",
  props: {
    charge: {
      required: true,
    },
    index: {
      default: 0,
    },
    showServiceChargesErrors: {
      default: false
    },
    showDeleteIcon: {
      default: false
    },
    shouldDisable: {
      default: false,
    }
  },
  computed: {
    showPriceError() {
      return this.showServiceChargesErrors && this.isEmpty(this.charge.price);
    },
    showNameError() {
      return this.showServiceChargesErrors && this.isEmpty(this.charge.name)
    },
  },
  methods: {
    isEmpty(value) {
      return numberHelper.isEmpty(value);
    },

  },
  watch: {
    'charge.price': function (newVal, oldVal) {
      newVal = regexHelper.filterNumericAndDecimalsOnly(newVal);
      if (newVal.includes('.')) {
        this.charge.price = newVal.split('.')[0] + '.' + newVal.split('.')[1].slice(0, 2)
      }
    }
  }
}
</script>

<style scoped>
  .border-radius-8 {
    border-radius: 8px !important;
  }
</style>
