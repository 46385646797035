<template>
  <div class="flex-display flex-column border-right-1px px-3 w-100">
    <p class="bold-font blue-100 fs-17">
      Details
    </p>
    <template v-if="isBulkInvoicingEnabled || isServiceChargesInvoice">
      <p class="detail-item">
        <label class="item-label">Client name: </label>
        <span class="item-value"> {{ claim.client.full_name }} </span>
      </p>

      <p
        v-if="claim.therapist"
        class="detail-item">
        <label class="item-label">Therapist name: </label>
        <span class="item-value"> {{ claim.therapist.full_name }} </span>
      </p>

      <p
        v-if="claim.user_voucher.voucher_code"
        class="detail-item">
        <label class="item-label">Plan title: </label>
        <span class="item-value"> {{ claim.user_voucher.voucher_code.plan.title }} </span>
      </p>

      <template v-if="claim.user_voucher.voucher_code.plan.service_charges">
        <p class="detail-item">
          <label class="item-label">Additional service charge names: </label>
          <span class="item-value"> {{ getServiceChargesNames(claim.user_voucher.voucher_code.plan.service_charges) }} </span>
        </p>

        <p class="detail-item">
          <label class="item-label">Additional service charges price: </label>
          <span class="item-value"> £{{ claim.service_charges }} </span>
        </p>
      </template>

      <p
        v-if="!isServiceChargesInvoice"
        class="detail-item">
        <label class="item-label">Number of sessions on invoice: </label>
        <span class="item-value"> {{ claim.total_number_of_sessions }} sessions</span>
      </p>

      <p
        v-if="!isServiceChargesInvoice"
        class="detail-item">
        <label class="item-label">Sessions total price: </label>
        <span class="item-value"> £{{ claim.therapy_charges }} </span>
      </p>

      <p
        v-if="!isServiceChargesInvoice"
        class="detail-item">
        <label class="item-label">Total invoice amount: </label>
        <span class="item-value"> £{{ claim.price }} </span>
      </p>
    </template>
    <template v-else>
      <p class="detail-item">
        <label class="item-label">Booking ID: </label>
        <span class="item-value"> {{ claim.booking.id }} </span>
      </p>

      <p class="detail-item">
        <label class="item-label">Booking Date: </label>
        <span class="item-value"> {{ claim.booking_date }} </span>
      </p>

      <p
        v-if="claim.slot"
        class="detail-item">
        <label class="item-label">Session Date: </label>
        <span class="item-value"> {{
          customDateFormat(claim.slot.start_time, "DD/MM/YYYY hh:mm A")
        }} </span>
      </p>

      <p class="detail-item">
        <label class="item-label">Client name: </label>
        <span class="item-value"> {{ claim.client.full_name }} </span>
      </p>

      <p
        v-if="claim.therapist"
        class="detail-item">
        <label class="item-label">Therapist name: </label>
        <span class="item-value"> {{ claim.therapist.full_name }} </span>
      </p>

      <p class="detail-item">
        <label class="item-label">Insurer: </label>
        <span class="item-value"> {{ claim.insurance_company }} </span>
      </p>

      <p class="detail-item">
        <label class="item-label">Booking status: </label>
        <span class="item-value"> {{ claim.booking.session_status }}</span>
      </p>

      <p class="detail-item">
        <label class="item-label">Session Type: </label>
        <span class="item-value"> {{ claim.session_type.title }}</span>
      </p>

      <p class="detail-item">
        <label class="item-label">Session Price: </label>
        <span class="item-value"> £{{ claim.session_price }}</span>
      </p>
    </template>
    <p class="detail-item">
      <label class="item-label">Date invoice submitted: </label>
      <span class="item-value"> {{ claim.invoice_submitted_timestamp }}</span>
    </p>

    <p class="detail-item">
      <label class="item-label">Date invoice paid in full: </label>
      <span class="item-value"> {{ claim.invoice_paid_timestamp }}</span>
    </p>

    <p class="detail-item">
      <label class="item-label">Invoice settlement days: </label>
      <span class="item-value"> {{ claim.invoice_settlement_days }}</span>
    </p>

    <div class="m-t-14 m-b-14 form-group flex-display flex-row align-center">
      <label
        class="item-label width-120 m-0 p-0"
        for="invoiceStatus">Invoice Status: </label>
      <div class="width-250 flex-display flex-row align-center width-206">
        <select
          id="invoiceStatus"
          v-model="claim.invoice_status"
          class="input-select w-100"
          name="invoiceStatus"
        >
          <template v-for="status in invoiceStatusValues">
            <option :value="status">{{ status }}</option>
          </template>
        </select>
      </div>
    </div>

    <div class="m-t-0 m-b-14 form-group flex-display flex-row align-center">
      <label
        class="item-label width-120 m-0 p-0"
        for="whoGetPaid">Paid to: </label>
      <div class="width-250 flex-display flex-row align-center width-206">
        <select
          id="whoGetPaid"
          v-model="claim.who_get_paid"
          class="input-select w-100"
          name="whoGetPaid"
        >
          <template v-for="status in whoGetPaidValues">
            <option :value="status">{{ status }}</option>
          </template>
        </select>
      </div>
    </div>

    <p class="detail-item mt-3">
      <label class="item-label">Amount outstanding: </label>
      <span class="item-value" v-if="claim.claim_amount_outstanding > 0"> £{{ claim.claim_amount_outstanding }}</span>
      <span class="item-value" v-else> £0.00</span>
    </p>
  </div>
</template>

<script>
import therapyHelper from '@/helpers/app/therapyHelper'
import moment from 'moment'

export default {
    name: 'InvoiceDetails',

    props: {
        invoice: {
            required: true
        },
        isServiceChargesInvoice: {
            default: false
        },
        isBulkInvoicingEnabled: {
            default: false
        }
    },
    data () {
        return {
            whoGetPaidValues: therapyHelper.getWhoGetPaidStatusList(),
            invoiceStatusValues: therapyHelper.getInvoiceStatusList(),
            claim: null
        }
    },
    watch: {
        'claim.invoice_status': function (value) {
            this.$emit('claimUpdated', this.claim)
        },
        'claim.who_get_paid': function (value) {
            this.$emit('claimUpdated', this.claim)
        }
    },
    created () {
        this.claim = this.invoice
    },
    methods: {
        getServiceChargesNames (serviceCharges) {
            let chargesNames = ''
            let count = 0
            if (serviceCharges) {
                serviceCharges.forEach(function (charge) {
                    if (count === 0) {
                        chargesNames = charge.name
                    } else {
                        chargesNames = chargesNames + ', ' + charge.name
                    }
                    count = count + 1
                })
            }
            return chargesNames
        },
        customDateFormat (time, format) {
            if (time === null) {
                return ''
            } else {
                if (time.indexOf(' ') !== -1) {
                    const utcTime = time.replace(' ', 'T') + 'Z'
                    return moment(utcTime).tz(moment.tz.guess()).format(format)
                } else {
                    return moment(time).tz(moment.tz.guess()).format(format)
                }
            }
        }
    }
}
</script>

<style scoped>
.item-label {
    color: #949494;
    font-family: MetropolisRegular;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
}
</style>
