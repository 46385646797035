<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >

      <template v-if="loaded">
        <data-table  ref="datatable"
                     :api-end-point="apiurl"
                     :search="search"
                     :sort-order="sortOrder"
                     :table-name="'Team'"
                     :isExport="true"
                     :isEdit="true"
                     :isDelete="true"
                     :pre-selected-filters="filters.selectedFilters"
                     track-by="id"
        >
          <template slot="datatable-actions">
            <div class="action-buttons">
              <button class="btn btn-primary" @click="showTeamModal">{{ addMemberButtonText }}</button>
            </div>
          </template>
          <template slot="actions">
              <actions :actions="filters.actions"></actions>
          </template>
          <template slot="filters">
            <filters :filters="filters.defaultFilters"></filters>
          </template>
        </data-table>
      </template>

      <!-- Add Member -->
      <add-member v-if="addMemberDialog"
                  :visible="addMemberDialog"
                  :action="this.action"
                  :member="this.member"
                  v-on:addMember="addTeamMember"
                  v-on:editMember="editTeamMember"
                  v-on:hide="hideTeamModal"
      >
      </add-member>

      <!-- delete dialog -->
      <confirmation v-if="deleteDialog" :visible="deleteDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This member will be removed from team. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removeTeamMember">Delete</button>
        </template>
      </confirmation>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "../components/common/dataTable/DataTable";
import Filters from "../components/common/dataTable/filters/Filters";
import Actions from "../components/common/dataTable/users/Actions";
import AddMember from "../components/account/AddMember";
import Confirmation from "../components/common/Confirmation";
export default {
  name: "Team",
    components: {
        DataTable,
        Filters,
        Actions,
        AddMember,
        Confirmation
    },
    data() {
        return {
            addMemberDialog: false,
            deleteDialog: false,
            activeMember: null,
            member: null,
            action: 'add',
            addMemberButtonText: 'Add Team Member',
            apiurl: 'team' ,
            search:{
                placeholder:  '',
                searchAble: false
            },
            sortOrder: [
                {
                    field: 'first_name',
                    sortField: 'first_name',
                    direction: 'desc'
                }
            ],
            filters: null,
            loaded: false
        }
    },
    created() {
      this.fetchFilters();
    },
    methods: {
        showTeamModal() {
            this.addMemberDialog = true;
        },
        hideTeamModal() {
            this.addMemberDialog  = false;
            this.action = 'add';
            this.member = null;
        },
        deleteConfirmation(member_id) {
            this.deleteDialog = true;
            this.activeMember =  member_id;
        },
        hideConfirmation() {
            this.deleteDialog = false;
            this.activeClient = '';
        },
        addTeamMember(email, role, id) {
            let self = this;
            let loader = this.$loading.show();
            this.$http.put(this.url+'team_member', {
                email: email,
                invitee_role: role
            }).then(function (response) {
                self.$events.fire('vuetable:reload');
                loader.hide();
                self.$toastr('success',response.data.message,'');
            }).catch(function (error) {
                loader.hide();
                self.$toastr('error', error.response.data.message,'');
            });
        },
        editTeamMember(email, role, id) {
            let self = this;
            let loader = this.$loading.show();
            this.$http.put(this.url+'team_member/edit', {
                id: id,
                role: role
            }).then(function (response) {
                self.action = 'add';
                self.member = null;
                loader.hide();
                self.$toastr('success',response.data.message,'');
                if (response.data.user_id === id) {
                  self.logout();
                  return;
                }
                self.$events.fire('vuetable:reload');
            }).catch(function (error) {
                loader.hide();
                self.$toastr('error', error.response.data.message,'');
            });
        },
        removeTeamMember() {
            let self = this;
            let loader = this.$loading.show();
            this.$http.delete(this.url+'team_member', {
                params: {
                    id: this.activeMember
                }
            }).then(function (response) {
                self.hideConfirmation();
                self.activeMember = null;
                self.$events.fire('vuetable:reload');
                loader.hide();
                self.$toastr('success',response.data.message,'');
            }).catch(function (error) {
                loader.hide();
                self.$toastr('error', error.response.data.message,'');
            });
        },
        fetchFilters(){
          this.$http.get(this.url+'filters/team').then((res) => {
            if(res.data) {
              this.filters = res.data;
              this.loaded = true;
            } else {
              alert('there is some problem');
            }
          });
        },
        logout() {
          this.$http.post(this.url+'logout').then((res) => {
            localStorage.removeItem("token");
            localStorage.removeItem("password_expire_date");
            localStorage.removeItem("sb_access_token");
            localStorage.removeItem("user");
            this.$router.push('/login');
          });
        },
    },
    events: {
        'create-new': function() {
            this.showTeamModal();
        },
        'edit-record': function(member) {
            this.action = 'edit';
            this.member = member;
            this.showTeamModal();
        },
        'delete-record': function(member) {
            this.deleteConfirmation(member.id);
        },
        'get-table-ref': function(){
            const selectedIds = this.$refs.datatable.$refs.vuetable.selectedTo;
            this.$events.fire('get-selected-data',selectedIds);
        }
    }
}
</script>
