<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ headerTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="MyOnlineTherapy" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="pricingForm"
            >
              <!-- Pricing title -->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">Title</span>
                  <textarea class="input-textArea form-group__input form-group__input--inputTextArea"
                            name="description"
                            v-model="form.title"
                            placeholder="Enter Title..."
                            rows="2"
                  ></textarea>
                </label>
              </div>
            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitPricing">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddPricing",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    action: {
      required: true,
      default: "add"
    },
    value: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      headerTitle: 'Add Pricing',
      actionButtonTitle: 'Add',
      form : {
        id: null,
        title: '',
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  created () {
    if ( this.action === 'update' ) {
      this.headerTitle =  'Update Pricing';
      this.actionButtonTitle = 'Update';
      this.form = {...this.value};
    }
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    validateSubmitRequest() {
      if (this.form.title === '') {
        this.showAlert('Please enter title.');
        return false;
      }
      return true;
    },
    submitPricing() {
      if (this.validateSubmitRequest()) {
        this.$emit('submitPricing', this.form, this.action);
      }
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    }
  }
}
</script>
