<template>
    <v-card>
        <v-card-title>
            <h1 class="model-heading">{{modalTitle}}</h1>
            <img class="close-model" src="/icons/close_ic.svg" alt="" @click.stop="hide"/>
        </v-card-title>

        <v-card-text>
            <div class="add-member-modal">
                <div class="therapist-slots" v-if="slots.length > 0">
                    <template v-for="slot in slots">
                        <p class="slot" :class="{active: ( selected && selected.slot_id === slot.slot_id )}" :id="'slot_' + slot.slot_id" @click="selectSlot(slot)">{{slot.formatted_start_time}}</p>
                    </template>
                </div>
                <div class="d-flex justify-center align-center" v-else>
                    <p class="text-center my-5">No Availability found! <br>Please select an other date.</p>
                </div>
            </div>

        </v-card-text>

        <v-card-actions class="flex-display flex-row">
            <v-spacer></v-spacer>
            <button class="btn modal-action-button" @click="applyTime" :disabled="disableSubmitButton">{{actionButtonTitle}}</button>
        </v-card-actions>
    </v-card>
</template>
<script>
    export default {
        name: "AvailabilitySlots",
        props: {
            slots: {
                type: Array,
                required: true
            },
            selected: {
                type: Object
            }
        },
        data: () => ({
            modalTitle : '',
            actionButtonTitle:'Apply',
            selected_slot: null
        }),
        computed: {
            disableSubmitButton: {
                get () {
                    return !(this.selected_slot);
                }
            }
        },
        created() {
            if ( this.selected ) {
                this.selectSlot(this.selected);
            }
        },
        methods:{
            hide() {
                this.$emit('switchView', 'form')
            },
            selectSlot(slot) {
                this.selected_slot = slot;
                $('.slot').removeClass('active');
                $('#slot_'+slot.slot_id).addClass('active');
            },
            applyTime() {
                this.$emit('applyTime', this.selected_slot)
            }
        },
        watch: {

        }
    }
</script>