<template>
  <div class="flex-display flex-row mb-3">
    <div class="flex-display flex-row w-100 align-items-center">
      <template v-if="activeView === 'read'">
        <div class="flex-display flex-row justify-content-space-between w-100">
          <div class="flex-display flex-column justify-content-center w-100">
            <label class="switch-label m-0 color-fade">Gender:</label>
          </div>
          <div class="flex-row flex-display justify-content-end w-100 align-items-center">
            <p class="m-0 pl-5">
              {{ clientGender }}
            </p>
            <img
              class="ml-2 cursor-pointer"
              src="/icons/start_a_conversation_ic.svg"
              alt="edit"
              @click="editGender">
          </div>
        </div>

      </template>
      <template v-else>
        <div class="flex-row flex-display justify-content-end w-100 align-items-center filters">
          <div class="flex-display flex-column justify-content-center w-100">
            <label class="switch-label m-0 color-fade">Gender:</label>
          </div>
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{clientGender}}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item"
                 v-for="(gender,index) in genders"
                 :key="index"
                 @click="updateGender(gender)" >{{gender.name}}
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'ClientGender',
        props: {
            user: {}
        },
        data () {
            return {
                activeView: 'read',
                clientGender: this.user.gender,
                genders:[
                {name: 'Male', value: 'male'},
                {name: 'Female', value: 'female'},
                {name: 'Non binary', value: 'Non binary'},
                {name: 'Prefer not to say', value: 'Prefer not to say'},
              ],
            }
        },
        methods: {
            editGender () {
                this.activeView = 'edit'
            },
            updateGender (gender) {
              this.clientGender = gender.name;
              this.$emit('updateGender', this.clientGender, 'gender')
                this.activeView = 'read'
            },
        }
    }
</script>
