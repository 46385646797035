<template>
  <div class="user-progress">
    <div class="item-actions">
      <a class="text-info" @click="openDetail">{{rowData.client.full_name}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      detailEvent: 'client-detail'
    }
  },
  created() {
    if ( this.rowData.detailEvent ) {
      this.detailEvent = this.rowData.detailEvent;
    }
  },
  methods: {
    openDetail() {
      this.$events.fire(this.detailEvent, this.rowData)
    }
  }
}
</script>
