<template>
  <div>
    <h1 class="m-b-12 fs-16 bold-font grey-900">Active payment plan</h1>

    <div v-if="planType !== planTypePackage && planType !== planTypeSelfPay" class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Organisation</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.organisation_name }}</p>
    </div>

    <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Plan</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.plan_title }}</p>
    </div>

    <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Date activated</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.activation_date }}</p>
    </div>

    <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Scheduled sessions</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.scheduled_sessions }}</p>
    </div>

    <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Completed sessions</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.completed_sessions }}</p>
    </div>

    <div v-if="planType !== planTypeSelfPay" class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
      <p class="mb-0 fs-14 grey-800 font-medium">Authorized sessions</p>
      <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ activePlan.authorized_sessions ? activePlan.authorized_sessions : 'NA' }}</p>
    </div>
  </div>
</template>

<script>
import TherapyHelper from "@/helpers/app/therapyHelper";

export default {
  name: "activePaymentPlan",
  props: {
    planType: {
      required: true,
    },
    activePlan: {
      required: false,
    },
  },
  data(){
    return{
      planTypeSelfPay:TherapyHelper.planTypeSelfPay,
      planTypePackage:TherapyHelper.planTypePackage,
    }
  }
}
</script>
