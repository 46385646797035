<template>
  <div class="h-100 mt-5 flex-display flex-column flex-center">
    <div>
      <img src="/img/logo_img.svg" alt="myonline therapy"/>
    </div>
    <h1 class="form-heading mt-5">Sign Up</h1>
    <h3 class="text-center" >You've been invited to join the <br/>  MyOnlineTherapy team!</h3>
    <p class="app-error mt-2">
      {{error}}
    </p>
    <div class="w-343 mt-3" id="form">
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input class="input-text" type="text" v-model="firstName" placeholder="First Name">
      </div>
      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input class="input-text" type="text" v-model="lastName" placeholder="Last Name">
      </div>
      <div class="form-group">
        <label class="form-label">Phone</label>
        <div class="d-flex">

          <input id="phone" v-model="phone" @keypress="isNumber($event)"
                 type="tel" class="input-text"
                 placeholder="123456789"/>
          <input id="country_code" v-model="country_code" name="country_code" type="hidden"/>

        </div>
      </div>
      <div class="form-group">
        <label class="form-label">New Password</label>
        <input class="input-text" type="password" v-model="password" placeholder="Enter New Password">
        <div class="error-block mt-3 text-left">
          <p v-for="rule in rules" class="mb-0" :class="rule.class">{{rule.message}}</p>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Confirm New Password</label>
        <input class="input-text md-6" type="password" v-model="confirmPassword" placeholder="Confirm New Password">
      </div>
      <button class="btn mb-5" :disabled="disable"  @click="signUp">Sign Up</button>
    </div>
    <confirmation-code v-if="addConfirmationCodeDialog"
                       :visible="addConfirmationCodeDialog"
                       v-on:hide="hideConfirmationModal"
                       :user = "user"
    ></confirmation-code>
  </div>
</template>

<script>
import numberHelper from "@/helpers/numberHelper";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import ConfirmationCode from "@/views/confirmationCode";
export default {
  name: "SignUp",
  components: {
    ConfirmationCode
  },
  data(){
    return{
      firstName:'',
      lastName:'',
      phone:'',
      password:'',
      confirmPassword:'',
      disable:true,
      error: '',
      rules: [
        { message:"Minimum 8 characters", regex:/.{8,}/,class:'text-dark' },
        { message:"At least 1 number", regex:/[0-9]+/,class:'text-dark' },
        { message:"At least 1 uppercase ",  regex:/[A-Z]+/,class:'text-dark' },
        { message:'At least 1 lowercase', regex:/[a-z]+/,class:'text-dark' },
      ],
      errors:[],
      country_code: "44",
      region: "",
      send_by: "mobile",
      addConfirmationCodeDialog: false,
      user: {},
    }
  },
  mounted(){
    const newInput = document.querySelector("#phone");
    let iti = intlTelInput(newInput, {
      initialCountry: this.region ? this.region : "gb",
      separateDialCode: true,
    });
    newInput.addEventListener("countrychange", () => {
      let getCode = iti.getSelectedCountryData();
      this.country_code = getCode.dialCode;
      this.region = getCode.iso2;
    });

  },
  methods:{
    isNumber: numberHelper.isNumber,
    removeLeadingZero(phone) {
      this.phone = numberHelper.removeCharactorAtFirstPlace(phone, "0");
    },
    signUp (){
      if (this.validateSubmitRequest()) {
        let loader = this.$loading.show()
        this.$http.put(this.url + 'invitation/validate-data', {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.phone,
          password: this.password,
          country_code: this.country_code,
          password_confirmation: this.confirmPassword,
          timezone: this.timezone,
          token: this.$route.query.token
        }).then(response => {
          loader.hide();
          this.$toastr('success', response.data.message, '');
          this.user = response.data.data;
          this.addConfirmationCodeDialog = true;
        }).catch(error => {
          loader.hide();
          this.$toastr("error", error.response.data.message);
          if (error.response.data.message === 'User already exists. please login') {
            this.$router.push('/login');
          }
        });
      }
    },
    validateSubmitRequest() {
      if (this.firstName === "") {
        this.showAlert("Please enter First Name");
        return false;
      }
      if (this.lastName === "") {
        this.showAlert("Please enter Second Name");
        return false;
      }
      if (this.phone === "") {
        this.showAlert("Please enter Phone");
        return false;
      }
      if (this.password !== this.confirmPassword) {
        this.showAlert("Confirm Password and New Password must match");
        return false;
      }
      return true;
    },
    showAlert(msg) {
      this.$toastr("error", msg);
    },
    updateStatus () {
      if( this.password !== '' && this.errors.length <= 0) {
        return false;
      }
      return true;
    },
    hideConfirmationModal() {
      this.addConfirmationCodeDialog  = false;
    },
  },
  watch:{
    password:{
      handler: function(val, oldVal){
        this.errors =[];
        for (let condition of this.rules) {
           if (this.password === '') {
            condition.class = 'text-dark';
          } else if (!condition.regex.test(this.password)) {
            condition.class = 'text-danger';
            this.errors.push(condition.message);
          } else {
            condition.class = 'text-success';
          }
        }
        this.disable = this.updateStatus()
      },
      deep: true
    },
    phone() {
      this.removeLeadingZero(this.phone);
    }
  }
}
</script>

<style scoped>
</style>
