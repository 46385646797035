<template>
  <div class="flex-display flex-column p-l-34 pr-3">
    <p class="bold-font blue-100 fs-17 mb-0">
      Collection workflow
    </p>
    <div class="m-t-0 m-b-14 form-group flex-display flex-row align-center">
      <label
        class="item-label width-200 m-0 p-0"
        for="collectionStatus">Client payment status: </label>
      <select
        id="collectionStatus"
        :disabled="!collectionStatusEditable"
        v-model="claim.collection_status"
        class="input-select width-255"
        name="collectionStatus"
      >
        <option
          value="N/A"
          selected
          disabled>Select client payment status</option>
        <template v-for="status in collectionStatusValues">
          <option :value="status">{{ status }}</option>
        </template>
      </select>
    </div>
    <p class="detail-item mt-4">
      <label class="item-label">Client amount outstanding: </label>
      <span class="item-value"> {{ claim.client_amount_outstanding }}</span>
    </p>
    <p class="detail-item">
      <label class="item-label">Date cc started: </label>
      <span class="item-value"> {{ claim.cc_started_timestamp }}</span>
    </p>
    <p class="detail-item">
      <label class="item-label">Date cc paid: </label>
      <span class="item-value"> {{ claim.cc_paid_timestamp }}</span>
    </p>
    <p class="detail-item">
      <label class="item-label">CC settlement days: </label>
      <span class="item-value"> {{ claim.cc_settlement_days }}</span>
    </p>
  </div>
</template>

<script>
import therapyHelper from "@/helpers/app/therapyHelper"

export default {
    name: "CollectionWorkFlow",
    props: {
        invoice: {
            required: true
        },
        collectionStatusEditable: {
            default: false
        }
    },
    data () {
        return {
            claim: null,
            collectionStatusValues: therapyHelper.getCollectionStatusList()
        }
    },
    watch: {
        "claim.collection_status": function (value) {
            this.$emit("claimUpdated", this.claim)
        }
    },
    created () {
        this.claim = this.invoice
    }
}
</script>

<style scoped>
.item-label {
    color: #949494;
    font-family: MetropolisRegular;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
}
</style>
