<template>
        <div class="details-section">
          <div class="header">
            <p class="title">
              Insurance Options
            </p>
            <a class="create-new" href="#" @click="showInsuranceProvidersModal()">New Option</a>
          </div>
              <div class="content">
                <div id="configuration">
                  <div class="item-container border-0"
                       v-for="(insurance, index) in supportedInsuranceProvider"
                       :key="index"
                  >
                    <p class="item-text">{{ insurance.insurance_company.name }}</p>
                    <div class="item-actions">
                      <template v-if="isPaidTypeEditable">
                        <therapist-insurance-paid :user_id="user_id"
                                                  :paid-types="insurancePaidTypes"
                                                  :paid-type="insurance.paid"
                                                  :key="index"
                                                  :active-insurance-provider="insurance.id"
                                                  v-on:updateInsuranceProviders="updateInsuranceProvidersEmit">
                        </therapist-insurance-paid>
                      </template>
                      <template v-else>
                        <p class="item-text mr-2">{{ insurance.paid }}</p>
                      </template>
                      <img class="cursor-pointer" src="/icons/delete_payout_ic.svg" @click="deleteConfirmation(insurance.id)"/>
                    </div>
                  </div>
                </div>
              </div>

    <!-- Add insurance -->
    <add-insurance-provider v-if="addInsuranceProviderDialog"
                            :visible="addInsuranceProviderDialog"
                            :insuranceProviders="insuranceProviders"
                            :supportedInsuranceProvider="getSelectedInsuranceIds(selectedInsuranceProvider)"
                            v-on:addInsuranceProvider="updateInsuranceProviders"
                            v-on:hide="hideInsuranceProvidersModal"
    >
    </add-insurance-provider>

    <!-- delete dialog -->
    <confirmation v-if="deleteDialog" :visible="deleteDialog">
      <template slot="title">
        Are you sure?
      </template>
      <template slot="desc">
        This insurance provider will be removed. This action cannot be undone.
      </template>
      <template slot="actions">
        <a class="cancel-model" @click="hideConfirmation">Cancel</a>
        <button class="btn modal-action-button confirmation-delete" @click="deleteInsuranceProvider">Delete</button>
      </template>
    </confirmation>
  </div>
</template>

<script>
import AddInsuranceProvider from './actions/AddInsuranceProvider.vue';
import Confirmation from '../common/Confirmation.vue';
import TherapistInsurancePaid from "@/components/people/therapist/TherapistInsurancePaid";

export default {
  name: 'InsuranceProvider',
  components: {
    AddInsuranceProvider,
    Confirmation,
    TherapistInsurancePaid
  },
  props: {
    user_id: {
      type: [Number, String],
      required: true
    },
    insuranceProviders: {
      type: Array,
      required: true
    },
    supportedInsuranceProvider: {
      type: Array,
      required: true
    },
    insurancePaidTypes: {
      type: Array,
      required: true
    },
    isPaidTypeEditable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      addInsuranceProviderDialog: false,
      deleteDialog: false,
      selectedInsuranceProvider: [],
      activeInsuranceProvider: 0
    }
  },
  created() {
    this.selectedInsuranceProvider = this.supportedInsuranceProvider;
  },
  methods: {

    updateInsuranceProviders(insurance_provider_ids) {
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/insurance-providers/update", {
        therapist_id: this.user_id,
        insurance_company_id: insurance_provider_ids.join(','),
        device_type: 'web'
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("updateInsuranceProviders", response.data);
        this.$emit("refresh-insurance");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    deleteInsuranceProvider() {
      this.deleteDialog = false;
      let loader = this.$loading.show();
      this.$http.delete(this.url + "therapist/insurance-provider/delete", {
        params: {
          therapist_id: this.user_id,
          insurance_id: this.activeInsuranceProvider,
          device_type: 'web'
        }
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("updateInsuranceProviders", response.data);
        this.$emit("refresh-insurance");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },


    updateInsuranceProvidersEmit(data) {
      this.$emit("updateInsuranceProviders", data);
    },
    deleteConfirmation(insurance_provider) {
      this.deleteDialog = true;
      this.activeInsuranceProvider = insurance_provider;
    },
    hideConfirmation() {
      this.deleteDialog = false;
      this.activeInsuranceProvider = 0;
    },

    showInsuranceProvidersModal() {
      this.addInsuranceProviderDialog = true;
    },
    hideInsuranceProvidersModal() {
      this.addInsuranceProviderDialog = false;
    },

    getSelectedInsuranceIds(Insurance_providers) {
      let selectedIds = Insurance_providers.map(function (obj) {
        return obj.insurance_company.id;
      });
      return selectedIds;
    },
    removeArrayValue(value, array) {
      const index = array.indexOf(value);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    }
  }
}
</script>
