<template>
  <div class="flex-display flex-column">
    <p class="bold-font blue-100 fs-17 mb-0">
      Claim workflow
    </p>
    <div class="m-t-12 m-b-14 form-group flex-view mb-4 mb-0">
      <label
        class="item-label width-170 m-0 p-0"
        for="claimStatus">Claim Status: </label>
      <select
        id="claimStatus"
        :disabled="!claimStatusEditable"
        v-model="claim.claim_status"
        class="input-select width-210"
        name="claimStatus"
      >
        <template v-for="status in claimStatusValues">
          <option :value="status">{{ status }}</option>
        </template>
      </select>
      <img
        v-if="claimStatusEditable"
        src="/icons/delete_ic.png"
        alt="clear"
        width="20px"
        class="cursor-pointer ml-2 mt--2"
        @click="clearClaimStatus">
    </div>

    <div class="m-t-0 m-b-14 form-group flex-view mb-0">
      <label
        class="item-label width-170 m-0 p-0"
        for="remittanceId">Remittance ID: </label>
      <input
        id="remittanceId"
        :disabled="!remittanceFormEditable"
        v-model="claim.remittance_id"
        class="input-text width-210"
        name="remittanceId"
        type="text"
      >
    </div>

    <div class="m-t-0 m-b-14 form-group flex-display flex-row align-center">
      <label
        class="item-label width-170 m-0 p-0"
        for="remittanceDate">Remittance date: </label>
      <div class="width-210 flex-display flex-row align-center">
        <template v-if="remittanceFormEditable">
          <date-picker
            id="remittanceDate"
            :org="true"
            :date="claim.remittance_date"
            class="p-0"
            placeholder=""
            @valueUpdated="updateRemittanceDate"
          />
        </template>
        <template v-else>
          <input
            v-model="claim.remittance_date"
            class="input-text width-210"
            disabled
            type="text"
          >
        </template>

      </div>
      <img
        v-if="remittanceFormEditable"
        src="/icons/delete_ic.png"
        alt="clear"
        width="20px"
        class="cursor-pointer ml-2 mt--2"
        @click="clearRemittanceDate">
    </div>

    <div class="m-t-0 m-b-14 form-group flex-view">
      <label
        class="item-label width-170 m-0 p-0"
        for="remittanceMethod">Remittance Method: </label>
      <select
        id="remittanceMethod"
        :disabled="!remittanceFormEditable"
        v-model="claim.remittance_method"
        class="input-select width-210"
        name="remittanceMethod"
      >
        <option value="Cheque">Cheque</option>
        <option value="BACS">BACS</option>
        <option value="Other">Other</option>
      </select>
      <img
        v-if="remittanceFormEditable"
        src="/icons/delete_ic.png"
        alt="clear"
        width="20px"
        class="cursor-pointer ml-2 mt--2"
        @click="clearRemittanceMethod">
    </div>

    <div class="m-t-0 m-b-14 form-group flex-view">
      <label
        class="item-label width-170 m-0 p-0"
        for="amountPaidByClient">Client liability: </label>
      <input
        id="amountPaidByClient"
        v-model="claim.client_copayment"
        class="input-text width-210"
        name="amountPaidByClient"
        type="text"
        disabled
        readonly
      >
    </div>

    <div class="m-t-0 m-b-14 form-group flex-view">
      <label
        class="item-label width-170 m-0 p-0"
        for="amountPaidByOrg">3rd party liability: </label>
      <input
        id="amountPaidByOrg"
        :class="{'error': showAmountPaidByOrgError}"
        :readonly="!thirdPartyLiabilityEditable"
        v-model="claim.amount_paid_by_org"
        class="input-text width-210"
        name="amountPaidByOrg"
        type="text"
        :disabled="!thirdPartyLiabilityEditable"
      >
    </div>
    <small
      v-if="showAmountPaidByOrgError"
      class="text-danger p-0">
      Field cannot be empty
    </small>
  </div>
</template>

<script>
import therapyHelper from "@/helpers/app/therapyHelper"
import DatePicker from "../../common/DatePicker.vue"

export default {
    name: "ClaimWorkFlow",
    components: {
        DatePicker
    },
    props: {
        invoice: {
            required: true
        },
        showAmountPaidByOrgError: {
            default: false
        },
        remittanceFormEditable: {
            default: false
        },
        claimStatusEditable: {
            default: false
        },
        thirdPartyLiabilityEditable: {
            default: false
        }
    },
    data () {
        return {
            claimStatusValues: therapyHelper.getClaimStatusList(),
            claim: null
        }
    },
    watch: {
        "claim.claim_status": function (value) {
            this.$emit("claimUpdated", this.claim)
        },
        "claim.remittance_id": function (value) {
            this.$emit("claimUpdated", this.claim)
        },
        "claim.remittance_date": function (value) {
            this.$emit("claimUpdated", this.claim)
        },
        "claim.remittance_method": function (value) {
            this.$emit("claimUpdated", this.claim)
        },
        "claim.client_copayment": function (value) {
            this.$emit("claimUpdated", this.claim)
        },
        "claim.amount_paid_by_org": function (value) {
            this.$emit("claimUpdated", this.claim)
        }
    },
    created () {
        this.claim = this.invoice
    },
    methods: {
        updateRemittanceDate (date) {
            this.claim.remittance_date = date
        },
        clearRemittanceDate () {
            this.claim.remittance_date = ""
        },
        clearRemittanceMethod () {
            this.claim.remittance_method = null
        },
        clearClaimStatus () {
            this.claim.claim_status = null
        }
    }
}
</script>

<style scoped>
.flex-view {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.item-label {
    color: #949494;
    font-family: MetropolisRegular;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
}

.error {
    background: none !important;
}
</style>
