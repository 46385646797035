<template>
  <div class="fee-container mt-0">
    <label class="switch-label mb-0">Standing Payment ID</label>
    <div class="fee-container__fee-content">
      <template v-if="activeView === 'read'">
        <span class="m-2">{{ standingPaymentId }}</span>
        <img
          class="cursor-pointer"
          src="/icons/start_a_conversation_ic.svg"
          alt="edit"
          @click="editInvoiceData">
      </template>
      <div
        v-else
        class="flex-display flex-row mt-2">
        <input
          v-model="standingPaymentId"
          class="form-control"
          type="text">
        <button
          class="btn btn-primary ml-2"
          @click="updateInvoiceData">Update</button>
        <button
          class="btn confirmation-delete ml-2"
          @click="cancelInvoiceData">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TherapistStandingPaymentId',
    props: {
        therapistId: {
            type: Number,
            required: true
        },
        paymentId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            activeView: 'read',
            standingPaymentId: this.paymentId,
            oldStandingPaymentId : '',
        }
    },
    methods: {
        editInvoiceData () {
            this.activeView = 'edit';
            this.oldStandingPaymentId = this.standingPaymentId;

        },
        cancelInvoiceData () {
            this.activeView = 'read';
            this.standingPaymentId = this.oldStandingPaymentId;
        },
        updateInvoiceData() {
            let loader = this.$loading.show();
            this.$http.put(this.url + "therapist/invoice-data/update", {
                therapist_id: this.therapistId,
                standing_payment_id: this.standingPaymentId,
            }).then(response => {
                loader.hide();
                this.activeView = "read";
                this.$emit("standingPaymentIdUpdated", this.standingPaymentId);
                this.$toastr("success", response.data.message, "");
            }).catch(error => {
                loader.hide();
                this.$toastr("error", error.response.data.message, "");
            });
        }
    },
}
</script>
