<template>
    <div class="item-actions">
        <a class="text-info pointer" @click="openRecord">{{rowData.id}}</a>
    </div>
</template>

<script>
    export default {
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        data() {
          return {
              clickEvent: 'open-record'
          }
        },
        created() {
            if ( this.rowData.clickEvent ) {
                this.clickEvent = this.rowData.clickEvent;
            }
        },
        methods: {
            openRecord() {
                this.$events.fire(this.clickEvent, this.rowData, 0)
            }
        }
    }
</script>
