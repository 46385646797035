<template>
  <div class="flex-display flex-row mb-3">
    <div class="flex-display flex-row w-100 align-items-center">
      <template v-if="activeView === 'read'">
        <div class="flex-display flex-row justify-content-space-between w-100">
          <div class="flex-display flex-column justify-content-center w-100">
            <label class="switch-label m-0 color-fade">Date of birth:</label>
          </div>
          <div class="flex-row flex-display justify-content-end w-100 align-items-center">
            <p class="m-0 pl-5">
              {{ clientDateOfBirth }}
            </p>
            <img
              class="ml-2 cursor-pointer"
              src="/icons/start_a_conversation_ic.svg"
              alt="edit"
              @click="editDob">
          </div>
        </div>

      </template>
      <template v-else>
        <label class="switch-label mr-3">Date of birth:</label>
        <date-picker
          :date="clientDateOfBirth"
          class="p-3"
          @valueUpdated="dobUpdated"/>
        <button
          :disabled="clientDateOfBirth === ''"
          class="btn"
          @click="updateDob">Add</button>
      </template>
    </div>
  </div>
</template>

<script>
    import DatePicker from '../common/DatePicker'
    export default {
        name: 'ClientDateOfBirth',
        components: {
            DatePicker
        },
        props: {
            user: {}
        },
        data () {
            return {
                activeView: 'read',
                clientDateOfBirth: this.getDob()
            }
        },
        methods: {
            getDob () {
              if (this.isDobValid(this.user.date_of_birth)) {
                return this.user.date_of_birth
              } else {
                return ''
              }
            },
            editDob () {
                this.activeView = 'edit'
            },
            dobUpdated (dob) {
              this.clientDateOfBirth = dob
            },
            updateDob () {
                this.$emit('updateDob', this.clientDateOfBirth, 'dob')
                this.activeView = 'read'
            },
            isDobValid (dob) {
              return dob && dob !== '0000-00-00'
            }
        }
    }
</script>
