<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modal_title }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">

            <form id="sessionTypeForm" class="w-100 flex-display flex-row flex-wrap flex-justify-between ">
              <div class="form-group w-48">
                <label class="form-label">Treatment Code</label>
                <input v-model="treatment.code" class="input-text" type="text" placeholder="Enter Treatment Code"/>
              </div>
              <div class="form-group w-48">
                <label class="form-label">Blog Id</label>
                <input v-model="treatment.blog_id" class="input-text" type="text" placeholder="Enter Blog Id"/>
              </div>
              <div class="form-group w-100">
                <label class="form-label">Treatment Name</label>
                <input v-model="treatment.name" class="input-text" type="text" placeholder="Enter Treatment Name"/>
              </div>
              <div class="form-group w-100">
                <label class="form-label">Treatment Summary</label>
                <textarea v-model="treatment.summary"
                          class="input-textArea form-group__input form-group__input--inputTextArea"
                >
                </textarea>
              </div>
              <div class="form-group w-100">
                <label class="form-label">Treatment Description</label>
                <trumbowyg v-model="treatment.description" :config="config" class="form-control"
                           name="description"></trumbowyg>
              </div>
              <div class="w-100 mt-1 mb-3">
                <label class="form-label">Controls</label>
                <div class="border-form-group w-100 px-3 py-3 flex-display flex-row flex-justify-between">
                  <!-- Is Searchable -->
                  <div class="mt-1 w-48">
                    <label class="switch-label pr-3">Searchable</label>
                    <label class="switch">
                      <input type="checkbox"
                             v-model="treatment.searchable"
                             :true-value="'1'"
                             :false-value="'0'"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </v-layout>
        </v-container>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button :disabled="treatment.name === ''" class="btn modal-action-button" @click="addTreatment">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Trumbowyg from "vue-trumbowyg";

// Import editor css
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min.js";
import "trumbowyg/dist/plugins/indent/trumbowyg.indent.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";

export default {
  name: "addTreatment",
  components: {
    Trumbowyg
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    actionType: {
      required: true,
      type: String
    },
    // issue object, null in the case of the add
    value: {}
  },
  data: () => ({
    modal_title: "",
    actionButtonTitle: "",
    treatment: "",
    config: {
      btns: [
        ["strong", "em"],
        ["undo", "redo"], // Only supported in Blink browsers
        ["lineheight", "fontsize"],
        ["link"],
        ["unorderedList", "orderedList"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["indent", "outdent"],
        ["fontfamily"],
        ["foreColor"],

      ],
      plugins: {
        fontsize: {
          sizeList: [
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px"
          ],
          allowCustomSize: true
        },
        fontfamily: {
          fontList: [
            {name: "Galano", family: "Galano Classic Alt"},
          ]
        }
      }
    }
  }),
  created() {
    if (this.actionType === "add") {
      this.modal_title = "Add Treatment";
      this.actionButtonTitle = "Add";
    } else {
      this.modal_title = "Update Treatment";
      this.actionButtonTitle = "Update";
    }
    this.treatment = {
      ...this.value
    };
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    addTreatment() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "add-treatment", this.treatment)
          .then(response => {
            loader.hide();
            this.$toastr("success", response.data.message, "");
            this.$emit("addTreatment");
          }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      })
    }
  }
}
</script>
<style scoped>
.text-xl-center {
  text-align: left !important;
  font-size: 15px !important;
}
</style>