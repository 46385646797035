let regexHelper = {
    getNumericRegex() {
        return /[^0-9]+/;
    },
    filterNumericOnly(value) {
        return value.replace(/[^0-9]+/, '');
    },
    filterNumericAndDecimalsOnly(value) {
        return value.replace(/[^0-9.]/g, '');
    },
    filterAlphaNumeric(value) {
        return value.replace(/[^0-9a-z]/gi, '');
    },
    numberUpto2DecimalsRegex(){
        return /^\d+(\.\d{1,2})?$/;
    }
};

export default regexHelper;