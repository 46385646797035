<template>
  <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{modal_title}}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>

        <div>
          <form>
            <!-- From -->
            <div class="form-group">
              <label class="form-label">From</label>
              <div><date-picker class="p-0" :date="value1" v-on:valueUpdated="updateValue1"></date-picker></div>
            </div>
            <!-- To -->
            <div class="form-group">
              <label class="form-label">To</label>
              <div><date-picker class="p-0" :date="value2" v-on:valueUpdated="updateValue2"></date-picker></div>
            </div>
          </form>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" :disabled="!allowSubmit" :class="allowSubmit ? '':'disabled'" @click="createFilter" type="button" name="create-filter">{{actionButtonTitle}}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
    import DatePicker from "../../DatePicker.vue";
    export default {
        components: {DatePicker},
        name: "CustomTimeFilter",
        props: {
            visible: {
                required: true,
                type: Boolean
            }
        },
        data: () => ({
            modal_title : 'Custom Time Duration',
            actionButtonTitle:'Apply Filter',
            value1: '',
            value2: '',
            filterName: '',
            date: '',
        }),
        created() {

        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
            allowSubmit: function() {
                return ( this.value1 !== '' && this.value2 !== '') ;
            }
        },
        methods:{
            hide(){
                this.$emit('hide');
            },
            reset() {
                this.value1 = '';
                this.value2 = '';
            },
            createFilter() {
                const self = this;
                if (!self.allowSubmit) {
                    return false
                }
                self.$emit('apply-custom-time-filter', self.value1, self.value2);
            },
            closeModal: function() {
                this.$events.fire('close-custom-time-filter-modal');
            },
            updateValue1(value) {
                this.value1 = value;
            },
            updateValue2(value) {
                this.value2 = value;
            }
        }
    }
</script>
<style scoped>
  .v-picker__body .v-btn {
    color: #212121 !important;
    padding: 0;
    border-radius: 50%;
  }
  .v-date-picker-table .v-btn--active {
    color: #000 !important;
  }
  .v-btn.v-btn--icon {
    height: 32px !important;
    width: 32px !important;
  }
  .v-text-field {
    padding: 0 !important;
  }
</style>