<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
              Payment details
            </p>
        </div>
        <div class="content">
            <div id="configuration" class="flex-display flex-column pa-3">
              <p class="fs-16 m-b-16 font-weight-medium grey-900">Insurance</p>
              <div class="flex-display flex-row justify-content-between align-center w-100 m-b-8">
                <p class="m-b-0 fs-14 grey-500 font-weight-medium">Insurance provider</p>
                <p class="m-b-0 fs-14 grey-900 font-weight-bold">{{ insuranceProvider }}</p>
              </div>
              <div class="flex-display flex-row justify-content-between align-center w-100 m-b-8">
                <p class="m-b-0 fs-14 grey-500 font-weight-medium">Membership number</p>
                <p class="m-b-0 fs-14 grey-900 font-weight-bold">{{ membershipNumber }}</p>
              </div>
              <a v-if="insurance" href="#" @click="dialog = true">View and edit insurance details</a>

              <div class="grey-divider"></div>

              <p class="fs-16 m-b-16 font-weight-medium grey-900">Card details</p>
              <div class="flex-display flex-row align-center w-100 m-b-8" v-if="card">
                <img src="/img/master-card.png" class="width-30 height-30" alt="MOT">
                <div class="flex-display flex-column m-l-16">
                  <p class="m-b-0 fs-12 grey-900 font-weight-medium">{{ capitalizeString(card.card_company) }}</p>
                  <p class="m-b-0 fs-10 grey-900">...{{ card.last4 }}</p>
                </div>
              </div>
              <div v-else class="flex-display flex-row align-center w-100 m-b-16">
                <img src="https://assets.myonlinetherapy.com/images/icons/client-details/card-missing.png" class="width-24 height-24" alt="MOT">
                <p class="m-b-0 fs-12 grey-500 m-l-16 font-weight-medium">No card added</p>
              </div>
            </div>
        </div>
      <edit-insurance :visible="dialog"
                       v-on:refreshData="insuranceUpdated"
                       :value="insurance"
                       v-if="dialog"
                       @close="dialog = false">
      </edit-insurance>
    </div>
</template>

<script>
    import editInsurance from '@/components/main/editInsurance'
    import stringHelper from '@/helpers/stringHelper'
    export default {
        name: 'Payment',
        props: {
            card: {
                type: Object
            },
            promo: {
                type: Object
            },
            insurance: {
                type: Object
            },
        },
      components: {
        editInsurance,
      },
        data() {
            return {
              membershipNumber: this.insurance && this.insurance.membership_number !== "" ? this.insurance.membership_number : "N/A",
              insuranceProvider: this.insurance ? this.insurance.insurance_company.name : "N/A",
              dialog: false
            }
        },
      methods: {
        capitalizeString: stringHelper.capitalizeText,
        insuranceUpdated(insurance) {
          this.insurance = insurance;
          this.membershipNumber = this.insurance && this.insurance.membership_number !== "" ? this.insurance.membership_number : "N/A";
        },
      },
    }
</script>
<style scoped>
.height-auto {
  height: auto !important;
}
</style>
