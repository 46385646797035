<template>
  <div class="ml-3" :class="parentClass">
    <p class="d-inline ls-3 bold-font fs-14 mr-2">{{placeHolder}}</p>
    <date-range-picker
        v-model="dateRange"
    >
    </date-range-picker>
    <v-btn v-if="showClearButton"
           @click="resetDates"
           class="ml-2"
           depressed
           color="error"
           elevation="2">
      Clear
    </v-btn>
  </div>

</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "DatePicker",
  props: {
    filterId: {
      type: String,
      required: true
    },
    operator: {
      type: String,
      default: "in"
    },
    column:{
      type: String,
      default: ""
    },
    queryType: {
      type: String,
      default: "text"
    },
    relationShipName: {
      type: String,
      default: ""
    },
    parentClass: {
      default: ""
    },
    placeHolder: {
      type: String,
      default: "Select Date range"
    }
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      selectedFilterData: {
        filter_id: '',
        constraints: {
          column: '',
          operator: 'all',
          value1: '',
          type: 'all',
          relationship_name: ''
        },
      },
      startDate : '',
      endDate: ''
    }
  },
  computed: {
    showClearButton: function () {
      return this.startDate !== "" && this.endDate !== "";
    }
  },
  components : {
    DateRangePicker
  },
  methods: {
    selectFilter() {
      if (this.startDate !== "" && this.endDate !== "") {
        this.selectedFilterData.filter_id = this.filterId;
        this.selectedFilterData.constraints.column = this.column;
        this.selectedFilterData.constraints.operator = this.operator;
        this.selectedFilterData.constraints.value1 = this.startDate;
        this.selectedFilterData.constraints.value2 = this.endDate;
        this.selectedFilterData.constraints.type = this.queryType;
        this.selectedFilterData.constraints.relationship_name =  this.relationShipName;
        this.selectedFilterData.constraints.columnType = 'DateTime';
      } else {
        this.selectedFilterData.filter_id = this.filterId;
        this.selectedFilterData.constraints.column = '';
        this.selectedFilterData.constraints.operator = 'all';
        this.selectedFilterData.constraints.value1 = '';
        this.selectedFilterData.constraints.type = 'all';
      }
      this.$events.fire('filter-set', this.selectedFilterData);
    },
    resetDates() {
      this.startDate = "";
      this.endDate = "";
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.selectFilter();
    },
    formatDate(date) {
      let d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  watch: {
    'dateRange': function () {
        if (this.dateRange.startDate) {
          this.startDate = this.formatDate(this.dateRange.startDate)+" 00:00:00";
        }
        if (this.dateRange.endDate) {
          this.endDate = this.formatDate(this.dateRange.endDate)+" 23:59:00";
        }
        this.selectFilter();
    }
  }
}
</script>
<style scoped>
table tbody tr {
  border: none !important;
}

</style>