<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Therapists
            </p>
            <a class="create-new" href="#" @click="showTherapistModal()">New Therapist</a>
        </div>
        <div class="content">
            <div id="configuration">
                <v-tabs>
                    <v-tab class="w-50" v-for="tab in tabs" :key="tab.key">{{tab.text}}</v-tab>
                    <v-tab-item key="matched">
                        <div class="item-container border-0"
                             v-for="(therapist, index) in recommendedTherapists"
                             :key="index"
                        >
                            <p class="item-text">
                                <router-link :to="'/therapist/details/'+therapist.id">
                                    {{therapist.full_name}}
                                    <span v-if="therapist.suitability_score">({{therapist.suitability_score}})</span>
                                </router-link>
                                <span> Rec.</span>
                            </p>
                        </div>
                      <div class="item-container border-0"
                           v-for="(therapist, index) in matched_therapists"
                           :key="'A'+index"
                      >
                        <p class="item-text">
                          <router-link :to="'/therapist/details/'+therapist.id">
                            {{therapist.full_name}}
                            <span v-if="therapist.suitability_score">({{therapist.suitability_score}})</span>
                          </router-link>
                        </p>
                      </div>
                    </v-tab-item>
                    <v-tab-item key="connected">
                        <div class="item-container border-0"
                             v-for="(therapist, index) in connected_therapists"
                             :key="index"
                        >
                            <p class="item-text">
                                <router-link :to="'/therapist/details/'+therapist.id">{{therapist.full_name}}</router-link>
                            </p>
                            <div class="item-actions">
                                <img class="cursor-pointer" src="/icons/delete_payout_ic.svg" @click="deleteConfirmation(therapist.id)"/>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </div>
        </div>

        <!-- Add Therapist -->
        <add-therapist  v-if="addTherapistDialog"
                        :visible="addTherapistDialog"
                        :selectedTherapists="getSelectedTherapistsIds(connected_therapists)"
                        v-on:addTherapist="addTherapist"
                        v-on:hide="hideTherapistModal"
        >
        </add-therapist>

        <!-- delete dialog -->
        <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
                Are you sure?
            </template>
            <template slot="desc">
                This Therapist will be disconnected. This action cannot be undone.
            </template>
            <template slot="actions">
                <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                <button class="btn modal-action-button confirmation-delete" @click="removeTherapist">Delete</button>
            </template>
        </confirmation>
    </div>
</template>

<script>
    import AddTherapist from './actions/AddTherapist';
    import Confirmation from '../common/Confirmation';
    export default {
        name: 'Therapists',
        components: {
            AddTherapist,
            Confirmation
        },
        props: {
            user_id: {
                type: [Number, String],
                required: true
            },
            matched_therapists: {
                type: Array,
                required: true
            },
            connected_therapists: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                tabs:[
                    {'key':'matched','text':'Matched'},
                    {'key':'connected','text':'Connected'},
                ],
                addTherapistDialog: false,
                deleteDialog: false,
                activeTherapist: '',
                matchedTherapistResponse: null,
                recommendedTherapistsResponse: null,
                matchedTherapists:[],
                recommendedTherapists:[],
            }
        },
      created() {
        this.matchedTherapistResponse = JSON.parse(this.matched_therapists.response);
        this.recommendedTherapistsResponse = JSON.parse("[" + this.matched_therapists.matched_therapists + "]");
        this.compareTherapistsResponse(this.matchedTherapistResponse,this.recommendedTherapistsResponse);
      },
        methods: {
          deleteConfirmation(therapist_id) {
                this.deleteDialog = true;
                this.activeTherapist =  therapist_id;
            },
            hideConfirmation() {
                this.deleteDialog = false;
                this.activeTherapist = '';
            },
            removeTherapist() {
                this.deleteDialog = false;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'therapist_connection', {
                    params: {
                        client_id: this.user_id,
                        therapist_id: this.activeTherapist,
                        user_type: 'client'
                    }
                }).then(response => {
                    loader.hide();
                    this.$toastr('success',response.data.message,'');
                    this.$emit('updateTherapists', response.data.connections);
                    this.$emit('updateActivites',response.data.activites);

                }).catch(error => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message,'');
                });
            },
            showTherapistModal() {
                this.addTherapistDialog = true;
            },
            hideTherapistModal() {
                this.addTherapistDialog  = false;
            },
            addTherapist(therapist_id) {
                let loader = this.$loading.show();
                this.$http.put(this.url+'therapist_connection', {
                    client_id: this.user_id,
                    therapist_id: therapist_id,
                    user_type: 'client'
                }).then(response => {
                    loader.hide();
                    this.$toastr('success',response.data.message,'');
                    this.$emit('updateTherapists', response.data.connections);
                    this.$emit('updateActivites',response.data.activites);
                }).catch(error => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message,'');
                });
            },
            getSelectedTherapistsIds(therapists) {
                let selectedIds = therapists.map( function(obj){
                    return obj.id;
                });
                return selectedIds;
            },
          compareTherapistsResponse(matchedTherapistsResponse, recommendedTherapistsResponse) {
            let self = this;
            $.each(matchedTherapistsResponse, function (index, item) {
              for (let i = 0; i < recommendedTherapistsResponse.length; i++) {
                if (item['id'] === recommendedTherapistsResponse[i]) {
                  self.recommendedTherapists.push(item);
                }
              }
            });
            this.matchedTherapists = matchedTherapistsResponse.filter(id => !this.recommendedTherapists.includes(id));
          },

        }
    }
</script>
