<template>
  <div class="w-100">

    <!--  Pricing Model  -->
    <div class="main-container mb-3">
      <div class="row mx-0">
        <div class="col-xs-6">
          <label class="org-label switch-label pr-3 ml-5">Pricing model:</label>
        </div>
        <div class="col-xs-6">
          <select class="input-select"
                  :class="{'error-border' : planPricingModelError}"
                  name="pricing_model"
                  @change="pricingModelChanged(plan.pricing_id)"
                  v-model="plan.pricing_id">
            <option value="0" disabled selected>Select pricing model</option>
            <template v-for="(pricingPlan, index) in plans[1].pricing">
              <option :value="pricingPlan.id">{{ pricingPlan.title }}</option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <!--  Session Entitlement  -->
    <div class="main-container my-3 w-100">
      <div class="flex-display flex-row align-center">
        <div class="toggle-switch flex-display flex-row align-center">
          <label class="org-label switch-label pr-3 lh-20">
            Therapy session entitlement<br>(disabled = undefined):</label>
          <label class="switch">
            <input type="checkbox"
                   name="self_care"
                   v-model="plan.undefined_sessions"
                   :true-value="false"
                   :false-value="true">
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="flex-display flex-row align-center ml-3" v-if="!plan.undefined_sessions">
        <!-- Plan title -->
        <div class="w-100 flex-display flex-row">
          <label class="org-label form-label pt-0 w-auto white-space-pre">
            # of sessions:
          </label>
          <input v-model="plan.allowed_sessions"
                 :class="{'error-border' : isEmpty(plan.allowed_sessions)}"
                 class="input-text w-100px m-l-12"
                 placeholder="Number">
        </div>
      </div>
    </div>

    <!--  Session Types  -->
    <div class="flex-display flex-column w-100">
      <div class="flex-display flex-row">
        <div class="flex-display flex-column">
          <label class="org-label switch-label height-40">Session types:</label>
          <div class="flex-display flex-column ml-5">
            <template v-for="(sessionType, index) in sessionTypes">
              <label class="height-40 flex-row flex-display align-center org-label light-label mb-0">
                {{ sessionType.title }}:
              </label>
            </template>
          </div>
        </div>
        <div class="flex-display flex-column ml-4">
          <label class="height-40 light-label org-label form-label">Initial</label>
          <template v-for="(sessionType, index) in sessionTypes">
            <div v-if="getInitialSessionType(sessionType.session_types).length > 0"
                 class="height-40 flex-display flex-row align-center man-cell field">
              <label>
                <input type="checkbox"
                       @change="sessionTypeSelection($event, sessionType, 'initial')"
                       v-model="getInitialSessionType(sessionType.session_types)[0].status"
                       :true-value="true"
                       :false-value="false">
                <div class="man-circle">
                  <img class="circle-check" src="/img/whitecheckmark.png">
                </div>
              </label>
              <span class="text light-label">{{ getInitialSessionTypePrice(sessionType.session_types) }}</span>
            </div>
            <div v-else class="height-40 flex-display flex-row align-center man-cell field"></div>
          </template>
        </div>
        <div class="flex-display flex-column m-l-100">
          <label class="height-40 light-label org-label form-label">Ongoing</label>
          <template v-for="(sessionType, index) in sessionTypes">
            <div v-if="getOngoingSessionType(sessionType.session_types).length > 0"
                 class="height-40 flex-display flex-row align-center man-cell field">
              <label>
                <input type="checkbox"
                       @change="sessionTypeSelection($event, sessionType, 'ongoing')"
                       v-model="getOngoingSessionType(sessionType.session_types)[0].status"
                       :true-value="true"
                       :false-value="false">
                <div class="man-circle">
                  <img class="circle-check" src="/img/whitecheckmark.png">
                </div>
              </label>
              <span class="text light-label">{{ getOngoingSessionTypePrice(sessionType.session_types) }}</span>
            </div>
            <div v-else class="height-40 flex-display flex-row align-center man-cell field"></div>
          </template>
        </div>
      </div>
    </div>

    <!--  Plan Diagnosis  -->
    <div class="main-container my-3">
      <div class="main-container__first">
        <div class="toggle-switch">
          <label class="org-label switch-label">Diagnosis</label>
          <label class="switch">
            <input type="checkbox"
                   name="is_diagnosis"
                   v-model="plan.is_diagnosis"
                   :true-value="true"
                   :false-value="false">
            <span class="slider"></span>
          </label>
        </div>
      </div>

      <div class="w-100 ml-3" v-if="plan.is_diagnosis">
        <div class="row w-100">
          <div class="col-xs-12">
            <select class="input-select width-200"
                    :class="{'error-border':  showDiagnosisError}"
                    name="icd_10_code"
                    v-model="plan.type_of_diagnosis"
            >
              <option value="ICD-10">ICD-10</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!--  Paid By Org  -->
    <div class="main-container my-3 w-100">
      <div class="toggle-switch">
        <label class="org-label switch-label pr-3">Therapy paid by 3rd party: &nbsp;</label>
        <label class="switch">
          <input type="checkbox"
                 v-model="plan.paid_by_org"
                 :true-value="true"
                 :false-value="false">
          <span class="slider"></span>
        </label>
      </div>
    </div>
    <div class="main-container my-3 w-100" v-if="!plan.paid_by_org">
      <div class="toggle-switch">
        <label class="org-label switch-label pr-3">Prepay: &nbsp;</label>
        <label class="switch">
          <input type="checkbox"
                 v-model="plan.prepay"
                 :true-value="true"
                 :false-value="false">
          <span class="slider"></span>
        </label>
      </div>
    </div>

    <div class="flex-display flex-column w-100 border-element border-radius-8 px-4 py-3" v-if="plan.paid_by_org">
      <!--  Additional Session Request  -->
      <div class="main-container my-3 w-100">
        <div class="flex-display flex-row align-center">
          <div class="toggle-switch flex-display flex-row align-center">
            <label class="org-label switch-label pr-3">
              Additional session request:</label>
            <label class="switch">
              <input type="checkbox"
                     v-model="plan.enable_additional_sessions_request"
                     :true-value="true"
                     :false-value="false">
              <span class="slider"></span>
            </label>
          </div>
        </div>

        <div class="flex-display flex-row align-center ml-3" v-if="plan.enable_additional_sessions_request">
          <!-- Plan title -->
          <div class="w-100 flex-display flex-row">
            <label class="org-label form-label pt-0 w-auto white-space-pre">
              Allow after
            </label>
            <input v-model="plan.additional_session_request_trigger"
                   class="input-text width-40 m-l-6 m-r-6"
                   :class="{'error-border' : isEmpty(plan.additional_session_request_trigger)}"
                   placeholder="#">
            <label class="org-label form-label pt-0 w-auto white-space-pre">
              sessions
            </label>
          </div>
        </div>
      </div>
      <!--   Additional Service Charges   -->
      <div>
        <div class="main-container mb-3 w-100">
          <div class="toggle-switch flex-display flex-row align-center">
            <label class="org-label switch-label pr-3">
              Additional service charge:</label>
            <label class="switch">
              <input type="checkbox"
                     v-model="plan.enable_service_charges"
                     :true-value="true"
                     :false-value="false"
                     :disabled="actionType !== 'add'">
              <span class="slider"></span>
            </label>
          </div>
        </div>

        <div class="flex-display flex-column w-100" v-if="plan.enable_service_charges">
          <template v-for="(charge, index) in plan.serviceCharges">
            <service-charge :charge="charge"
                            :show-delete-icon="plan.serviceCharges.length > 1 && actionType === 'add'"
                            :should-disable="actionType !== 'add'"
                            :show-service-charges-errors="showServiceChargesErrors"
                            :index="index"
                            @delete-service-charge="removeServiceCharge">
            </service-charge>
          </template>
          <a v-if="actionType === 'add'"
             @click="createNewServiceCharge()"
             class="btn-edit width-85 height-32 border-radius-8 ml-2">
            Add more
          </a>
        </div>
      </div>
    </div>

    <!--  GAIA Plan  -->
    <div class="main-container my-3 w-100">
      <div class="grid">
        <div class="row">
          <div class="col-md-12">
            <div class="toggle-switch">
              <label class="org-label switch-label pr-3">GAIA</label>
              <label class="switch">
                <input type="checkbox" v-model="plan.is_gaia" :true-value="true" :false-value="false">
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <!--parent div to hide toggles if GAIA is not enabled-->
        <div v-if="plan.is_gaia">
          <div class="main-container w-100 mt-3">
            <div class="w-100">
              <div class="flex-row flex-display align-center pl-3">
                <div class="flex-display flex-row justify-space-between align-center w-160px">
                  <label class="flex-row flex-display align-center org-label switch-label mb-0">
                    Deprexis
                  </label>
                  <label class="switch">
                    <input type="checkbox" v-model="plan.gaia_configuration.deprexis" :true-value="true"
                           :false-value="false">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.deprexis">
                  <select v-model="plan.gaia_configuration.deprexis_plan_id" class="form-control width-299 height-40" id="deprexisPlan">
                    <option value="" disabled selected>Select Plan</option>
                    <option v-for="plan in gaiaPlanList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="main-container w-100 mt-3">
            <div class="w-100">
              <div class="flex-row flex-display align-center pl-3">
                <div class="flex-display flex-row justify-space-between align-center w-160px">
                  <label class="flex-row flex-display align-center org-label switch-label mb-0">
                    Velibra
                  </label>
                  <label class="switch">
                    <input type="checkbox" v-model="plan.gaia_configuration.velibra" :true-value="true"
                           :false-value="false">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.velibra">
                  <select v-model="plan.gaia_configuration.velibra_plan_id" class="form-control width-299 height-40" id="valibraPlan">
                    <option value="" disabled selected>Select Plan</option>
                    <option v-for="plan in gaiaPlanList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="main-container w-100 mt-3">
            <div class="w-100">
              <div class="flex-row flex-display align-center pl-3">
                <div class="flex-display flex-row justify-space-between align-center w-160px">
                  <label class="flex-row flex-display align-center org-label switch-label mb-0">
                    Vorvida
                  </label>
                  <label class="switch">
                    <input type="checkbox" v-model="plan.gaia_configuration.vorvida" :true-value="true"
                           :false-value="false">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.vorvida">
                  <select v-model="plan.gaia_configuration.vorvida_plan_id" class="form-control width-299 height-40" id="vorvidaPlan">
                    <option value="" disabled selected>Select Plan</option>
                    <option v-for="plan in gaiaPlanList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Outcome Measure  -->
    <div class="row">
      <div class="col-md-12">
        <div class="toggle-switch">
          <label class="org-label switch-label pr-3">Outcome measures:</label>
          <label class="switch">
            <input type="checkbox"
                   v-model="plan.enable_outcome_measures"
                   :true-value="true"
                   :false-value="false">
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>

    <!--  NPS Plan  -->
    <div class="main-container my-3 w-100">
      <div class="flex-display flex-row align-center">
        <div class="toggle-switch flex-display flex-row align-center">
          <label class="org-label switch-label pr-3">
            NPS:</label>
          <label class="switch">
            <input type="checkbox"
                   v-model="plan.enable_nps"
                   :true-value="true"
                   :false-value="false">
            <span class="slider"></span>
          </label>
        </div>
      </div>

      <div class="flex-display flex-row align-center ml-3" v-if="plan.enable_nps">
        <!-- Plan title -->
        <div class="w-100 flex-display flex-row">
          <label class="org-label form-label pt-0 w-auto white-space-pre">
            Trigger after
          </label>
          <input v-model="plan.nps_trigger_number"
                 class="input-text width-40 m-l-6 m-r-6"
                 :class="{'error-border' : isEmpty(plan.nps_trigger_number)}"
                 placeholder="#">
          <label class="org-label form-label pt-0 w-auto white-space-pre">
            sessions
          </label>
        </div>
      </div>
    </div>

    <!--  Bulk Invoicing  -->
    <div class="row">
      <div class="col-md-12">
        <div class="toggle-switch">
          <label class="org-label switch-label pr-3">Bulk Invoicing:</label>
          <label class="switch">
            <input type="checkbox"
                   v-model="plan.enable_bulk_invoicing"
                   :true-value="true"
                   :false-value="false"
                   :disabled="actionType !== 'add'">
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import regexHelper from '@/helpers/regexHelper'
import numberHelper from '@/helpers/numberHelper'
import ServiceCharge from '@/components/main/orgConsole/ServiceChargeLatest'

export default {
  name: 'therapyPlan',
  props: {
    therapyPlan: {
      default: null
    },
    plans: {
      default: null
    },
    actionType: {
      default: 'add'
    },
    gaiaPlanList: {
      default: []
    },
    showDiagnosisErrors: {
      default: false
    },
    showServiceChargesErrors: {
      default: false
    },
    planPricingModelError: {
      default: false,
    }
  },
  components: {
    ServiceCharge,
  },
  created () {
    this.plan = {
      ...this.therapyPlan,
    };
    this.sessionTypes = this.plan.session_types;
  },
  computed: {
    showDiagnosisError () {
      return this.plan.is_diagnosis && this.isEmpty(this.plan.type_of_diagnosis) && this.showDiagnosisErrors
    },

  },
  data () {
    return {
      plan: null,
      sessionTypes: null,
    }
  },
  methods: {
    isEmpty (value) {
      return numberHelper.isEmpty(value)
    },
    createNewServiceCharge() {
      this.plan.serviceCharges.push({
        name: '',
        price: '',
        plan_id: this.plan.id
      });
    },
    removeServiceCharge(index) {
      this.plan.serviceCharges.splice(index, 1);
    },
    sessionTypeSelection (e, sessionType, sessionStage) {
      let id = sessionStage === "initial"
          ? this.getInitialSessionType(sessionType.session_types)[0].id
          : this.getOngoingSessionType(sessionType.session_types)[0].id;
      let index = this.getIndexOfSelectedSessionType(sessionType.session_types, id, sessionStage);
      this.plan.session_types[sessionType.title].session_types[index].status = e.target.checked;
    },
    getInitialSessionType (sessionTypes) {
      return sessionTypes.filter(function (sessionType) {
        return sessionType.type === 'initial'
      })
    },
    getInitialSessionTypePrice (sessionTypes) {
      let sessionType = this.getInitialSessionType(sessionTypes)
      return '£' + sessionType[0].price
    },
    getIndexOfSelectedSessionType(sessionTypes, id, sessionStage){
      return sessionTypes.findIndex(x => x.id === id && x.type === sessionStage)
    },
    getOngoingSessionType (sessionTypes) {
      return sessionTypes.filter(function (sessionType) {
        return sessionType.type === 'ongoing'
      })
    },
    getOngoingSessionTypePrice (sessionTypes) {
      let sessionType = this.getOngoingSessionType(sessionTypes)
      return '£' + sessionType[0].price
    },
    pricingModelChanged(val) {
      let pricingModel = this.plans[1].pricing.filter((p) => {
        return p.id === val;
      });
      this.plan.session_types = pricingModel[0].sessionTypes;
      this.sessionTypes = this.plan.session_types;
    }
  },
  watch: {
    therapyPlan: {
      handler (newVal, oldVal) {
        this.plan = {...newVal};
      },
      deep: true,
    },
    plan: {
      handler (newVal, oldVal) {
        this.$emit('planChanged', newVal)
      },
      deep: true,
    },
    'plan.allowed_sessions': function (value) {
      this.plan.allowed_sessions = regexHelper.filterNumericOnly(value.toString())
    },
    'plan.nps_trigger_number': function (value) {
      if (value) {
        this.plan.nps_trigger_number = regexHelper.filterNumericOnly(value.toString())
      }
    },

    'plan.paid_by_user': function (value) {
      if (value === true) {
        this.plan.paid_by_org = false
      }
    },
    'plan.is_diagnosis': function (value) {
      if (value === false) {
        this.plan.type_of_diagnosis = ''
      }
    },
    'plan.paid_by_org': function (value) {
      if (value === true) {
        this.plan.paid_by_user = false
      }
      if (value === false) {
        this.plan.paid_by_user = true
        this.plan.add_insurance = false
        this.plan.card_details_required = true
        this.plan.enable_additional_sessions_request = false
        this.plan.enable_service_charges = false
      }
    },
    'plan.additional_session_request_trigger': function (value) {
      if (value) {
        this.plan.additional_session_request_trigger = regexHelper.filterNumericOnly(value.toString())
      }
    },
    'plan.enable_additional_sessions_request': function (value) {
      if (this.plan.enable_bulk_invoicing) {
        this.$nextTick(() => this.plan.enable_additional_sessions_request = false)
      }
    },
    'plan.enable_bulk_invoicing': function (value) {
      if (this.plan.enable_additional_sessions_request) {
        this.$nextTick(() => this.plan.enable_bulk_invoicing = false)
      }
    },
  }
}
</script>

