<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
                <div class="add-member-modal">
                    <p class="add-member-modal__header-text">You can add other team members to your app here! They will be able to edit content, manage users, and contribute to all aspects of your application.</p>
                    <form>
                        <div class="form-group">
                            <label class="form-label">Email</label>
                            <input v-model="email" class="input-text" type="text" placeholder="Enter email" :disabled="this.action === 'edit'"/>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Role</label>
                            <select name="role" class="input-select" v-model="role">
                                <option value="" disabled selected>Select a role</option>
                                <option value="APP_ADMIN">Admin</option>
                                <option value="SUPPORT">Support</option>
                            </select>
                        </div>
                    </form>

                </div>

            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button class="btn modal-action-button" @click="addMember">{{actionButtonTitle}}</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "AddMember",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            action: {
                type: String,
                required: true
            },
            member: {
                type: Object,
                default: null
            },

        },
        data: () => ({
            modal_title : 'Add team member',
            actionButtonTitle:'Add Member',
            id: null,
            email: '',
            role: '',
        }),
        created() {
            if ( this.member && this.action === 'edit' ) {
                this.modal_title = 'Edit team member';
                this.actionButtonTitle = 'Save Member';
                this.id = this.member.id;
                this.email = this.member.email;
                this.role = this.member.app.role
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide() {
                this.$emit('hide')
            },
            addMember() {
                const eventName = this.action === 'edit' ? 'editMember' : 'addMember';
                this.$emit(eventName, this.email, this.role, this.id);
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>

</style>