<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
              <div class="search-bar-container">
                <input type="text" class="w-100 search-container" id="search" v-model="keyword" v-on:keyup="keywordUpdated" placeholder="Search for a therapist">
                <span class="submit" @click="search">
                    </span>
              </div>
              <template v-if="therapists.length === 0">
                <div class="user-tile">
                  <div class="user-tile__user_detail">
                    <p class="user-tile__user_detail__sub-header">No results found.</p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="users-list" id="therapists_list" v-on:scroll="loadMore(false)">
                  <div v-for="therapist in therapists">
                    <div class="user-tile">
                      <div class="user-tile__user_img">
                        <img :src="therapist.profile_picture !== '' ? therapist.profile_picture : defaultImage"/>
                      </div>
                      <div class="user-tile__user_detail col-md-7">
                        <p class="user-tile__user_detail__header word-break">{{ therapist.full_name }}</p>
                        <p class="user-tile__user_detail__sub-header word-break">{{ therapist.designation }}</p>
                        <p class="user-tile__user_detail__text"></p>
                      </div>
                      <div class="user-tile__actions">
                        <span class="user-tile__actions___status" v-if="selectedTherapists.indexOf(therapist.id) > -1">Connected</span>
                        <button class="btn user-tile__actions___connect" @click="addTherapist(therapist.id)" v-else>
                          Connect
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="user-tile flex-display flex-row justify-center align-center h-100" v-if="showLoadMore">
                      <div class="user-tile__actions">
                          <a class="user-tile__actions___status cursor-pointer" @click="loadMore(true)">Load more</a>
                      </div>
                  </div>
              </template>

            </v-card-text>

            <v-card-actions class="flex-display flex-row">

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "AddTherapist",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            selectedTherapists: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            modal_title : 'Add a Therapist',
            actionButtonTitle:'Apply',
            defaultImage: '/img/default_user_img.svg',
            therapists: [],
            keyword: '',
            take: 10,
            skip: 0,
            showLoadMore: true,
        }),
        created() {
            this.getTherapists();
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            getTherapists() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.get(this.url+'users', {
                    params: {
                        skip: this.skip,
                        take: this.take,
                        keyword: this.keyword,
                        type: 'therapist'
                    }
                }).then(function (response) {
                    loader.hide();
                    if ( self.skip === 0 ) {
                        self.therapists = response.data.users;
                    }
                    else {
                        self.therapists = self.therapists.concat(response.data.users);
                    }
                    if (response.data.users.length === 0) {
                        self.showLoadMore = false;
                    }
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, 'Error');
                })
            },
            hide(){
                this.$emit('hide')
            },
            addTherapist(therapist_id){
                this.$emit('addTherapist', therapist_id);
                this.$emit('hide');
            },
            loadMore(skipScroll) {
                const self = this;
                let container = document.getElementById('therapists_list');
                if (!skipScroll) {
                    if ( ( container.clientHeight + container.scrollTop ) >= container.scrollHeight ) {
                        console.log("Load more");
                        self.skip = self.skip + self.take;
                        self.getTherapists();
                    }
                } else {
                    self.skip = self.skip + self.take;
                    self.getTherapists();
                }
            },
            keywordUpdated(event) {
              if (this.keyword === '' || event.keyCode === 13) {
                this.search();
              }
          },
            search () {
                this.skip = 0;
                this.getTherapists();
            }
        }
    }
</script>
<style scoped>
.word-break {
  word-break: break-all;
}
</style>
