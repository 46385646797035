<template>
  <div class="booking-filters">
    <filters
      :filters="filters"
      :source="'dashboard-booking'"/>
  </div>
</template>

<script>
    import Filters from '../common/dataTable/filters/Filters'

    export default {
        name: 'DashboardBookingFilters',
        components: { Filters },
        props: {
            filters: {
                type: Array,
                required: true
            }
        },
        mounted () {
            this.$events.listen('filter-set', e => this.$emit('applyBookingFilters', e))
        }
    }
</script>
