<template>
    <div class="user-progress">
        <div class="item-actions">
            <button @click="deleteRecord" class="btn-delete">Delete</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        methods: {
            deleteRecord() {
                this.$events.fire('delete-record', this.rowData)
            }
        }
    }
</script>
