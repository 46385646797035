<template>
    <span>
        <span class="slickdot slickdot-nav-unread-message" v-if="pendingRequests > 0"></span>
    </span>
</template>

<script>
    export default {
        data: function () {
            return {
                pendingRequests: []
            }
        },
        created: function () {
            this.getPendingRequests();
        },
        methods: {
            getPendingRequests: function () {
                var self = this;
                this.$http.get(this.url+'session/pending_requests').then(function (response) {
                    self.pendingRequests = response.data.requests;
                }).catch(function (error) {
                    self.$toastr('error', error.response.data.message, 'Error');
                });
            }
        },
        events: {
            'session-request-responded': function() {
                this.getPendingRequests();
            },
        }

    }
</script>