<template>
  <!-- details modal -->
  <v-dialog v-model="show" persistent max-width="400px" min-height="263px" v-else>
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" alt="mot" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>

        <div class="import-form">
          <div class="detail-item">
            <label class="item-label">Upload File:</label>
            <input type="file" name="file" class="form-control-file" id="importFile" @change="onImportFileChanged">
          </div>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <button class="btn modal-action-button confirmation-delete" @click.stop="hide">Cancel</button>
        <button class="btn modal-action-button" @click="submit()">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Confirmation from "../common/Confirmation";

export default {
  name: "ImportCsv",
  components: {Confirmation},
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    apiUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalTitle: 'Import CSV',
      actionButtonTitle: 'Submit',
      csv_file: null,

    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    hide() {
      this.$emit('hide')
    },
    onImportFileChanged(event) {
      this.csv_file = event.target.files[0];
    },
    submit(event) {
      if (this.csv_file === null) {
        alert('Please Select File to Upload');
      } else {
        this.respondFileImportRequest();
      }
    },
    respondFileImportRequest() {
      let self = this;

      let loader = self.$loading.show();
      const formData = new FormData();
      formData.append('file', this.csv_file, this.csv_file.name);
      self.$http.post(this.url + 'import/' + this.apiUrl, formData).then((response) => {
        self.$toastr('success', response.data.message, '');
        self.$events.fire('vuetable:reload');
        self.$emit('hide');
        self.$emit('updateTab');
        loader.hide();
      }).catch(function (error) {
        loader.hide();
        self.$toastr('error', error.response.data.message, '');
      })
    },

  },
  watch: {},
}
</script>
