<template>
    <v-card>
        <v-card-title>
            <h1 class="model-heading">{{modalTitle}}</h1>
            <img class="close-model" src="/icons/close_ic.svg" alt="" @click.stop="hide"/>
        </v-card-title>

        <v-card-text>
            <div class="add-member-modal">
                <div class="custom-date-picker mt-4">
                    <v-date-picker
                            :events="availability_dates"
                            event-color="green lighten-1"
                            :min="minimum_date"
                            v-model="date"
                            :reactive="reactive">
                    </v-date-picker>
                </div>
            </div>

        </v-card-text>

        <v-card-actions class="flex-display flex-row">
            <v-spacer></v-spacer>
            <button class="btn modal-action-button" @click="applyDate">{{actionButtonTitle}}</button>
        </v-card-actions>
    </v-card>
</template>
<script>
    export default {
        name: "AvailabilityCalendar",
        props: {
            availability_dates: {
                type: Array,
                required: true
            },
            minimum_date: {
                type: String
            },
            selected_date: {
                type: String
            }
        },
        data: () => ({
            modalTitle : '',
            actionButtonTitle:'Apply',
            reactive: true,
            date: null
        }),
        created() {
            this.date = this.selected_date;
            this.booking_data = this.booking;
        },
        methods:{
            hide() {
                this.$emit('switchView', 'form')
            },
            applyDate() {
                this.$emit('applyDate', this.date)
            }
        },
        watch: {

        }
    }
</script>