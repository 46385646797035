<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click="hideAddOrgModal"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="organisationForm"
            >

              <!-- Add Organisation Title-->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">Organisation title</span>
                  <input class="input-text"
                         type="text"
                         name="name"
                         v-model="organisation.name"
                         placeholder="Enter organisation Title"
                  />
                </label>
              </div>

              <!-- Add Organisation Logo Url -->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">Organisation logo</span>
                  <input class="input-text"
                         type="text"
                         name="name"
                         v-model="organisation.logo_url"
                         placeholder="Enter organisation logo url"
                  />
                </label>
              </div>

              <!-- Add emails -->
              <div class="from-group w-100 flex-display flex-row flex-wrap flex-justify-between ">
                <div class="form-group w-48">
                  <label>
                    <span class="form-label">Add Emails(Press Enter to add more)</span>
                    <keywords-component :keywords="organisation.emails"
                                        v-on:update="updateEmails"
                                        :placeholder="'Enter emails'">
                    </keywords-component>
                  </label>
                </div>

                <div class="form-group w-10">
                  <label>
                    <span class="form-label">Is Default</span>
                    <label class="switch">
                      <input type="checkbox" v-if="!defaultOrgFlag || (defaultOrgFlag && initialValue)" name="default" v-model="organisation.default" :true-value=true :false-value=false>
                      <input type="checkbox" v-else :disabled="true" name="default" v-model="organisation.default" :true-value=true :false-value=false>
                      <span class="slider round"></span>
                    </label>
                  </label>
                </div>
                <div class="form-group w-30">
                  <label>
                    <span class="form-label">Is Insurer</span>
                    <label class="switch">
                      <input type="checkbox" name="is_insurer" v-model="organisation.is_insurer" :true-value=true :false-value=false>
                      <span class="slider round"></span>
                    </label>
                  </label>
                </div>
              </div>

              <div class="from-group w-100 flex-display flex-row flex-wrap flex-justify-between ">
                <div v-if="organisation.is_insurer" class="form-group w-48">
                  <label>
                    <span class="form-label">Choose Insurance Provider</span>
                        <select  class="input-select w-100" v-model="organisation.insurance_company_id">
                          <option value="" disabled>Select Insurance Provider</option>
                          <template v-for="insuranceProvider in insuranceProviders">
                            <option :value="insuranceProvider.id" :key="insuranceProvider.id">{{ insuranceProvider.name }}</option>
                          </template>
                        </select>
                  </label>
                </div>
              </div>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hideAddOrgModal">Cancel</a>
        <button class="btn modal-action-button" @click="addOrg">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeywordsComponent from "@/components/common/KeywordsComponent";
export default {
  name: "addOrganisation",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    org: {
      required: false,
      type: Object
    },
    actionType: {
      type: String,
    }
  },
  components: {
    KeywordsComponent
  },
  data: () => ({
    modalTitle: "",
    actionButtonTitle: "",
    organisation: {
      name: "",
      logo_url: "",
      emails: [],
      default: false,
      is_insurer: false,
      insurance_company_id: ''
    },
    defaultOrgFlag: false,
    initialValue: false,
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      },
    }
  },
  created() {
    this.getInsuranceProviders();
    this.orgFlag();
    if( this.actionType === "add" ) {
      this.modalTitle  = "Add Organisation";
      this.actionButtonTitle = "Add Org";
    }
    else {
      this.modalTitle = "Update Organisation";
      this.actionButtonTitle = "Update Org";
    }
    if (this.org) {
      this.organisation = this.org;
    }
    this.initialValue  =  this.organisation.default;

  },
  methods: {
    hideAddOrgModal() {
      this.$emit("hideAddOrgModal");
    },
    addOrganisation() {
      if (this.validateSubmitRequest()) {
        this.$emit("submit", this.organisation);
      }
    },
    validateSubmitRequest() {
      if (this.organisation.name === "") {
          this.showAlert("Please enter organisation title.");
          return false;
      }
      return true;
    },
    addOrg() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "org", this.organisation).then(response => {
        loader.hide();
        this.hideAddOrgModal();
        this.$toastr("success", response.data.message, "");
        this.$events.fire("vuetable:reload");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },

    orgFlag(){
      this.$http.get(this.url+"default-org", {
      }).then(response => {
        this.defaultOrgFlag = response.data.default_org_flag;
      }).catch(error => {
        //
      })
    },
    getInsuranceProviders() {
      let loader = this.$loading.show();
      this.$http.get(this.url + "get-insurance-providers").then(response => {
        this.insuranceProviders = response.data.insurance_providers;
        loader.hide();
      }).catch(error => {
        loader.hide();
      })
    },
    showAlert(msg) {
      this.$toastr("error", msg);
    },
    updateEmails(emails) {
      this.organisation.emails = emails;
    },
  }
}
</script>
