// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueEvents from 'vue-events'
// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import {sync} from 'vuex-router-sync'

// Application imports
import router from '@/router'
import App from './App'
import i18n from '@/i18n'
import store from '@/store'
import 'nprogress/nprogress.css'
import Axios from 'axios'
import Popover from 'vue-js-popover';
//sendbird


import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// vue lazy image
import {VLazyImagePlugin} from "v-lazy-image";
import moment from 'moment'

// Show notification
import VueToastr from '@deveodk/vue-toastr'
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'

Vue.use(VueEvents)

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.withCredentials = true;

Vue.use(Popover);

Vue.use(Loading, {
  // props
  color: '#308BC3'
},{
  // slots
});

Vue.use(VLazyImagePlugin);

Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 2000
});

// Sync store with router
sync(store, router)

Vue.config.productionTip = false
Vue.prototype.$http.defaults.headers.common['Accept'] ='application/json';
// Token
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] ='Bearer ' +token;
  // Add a response interceptor
  Vue.prototype.$http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    // Do something with response error
    if(error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
    }
    return Promise.reject(error);
  })

}

Vue.mixin({
  data: function() {
    return {
      url: process.env.VUE_APP_API_PATH,
      environment: process.env.VUE_APP_APP_ENV,
      sendBirdId: process.env.VUE_APP_SENDBIRD_APP_ID,
      token: localStorage.getItem('token'),
      deviceType: 'web',
        get environmentPrefix () {
            let environment = process.env.VUE_APP_APP_ENV
            switch (environment) {
                case 'production':
                    return 'live-';
                case 'staging':
                    return 'stag-';
                case 'development':
                    return 'dev-';
                case 'local':
                    return 'local-';
                default:
                    return environment + '-';
            }
        },
      get timezone() {
          let tz = jstz.determine();
          return tz.name()
      },
        get userType() {
            return 'admin';
      }
    }
  },
  methods: {
      customDateFormat(time, format) {
          if (time === null) {
              return '';
          } else {
              if (time.indexOf(' ') !== -1) {
                  const utcTime = time.replace(' ', 'T') + 'Z'
                  return moment(utcTime).tz(moment.tz.guess()).format(format)
              } else {
                  return moment(time).tz(moment.tz.guess()).format(format)
              }
          }
      },
      formatDate(timestamp) {
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let date = new Date(timestamp);
          return months[date.getMonth()] + ' ' + date.getDate();
      },
      formatTime(timestamp) {
          let date = new Date(timestamp);
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let time = 'AM';
          if ( hours >= 12 ) {
              hours = hours - 12;
              time = 'PM';
          }

          if ( hours === 0 ) {
              hours = 12;
          }
          if ( hours < 10 ) {
              hours = '0' + hours;
          }
          if ( minutes < 10 ) {
              minutes = '0' + minutes;
          }
          return hours + ':' + minutes + ' ' + time;
      },
      scrollDownToBottom(parent_container, child_container) {
          $( document ).ready(function() {
              let container = document.getElementById(child_container);
              document.getElementById(parent_container).scrollTop = container.scrollHeight;
          });
      },
      isEmpty(value) {
          return value === '' || typeof value === 'undefined';
      },
      setHeaderToken(token) {
          Vue.prototype.$http.defaults.headers.common['Authorization'] ='Bearer ' + token;
      }
  }
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
