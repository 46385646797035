<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px" v-if="activeView === 'assessment'">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
                <div class="client_initial_assessment">
                    <div class="form_container">
                        <div class="form_header">
                            <p>Based on your initial consultation, please add your initial review</p>
                        </div>
                        <div class="assessment_form">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <label class="app-label">Select Client Issue</label>
                                    <div class="issues_container" @click.stop="openIssuesDialog">
                                        <span class="float-left">Select issues</span>
                                        <img class="float-right" src="/icons/dropdown_right_ic.svg"/>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div v-if="selectedIssues.length > 0" class="selected_issues"> {{ selectedIssues.toString() }}</div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <label class="app-label">Severity</label>
                                    <div>
                                        <select class="form-control" v-model="selectedSeverity">
                                            <option value="" selected hidden disabled>Select Severity</option>
                                            <template v-for="sev in allSeverities">
                                                <option :value="sev.name">{{ sev.name }}</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <label class="app-label">Optional Notes</label>
                                    <textarea v-model="optional_notes" class="form-control" rows="4" placeholder="Enter here..."></textarea>
                                </div>

                                <div class="col-md-12 mt-4">
                                    <button class="btn next_button app_button w-100 h-40" :disabled="disabled" @click="addAssessment">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <div v-else-if="activeView === 'issues' && isEverythingReady">
        <issues :visible="issuesDialog"
                :issues="allIssues"
                :selectedIssues="selectedIssues"
                v-on:addIssue="updateIssues"
                v-on:hide="closeIssuesDialog"
        ></issues>
    </div>
</template>
<script>
    import Issues from '../../people/actions/AddIssue';
    export default {
        name: "addIssue",
        components: {
            Issues
        },
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            client: {
                required: true,
                type: Object
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        data: () => ({
            activeView: 'assessment',
            isEverythingReady: true,
            modal_title : 'Add Initial Review',
            actionButtonTitle:'Save',
            issuesDialog: false,
            issues: '',
            severity: '',
            notes: '',
            allIssues: [],
            allSeverities: [],
            selectedIssues: [],
            selectedSeverity: '',
            optional_notes: '',
            disabled: true
        }),
        created() {
            if( this.client.initial_assessment ) {
                this.issues = this.client.initial_assessment.issues;
                this.severity = this.client.initial_assessment.severity;
                this.notes = this.client.initial_assessment.notes;
                this.selectedIssues = this.client.initial_assessment.issues.split(',');
                this.selectedSeverity = this.client.initial_assessment.severity;
                this.optional_notes = this.client.initial_assessment.notes;
            }
            this.getInitialAssessmentData();
        },
        methods:{
            hide() {
                this.$emit('hide')
            },
            getInitialAssessmentData() {
                let self = this;
                let loader = this.$loading.show();
                self.isEverythingReady = false;
                this.$http.get(this.url+'initial_assessment/data', {
                    params: {
                        client_id: self.client.id,
                    }
                }).then(function (response) {
                    loader.hide();
                    self.allIssues = response.data.issues;
                    self.allSeverities = response.data.severities;
                    self.isEverythingReady = true;
                }).catch(function (error) {
                    loader.hide();
                    self.isEverythingReady = true;
                    self.$toastr('error', error.response.data.message, 'Error');
                })
            },
            openIssuesDialog() {
                this.activeView = 'issues';
                this.issuesDialog = true;
            },
            closeIssuesDialog() {
                this.issuesDialog = false;
                this.activeView = 'assessment';
            },
            updateIssues(issues) {
                this.selectedIssues = issues;
                this.closeIssuesDialog();
            },
            addAssessment() {
                let self = this;
                let loader = self.$loading.show();
                this.$http.put(self.url+'initial_assessment', {
                    client_id: self.client.id,
                    issues: self.selectedIssues.join(','),
                    severity: self.selectedSeverity,
                    notes: self.optional_notes,
                    device_type:'web'
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                    self.$emit('addReview', response.data.client.initial_assessment);
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                });
            },
            updateStatus() {
                this.disabled = !(
                    (
                        this.selectedIssues.join(',') !== this.issues ||
                        this.selectedSeverity !== this.severity ||
                        this.optional_notes !== this.notes
                    ) &&
                    (
                        this.selectedIssues.length > 0 &&
                        this.selectedSeverity !== ''
                    )
                );
            }
        },
        watch: {
            'selectedIssues': function(value) {
                this.updateStatus();
            },
            'selectedSeverity': function(value) {
                this.updateStatus();
            },
            'optional_notes': function(value) {
                this.updateStatus();
            }
        }
    }
</script>
<style scoped>

</style>