<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>

                <div class="mt-4 scroll_auto issue-treatment-modal_height">
                    <div v-for="i in insuranceProviders" class="man-cell field">
                        <label>
                            <input type="checkbox" :checked="i.is_supported" :value="i.id" v-model="selectedInsurances">
                            <div class="man-circle">
                                <img class="circle-check" src="/img/whitecheckmark.png">
                            </div>
                        </label>
                        <span class="text">{{i.name}}</span>
                    </div>
                </div>

            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button class="btn modal-action-button" @click="addInsuranceProvider">{{actionButtonTitle}}</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "AddInsuranceProvider",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            insuranceProviders: {
                type: Array,
                required: true
            },
            supportedInsuranceProvider: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            modal_title : 'Add an Insurance Provider',
            actionButtonTitle:'Apply',
            selectedInsurances: []
        }),
        created(){
            this.selectedInsurances = this.supportedInsuranceProvider;
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide(){
                this.$emit('hide')
            },
            addInsuranceProvider(){
                this.$emit('addInsuranceProvider', this.selectedInsurances);
                this.$emit('hide');
            }
        }
    }
</script>
<style scoped>

</style>