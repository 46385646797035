<template>
  <v-dialog v-model="show" persistent max-width="750px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title class="pb-0 pt-3 pl-4">
        <h1 class="model-heading pl-3">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click="hide" alt="myonlinetherapy"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text class="pt-0">
        <v-container fluid class="pt-0">
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="organisationForm"
            >

              <div class="main-container">
                <!-- Plan title -->
                <div class="form-group w-100">
                  <label>
                    <span class="org-label form-label pt-0">Plan Title</span>
                    <input  class="input-text"
                           type="text"
                           v-model="plan.title"
                           placeholder="Enter plan title"
                    />
                  </label>
                </div>
                <!--plan type-->
                <div class="form-group w-100">
                  <label>
                    <span class="org-label ml-5 form-label pl-5 pt-0">Plan Type</span>
                    <select class="input-text ml-5"
                            v-model="plan.plan_type" @change="selectTherapyType($event)">
                      <option selected value="">Please Select Plan Type</option>
                      <option :value="planTypeSelfCare">Self Care</option>
                      <option :value="planTypeTherapy">Therapy</option>
                    </select>
                  </label>
                </div>


              </div>
              <div class="w-100" v-if="plan.plan_type === planTypeSelfCare">
              <hr class="gray-hr thicker-hr">

              <!-- subscription and discounts -->

              <div class="w-100 mx-5 mb-2">
                <div class="row">
                  <div class="col-md-10">
                    <div class="flex-display flex-row justify-space-between align-center w-160px">
                      <label class="align-center org-label light-label mb-0">
                        Subscription
                      </label>
                      <input type="checkbox" v-model="plan.self_care_config.subscription" class="checkbox-size-20" :true-value="true" :false-value="false"/>
                    </div>

                    <template v-if="plan.self_care_config.subscription">
                      <div v-if="plan.self_care_config.subscription">
                        <div class="d-flex mb-3">
                          <div class=" flex-fill">
                            <div class="toggle-switch">
                              <label class="org-label switch-label pr-3">Monthly discount &nbsp;</label>
                              <label class="switch">
                                <input type="checkbox" name="therapy" v-model="plan.self_care_config.enable_monthly_discount" :true-value="true"
                                       :false-value="false">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div class=" flex-fill">
                            <select v-model="plan.self_care_config.monthly_discount_percent" class="form-control"
                                    v-if="plan.self_care_config.enable_monthly_discount">
                              <option v-for="i in 10" :value="i*5" :key="i*5">{{ i*5 }}%</option>
                            </select>
                          </div>
                        </div>

                        <div class="d-flex mb-3">
                          <div class=" flex-fill">
                            <div class="toggle-switch">
                              <label class="org-label switch-label pr-4">Annual discount &nbsp;</label>
                              <label class="switch">
                                <input type="checkbox" name="therapy" v-model="plan.self_care_config.enable_yearly_discount" :true-value="true"
                                       :false-value="false">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div class=" flex-fill">
                            <select v-model="plan.self_care_config.yearly_discount_percent" class="form-control"
                                    v-if="plan.self_care_config.enable_yearly_discount">
                              <option v-for="i in 10" :value="i*5" :key="i*5">{{ i*5 }}%</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </template>

                  </div>
                </div>
              </div>
             </div>
              <!-- Therapy -->
              <div class="w-100" v-if="plan.plan_type === planTypeTherapy">
              <hr class="gray-hr thicker-hr">
              <div class="grid">
                <div class="row pl-5 mt-5" v-if="plan.plan_type === planTypeTherapy">
                  <div class="w-100">
                    <div class="flex-row flex-display justify-start align-center">
                      <label class="light-label org-label form-label pr-2"># of sessions: </label>
                      <input v-model="plan.allowed_sessions"
                             class="input-text w-100px"
                             placeholder="Number">
                      <div class="toggle-switch ml-4">
                        <label class="light-label org-label switch-label pr-3">Undefined &nbsp;</label>
                        <label class="switch">
                          <input type="checkbox" name="undefined" v-model="plan.undefined_sessions" :true-value="true"
                                 :false-value="false">
                          <span class="slider round"></span>
                        </label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <!-- Session types -->
              <hr class="gray-hr" v-if="plan.plan_type === planTypeTherapy">

              <div class="main-container w-100" v-if="plan.plan_type === planTypeTherapy">
                <div class=""></div>
                <div class="w-100">
                  <label class="light-label org-label form-label">Initial session types:</label>
                  <template v-for="sessionType in initialSessionTypes">
                    <div class="flex-row flex-display align-center my-3 pl-3">
                      <div class="flex-display flex-row justify-space-between align-center w-215px">
                        <label class="flex-row flex-display align-center org-label light-label mb-0">
                          {{ sessionType.formatted_name }}
                        </label>
                        <input type="checkbox" class="checkbox-size-20" v-model="sessionType.active" true-value="true"
                               false-value="false"/>
                      </div>
                      <div class="flex-row flex-display justify-start align-center ml-5">
                        <label class="light-label org-label form-label pr-2">Price: </label>
                        <input v-model="sessionType.price"
                               class="input-text w-100px"
                               placeholder="Number">
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="main-container w-100 mt-3" v-if="plan.plan_type === planTypeTherapy">
                <div class=""></div>
                <div class="w-100">
                  <label class="light-label org-label form-label">Ongoing session types:</label>
                  <template v-for="sessionType in ongoingSessionTypes">
                    <div class="flex-row flex-display align-center my-3 pl-3">
                      <div class="flex-display flex-row justify-space-between align-center w-215px">
                        <label class="flex-row flex-display align-center org-label light-label mb-0">
                          {{ sessionType.formatted_name }}
                        </label>
                        <input type="checkbox" class="checkbox-size-20" v-model="sessionType.active" true-value="true"
                               false-value="false"/>
                      </div>
                      <div class="flex-row flex-display justify-start align-center ml-5">
                        <label class="light-label org-label form-label pr-2">Price: </label>
                        <input v-model="sessionType.price"
                               class="input-text w-100px"
                               placeholder="Number">
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <hr class="gray-hr" >

              <div class="main-container my-3">
                <div class="main-container__first">
                  <div class="toggle-switch">
                    <label class="org-label switch-label">Diagnosis</label>
                    <label class="switch">
                      <input type="checkbox" name="is_diagnosis" v-model="plan.is_diagnosis" :true-value="true"
                             :false-value="false">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="main-container" v-if="plan.is_diagnosis">
                <div class="row pl-5">
                  <div class="col-xs-6">
                    <label class="org-label switch-label pr-3 ml-5">Type:</label>
                  </div>
                  <div class="col-xs-6" >
                    <select class="input-select" :class="{ 'error-border':  showDiagnosisError }" name="icd_10_code" v-model="plan.type_of_diagnosis"
                             >
                      <option value="" disabled selected>Type of Diagnosis</option>
                      <option value="ICD-10">ICD-10</option>
                    </select>
                  </div>
                </div>
              </div>

              <hr class="gray-hr">


              <div class="grid w-100">
                <label class="pr-3 pt-3">Payment</label>
                <div class="row mr-3">
                  <div class="col-md-12 mb-2">
                    <div class="toggle-switch ml-4">
                      <label class="org-label switch-label pr-3">Therapy paid by org</label>
                      <label class="switch">
                        <input type="checkbox" v-model="plan.paid_by_org" :true-value="true" :false-value="false">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" v-if="!plan.paid_by_org">
                    <div class="toggle-switch ml-4">
                      <label class="org-label switch-label pr-3">Prepay</label>
                      <label class="switch">
                        <input type="checkbox" v-model="plan.prepay" :true-value="true" :false-value="false">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <!--parent div to hide toggles if therapy paid by org is not enabled-->
                  <div v-if="plan.paid_by_org" class="w-100">
                    <div class="col-md-12 mb-2">
                      <div class="toggle-switch ml-4">
                        <label class="org-label switch-label pr-3">Requires user card details</label>
                        <label class="switch">
                          <input type="checkbox" v-model="plan.card_details_required" :true-value="true"
                                 :false-value="false">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <!--Toggle for additional session request-->
                    <div class="col-md-12 mb-2">
                      <div class="toggle-switch ml-4">
                        <label class="org-label switch-label pr-3">Enable additional sessions request </label>
                        <label class="switch">
                          <input type="checkbox" v-model="plan.enable_additional_sessions_request" :true-value="true"
                                 :false-value="false">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div class="row pl-5">
                      <div class="w-100 pl-5" v-if="plan.enable_additional_sessions_request">
                        <div class="flex-row flex-display justify-start align-center">
                          <label class="light-label org-label form-label pr-2">Session completion trigger: </label>
                          <input v-model="plan.additional_session_request_trigger"
                                 class="input-text w-100px"
                                 :class="{'error-border' : isEmpty(plan.additional_session_request_trigger)}"
                                 placeholder="Number">
                        </div>
                      </div>
                    </div>

                    <div class="d-flex row w-100 border-element ml-4 m-r-0 my-3">
                      <div class="col-md-6">
                        <div class="toggle-switch">
                          <label class="org-label switch-label pr-3">Additional service charge</label>
                          <label class="switch">
                            <input type="checkbox" v-model="plan.enable_service_charges" :true-value="true" :false-value="false" :disabled="actionType !== 'add'">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-6 pr-4 pb-3" v-if="plan.enable_service_charges">
                        <template v-for="(charge, index) in plan.serviceCharges">
                          <service-charge :charge="charge" :show-delete-icon="plan.serviceCharges.length > 1 && actionType === 'add'" :should-disable="actionType !== 'add'" :show-service-charges-errors="showServiceChargesErrors" :index="index" @delete-service-charge="removeServiceCharge"></service-charge>
                        </template>
                        <a @click="createNewServiceCharge()" class="btn-edit width-85 height-32 border-radius-8 mt-3 ml-2" v-if="this.actionType === 'add'">Add more</a>
                      </div>
                    </div>


                    <div class="col-md-12 mb-2">
                      <div class="toggle-switch ml-4">
                        <label class="org-label switch-label pr-3">Insurer</label>
                        <label class="switch">
                          <input type="checkbox" v-model="plan.add_insurance" :true-value="true" :false-value="false">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pl-5 mb-2">
                  <div class="w-100">
                    <div class="toggle-switch ml-5" v-if="plan.add_insurance">
                      <label class="org-label switch-label pr-3">Auth number and auth sessions defined by user &nbsp;</label>
                      <label class="switch">
                        <input type="checkbox" v-model="plan.user_defined_auth_config" :true-value="true"
                               :false-value="false">
                        <span class="slider round"></span>
                      </label>
                      <div class = "row pl-3 pb-2">
                        <label class="org-label switch-label pr-3 ml-5">Cover title</label>
                        <input  class="input-text col-md-6" :class="{ 'error-border': showCoverTitleError }"
                               type="text"
                               v-model="plan.cover_title"
                               placeholder="Core Policy"
                        />
                      </div>
                      <div class = "row pl-3">
                        <label class="org-label switch-label pr-3">Cover description</label>
                        <input class="input-text col-md-8" :class="{ 'error-border': showCoverDescriptionError }"
                               type="text"
                               v-model="plan.cover_description"
                               placeholder="Member entitled to…"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Add GAIA Toggle -->
              <div class="grid">
                <div class="row">
                  <div class="col-md-12">
                    <div class="toggle-switch">
                      <label class="org-label switch-label pr-3">GAIA</label>
                      <label class="switch">
                        <input type="checkbox" v-model="plan.is_gaia" :true-value="true" :false-value="false">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!--parent div to hide toggles if GAIA is not enabled-->
                <div v-if="plan.is_gaia">
                  <div class="main-container w-100 mt-3">
                    <div class="w-100">
                      <div class="flex-row flex-display align-center pl-3">
                        <div class="flex-display flex-row justify-space-between align-center w-160px">
                          <label class="flex-row flex-display align-center org-label switch-label mb-0">
                            Deprexis
                          </label>
                          <label class="switch">
                            <input type="checkbox" v-model="plan.gaia_configuration.deprexis" :true-value="true"
                                   :false-value="false">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.deprexis">
                          <select v-model="plan.gaia_configuration.deprexis_plan_id" class="form-control width-299 height-40" id="deprexisPlan">
                            <option value="" disabled selected>Select Plan</option>
                            <option v-for="plan in plansList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-container w-100 mt-3">
                    <div class="w-100">
                      <div class="flex-row flex-display align-center pl-3">
                        <div class="flex-display flex-row justify-space-between align-center w-160px">
                          <label class="flex-row flex-display align-center org-label switch-label mb-0">
                            Velibra
                          </label>
                          <label class="switch">
                            <input type="checkbox" v-model="plan.gaia_configuration.velibra" :true-value="true"
                                   :false-value="false">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.velibra">
                          <select v-model="plan.gaia_configuration.velibra_plan_id" class="form-control width-299 height-40" id="valibraPlan">
                            <option value="" disabled selected>Select Plan</option>
                            <option v-for="plan in plansList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-container w-100 mt-3">
                    <div class="w-100">
                      <div class="flex-row flex-display align-center pl-3">
                        <div class="flex-display flex-row justify-space-between align-center w-160px">
                          <label class="flex-row flex-display align-center org-label switch-label mb-0">
                            Vorvida
                          </label>
                          <label class="switch">
                            <input type="checkbox" v-model="plan.gaia_configuration.vorvida" :true-value="true"
                                   :false-value="false">
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div class="flex-row flex-display justify-start align-center ml-5" v-if="plan.gaia_configuration.vorvida">
                          <select v-model="plan.gaia_configuration.vorvida_plan_id" class="form-control width-299 height-40" id="vorvidaPlan">
                            <option value="" disabled selected>Select Plan</option>
                            <option v-for="plan in plansList" :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


                <hr class="gray-hr">

                <div class="row">
                    <div class="col-md-12">
                        <div class="toggle-switch">
                            <label class="org-label switch-label pr-3">Outcome measures</label>
                            <label class="switch">
                                <input type="checkbox" v-model="plan.enable_outcome_measures" :true-value="true" :false-value="false">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <hr class="gray-hr">

                <div class="row w-100 mb-2">
                <div class="col-md-12">
                  <div class="toggle-switch">
                    <label class="org-label switch-label pr-3">NPS </label>
                    <label class="switch">
                      <input type="checkbox" v-model="plan.enable_nps" :true-value="true" :false-value="false">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div class="row pl-5" v-if="plan.enable_nps">
                    <div class="w-100">
                        <div class="flex-row flex-display justify-start align-center">
                            <label class="light-label org-label form-label pr-2">Triggered after </label>
                            <input v-model="plan.nps_trigger_number"
                                   class="input-text w-100px"
                                   :class="{'error-border' : isEmpty(plan.nps_trigger_number)}"
                                   placeholder="Number">
                            <label class="light-label org-label form-label pl-2">completed sessions</label>
                        </div>
                    </div>
                </div>
              </div>

              <hr class="gray-hr">

              <div class="row">
                <div class="col-md-12">
                  <div class="toggle-switch">
                    <label class="org-label switch-label pr-3">Bulk Invoicing</label>
                    <label class="switch">
                      <input type="checkbox" v-model="plan.enable_bulk_invoicing" :true-value="true" :false-value="false" :disabled="actionType !== 'add'">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <hr class="gray-hr">
              </div>
            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <div class="w-100" v-if="plan.plan_type === planTypeTherapy || plan.plan_type === planTypeSelfCare">
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="addPlan">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import regexHelper from "@/helpers/regexHelper";
import numberHelper from "@/helpers/numberHelper";
import MultiSelect from "vue-multiselect";
import ServiceCharge from "@/components/main/orgConsole/ServiceCharge";
import routeHelper from "@/helpers/app/routeHelper";
import planHelper from '../../../helpers/app/partner/planHelper'

export default {
  name: "addPlan",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: String,
      default: "add",
    },
    value: null,
    session_types_initial: {
      required: true,
      type: Array
    },
    session_types_ongoing: {
      required: true,
      type: Array
    }
  },
  components: {
    MultiSelect,
    ServiceCharge
  },
  data: () => ({
    icd10Codes: [],
    isEverythingReady: true,
    modalTitle: "Add a Plan",
    actionButtonTitle: "Add a plan",
    plan: null,
    blankPlan: {
      title: "",
      plan_type: "",
      self_care_config: {
        enable: false,
        subscription: false,
        enable_monthly_discount: false,
        enable_yearly_discount: false,
        monthly_discount_percent: 0,
        yearly_discount_percent: 0
      },

      is_diagnosis: false,
      allowed_sessions: "",
      undefined_sessions: false,
      user_defined_auth_config: false,
      paid_by_user: true,
      paid_by_org: false,
      add_insurance: false,
      enable_additional_sessions_request: false,
      additional_session_request_trigger: null,
      enable_outcome_measures: false,
      enable_service_charges: false,
      enable_bulk_invoicing: false,
      enable_nps: false,
      nps_trigger_number: null,
      card_details_required: false,
      type_of_diagnosis: '',
      cover_title: '',
      cover_description: '',
      is_gaia: false,
      gaia_configuration: {
        deprexis: false,
        deprexis_plan_id: '',
        vorvida: false,
        vorvida_plan_id: '',
        velibra: false,
        velibra_plan_id: '',
      },
      serviceCharges: [],
      disable: false,
      initial_session_types: [],
      ongoing_session_types: [],
    },
    organisations: [],
    loadingMultiSelects: false,
    selfCareActive: false,
    duration_number: null,
    clinical_numbers_options: [],
    outcome_numbers_options: [],
    initialSessionTypes: [],
    ongoingSessionTypes: [],
    subscriptionProducts: [],
    selectedDiscountedProduct: null,
    showDiagnosisErrors: false,
    showInsuranceCoverErrors: false,
    plansList:[],
    showServiceChargesErrors: false,
    planTypeTherapy: planHelper.typeTherapy,
    planTypeSelfCare: planHelper.typeSelfCare
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    showDiagnosisError() {
      return this.plan.is_diagnosis && this.isEmpty(this.plan.type_of_diagnosis) && this.showDiagnosisErrors;
    },
    showCoverTitleError() {
      return this.plan.add_insurance && this.isEmpty(this.plan.cover_title) && this.showInsuranceCoverErrors;
    },
    showCoverDescriptionError() {
      return this.plan.add_insurance && this.isEmpty(this.plan.cover_description) && this.showInsuranceCoverErrors;
    }
  },
  created() {

    // get plans for Gaia drop down
    this.getGaiaPlansList();

    this.initialSessionTypes = [
      ...this.session_types_initial
    ]
    this.ongoingSessionTypes = [
      ...this.session_types_ongoing
    ]

    this.plan = {
      ...this.blankPlan
    }
    if (this.actionType === "add") {
      this.modalTitle = "Add a Plan";
      if (this.plan.serviceCharges.length < 1) {
        this.createNewServiceCharge();
      }
      this.actionButtonTitle = "Add a plan";
    } else {
      this.modalTitle = "Edit a plan";
      this.actionButtonTitle = "Save";
    }

    if (this.value) {
      this.getPlanDetails(this.value.id);
    }

  },
  methods: {

    getGaiaPlansList() {
      this.$http.get(this.url + "gaia/plans").then(response => {
        this.plansList = response.data;
      }).catch(error => {
        // nothing requires here
      });
    },

    isEmpty(value) {
        return numberHelper.isEmpty(value);
    },

    hide() {
      this.$emit("hide");
    },

    updateNumberOfSessions() {
      this.clinical_numbers_options = [];
      this.outcome_numbers_options = [];
      this.loadingMultiSelects = true;
      if (this.plan.allowed_sessions > 0) {
        for (let i = 1; i <= this.plan.allowed_sessions; i++) {
          this.clinical_numbers_options.push({"id": i, "title": i});
          this.outcome_numbers_options.push({"id": i, "title": i});
        }
      }
      this.loadingMultiSelects = false;
    },
    getPlanDetails(plan_id) {
      let loader = this.$loading.show();
      this.$http.get(this.url + "plan?plan_id=" + plan_id).then(response => {
        this.plan = response.data.plan;
        if (this.plan.serviceCharges.length < 1) {
          this.createNewServiceCharge();
        }
        this.parsePlanSessionTypes();
        this.updateNumberOfSessions();
        loader.hide();
      }).catch(error => {
        loader.hide();
      })
    },
    addPlan() {
      if (this.validateSubmitRequest()) {
        let loader = this.$loading.show();
        this.$http.put(this.url + "plan", {
          ...this.plan,
          plan_type: this.plan.plan_type,
          undefined_sessions: this.plan.undefined_sessions ? "true" : "false",
          user_defined_auth_config: this.plan.user_defined_auth_config ? "true" : "false",
          paid_by_user: this.plan.paid_by_user ? "true" : "false",
          paid_by_org: this.plan.paid_by_org ? "true" : "false",
          prepay: this.plan.prepay ? "true" : "false",
          add_insurance: this.plan.add_insurance ? "true" : "false",
          is_gaia: this.plan.is_gaia ? "true" : "false",
          gaia_configuration: {
            ...this.plan.gaia_configuration,
            deprexis: this.plan.gaia_configuration.deprexis ? "true" : "false",
            deprexis_plan_id: this.plan.gaia_configuration.deprexis_plan_id,
            vorvida: this.plan.gaia_configuration.vorvida ? "true" : "false",
            vorvida_plan_id: this.plan.gaia_configuration.vorvida_plan_id,
            velibra: this.plan.gaia_configuration.velibra ? "true" : "false",
            velibra_plan_id: this.plan.gaia_configuration.velibra_plan_id,
          },
          enable_additional_sessions_request: this.plan.enable_additional_sessions_request ? "true" : "false",
          enable_outcome_measures: this.plan.enable_outcome_measures ? "true" : "false",
          enable_bulk_invoicing: this.plan.enable_bulk_invoicing ? "true" : "false",
          enable_nps: this.plan.enable_nps ? "true" : "false",
          card_details_required: this.plan.card_details_required ? "true" : "false",
          disable: this.plan.disable ? "true" : "false",
          is_diagnosis: this.plan.is_diagnosis ? "true" : "false",
          type_of_diagnosis: this.plan.type_of_diagnosis ? this.plan.type_of_diagnosis : '',
          cover_title: this.plan.cover_title,
          cover_description: this.plan.cover_description,
          initial_session_types: this.getSelectedSessionTypes(this.initialSessionTypes),
          ongoing_session_types: this.getSelectedSessionTypes(this.ongoingSessionTypes),
          enable_service_charges: this.plan.enable_service_charges ? "true" : "false",
        }).then(response => {
          loader.hide();
          this.$emit("submit");
          this.$toastr("success", response.data.message, "");
        }).catch(error => {
          loader.hide();
          this.$toastr("error", error.response.data.message, "");
        });
      }
    },
    validateSubmitRequest() {
      if (this.plan.is_diagnosis) {
        this.showDiagnosisErrors = true;
      }
      if (this.plan.add_insurance) {
        this.showInsuranceCoverErrors = true;
      }
      if (this.plan.enable_service_charges) {
        this.showServiceChargesErrors = true;
      }

      if (this.plan.title === "") {
        this.showAlert("Please enter title.");
        return false;
      }
      if (this.plan.self_care_config.enable_yearly_discount && this.plan.self_care_config.yearly_discount_percent == 0 ) {
        this.showAlert("Annual discount percent must be greater than zero");
        return false;
      }
      if (this.plan.self_care_config.enable_monthly_discount && this.plan.self_care_config.monthly_discount_percent == 0 ) {
        this.showAlert("Monthly discount percent must be greater than zero");
        return false;
      }
      if( this.plan.self_care_config.enable_monthly_discount === false  ){
        this.plan.self_care_config.monthly_discount_percent = 0;
      }
      if( this.plan.self_care_config.enable_yearly_discount === false  ){
        this.plan.self_care_config.yearly_discount_percent = 0;
      }
      if (this.validateCoverFields()){
        this.showAlert("Please enter cover title.");
        this.showAlert("Please enter cover description.");
        return false;
      }
      if (this.plan.add_insurance && this.isEmpty(this.plan.cover_title)){
          this.showAlert("Please enter cover title.");
          return false;
      }
      if (this.plan.add_insurance && this.isEmpty(this.plan.cover_description)){
        this.showAlert("Please enter cover description.");
        return false;
      }
      if (this.plan.is_diagnosis && this.isEmpty(this.plan.type_of_diagnosis)){
        this.showAlert("Please Choose Type of diagnosis.");
        return false;
      }
      if (this.validateServiceChargesFields()){
        this.showAlert("Please fill all the service charges.");
        return false;
      }
      if (this.plan.enable_nps && this.isEmpty(this.plan.nps_trigger_number)) {
          this.showAlert("Please enter valid NPS trigger which should be greater than 0");
          return false;
      }
      if (this.plan.enable_additional_sessions_request && this.isEmpty(this.plan.additional_session_request_trigger)) {
        this.showAlert("Please enter valid session completion trigger which should be greater than 0");
        return false;
      }
      if (this.plan.is_gaia && this.plan.gaia_configuration.deprexis && this.isEmpty(this.plan.gaia_configuration.deprexisPlanId)) {
        this.showAlert("Please Select Plan for Deprexis");
        return false;
      }
      if (this.plan.is_gaia && this.plan.gaia_configuration.vorvida && this.isEmpty(this.plan.gaia_configuration.vorvidaPlanId)) {
        this.showAlert("Please Select Plan for Vorvida");
        return false;
      }
      if (this.plan.is_gaia && this.plan.gaia_configuration.velibra && this.isEmpty(this.plan.gaia_configuration.velibraPlanId)) {
        this.showAlert("Please Select Plan for Velibra");
        return false;
      }

      return true;
      },
    showAlert(msg) {
      this.$toastr("error", msg);
    },
    pluckIds(data) {
      let _ids = [];
      for (let i = 0; i < data.length; i++) {
        _ids.push(data[i].id);
      }
      return _ids.toString();
    },
    formatOptions(values) {
      let options = [];
      for (let i = 1; i <= this.plan.allowed_sessions; i++) {
        if (values.includes(i.toString())) {
          options.push({"id": i, "title": i});
        }
      }
      return options;
    },
    getSelectedSessionTypes(sessionTypes) {
      return sessionTypes.filter(sessionType => sessionType.active == "true");
    },
    parsePlanSessionTypes() {
      const self = this;
      $.each(self.plan.session_types, function (key, sessionType) {
        if (sessionType.session_type === "initial") {
          let index = self.initialSessionTypes.findIndex(x => x.session_type_id == sessionType.session_type_id);
          self.initialSessionTypes[index].active = "true";
          self.initialSessionTypes[index].price = sessionType.price;
        } else if (sessionType.session_type === "ongoing") {
          let index = self.ongoingSessionTypes.findIndex(x => x.session_type_id == sessionType.session_type_id);
          self.ongoingSessionTypes[index].active = "true";
          self.ongoingSessionTypes[index].price = sessionType.price;
        }
      });
    },

    validateCoverFields () {
      return this.plan.add_insurance && this.isEmpty(this.plan.cover_description) && this.isEmpty(this.plan.cover_title) ;
    },

    validateServiceChargesFields() {
      let isFieldEmpty = false;
      this.plan.serviceCharges.forEach((charge) => {
        if (
            (
              this.isEmpty(charge.price) ||
              this.isEmpty(charge.name)
            ) &&
            !isFieldEmpty &&
            this.plan.enable_service_charges &&
            this.plan.paid_by_org
        ) {
          isFieldEmpty = true;
        }
      });
      return isFieldEmpty;
    },
    createNewServiceCharge() {
      this.plan.serviceCharges.push({
        name: '',
        price: '',
        plan_id: this.plan.id
      });
    },
    removeServiceCharge(index) {
      this.plan.serviceCharges.splice(index, 1);
    },
    selectTherapyType(e) {
      this.plan.plan_type = e.target.value;
      if(this.plan.plan_type === this.planTypeTherapy) {
        this.plan.self_care_config.enable = false;
      }
      if(this.plan.plan_type === this.planTypeSelfCare) {
        this.plan.self_care_config.enable = true;
      }
    },
  },
  watch: {

    "plan.allowed_sessions": function (value) {
      this.plan.allowed_sessions = regexHelper.filterNumericOnly(value.toString());
      this.updateNumberOfSessions();
    },
    "plan.nps_trigger_number": function (value) {
        if (value) {
            this.plan.nps_trigger_number = regexHelper.filterNumericOnly(value.toString());
        }
    },

    "plan.paid_by_user": function (value) {
      if (value === true) {
        this.plan.paid_by_org = false;
      }
    },
    "plan.add_insurance": function (value) {
      if (value === false) {
      }
    },
    "plan.is_diagnosis": function(value){
      if (value === false){
        this.plan.type_of_diagnosis = '';
      }
    },
    "plan.paid_by_org": function (value) {
      if (value === true) {
        this.plan.paid_by_user = false;
      }
      if (value === false) {
        this.plan.paid_by_user = true;
        this.plan.add_insurance = false;
        this.plan.card_details_required = true;
        this.plan.enable_additional_sessions_request = false;
        this.plan.user_defined_auth_config = false;
        this.plan.enable_service_charges = false;
      }
    },
    "plan.additional_session_request_trigger": function (value) {
      if (value) {
        this.plan.additional_session_request_trigger = regexHelper.filterNumericOnly(value.toString());
      }
    },
    "plan.enable_additional_sessions_request": function (value) {
      if (this.plan.enable_bulk_invoicing) {
        this.$nextTick(() => this.plan.enable_additional_sessions_request = false);
      }
    },
    "plan.enable_bulk_invoicing": function (value) {
      if (this.plan.enable_additional_sessions_request) {
        this.$nextTick(() => this.plan.enable_bulk_invoicing = false);
      }
    },

  }
}

</script>
<style scoped>
select {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}
.border-radius-8 {
  border-radius: 8px !important;
}
</style>
