<template>
  <div class="bookingFlow__BoxWidth bookingFlow__w-80 ma-auto">
    <div class="bookingFlow__boxShadow flex-column  justify-start bg-white bookingFlow__padding pb-4">
      <client-info-header
          :client="client"
      ></client-info-header>
      <divider></divider>
      <h1 class="m-b-12 fs-16 bold-font grey-900 pb-3">{{pageTitle}}</h1>
      <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium">Session</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{sessionType.formatted_title}}</p>
      </div>

      <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium">Date</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{formattedDate}}</p>
      </div>

      <div v-if="isNotMessaging" class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium">Time</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ selectedSlot.formatted_start_time }} -
          {{ selectedSlot.formatted_end_time }}</p>
      </div>

      <div class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium">Payment method</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{ paymentMethod }}</p>
      </div>
      <div v-if="planType !== planTypePackage" class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium">Price</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">£{{ sessionType.discounted_price }} <span
            v-if="planType === planTypeSelfPayDiscounted" class="bookingFlow__lineThrough">(£{{
            sessionType.price
          }})</span></p>
      </div>
      <div class="bookingFlow__flex flex-row justify-space-between align-center w-100" :class="totalSessionsCheck ? '' : 'm-b-12'">
        <p class="mb-0 fs-14 grey-800 font-medium">Payment plan</p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{paymentPlan}}</p>
      </div>
      <div v-if="totalSessionsCheck" class="bookingFlow__flex flex-row justify-space-between align-center w-100 m-b-12">
        <p class="mb-0 fs-14 grey-800 font-medium"></p>
        <p class="mb-0 fs-14 grey-800 font-semi-bold">{{totalSessions}}</p>
      </div>

      <alert-box v-if="type === 'review'" :type="'info'" :msg-text="infoBoxText"></alert-box>
    </div>
    <div class="ma-auto m-t-60 text-center" v-if="type === 'review'">
      <button
          class="d-inline-block white--text bg-blue-500 width-452 height-44 bold-font fs-16"
          @click="bookSession">
        Book
      </button>
    </div>
    <div class="ma-auto m-t-80 text-center" v-if="type === 'success'">
    <button
          class="d-inline-block white--text bg-blue-500 width-452 height-44 bold-font fs-16"
          @click="redirect">
        Ok
      </button>
    </div>
  </div>
</template>

<script>
import ClientInfoHeader from "../components/clientInfoHeader";
import Divider from "../components/divider";
import AlertBox from "../components/alertBox";
import TherapyHelper from "@/helpers/app/therapyHelper";

export default {
  name: "reviewSessionDetail",
  props: {
    sessionType: {
      required: true,
    },
    planType: {
      required: true,
    },
    selectedSlot: {
      type: Object,
      required: true,
    },
    planDetails:{
      type: Object,
      required: true
    },
    therapistId: {
      required: true
    },
    action: {
      required: true
    },
  },
  components: {
    AlertBox,
    Divider,
    ClientInfoHeader
  },
  data() {
    return {
      type: "review",
      paymentMethod: "",
      paymentPlan: "",
      totalSessions: "",
      totalSessionsCheck: false,
      client: {},
      plan: {},
      pageTitle: "Review session details",
      infoBoxText: "Please ensure you have the client’s permission to book this session " + `<strong>before</strong>` + " the booking is made.",
      planTypePackage:TherapyHelper.planTypePackage,
      planTypeSelfPayDiscounted:TherapyHelper.planTypeSelfPayDiscounted,
      sessionTypeMethodCoaching: TherapyHelper.sessionTypeMethodCoaching,
      therapistSlotId: null,
      isNotMessaging: true,
      formattedDate: "",

    }
  },
  created() {
    this.client = this.planDetails.client;
    this.plan = this.planDetails.plan;
    if (this.sessionType.method === this.sessionTypeMethodCoaching) {
      this.therapistSlotId = null;
      this.isNotMessaging = false;
    } else {
      this.therapistSlotId = this.selectedSlot.slot_id;
    }
    this.setBookingDetails();

  },
  methods: {
    bookSession() {
      let loader = this.$loading.show();
      let formData = {
        user_id: this.therapistId,
        therapist_id: this.therapistId,
        therapist_slot_id: this.therapistSlotId,
        client_id: this.client.id,
        session_type_id: this.sessionType.id,
        action: this.action,
        device_type: this.deviceType,
        timezone: "europe/london"
      };
      this.$http.put(this.url + 'therapy/session/booking', formData).then(response => {
        loader.hide();
        this.type = 'success';
        this.pageTitle = 'Session details';
        this.$emit('sessionConfirmed');
        this.$toastr("success", response.data.message);

      }).catch(error => {
        loader.hide();
          this.$toastr("error", error.response.data.message, "");
      });
    },
    redirect(){
      window.location=document.referrer;
    },
    setBookingDetails() {
      this.paymentMethod = this.planDetails.payment_method;
      this.paymentPlan = this.plan.plan_title;
      if (this.planType !== TherapyHelper.planTypeSelfPay) {
        this.totalSessionsCheck = true;
        this.totalSessions = "(Session " + this.plan.booked_sessions + " of " + (this.plan.authorized_sessions ? this.plan.authorized_sessions : 'NA') + ")";
      }
      if (this.isNotMessaging) {
        this.formattedDate = this.selectedSlot.formatted_date
      } else {
        this.formattedDate = this.sessionType.formatted_date
      }

    }
  }
}
</script>