<template>

    <div class="mb-4">
        <p class="error">{{error}}</p>
        <input type="file" name="file" id="image_upload" accept="image/png,image/jpeg" class="d-none" @change="onFileChanged"/>
        <v-menu open-on-hover bottom offset-y>
            <div class="image-container" slot="activator">

                <img v-if="profile_picture === ''" class="image" src="https://assets.care.myonlinetherapy.com/images/default_user_profile.png" alt="myonline therapy">
                <v-lazy-image
                        v-else
                        class="image"
                        :src="profile_picture"
                        src-placeholder="https://assets.care.myonlinetherapy.com/images/default_user_profile.png"
                />
                <div v-if="userType === 'therapist'" class="middle">
                    <div class="text">
                        <img class="camera_icon" src="/assets/images/photo_ic.png" alt="MyOnlineTherapy"/>
                    </div>
                </div>
            </div>
            <v-list v-if="userType === 'therapist'" class="upload_image">
                <v-list-tile @click="takeImage">
                    <v-list-tile-title>Upload Photo</v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="profile_picture !== '' || profile_picture === default_image" @click="deleteImage">
                    <v-list-tile-title >Delete Photo</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>




</template>

<script>
    export default {
        name: "profilePicture",
        props: {
          value: {},
          user_id: {},
          userType: {
            type: String,
            default: 'therapist'
          }
        },
        data(){
            return{
                profile_picture: this.value,
                default_image: 'https://assets.care.myonlinetherapy.com/images/default_user_profile.png', //this should be an env
                items: [
                    { title: 'Change Photo' },
                    { title: 'Delete Photo' }
                ],
                error: '',
                selectedFile: null
            }
        },
        methods:{
            takeImage() {
                $('#image_upload').trigger('click');
            },
            onFileChanged(event){
                let file = event.target.files[0];
                if (file.type === "image/jpeg" || file.type === "image/png") {
                    let loader = this.$loading.show();
                    const formData = new FormData();
                    formData.append("therapist_id" , this.user_id);
                    formData.append('profile_picture', event.target.files[0]);
                    this.$http.post(this.url+ "therapist/profile-picture/update", formData).then((response) => {
                        loader.hide();
                        this.error= "";
                        $('#image_upload').val("");
                        this.selectedFile = null;
                        this.profile_picture = response.data.therapist.profile_picture;
                        this.$emit('updateProfileImage');
                        this.$toastr("success",response.data.message, "");
                    }).catch((error) => {
                        loader.hide();
                        this.$toastr("error", error.response.data.message, "");
                    });
                } else {
                    this.$toastr("error", "Only jpeg,png images are allowed", "");
                }

            },

            deleteImage(){
                let loader = this.$loading.show();
                this.$http.delete(this.url+'therapist/delete-picture', {
                    params: {
                        therapist_id: this.user_id,
                    }
                }).then((response) => {
                    loader.hide();
                    this.error= '';
                    this.$emit('updateProfileImage');
                    this.profile_picture = this.default_image;
                    this.$toastr('success',response.data.message,'');
                }).catch((error) => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message,'');
                });
            },
        },
        watch:{
            profile_picture(){
                $('.nav_profile_image').attr('src', this.profile_picture)
            }
        }
    }
</script>

