<template>
  <div class="single-select-filter">
    <div class="dropdown" :id="id">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
        {{ !empty(selected) ? selected.name : allItemsLabel }} <img src="/icons/dropdown_ic.svg">
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item"
           v-if="showAllOption"
           :class="{selected: empty(selected)}"
           :key="'all'"
           @click="clearSelection">
          {{ allItemsLabel }}
        </a>
        <a class="dropdown-item"
           :class="{'selected': !empty(selected) && selected.id === item.id}"
           v-for="(item,index) in items"
           :key="index"
           @click="selectItem(item)">{{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import stringHelper from '../../helpers/stringHelper';

export default {
  name: 'SingleSelectFilter',
  props: {
    id: {
      type: String,
      required: true
    },
    allItemsLabel: {
      type: String,
      default: 'All'
    },
    items: {
      type: Array,
      required: true,
      default: []
    },
    selected: {
      type: Object,
    },
    // If you don't want to show the default hide option
    showAllOption: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      selectedItems: this.selected
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('change', item);
    },
    clearSelection() {
      this.$emit('clear');
    },
    empty(string) {
      return stringHelper.empty(string);
    }
  }
}
</script>

<style scoped>
.dropdown-toggle::after {
  display: none;
}
</style>
