export default {
    // plan types
    typeTherapy: "therapy",
    typeSelfCare: "self-care",

    // additional session request statuses
    requestPending: "Pending submission",
    requestSubmitted: "Request submitted",
    requestPartiallyApproved: "Partially approved",
    requestApproved: "Approved",
    requestRejected: "Rejected"
}
