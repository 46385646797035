<template>
    <div v-if="isEverythingReady" class="tab_content clinical-assessment-main m-t-20 pa-3">
        <template v-if="clientOnlineAssessment.length > 0">
            <template v-for="(data, index) in clientOnlineAssessment">
                <div class="flex-display flex-row m-b-20 w-100" :class="{'question-assessment' : data.question_id === '8'}">
                    <div class="width-143 m-r-15">
                        <p class="width-143 fs-14 lh-20 text-gray mb-0 font-medium">{{ data.question_title }}</p>
                    </div>
                    <p class="fs-14 lh-16 black--text mb-0 break-all font-medium">
                        {{ data.answer }}
                    </p>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="w-100 review-height flex-display flex-row justify-center align-center">
                <clinical-assessment-banner :type="'error'"
                                            :show-banner="onlineAssessmentBanner.show_banner"
                                            :banner-text="onlineAssessmentBanner.text">
                </clinical-assessment-banner>
            </div>
        </template>

    </div>
</template>

<script>
import clinicalAssessmentBanner from "@/components/people/client/clinicalAssessment/banner/clinicalAssessmentBanner";
export default {
    name: "onlineAssessment",
    props: {
        clientId: {
            required: true,
        },
    },
    components: {
        clinicalAssessmentBanner
    },
    data() {
      return {
        clientOnlineAssessment: [],
        onlineAssessmentBanner: {
          text: "",
          show_warning: false,
        },
        isEverythingReady: false,
      }
    },
  created() {
      this.getOnlineAssessment();
  },
  methods:{
    getOnlineAssessment() {
      this.$http.get(this.url + "client/online-assessment", {
        params: {
          timezone: this.timezone,
          device_type: this.deviceType,
          client_id: this.clientId
        }
      }).then(response => {
        this.clientOnlineAssessment = response.data.onboarding_data;
        this.onlineAssessmentBanner = response.data.onboarding_banner;
        this.isEverythingReady = true;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
      });
    },
  }
}
</script>


