let arrayHelper = {
    removeArrayValue(value, array) {
        const index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    },
    getElementIndexByKeyValue(array, key, value) {
        return array.findIndex(element => element[key] === value);
    },
    removeElementByIndex(array, index) {
        array.splice(index, 1);
        return array
    },
    removeElementByKeyValue(array, key, value) {
        let index = array.findIndex(element => element[key] === value);
        if ( index > -1 ) {
            array.splice(index, 1);
        }
        return array;
    },
    pluck(array, key) {
        return array.map(obj => obj[key]);
    }
};

export default arrayHelper;