<template>
  <div class="bookingFlow__flex align-center justify-space-between">
    <div :class="sessionConfirmed ? 'bookingFlow__visibility' : ''" class="bookingFlow__flex align-center">
    <img class="cursor-pointer width-36 height-36"  :src="backIcon" alt="myonlinetherapy" @click="goBack">
    <p class="fs-16 mb-0 pl-2">{{ title }}</p>
    </div>
    <div >
      <p v-if="sessionConfirmed" class="fs-20 lh-28 mb-0 bookingFlow__sessionConfirmed">Session confirmed!</p>
    </div>
    <div class="right justify-end bookingFlow__flex">
      <img src="https://assets.myonlinetherapy.com/images/icons/booking/booking/close_ic.svg" @click="close" alt="close" class="cursor-pointer width-36 height-36">
    </div>
  </div>
</template>

<script>
export default {
  name: "back",
  props: {
    title: {
      type: String,
      required: false,
      default: 'Book session'
    },
    sessionConfirmed:{
      required: true,
    }
  },
  data() {
    return {
      backIcon: "https://assets.myonlinetherapy.com/images/icons/booking/booking/back.svg",
      backBtnDisable: false,
    }
  },
  methods:{
    goBack() {
      this.$emit('goBack');
    },
    close(){
      window.history.back();
    }
  }
}
</script>