<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" alt="MyOnlineTherapy" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
                <div class="client_initial_assessment">
                    <div class="form_container">
                        <div class="assessment_form">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <label class="app-label">First line</label>
                                    <input class="form-control" placeholder="Enter first line" v-model="houseAddress">
                                </div>

                                <div class="col-md-12 mt-3">
                                    <label class="app-label">Town/City</label>
                                    <input class="form-control" placeholder="Enter town/city" v-model="city">
                                </div>

                                <div class="col-md-12 mt-3">
                                    <label class="app-label">Postcode</label>
                                    <input class="form-control" placeholder="Enter postcode" v-model="postalCode">
                                </div>

                                <div class="col-md-12 mt-4">
                                    <button class="btn next_button app_button w-100 h-40" :disabled="disabled" @click="addAddress">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>
<script>
    export default {
        name: "addAddress",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            clientPostalCode: {},
            clientHouseAddress: {},
            clientCity: {},
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit("close")
                    }
                },
            },
        },
        data() {
            return {
                modal_title : "Add Address",
                actionButtonTitle: "Save",
                city: this.clientCity,
                postalCode: this.clientPostalCode,
                houseAddress: this.clientHouseAddress,
                disabled: true
            }
        },
        methods:{
            hide() {
                this.$emit("hide")
            },
            addAddress() {
                this.hide();
                this.$emit("addAddress", {
                    "house_address": this.houseAddress,
                    "city": this.city,
                    "postal_code": this.postalCode,
                });
            },
            updateStatus() {
                this.disabled = !(
                        this.city !== "" &&
                        this.houseAddress !== "" &&
                        this.postalCode !== ""
                );
            }
        },
        watch: {
            "city": function(value) {
                this.updateStatus();
            },
            "houseAddress": function(value) {
                this.updateStatus();
            },
            "postalCode": function(value) {
                this.updateStatus();
            }
        }
    }
</script>