<template>
  <v-dialog v-model="show" max-width="600px" min-height="742">
    <v-card class="p-l-32 p-b-12 p-t-32 p-r-12 border-radius-8">
      <v-card-title>
        <h1 class="model-heading">Update insurance details</h1>
        <img class="close-model top-40" alt="close" src="/icons/close_ic.svg" @click.stop="show=false"/>
      </v-card-title>

      <v-card-text class="p-t-8 p-b-4">
        <div class="text-xs-center">
          <p class="error" v-show="(error !=='')">{{ error }}</p>
        </div>
        <div class="p-b-8" id="add-insurance-dialog">

          <div class="form-group">
            <div class="row">

              <div class="col-md-12 select-height-limit">
                <label class="form-label">Select insurer</label>
                <input type="text" class="input-text" :value="company.text" readonly="true"/>
              </div>
            </div>

            <template v-if="company.text !== ''">
              <div class="row m-t-12">
                <div class="col-md-12">
                  <label class="form-label">Membership number</label>
                  <input type="text"
                         class="input-text"
                         v-model="membership_number"
                         placeholder="Enter membership number">
                </div>
              </div>

              <div class="row m-t-12">
                <div class="col-md-12">
                  <label class="form-label">Date of birth</label>
                  <v-menu ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="0"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          min-width="290px"
                  >
                    <input type="text"
                           class="input-text"
                           readonly
                           slot="activator"
                           v-model="dob"
                           placeholder="Enter date of birth">
                    <v-date-picker
                        ref="picker"
                        v-model="dob"
                        :max="max"
                        @change="save"
                        :no-title="true"
                        :show-current="true"
                        class="dob-picker"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div class="row m-t-24">
                <div class="col-md-12">
                  <label class="form-label m-b-4">Sex assigned at birth</label>
                  <p class="m-b-12 text-xs-left secondary-color fs-14 lh-22">This ensures the invoices we submit for your
                    sessions match the information you previously provided your insurer.</p>
                  <select class="input-select"
                          v-model="gender.value"
                          placeholder="Select option">
                    <option v-for="gender in genders" :value="gender.value">{{ gender.text }}</option>
                  </select>
                </div>
              </div>
              <div class="row m-t-12">
                <div class="col-md-12">
                  <label class="form-label">House address</label>
                  <input type="text"
                         class="input-text"
                         v-model="house_address"
                         placeholder="Enter home address">
                </div>
              </div>

              <div class="row m-t-12">
                <div class="col-md-12">
                  <label class="form-label">City / Town</label>
                  <input type="text" class="input-text" v-model="city" placeholder="Enter city">
                </div>
              </div>

              <div class="row m-t-12">
                <div class="col-md-12">
                  <label class="form-label">Postal code</label>
                  <input type="text" class="input-text" v-model="postal_code" placeholder="Enter postal code">
                </div>
              </div>

              <div class="row m-t-12">
                <div class="col-md-12 select-height-limit">
                  <label class="form-label">Country</label>
                  <select class="input-select"
                          v-model="country.value"
                          placeholder="Select country">
                    <option v-for="country in countries" :value="country.value">{{ country.text }}</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
        </div>

      </v-card-text>


      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="show=false">Cancel</a>
        <button :disabled="disable" class="btn modal-action-button" @click="updateInsurance()">Update</button>
      </v-card-actions>
    </v-card>



  </v-dialog>
</template>
<script>
import stringHelper from '@/helpers/stringHelper'
import moment from 'moment';
export default {
  props: ['visible', 'type', 'value', 'clientDobData'],
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
  },
  data() {
    return {
      max: moment().format('YYYY-MM-DD'),
      menu: false,
      insurance: this.value,
      company: this.getCompany(),
      membership_number: (this.value) ? this.value.membership_number : '',
      house_address: (this.value) ? this.value.house_address : '',
      city: (this.value) ? this.value.city : '',
      dob: '',
      disable: false,
      postal_code: (this.value) ? this.value.postal_code : '',
      gender: {
        value: this.value && this.value.gender ? this.value.gender.toLowerCase() : '',
        text: this.value && this.value.gender ? this.capitalizeString(this.value.gender) : ''
      },
      error: '',
      countries: [],
      country: {
        value: '',
        text: ''
      },
      genders: [],
    }
  },
  created() {
    this.disable = !this.update();
    this.getDateOfBirth();
    this.initializeGendersInput();
    this.$nextTick(() => {
      if (!this.countries.length > 0) {
        let code = this.value && this.value.country ? this.value.country : '';
        this.getCountries(code);
      }
    });

  },

  methods: {
    getCompany() {
      return {
        text: (this.value && this.value.insurance_company) ? this.value.insurance_company.name : '',
        value: (this.value && this.value.insurance_company) ? this.value.insurance_company.id : '',
      };
    },
    capitalizeString: stringHelper.capitalizeText,
    save(date) {
      this.$refs.menu.save(date)
    },
    getCountries(code = '') {
      let loader = this.$loading.show();
      this.$http.get("https://restcountries.com/v2/all").then((response) => {
        loader.hide();
        response.data.forEach((item) => {
          let countryName = item.name === 'United Kingdom of Great Britain and Northern Ireland' ? 'United Kingdom' : item.name;
          let countryCode = item.name === 'United Kingdom of Great Britain and Northern Ireland' ? 'GB' : item.alpha2Code;
          let data = {"value": countryCode, "text": countryName};
          if (code === countryCode) {
            this.country.value = countryCode;
            this.country.text = countryName;
          }
          this.countries.push(data);
        });
      }).catch((error) => {
        loader.hide();
      })
    },
    initializeGendersInput() {
      let male = {"value": "male", "text": "Male"};
      let female = {"value": "female", "text": "Female"};
      this.genders.push(male);
      this.genders.push(female);
    },
    updateInsurance() {
      let loader = this.$loading.show();
      let data = {
        id: this.value ? this.value.id : null,
        membership_number: this.membership_number,
        house_address: this.house_address,
        date_of_birth: this.dob,
        city: this.city,
        country: this.country.value,
        gender: this.gender.value,
        postal_code: this.postal_code,
      }
      this.$http.put(this.url + "client/insurance-details", data).then(response => {
        loader.hide();
        this.insurance = response.data.insurance;
        this.$emit('refreshData', this.insurance);
        this.$toastr("success", response.data.message, "");
        this.show = false;

      }).catch(error => {
        loader.hide();
          this.error = error.response.data.message;
      });
    },
    update() {
      return this.company.value !== '' &&
          this.dob !== '' &&
          this.house_address !== '' &&
          this.city !== '' &&
          this.postal_code !== '' &&
          this.gender.value !== '' &&
          this.country.value !== '';
    },
    getDateOfBirth() {
      if (this.value && this.isDobValid(this.value.date_of_birth)) {
        this.dob = this.value.date_of_birth;
      }
    },
    isDobValid(dob) {
      return dob && dob !== '0000-00-00'
    }
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    membership_number() {
      this.disable = !this.update();
    },
    house_address() {
      this.disable = !this.update();
    },
    city() {
      this.disable = !this.update();
    },
    postal_code() {
      this.disable = !this.update();
    },
    country: {
      handler: function (val, oldVal) {
        this.disable = !this.update();
      },
      deep: true
    },
    gender: {
      handler: function (val, oldVal) {
        this.disable = !this.update();
      },
      deep: true
    },
    dob() {
      this.disable = !this.update();
    }
  }
}
</script>
<style scoped>
.top-40 {
  top: 40px !important;
}
.border-radius-8 {
  border-radius: 8px !important;
}
.form-label {
  font-family: 'metropolisBold' !important;
  font-size: 15px !important;
}

</style>
