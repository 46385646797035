<template>
  <div class="details-section client-top-section">
    <div class="header">
      <p class="title">{{ this.sectionTitle }}</p>
    </div>
    <div v-if="userBookingPermissions.length > 0" class="flex-display flex-column pa-3 w-100">
      <div class="toggle-switch ma-1"
           v-for="(bookingPermission, index) in userBookingPermissions"
           :key="bookingPermission.id">
        <template v-if="bookingPermission.session_status">
          <label class="switch-label w-270"> {{ bookingPermission.type }} - {{ bookingPermission.method }} - {{ bookingPermission.duration }}</label>
          <template v-if="userType === 'therapist'">
            <label class="switch float-right">
              <input type="checkbox"  @click.inner="changeSessionPermissionDialog($event, 'therapist_fee' + bookingPermission.id, bookingPermission.id, bookingPermission.status)"
                     v-model="bookingPermission.status" name="session_type_permission" true-value="1" false-value="0"
              >
              <span class="slider round"></span>
            </label>

            <input class="input-text-payout w-100px"
                   placeholder="Payout"
                   type="number"
                   :ref="'therapist_fee' + bookingPermission.id"
                   :value="bookingPermission.therapist_fee"
                   v-on:keyup.enter="setTherapistFee($event, bookingPermission.id)"
            >
          </template>
          <template v-else>
            <label class="switch float-right">
              <input type="checkbox"
                     :disabled="bookingPermission.is_locked"
                     v-on:change="changeSessionPermission($event, bookingPermission.id)"
                     v-model="bookingPermission.status" name="session_type_permission" true-value="1" false-value="0"
              >
              <span class="slider round" :class="{'opacity-5': bookingPermission.is_locked}"></span>
            </label>
          </template>
        </template>
      </div>
    </div>
    <div v-else>
      <p class="mt-2 text-center">There are no session types yet for this User.</p>
    </div>

    <!-- confirmation dialog -->
    <confirmation v-if="payOutDialog" :visible="payOutDialog">
      <template slot="title">
        Add Payout
      </template>
      <template slot="desc">
        Payout figure must be added before a session type can be made active
      </template>
      <template slot="actions">
        <button class="btn modal-action-button btn-approve" @click="hidePayOutDialog">Ok</button>
      </template>
    </confirmation>

  </div>

</template>

<script>
import Confirmation from '../common/Confirmation';
export default {
  components: {Confirmation},
  name: "sessionTypePermissions",
  data: () => ({
    payOutDialog: false,
  }),
  props: {
    userBookingPermissions: {
      required: true,
      type: Array
    },
    userId: {
      required: true,
    },
    sectionTitle: {
      type: String,
      default: "Therapy session types",
    },
    userType: {
      type: String,
      default: ''
    }
  },

  methods: {
    changeSessionPermissionDialog (event, sessionTypeRef, sessionTypeId, permissionStatus) {
      if (this.$refs[sessionTypeRef][0].value === '' && permissionStatus != 1) {
        this.payOutDialog = true;
        event.preventDefault();
      } else {
        this.changeSessionPermission(event, sessionTypeId);
      }
    },
    changeSessionPermission (event, sessionTypeId) {
      let loader = this.$loading.show()
      this.$http.put(this.url + "booking-permissions", {
        user_id: this.userId,
        status: event.target.checked == true ? 1 : 0,
        session_type_id: sessionTypeId
      }).then(response => {
        loader.hide()
        this.$toastr("success", response.data.message, "")
      }).catch(error => {
        loader.hide()
        this.$toastr("error", error.response.data.message, "")
      })
    },

    setTherapistFee (event, sessionTypeId) {

      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/fee", {
        therapist_id: this.userId,
        therapist_fee: event.target.value,
        session_type_id: sessionTypeId
      }).then(response => {
        loader.hide()
        this.$emit('refreshPage');
        this.$toastr("success", response.data.message, "")
      }).catch(error => {
        loader.hide();
        this.$emit('refreshPage');
        this.$toastr("error", error.response.data.message, "")
      })
    },
    hidePayOutDialog () {
      this.payOutDialog = false;
    },
  }
}
</script>

<style scoped>
.input-text-payout {
  border: 1px solid #D4D4D4;
  border-radius: 3px;
  padding: 0 10px;
}
.w-270 {
  width: 270px !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
</style>
