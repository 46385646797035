<template>
    <div class="flex-display flex-row mb-3">
        <div class="flex-display flex-row w-100 align-items-center">
            <div class="flex-display flex-row justify-content-space-between w-100">
                <div class="flex-display flex-column justify-content-center">
                    <label class="switch-label m-0 color-fade">Risk</label>
                </div>
              <div class="flex-display flex-row align-center justify-content-end w-100">
                <template v-if="risk.name === 'Not assessed'">
                  <div class="border-1px assessment-icon"></div>
                  <p class=" mb-0 ml-2 font-weight-medium">{{ risk.name }}</p>
                </template>
                <template v-else>
                  <template v-if="risk.name === 'Risk not assessed'">
                    <div class="border-1px assessment-icon"></div>
                    <p class=" lh-20 mb-0 ml-2 font-weight-medium">{{ risk.name }}</p>
                  </template>
                  <template v-else>
                    <div class="border-1px assessment-icon" :style="{'background-color' : bgColor}"></div>
                    <p class=" mb-0 ml-2 font-weight-medium">{{ risk.name }}</p>
                  </template>
                </template>
              </div>
            </div>

        </div>
    </div>
</template>

<script>
    import StringHelper from "@/helpers/stringHelper";

    export default {
        name: "ClientRisk",
        props: {
            client: {
              required: true
            }
        },
        data() {
          return {
            bgColor: "",
            risk: {
              name: "",
              color: ""
            },
          }
        },
        created() {
          this.parseClientRisk();
          if (!StringHelper.empty(this.risk.color)) {
            this.bgColor = this.risk.color;
          }
        },
        methods:{
          parseClientRisk() {
            if (StringHelper.empty(this.client.risk) || this.client.risk === 'No assessed') {
              this.risk.name = "Not assessed";
              this.risk.color = "";
            } else {
              this.risk.name = this.client.risk;
              this.risk.color = this.client.risk_color;
            }
          }
        },
        watch: {
          'risk': {
            deep: true,
            handler: function (value) {
              if (!StringHelper.empty(value.color)) {
                this.bgColor = value.color;
              }
            }
          }
        }
    }
</script>
<style scoped>
.assessment-icon{
  width: 12px;
  height: 12px;
  border-width: 1px !important;
  border-radius: 9999px !important;
  border-color: #9ca3af !important;
}

</style>