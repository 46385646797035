<template>
    <div class="fee-container">
        <label class="switch-label mt-2">Commission</label>
        <div class="fee-container__fee-content">
            <template v-if="activeView === 'read'">
                <span class="m-2">{{feePercentage}}%</span>
                <img class="cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editFee">
            </template>
            <template v-else>
                <input class="input-text" type="text" maxlength="2" v-model="feePercentage" />
                <span class="m-2"> % </span>
                <button class="btn btn-primary" @click="updateFee">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    import regexHelper from "../../helpers/regexHelper";
    export default {
        name: 'TherapistFee',
        props: {
            therapistId: {
                type: Number,
                required: true
            },
            fee: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                activeView: 'read',
                feePercentage: this.fee
            }
        },
        methods: {
            editFee() {
                this.activeView = 'edit';
            },
            updateFee() {
                let loader = this.$loading.show();
                this.$http.put(this.url + "therapist/psychologist-fee/update", {
                    therapist_id: this.therapistId,
                    fee: this.feePercentage
                }).then(response => {
                    loader.hide();
                    this.activeView = "read";
                    this.$emit("feeUpdated", this.feePercentage);
                    this.$toastr("success", response.data.message, "");
                }).catch(error => {
                    loader.hide();
                    this.$toastr("error", error.response.data.message, "");
                });
            }
        },
        watch: {
            'feePercentage': function(value) {
                this.feePercentage = regexHelper.filterNumericOnly(value);
            }
        }
    }
</script>