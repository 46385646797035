<template>
    <div class="item-actions" v-if="rowData.is_reason_code_missing">
        <span class="text-warning">
            <span class="slickdot slickdot-nav-unread-message m-0"></span> {{ rowData.session_status }}
        </span>
    </div>
    <div class="item-actions" v-else-if="rescheduleRequestFromTherapist">
        <span class="text-warning">
            <span class="slickdot slickdot-nav-unread-message m-0"></span> {{ rowData.session_status }}
        </span>
    </div>
    <div class="item-actions" v-else>
        {{ rowData.session_status }}
    </div>
</template>

<script>
export default {
    name: 'BookingSessionStatus',
    props: {
        rowData: {
            type: Object,
            required: true
        },
    },
    computed: {
        rescheduleRequestFromTherapist() {
            let status = this.rowData.sessionStatusAndSetBy.split('|');
            return status[0].toLowerCase() === 'reschedule request' && status[1].toLowerCase() === 'therapist';
        }
    },
}
</script>
