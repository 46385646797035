import { render, staticRenderFns } from "./ClinicalAssessment.vue?vue&type=template&id=1c92f256&"
import script from "./ClinicalAssessment.vue?vue&type=script&lang=js&"
export * from "./ClinicalAssessment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports