<template>
  <!-- call logs modal -->
  <v-dialog
    v-model="show"
    persistent
    max-width="400px"
    min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img
          class="close-model"
          src="/icons/close_ic.svg"
          @click.stop="hide">
      </v-card-title>

      <v-card-text>
        <div
          v-if="rowData.call_logs.length > 0"
          class="booking-details">
          <template v-for="(log, index) in rowData.call_logs">
            <div
              :key="index"
              class="detail-item">
              <label class="item-label"> {{ log.date }} | </label>
              <span class="item-value"> {{ log.action }}</span>
            </div>
          </template>
        </div>
        <div
          v-else
          class="booking-details">
          <p>There are no call logs yet.</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
    export default {
        name: 'BookingCallLogs',
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                modalTitle: 'Call Logs'
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        watch: {},
        methods: {
            hide () {
                this.$emit('hide')
            }
        }
    }
</script>
<style scoped>

</style>
