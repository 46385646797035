<template>
  <div class="tabs pointer mb-2" :class="{'tab-active' : currentTab.id === tab.id }" @click="$emit('changeTab',tab)">
    {{tab.name}} <span class="fs-12"> ({{ tab.total }}) </span>
  </div>
</template>

<script>
export default {
  name: "tabs",
  props:{
    currentTab:{
      type: Object,
      required: true
    },
    tab:{
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>