<template>
  <v-dialog v-model="show" persistent max-width="533px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{ modal_title }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>

        <div class="text-xl-center">
          <div class="form-group">
            <label class="form-label">Issue Name</label>
            <input v-model="issue" class="input-text" type="text" placeholder="Enter Issue Name"/>
          </div>
        </div>

        <div class="text-xl-center">
          <div class="form-group">
            <label class="form-label">Description</label>
            <trumbowyg v-model="description" :config="config" class="form-control" name="description"></trumbowyg>
          </div>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button :disabled="issue===''" class="btn modal-action-button" @click="addIssue">{{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import Trumbowyg from "vue-trumbowyg";

// Import editor css
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/lineheight/trumbowyg.lineheight.min.js";
import "trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.min.js";
import "trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min.js";
import "trumbowyg/dist/plugins/indent/trumbowyg.indent.min.js";
import "trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js";

export default {
  name: "addIssue",
  components: {
    Trumbowyg
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    actionType: {
      required: true,
      type: String
    },
    // issue object, null in the case of the add
    value: {}
  },
  data: () => ({
    modal_title: "",
    actionButtonTitle: "",
    issue: "",
    description: "",
    config: {
      btns: [
        ["strong", "em"],
        ["undo", "redo"], // Only supported in Blink browsers
        ["lineheight", "fontsize"],
        ["link"],
        ["unorderedList", "orderedList"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["indent", "outdent"],
        ["fontfamily"],
        ["foreColor"],

      ],
      plugins: {
        fontsize: {
          sizeList: [
            "12px",
            "14px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px"
          ],
          allowCustomSize: true
        },
        fontfamily: {
          fontList: [
            {name: "Galano", family: "Galano Classic Alt"},
          ]
        }
      }
    }
  }),
  created() {
    if (this.actionType === "add") {
      this.modal_title = "Add Issue";
      this.actionButtonTitle = "Add";
    } else {
      this.modal_title = "Update Issue";
      this.issue = this.value.name;
      this.description = this.value.description;
      this.actionButtonTitle = "Update";
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    addIssue() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "add-issue", {
        id: this.actionType === "add" ? 0 : this.value.id,
        name: this.issue,
        description: this.description,
        action_type: this.actionType
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("addIssue");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      })
    }
  }
}
</script>
<style scoped>
.text-xl-center {
  text-align: left !important;
  font-size: 15px !important;
}
</style>