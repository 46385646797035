<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" alt="close" src="/icons/close_ic.svg"
             @click="hideAddOrgPlanModal"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form
                class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                id="organisationForm"
            >


              <div class="form-group w-100">
                <label class="form-label">Organisations</label>
                <select name="use" class="input-select"
                        v-model="organisationPlan.org_id">
                  <option value="" disabled selected>Select Org</option>
                  <option v-for="org in organisations" :key="org.id"
                          :value="org.id">{{ org.name }}
                  </option>
                </select>
              </div>


              <div class="form-group w-100">
                <label class="form-label">Plans</label>
                <select name="use" class="input-select"
                        v-model="organisationPlan.plan_id">
                  <option value="" disabled selected>Select Plan</option>
                  <option v-for="plan in plans" :key="plan.id" :value="plan.id">
                    {{ plan.title }}
                  </option>
                </select>
              </div>

              <div class="form-group w-100">
                <label class="form-label">Next Plan</label>
                <select name="use" class="input-select"
                        v-model="organisationPlan.next_plan"
                        :disabled="isDisabled">
                  <option value="" selected>Select Next Plan</option>
                  <option v-for="nextPlan in nextPlans" :key="nextPlan.id" :value="nextPlan.id">
                    {{ nextPlan.title }}
                  </option>
                </select>
              </div>

              <div class="form-group w-100">
                <label>
                  <span class="form-label">Is Default</span>
                  <label class="switch">
                    <input type="checkbox"
                           name="default" v-model="organisationPlan .default"
                           :true-value=true :false-value=false>
                    <span class="slider round"></span>
                  </label>
                </label>
              </div>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hideAddOrgPlanModal">Cancel</a>
        <button class="btn modal-action-button" @click="addOrgPlan">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "addOrgPlan",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      type: String,
    },
    orgPlan: {
      required: false,
      type: Object
    },
  },
  data: () => ({
    modalTitle: "",
    actionButtonTitle: "",
    organisations: [],
    plans: [],
    nextPlans: [],
    organisationPlan: {
      org_id: "",
      plan_id: "",
      next_plan: "",
      default: false
    },
    disabled: true,
  }),
  computed: {
    isDisabled() {
     return !(this.organisationPlan.org_id !== "" && this.organisationPlan.plan_id !== "");
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit("close")
        }
      },
    }
  },
  created() {
    this.getOrgPlans();
    if (this.actionType === "add") {
      this.modalTitle = "Add Org Plan";
      this.actionButtonTitle = "Add";
    } else {
      this.modalTitle = "Update Org Plan";
      this.actionButtonTitle = "Update";
    }
    if (this.orgPlan) {
      this.organisationPlan = this.orgPlan;
    }
  },
  methods: {
    getOrgPlans() {
      let loader = this.$loading.show();
      this.$http.get(this.url + "org-plan", {}).then(response => {
        this.organisations = response.data.organisations;
        this.plans = response.data.plans;
        this.nextPlans = response.data.plans;
        loader.hide();
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
    addOrgPlan() {
      let loader = this.$loading.show();
      this.$http({
        method: "put",
        url: this.url + "org-plan",
        data: this.organisationPlan
      }).then(response => {
        loader.hide();
        this.hideAddOrgPlanModal();
        this.$toastr("success", response.data.message);
        this.$events.fire("vuetable:reload");
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },
    hideAddOrgPlanModal() {
      this.$emit("hideAddOrgPlanModal");
    },

  },
  watch: {
    "organisationPlan.plan_id": function (newVal) {
      let i;
      this.nextPlans = [];
      let selectedPlan = this.plans.filter((plan) => {
        return plan.id === newVal
      })
      for (i = 0; i < this.plans.length; i++) {
        if (this.plans[i].id !== newVal && this.plans[i].enable_therapy === selectedPlan[0].enable_therapy ) {
          this.nextPlans.push(this.plans[i]);
        }
      }
    },

  }
}
</script>
