<template>
    <div class="fee-container mt-0">
        <label class="switch-label mb-0">Xero Contact</label>
        <div class="fee-container__fee-content">
            <template v-if="activeView === 'read'">
                <span class="m-2">{{ xeroContact }}</span>
                <img class="cursor-pointer " src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editXero">
            </template>
            <div class="flex-display flex-row mt-2" v-else>
                <input class="form-control" type="text" v-model="xeroContact"/>
                <button class="btn btn-primary ml-2" @click="updateXeroContact">Update</button>
                <button class="btn confirmation-delete ml-2" @click="cancelXero">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TherapistXeroContact',
        props: {
            therapistId: {
                type: Number,
                required: true
            },
          xeroContact: {
            required: true
          }
        },
        data() {
            return {
                activeView: 'read',
                xeroContact: this.xeroContact,
                oldXeroContact : '',
            }
        },
        methods: {
          editXero() {
                this.activeView = 'edit';
                this.oldXeroContact = this.xeroContact

          },
          cancelXero() {
            this.activeView = 'read';
            this.xeroContact = this.oldXeroContact
          },
            updateXeroContact() {
                let loader = this.$loading.show();
                this.$http.put(this.url + "therapist/xero-contact/update", {
                    therapist_id: this.therapistId,
                    xero_contact: this.xeroContact
                }).then(response => {
                    loader.hide();
                    this.activeView = "read";
                   this.$emit("xeroContactUpdated", this.xeroContact);
                    this.$toastr("success", response.data.message, "");
                }).catch(error => {
                    loader.hide();
                    this.$toastr("error", error.response.data.message, "");
                });
            }
        },
    }
</script>