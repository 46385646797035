<template>
  <div>
    <hr class="mt-0">
    <form class="flex-display flex-row flex-wrap flex-justify-between w-100"
          id="skillForm"
    >
      <div class="w-100" v-if="plan.is_icd_10_code">
        <div class="form-group w-50">
          <p class="font-weight-bold">Treatment overview</p>
          <label>
            <span class="form-label">
              <span class="slickdot slickdot-nav-unread-message bg-danger-admin" v-if="plan.plan_actions.icd_10_code"></span>
              &nbsp; ICD-10 code
            </span>
            <select class="input-select" name="status" v-model="plan.icd10_code">
              <option value="" disabled selected>Select ICD-10 Code</option>
              <template v-for="code in icd_10_codes">
                <option :value="code.value">{{ code.code }}</option>
              </template>
            </select>
          </label>
        </div>
      </div>

      <div class="w-100">
        <div class="form-group w-50">
          <p class="font-weight-bold">
            <span class="slickdot slickdot-nav-unread-message bg-danger-admin" v-if="plan.plan_actions.therapist_plan"></span>
            &nbsp; Treatment Plan
          </p>
          <label>
            <span class="form-label">How many sessions does your client require for their entire treatment plan?</span>
              <input class="input-text form-group__input" v-model="plan.recommended_sessions">
          </label>
        </div>
      </div>

      <div class="w-100" v-if="plan.is_gaia">
        <div class="form-group w-50">
          <label>
            <span class="form-label">GAIA digital care</span>
            <select class="input-select" name="status" v-model="plan.gaia_refer">
              <option value="" disabled selected>Select GAIA status</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
    import regexHelper from "../../../helpers/regexHelper";

    export default {
        name: 'Details',
        components: {  },
        props: {
          plan_details: {},
          icd_10_codes: {}
        },
        data () {
            return {
              plan: null
            }
        },
        created () {
            this.plan = {
              ...this.plan_details
            }
            if (this.plan && this.plan.recommended_sessions == 0) {
                this.plan.recommended_sessions = null;
            }
        },
      watch: {
        'plan': {
          deep: true,
          handler: function () {
            if (this.plan.icd10_code !== this.plan_details.icd10_code ||
              this.plan.recommended_sessions !== this.plan_details.recommended_sessions ||
              this.plan.gaia_refer !== this.plan_details.gaia_refer
            ) {
              this.$emit('updated', this.plan);
            }
          }
        },
        'plan.recommended_sessions': function(value) {
            this.plan.recommended_sessions = regexHelper.filterNumericOnly(value);
        }
      }
    }
</script>
<style scoped>
  .form-group {
    margin: 20px 20px 0 0 !important;
  }
  label {
    width: 100%;
  }
</style>
