<template>
    <div class="user-progress" v-if="rowData.voucher_type !=='validated'">
        <div class="item-actions">
            <button @click="openAdd" class="btn-edit">Add</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        data() {
            return {
                voucherEvent: 'add-record'
            }
        },
        methods: {
            openAdd() {
                this.$events.fire(this.voucherEvent, this.rowData)
            }
        }
    }
</script>
