<template>
  <v-dialog v-model="visible" persistent max-width="500px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{modal_title}}</h1>
        <img class="close-model" alt="closeIcon" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>

        <div class="flex-display flex-column w-100">
          <div class="flex-display flex-row w-100 mb-3">
            <p class="w-25 fs-16 ls-3 text-gray mb-0 font-weight-medium">GP</p>
            <textarea v-if="isEditable" v-model="clientGp" class="form-control placeholder-color ml-2 mr-1"
                      rows="4"
                      placeholder="Dr. Jane Smith
Smith Family Practice
0208 333 2984
j.smith@familypractice.com"
            ></textarea>
            <p v-else class="mb-0 fs-16 text-details grey-900 w-75">{{ getGp }}</p>
          </div>
        </div>

        <div class="flex-display flex-column w-100">
          <div class="flex-display flex-row w-100 mb-3">
            <p class="w-25 fs-16 ls-3 text-gray mb-0 font-weight-medium">Next To Kin</p>
            <textarea class="form-control ml-2 mr-1 placeholder-color" v-if="isEditable" v-model="clientNextToKin"
                      placeholder="John Doe
0208 333 2984
john.doe@nextofkin.com"
                      rows="4"></textarea>
            <p v-else class="mb-0 fs-16 text-details grey-900 w-75">{{ getNextToKin }}</p>
          </div>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button :disabled="!isEditable" class="btn modal-action-button" @click="addClientNextToKin">{{actionButtonTitle}}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import StringHelper from "@/helpers/stringHelper";

export default {
  name: "addClientNextToKin",
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    isEditable: {
      required: true,
      type: Boolean
    },
    client: {
      required: true,
    },
  },
  data(){
    return{
      modal_title : 'Next of kin and GP contact details',
      actionButtonTitle:'Save',
      clientGp: this.client.gp,
      clientNextToKin: this.client.next_to_kin,
    }
  },
    computed: {
      getGp() {
        return StringHelper.empty(this.clientGp) ? "N/A" : this.clientGp;
      },
      getNextToKin() {
        return StringHelper.empty(this.clientNextToKin) ? "N/A" : this.clientNextToKin;
      }
    },
    methods:{
      hide() {
        this.$emit('hide')
      },
      addClientNextToKin() {
        let formData = {};
        formData = {
          client_id: this.client.id,
          gp: this.clientGp,
          next_to_kin: this.clientNextToKin,
          device_type: "web"
        };

        let loader = this.$loading.show();
        this.$http.put(this.url + "client/contact-details", formData).then((response) => {
          loader.hide();
          this.$emit('hide');
          this.$toastr("success", response.data.message, "");
          location.reload();
        }).catch((error) => {
          loader.hide();
          this.$toastr("error", error.response.data.message, "");
        });
      },
  }
}
</script>
<style scoped>
.placeholder-color::placeholder{
  color: gray !important;
  opacity: 0.7 !important;
}
.text-details{
  white-space: pre-line !important;
  font-family: metropolisMedium;

}
</style>