<template>
    <v-card>
        <v-card-title>
            <h1 class="model-heading">{{modalTitle}}</h1>
            <img class="close-model" src="/icons/close_ic.svg" alt="" @click.stop="hide"/>
        </v-card-title>

        <v-card-text>
            <div class="add-member-modal">
                <form>
                    <div class="form-group" id="client_selection">
                        <label class="form-label">Client</label>
                        <multi-select v-model="selected_client"
                                      :options="clients"
                                      :allow-empty="false"
                                      :show-labels="false"
                                      :internal-search="false"
                                      label="full_name"
                                      track-by="id"
                                      placeholder="Select client"
                                      @search-change="searchClient"
                                      :disabled="action === 'reschedule' || pre_selected_client"
                        ></multi-select>
                        <p v-if="clientUserName" class="m-l-2 m-t-2">(@ {{clientUserName}})</p>
                    </div>
                    <div class="form-group" id="therapist_selection">
                        <label class="form-label">Therapist</label>
                        <multi-select  v-model="selected_therapist"
                                       :options="therapists"
                                       :allow-empty="false"
                                       :show-labels="false"
                                       :internal-search="false"
                                       label="full_name"
                                       track-by="id"
                                       placeholder="Select therapist"
                                       :disabled="action === 'reschedule' || therapists.length <= 0"
                        ></multi-select>
                      </div>
                      <div class="form-group" id="session_type_selection">
                        <label class="form-label">Session Type</label>
                        <multi-select v-model="selected_session_type"
                                      :options="session_types"
                                      :searchable="false"
                                      :allow-empty="false"
                                      :show-labels="false"
                                      label="formatted_name"
                                      track-by="id"
                                      placeholder="Select session type"
                                      :disabled="action === 'reschedule' || therapists.length <= 0"
                        ></multi-select>
                      </div>
                      <div class="form-group" id="payment_method">
                        <label class="form-label">Payment Method</label>
                        <multi-select v-model="selected_payment_method"
                                      :options="payment_options"
                                      :searchable="false"
                                      :allow-empty="false"
                                      :show-labels="false"
                                      label="label"
                                      track-by="value"
                                      placeholder="Select payment method"
                                      :disabled="disablePaymentOptions"
                                      @select="fireGaEvent"
                        ></multi-select>
                      </div>
                      <div class="form-group">
                        <label class="form-label">Date</label>
                        <input class="input-text" :class="{disabled: disabledDateField}" type="text" v-model="session_date" :readonly="true" :disabled="disabledDateField" @click="selectDate" placeholder="Select date"/>
                      </div>
                      <div class="form-group">
                        <label class="form-label">Time</label>
                        <input class="input-text" :class="{disabled: disabledDateField}" type="text" v-model="selected_time" :readonly="true" :disabled="disabledDateField" @click="selectTime" placeholder="Select time"/>
                      </div>
                </form>
            </div>

        </v-card-text>

        <v-card-actions class="flex-display flex-row">
            <v-spacer></v-spacer>
            <a class="cancel-model" @click="hide">Cancel</a>
            <button class="btn modal-action-button" @click="addBooking" :disabled="disableSubmitButton">{{actionButtonTitle}}</button>
        </v-card-actions>
    </v-card>
</template>
<script>
    import moment from 'moment';
    import MultiSelect from "vue-multiselect";
    export default {
        name: "BookingForm",
        components: { MultiSelect },
        props: {
            action: {
                type: String,
                required: true
            },
            booking: {
                type: Object,
                required: true
            },
            clients: {
                type: Array
            },
            session_types: {
                type: Array
            },
            selected_date: {
                type: String
            },
            selected_time: {
                type: String
            },
            client: {
                type: Object
            },
            therapist: {
                type: Object
            },
            session_type: {
                type: Object
            },
            payment_method: {
                type: Object
            },
            card: {
                type: Object
            },
            insurance: {
                type: Object
            },
            pre_selected_client: {
                type: Object,
                default: null
            }
        },
        computed: {
            disablePaymentOptions: {
                get () {
                    return !(this.booking.patient_id && this.booking_data.session_type_id);
                }
            },
            disabledDateField: {
                get () {
                    return !((this.booking_data.therapist_id && this.booking_data.session_type_id) && !this.booking_data.is_messaging);
                }
            },
            disableSubmitButton: {
                get () {
                    return !(this.booking_data.patient_id &&
                        this.booking_data.therapist_id &&
                        this.booking_data.session_type_id &&
                        (this.booking_data.therapist_slot_id || this.booking_data.is_messaging));
                }
            },
            session_date: {
                get () {
                    return (this.booking_data.is_messaging ? moment().add(1, 'days').format('YYYY-MM-DD') + ' - ' + moment().add(7, 'days').format('YYYY-MM-DD') : this.selected_date);
                }
            },
            payment_options: {
                get () {
                    let paymentOptions = [
                        {label: 'Credit Card', value: 'card'}
                    ];
                    if ( this.selected_session_type && this.selected_session_type.insurance_accepted && this.insurance ) {
                        paymentOptions.push({label: 'Insurance', value: 'insurance'});
                    }
                    if (this.pre_selected_client && this.pre_selected_client.insurance &&
                        this.selected_session_type && this.selected_session_type.insurance_accepted) {
                      paymentOptions.push({ label: 'Insurance', value: 'insurance' })
                    }
                    return paymentOptions;
                }
            }
        },
        data: () => ({
            modalTitle : 'Add booking',
            actionButtonTitle:'Add',
            booking_data: {},
            selected_client: null,
            selected_therapist: null,
            selected_session_type: null,
            selected_payment_method: null,
            typingTimer: 0,
            doneTypingInterval: 500,
            therapists: [],
            clientUserName: ''
        }),
        mounted: function() {
            const self = this;
            const client_selector = '#client_selection .multiselect .multiselect__content-wrapper';
            const therapist_selector = '#therapist_selection .multiselect .multiselect__content-wrapper';
            const session_type_selector = '#session_type_selection .multiselect .multiselect__content-wrapper';
            $(client_selector).scroll(function(){
                self.scrollFunction('client', client_selector);
            });
            $(therapist_selector).scroll(function(){
                self.scrollFunction('therapist', therapist_selector);
            });
            $(session_type_selector).scroll(function(){
                self.scrollFunction('session_type', session_type_selector);
            });
        },
        created() {
            this.booking_data = this.booking;
            this.selected_client = this.client;
            this.selected_therapist = this.therapist;
            this.selected_session_type = this.session_type;
            this.selected_payment_method = this.payment_method;
            if ( this.action === 'reschedule' ) {
                this.modalTitle = 'Reschedule booking';
                this.actionButtonTitle = 'Reschedule';
            }
        },
        methods:{
            hide() {
                this.$emit('hide')
            },
            selectDate() {
                this.$emit('switchView', 'calendar');
            },
            selectTime() {
                this.$emit('switchView', 'slots');
            },
            addBooking() {
                this.$emit('addBooking', this.booking_data)
            },
            scrollFunction(type, selector) {
                const container = document.querySelector(selector);
                if ( ( container.clientHeight + container.scrollTop + 5 ) >= ( container.scrollHeight ) ) {
                    this.$emit('scroll', type);
                }
            },
            searchClient(query) {
                const self = this;
                clearTimeout(self.typingTimer);
                self.typingTimer = setTimeout(function () {
                    self.$emit('search', query, 'client');
                }, self.doneTypingInterval);
            },
            getConnectedTherapist() {
              this.$http.get(this.url+'client/connected-therapists', {
                params: {
                 client_id : this.selected_client.id
                }
              }).then(response => {
                 this.therapists = response.data;
                 if (this.therapists.length <= 0) {
                   this.$toastr('success','This client has no connected therapist','');
                 }
              }).catch(error => {
                 this.$toastr('error', error.response.data.message,'');
              });
            },
            fireGaEvent(value) {
              // E-commerce event
              window.dataLayer = window.dataLayer || [];

              window.dataLayer.push({
                'event': 'eec_checkout',
                'ecommerce': {
                  'checkout': {
                    'actionField': {'step': 1, 'option': value.label},
                    'products': [{

                    }]
                  }
                }
              });
              console.log("ga checkout event in admin");
            },
        },
        watch: {
            'selected_client' : function () {
                if ( this.selected_client ) {
                    this.booking_data.patient_id = this.selected_client.id;
                    this.getConnectedTherapist();
                    this.$emit('clientSelected', this.selected_client);
                    this.clientUserName = this.selected_client.username;
                }
            },
            'selected_therapist' : function () {
                if ( this.selected_therapist ) {
                    this.booking_data.therapist_id = this.selected_therapist.id;
                    this.$emit('therapistSelected', this.selected_therapist);
                }
            },
            'selected_session_type' : function () {
                if ( this.selected_session_type ) {
                    this.booking_data.session_type_id = this.selected_session_type.id;
                    this.$emit('sessionTypeSelected', this.selected_session_type);
                }
            },
            'selected_payment_method': function () {
                if ( this.selected_payment_method ) {
                    this.booking_data.payment_type = this.selected_payment_method.value;
                    this.$emit('paymentMethodSelected', this.selected_payment_method);
                }
            }
        }
    }
</script>
