<template>
  <div v-if="(planType === planTypeSelfPay ||  planType === planTypeSelfPayDiscounted) && paymentMethodDialogue">
    <pop-up :visible="paymentMethodDialogue" @hide="hide" :width="'397'" :style-classes="'width-22 height-22'">
      <template v-slot:header>
        <p class="grey-900 bold-font fs-20 lh-28 text-left">No payment details added</p>
      </template>
      <template v-slot:main>
        <p class="grey-900 fs-16 lh-20 text-left">It looks
          like {{ client.first_name }} has no payment details saved in their account - so it’s not possible to book a
          therapy session. Please ask {{ client.first_name }} to provide payment details to proceed.
        </p>
      </template>
      <template v-slot:footer>
        <button @click="ConfirmationBtn" class="d-inline-block white--text bg-blue-500 width-95 height-44 bold-font fs-16">Ok</button>
      </template>
    </pop-up>
  </div>

</template>

<script>
import PopUp from "./popUp";
import TherapyHelper from "@/helpers/app/therapyHelper";
export default {
  name: "NoPaymentDetailsDialogue",
  components: {PopUp},
  props:{
    planType: {
      required: true,
    },
    paymentMethodDialogue: {
      required: true,
      type: Boolean
    },
    client: {
      type: Object,
      required: true
    },
    step: {}
  },
  data() {
    return {
      planTypeSelfPay: TherapyHelper.planTypeSelfPay,
      planTypeSelfPayDiscounted: TherapyHelper.planTypeSelfPayDiscounted,
    }
  },
  methods:{
    hide() {
      this.paymentMethodDialogue = false
      this.$emit("closePaymentMethodDialogue");

    },
    ConfirmationBtn(){
      this.paymentMethodDialogue = false
      this.$emit("closePaymentMethodDialogue");


    }
  }
}
</script>