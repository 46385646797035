<template>
    <div class="flex-display flex-row w-100 justify-content-center " :class="userType === 'therapist' ? 'mb-3' : ''">
        <div class="flex-display flex-row">
            <template v-if="activeView === 'read'">
                <p class="full-name">
                    {{therapistFirstName}} {{therapistLastName}}
                </p>
                <img v-if="userType === 'therapist'" class="ml-2 cursor-pointer" src="/icons/start_a_conversation_ic.svg" alt="edit" @click="editName">
            </template>
            <template v-else>
                <input class="form-control ml-2 mr-1" maxlength="50" v-model="therapistFirstName">
                <input class="form-control mr-1" maxlength="50" v-model="therapistLastName">
                <button class="btn" @click="updateProfile" :disabled="disable">Update</button>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TherapistName',
        props: {
            first_name: {
                type: String,
                required: true
            },
            last_name: {
                type: String,
                required: true
            },
            userType: {
              type: String,
              default: 'therapist'
            }

        },
        data() {
            return {
                activeView: 'read',
                therapistFirstName: this.first_name,
                therapistLastName: this.last_name,
                disable: true,
            }
        },
        created() {
            this.updateStatus();
        },
        methods: {
            editName() {
                this.activeView = 'edit';
            },
            updateProfile() {
                this.$emit('updateProfile', [this.therapistFirstName, this.therapistLastName], 'name');
                this.activeView = 'read';
            },
            updateStatus() {
                if (this.therapistFirstName !== '' && this.therapistLastName !== '') {
                    this.disable = false;
                } else {
                    this.disable = true;
                }
            }
        },
        watch: {
            'therapistFirstName': function() {
                this.updateStatus();
            },
            'therapistLastName': function() {
                this.updateStatus();
            }
        }
    }
</script>
