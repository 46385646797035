<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <template v-if="currentView === 'form'">
            <booking-form v-if="isEverythingReady"
                          :action="action"
                          :booking="booking_data"
                          :clients="clients"
                          :session_types="session_types"
                          :client="selected_client"
                          :therapist="selected_therapist"
                          :session_type="selected_session_type"
                          :payment_method="selected_payment_method"
                          :card="client_card"
                          :insurance="client_insurance"
                          :selected_date="selected_date"
                          :selected_time="selected_slot ? selected_slot.starting_time : ''"
                          :pre_selected_client = "pre_selected_client"
                          v-on:hide="hide"
                          v-on:clientSelected="clientSelected"
                          v-on:therapistSelected="therapistSelected"
                          v-on:sessionTypeSelected="sessionTypeSelected"
                          v-on:paymentMethodSelected="paymentMethodSelected"
                          v-on:switchView="switchView"
                          v-on:addBooking="addBooking"
                          v-on:scroll="loadMore"
                          v-on:search="search"
            ></booking-form>
        </template>
        <template v-else-if="currentView === 'calendar'">
            <availability-calendar :availability_dates="availability_dates"
                                   :minimum_date="minimum_date"
                                   :selected_date="selected_date"
                                   v-on:switchView="switchView"
                                   v-on:applyDate="applyDate"
            ></availability-calendar>
        </template>
        <template v-else-if="currentView === 'slots'">
            <availability-slots :slots="therapist_slots"
                                :selected="selected_slot"
                                v-on:switchView="switchView"
                                v-on:applyTime="applyTime"
            ></availability-slots>
        </template>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import BookingForm from "./BookingForm";
    import AvailabilityCalendar from "./AvailabilityCalendar"
    import AvailabilitySlots from "./AvailabilitySlots"
    import Team from "../../views/Team";
    import therapyHelper from "@/helpers/app/therapyHelper";
    export default {
        name: "AddBooking",
        components: {
            Team,
            BookingForm,
            AvailabilityCalendar,
            AvailabilitySlots
        },
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            action: {
                type: String,
                required: true
            },
            booking: {
                type: Object,
                default: null
            },
            pre_selected_client: {
                type: Object,
                default: null
            },
            pre_selected_therapist: {
              type: Array,
              default: []
            }

        },
        data: () => ({
            currentView: 'form',
            id: null,
            clients: [],
            session_types: [],
            availability_dates: [],
            is_first_date: true,
            minimum_date: new Date().toISOString().substr(0, 10),
            selected_date: moment().format('YYYY-MM-DD'),
            selected_slot: null,
            therapist_slots: [],
            booking_data: {
                patient_id: null,
                therapist_id: null,
                therapist_slot_id: null,
                session_type_id: null,
                is_messaging: 0,
                payment_type: null,
                session_status: 'scheduled',
                recent_action: 'session_created',
                timezone: '',
                device_type: 'web'
            },
            selected_client: null,
            selected_therapist: null,
            selected_session_type: null,
            selected_payment_method: null,
            client_skip: 0,
            client_take: 10,
            client_keyword: '',
            client_card: null,
            client_insurance: null,
            isEverythingReady: false,
            showAvailability: false,
            isFirstDateCall: true,
            isCallbackActive: false,
            isFirstLoading: true
        }),
        created() {
            this.booking_data.timezone = this.timezone;
            this.booking_data.device_type = this.deviceType;
            if (this.pre_selected_client !== null || this.pre_selected_therapist !== null) {
              this.selected_client = this.pre_selected_client;
              this.clients = [this.selected_client];
              this.selected_therapist = this.pre_selected_therapist[0];
            }
            if ( this.action === 'reschedule' ) {
                this.booking_data = this.booking;
                this.selected_client = this.booking.client;
                this.clients = [this.selected_client];
                this.selected_therapist = this.booking.therapist;
                this.selected_session_type = this.booking.session_types;
                this.session_types = [this.booking.session_types];
                this.selected_date = moment(this.booking.slot.start_time).format('YYYY-MM-DD');
                this.selected_slot = this.booking.slot;
                this.is_first_date = false;
                this.getBookingData(['availability_dates']);
            }
            else {
                this.getInitialData();
            }
            this.isEverythingReady = true;
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            }
        },
        methods:{
            hide() {
                this.$emit('hide');
            },
            switchView (view) {
                this.currentView = view;
            },
            getClients() {
                let self = this;
                self.isCallbackActive = true;
                this.$http.get(self.url+'users', {
                    params: {
                        skip: self.client_skip,
                        take: self.client_take,
                        keyword: self.client_keyword,
                        type: 'client'
                    }
                }).then(function (response) {
                    if ( self.client_skip === 0 ) {
                        self.clients = response.data.users;
                    }
                    else {
                        self.clients = self.clients.concat(response.data.users);
                    }
                    self.isCallbackActive = false;
                }).catch(function (error) {
                    self.isCallbackActive = false;
                });
            },
            getBookingData(required_data) {
                let self = this;
                let loader = null;
                if ( !required_data.includes('payment_details') ) {
                    loader = self.$loading.show();
                }
                let params = {
                    required_data: required_data.join(','),
                    timezone: self.timezone,
                    device_type: self.deviceType
                };
                if ( required_data.includes('availability_dates') ) {
                    params.therapist_id = self.booking_data.therapist_id;
                    params.session_type_id = self.booking_data.session_type_id;
                    params.is_first_date = self.is_first_date;
                    if (!self.is_first_date  ) {
                        params.date = self.selected_date;
                    }
                }
                if ( required_data.includes('payment_details') ) {
                    params.patient_id = self.booking_data.patient_id;
                }
                if ( self.action === 'reschedule' ) {
                    params.exact_date = 1;
                }
                self.$http.get(self.url+'booking_data', {
                    params: params
                }).then(function (response) {
                    if ( loader ) {
                        loader.hide();
                    }
                    if ( response.data.hasOwnProperty('session_types') ) {
                        self.session_types = response.data.session_types;
                    }
                    if ( response.data.hasOwnProperty('availability_dates') ) {
                        self.selected_date = response.data.availability_dates.date;
                        self.therapist_slots = response.data.availability_dates.slots;
                        self.availability_dates = response.data.availability_dates.availability;
                        self.showAvailability = true;
                    }
                    if ( response.data.hasOwnProperty('card') ) {
                        self.client_card = response.data.card;
                    }
                    if ( response.data.hasOwnProperty('insurance') ) {
                        self.client_insurance = response.data.insurance;
                    }
                    self.isFirstLoading = false;
                }).catch(function (error) {
                    if ( loader ) {
                        loader.hide();
                    }
                });
            },
            loadMore(type) {
                const self = this;
                if ( !self.isCallbackActive ) {
                    switch (type) {
                        case 'client':
                            self.client_skip = self.client_skip + self.client_take;
                            self.getClients();
                            break;
                        default:
                            break
                    }
                }
            },
            search(query, type) {
                const self = this;
                switch (type) {
                    case 'client':
                        self.client_skip = 0;
                        self.client_keyword = query;
                        self.getClients();
                        break;
                    default:
                        break
                }
            },
            clientSelected(client) {
                this.selected_client = client;
            },
            therapistSelected(therapist) {
                this.selected_therapist = therapist;
            },
            sessionTypeSelected(session_type) {
                this.selected_session_type = session_type;
            },
            paymentMethodSelected(payment_method) {
                this.selected_payment_method = payment_method;
            },
            applyDate(date) {
                this.selected_date = date;
                this.switchView('form');
            },
            applyTime(slot) {
                this.selected_slot = slot;
                this.booking_data.therapist_slot_id = slot.slot_id;
                this.switchView('form');
            },
            addBooking(booking_data) {
                this.booking_data = booking_data;
                const eventName = this.action === 'reschedule' ? 'reschedule' : 'addBooking';
                this.$emit(eventName, this.booking_data, this.id);
            },
            getInitialData() {
                let self = this;
                let promise = new Promise(function(resolve, reject) {
                    resolve();
                });

                promise.then(function(result) {
                    self.getClients();
                });

                promise.then(function(result) {
                    self.getBookingData(['session_types']);
                });
            }
        },
        watch: {
            'selected_client': function (client) {
                if ( client && !this.isFirstLoading ) {
                    this.getBookingData(['payment_details']);
                }
            },
            'selected_session_type' : function (session_type) {
                this.booking_data.is_messaging = session_type.method === therapyHelper.sessionTypeMethodCoaching;
            },
            'booking_data.is_messaging' : function (is_messaging) {
                this.isFirstDateCall = true;
                if ( is_messaging ) {
                    this.booking_data.session_status = 'subscribed';
                    this.booking_data.recent_action = 'subscribed';
                }
                else {
                    this.booking_data.session_status = 'scheduled';
                    this.booking_data.recent_action = 'session_created';
                }
            },
            'booking_data.therapist_id' : function () {
                if ( !this.isFirstLoading && this.booking_data.therapist_id && this.booking_data.session_type_id && this.selected_session_type.method !== therapyHelper.sessionTypeMethodCoaching ) {
                    this.is_first_date = true;
                    this.selected_date = null;
                    this.selected_slot = null;
                    this.getBookingData(['availability_dates']);
                }
            },

            'booking_data.session_type_id' : function () {
                if ( !this.isFirstLoading && this.booking_data.therapist_id && this.booking_data.session_type_id && this.selected_session_type.method !== therapyHelper.sessionTypeMethodCoaching ) {
                    this.is_first_date = true;
                    this.selected_date = null;
                    this.selected_slot = null;
                    this.getBookingData(['availability_dates']);
                }
            },
            'selected_date' : function () {
              if ( this.action === 'add' || !this.isFirstDateCall || !this.isFirstLoading) {
                this.selected_slot = null;
                this.booking_data.therapist_slot_id = null;
              }
              this.is_first_date = false;
              if (!this.isFirstLoading) {
                this.getBookingData(['availability_dates']);
                this.isFirstDateCall = false;
              }
            }
        }
    }
</script>
<style scoped>
    .v-btn--disabled{
        background-color: #FFFFFF !important;
    }
    .v-btn__content {
        color: #D4D4D4 !important;
    }
</style>
