<template>
    <v-dialog v-model="show" persistent max-width="900px" min-height="263px" v-else>
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modalTitle}}</h1>
                <img class="close-model" alt="close" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>
                <template v-if="transactions.length > 0">
                    <div class="row">
                        <div class="col-sm-1 font-weight-bold">Id</div>
                        <div class="col-sm-2 font-weight-bold">Client Name</div>
                        <div class="col-sm-2 font-weight-bold">Source</div>
                        <div class="col-sm-2 font-weight-bold">Status</div>
                        <div class="col-sm-2 font-weight-bold">Price</div>
                        <div class="col-sm-3 font-weight-bold">Payment Date</div>
                    </div>
                    <hr>
                    <div class="row mb-2" v-for="transaction in transactions">
                        <div class="col-sm-1">s{{transaction.subscription_id}}</div>
                        <div class="col-sm-2"><client-profile-link :rowData="rowData"></client-profile-link></div>
                        <div class="col-sm-2">{{transaction.source}}</div>
                        <div class="col-sm-2">{{transaction.status}}</div>
                        <div class="col-sm-2">{{transaction.charges}}</div>
                        <div class="col-sm-3">{{formatDate(transaction.payment_date)}}</div>
                    </div>
                </template>
                <template v-else>
                    <p class="font-weight-bold text-xs-center">There are no transactions against this subscription</p>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ClientProfileLink from "../common/dataTable/ClientProfileLink";
    import moment from "moment";
    export default {
        name: "SubscriptionTransactions",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            },
        },
        components: {
            ClientProfileLink
        },
        data () {
            return {
                modalTitle : 'Subscription Transactions',
                transactions: this.rowData.transactions,
                items: [
                    { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                    { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                    { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
                    { age: 38, first_name: 'Jami', last_name: 'Carney' }
                ]
            }
        },
        mounted() {
          console.log(this.rowData);
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide(){
                this.$emit('hide')
            },
            formatDate(time) {
                const format = 'DD/MM/YYYY hh:mm A';
                if (time === null) {
                    return '';
                } else {
                    if (time.indexOf(' ') !== -1) {
                        const utcTime = time.replace(' ', 'T') + 'Z'
                        return moment(utcTime).tz(moment.tz.guess()).format(format)
                    } else {
                        return moment(time).tz(moment.tz.guess()).format(format)
                    }
                }
          },
        },
    }
</script>