import userRoles from '../helpers/app/userRoleHelper'

/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

let routes = [
    {
        path:'/invitation/signup',
        view: 'SignUp',
        meta: {
            requiresAuth: false
        }
    },
    {
        path:'/login',
        view: 'Login',
        name: 'Login',
        meta: {
            requiresAuth: false
        }
    },
    {
      path:'/forgot-password',
      view: 'ForgotPassword',
      meta: {
        requiresAuth: false
      },
    },
    {
      path:'/reset-password',
      view: 'ResetPassword',
      meta: {
        requiresAuth: false
      },

    },
    {
        path:'/add-phone-number',
        view: 'Account/AddPhoneNumber',
        meta: {
            requiresAuth: false,
        },

    },
    {
        path:'/edit-phone-number',
        view: 'Account/EditPhoneNumber',
        meta: {
            requiresAuth: true
        },

    },
    {
        path:'/profile',
        view: 'Account/Profile',
        meta: {
            requiresAuth: true
        },

    },
    {
        path:'/expired-password-change',
        view: 'ExpiredPasswordChange',
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/dashboard',
        // Relative to /src/views,
        name: 'Dashboard',
        view: 'Dashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/schedule',
        name: 'Schedule',
        view: 'Schedule',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/bookings',
        name: 'Bookings',
        view: 'Bookings',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_booking',
        name: 'Add Bookings',
        view: 'AddBookingFlow',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subscriptions',
        name: 'Subscriptions',
        view: 'Subscriptions',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documents/:clientId',
        name: 'All Client Documents',
        view: 'AllDocuments',
        meta: {
            requiresAuth: true
        }
    },
    // org routes
    {
        path: '/org_console',
        name: 'OrgConsole Console',
        view: 'OrgConsole',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapyPlanActions',
        name: 'Therapy Plan Actions',
        view: 'TherapyPlanActions',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manageOrgs',
        name: 'Manage Orgs',
        view: 'OrgConsole/ManageOrgs',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manageVouchers',
        name: 'Manage Vouchers',
        view: 'OrgConsole/ManageVouchers',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manageVoucherCodes/:id',
        name: 'Manage Voucher Codes',
        view: 'OrgConsole/ManageVoucherCodes',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/managePlans',
        name: 'Manage Plans',
        view: 'OrgConsole/ManagePlans',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/managePlansV2',
        name: 'Manage PlansV2',
        view: 'OrgConsole/ManagePlansV2',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/manageOrgPlans',
        name: 'Manage Org Plans',
        view: 'OrgConsole/ManageOrgPlans',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subscription/details/:id',
        name: 'Subscription Details',
        view: 'People/SubscriptionDetails',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clients',
        view: 'Clients',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapists',
        view: 'Therapists',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/promoters',
        view: 'Promoters',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/insurance',
        view: 'Insurance',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transactions',
        view: 'Transactions',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/selfCareTransactions',
        view: 'SelfCareTransactions',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transfers',
        view: 'Transfers',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/drafts',
        view: 'Drafts',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/draft/detail/:id',
        name: 'Draft Details',
        view: 'People/DraftDetail',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Invoices',
        view: 'Invoices',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/invoice/detail/:id',
        name: 'Invoice Details',
        view: 'People/InvoiceDetail',
        meta: {
            requiresAuth: true
        }
    },
    /*{
        path: '/payouts',
        view: 'Payouts',
        meta: {
            requiresAuth: true
        }
    },*/
    {
        path: '/payouts',
        view: 'Bills',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payout/details/:id',
        name: 'Payout Details',
        view: 'People/BillDetail',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/chat',
        view: 'Chat',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notifications',
        view: 'Notifications',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/emails',
        view: 'Emails',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notificationLogs',
        view: 'NotificationLogs',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/pricing',
        view:'OrgConsole/Pricing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/therapyPricingItems/:id',
        view:'OrgConsole/TherapyPricingItems',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/selfCarePricingItems/:id',
        view:'OrgConsole/SelfcarePricingItems',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/issues',
        view:'Issues',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/treatments',
        view:'Treatments',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/sessionTypes',
        view:'SessionTypesV2',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/sessionPackages',
        view:'SessionPackages',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/selfCareCollection',
        view:'SelfCareCollection',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/selfCareCourses',
        view:'SelfCareCourses',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/selfCareSkills',
        view:'SelfCareSkills',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/selfCareSkills/requirements',
        view:'Configuration/SelfCareSkillRequirements',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/pgInterviews',
        view:'SelfCarePGInterviews',
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/team',
        view:'Team',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/client/details/:id',
        name: 'Client Details',
        view: 'People/ClientDetails',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapist/details/:id',
        name: 'Therapist Details',
        view: 'People/TherapistDetails',
        meta: {
            requiresAuth: true
        }
    }
];
var user = JSON.parse(localStorage.getItem('user'));
if (user && [userRoles.support].includes(user.user_type)) {
    routes = [
        {
            path:'/invitation/signup',
            view: 'SignUp',
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/login',
            view: 'Login',
            meta: {
                requiresAuth: false
            }
        },
        {
            path:'/forgot-password',
            view: 'ForgotPassword',
            meta: {
                requiresAuth: false
            },
        },
        {
            path:'/reset-password',
            view: 'ResetPassword',
            meta: {
                requiresAuth: false
            },

        },
        {
            path:'/add-phone-number',
            view: 'Account/AddPhoneNumber',
            meta: {
                requiresAuth: false
            },

        },
        {
            path:'/edit-phone-number',
            view: 'Account/EditPhoneNumber',
            meta: {
                requiresAuth: true
            },

        },
        {
            path:'/profile',
            view: 'Account/Profile',
            meta: {
                requiresAuth: true
            },

        },
        {
            path:'/expired-password-change',
            view: 'ExpiredPasswordChange',
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            view: 'Dashboard',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/schedule',
            name: 'Schedule',
            view: 'Schedule',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bookings',
            name: 'Bookings',
            view: 'Bookings',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/subscriptions',
            name: 'Subscriptions',
            view: 'Subscriptions',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/therapyPlanActions',
            name: 'Therapy Plan Actions',
            view: 'TherapyPlanActions',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/manageOrgs',
            name: 'Manage Orgs',
            view: 'OrgConsole/ManageOrgs',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/manageVouchers',
            name: 'Manage Vouchers',
            view: 'OrgConsole/ManageVouchers',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/manageVoucherCodes/:id',
            name: 'Manage Voucher Codes',
            view: 'OrgConsole/ManageVoucherCodes',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/managePlans',
            name: 'Manage Plans',
            view: 'OrgConsole/ManagePlans',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/subscription/details/:id',
            name: 'Subscription Details',
            view: 'People/SubscriptionDetails',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/clients',
            view: 'Clients',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/therapists',
            view: 'Therapists',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/client/details/:id',
            name: 'Client Details',
            view: 'People/ClientDetails',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/therapist/details/:id',
            name: 'Therapist Details',
            view: 'People/TherapistDetails',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/promoters',
            view: 'Promoters',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/insurance',
            view: 'Insurance',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/transactions',
            view: 'Transactions',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/transfers',
            view: 'Transfers',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/drafts',
            view: 'Drafts',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/draft/detail/:id',
            name: 'Draft Details',
            view: 'People/DraftDetail',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Invoices',
            view: 'Invoices',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/invoice/detail/:id',
            name: 'Invoice Details',
            view: 'People/InvoiceDetail',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/payouts',
            view: 'Bills',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/payout/details/:id',
            name: 'Payout Details',
            view: 'People/BillDetail',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/chat',
            view: 'Chat',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/notifications',
            view: 'Notifications',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/emails',
            view: 'Emails',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/notificationLogs',
            view: 'NotificationLogs',
            meta: {
                requiresAuth: true
            }
        },
    ]
}

export default routes;
