<template>
  <v-dialog v-model="show" persistent max-width="750px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="MyOnlineTherapy" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100">

              <!-- plan -->
              <h6 v-if="actionType === 'edit'" class="org-label">Voucher entitlement details:</h6>
              <label class="w-100">
                <span class="org-label form-label">Select Plan</span>
                <select v-if="actionType !== 'edit'" class="input-select w-100" v-model="voucher.plan_id">
                  <option value="" disabled>Select plan</option>
                  <template v-for="plan in plans">
                    <option :value="plan.id" :key="plan.id">{{ plan.title }}</option>
                  </template>
                </select>
                <input v-else class="input-text w-100 text-light-gray" v-model="'P'+value.plan_id" readonly/>
              </label>

              <!-- Organisation -->
              <label class="w-100">
                <span class="org-label form-label">Select Org</span>
                <select v-if="actionType !== 'edit'" class="input-select w-100" v-model="voucher.org_id">
                  <option value="" disabled>Select organisation</option>
                  <template v-for="org in orgs">
                    <option :value="org.id" :key="org.id">{{ org.name }}</option>
                  </template>
                </select>
                <input v-else class="input-text w-100 text-light-gray" v-model="value.org.name" readonly/>
              </label>

              <!-- Entitlement Detail -->

              <h6 v-if="actionType !== 'edit'" class="org-label">Voucher entitlement details:</h6>
              <div class="main-container mb-1" :class="[actionType !== 'edit' && actionType !== 'add' ? 'mt-2': '']">
                <div class="w-100">
                  <label class="w-100">
                    <span class="org-label form-label">Type</span>
                    <select v-if="actionType !== 'edit'  && actionType !== 'add'" name="type" class="input-select w-100"
                            v-model="voucher.voucher_type">
                      <option value="" disabled>Select type</option>
                      <option value="validated">Validated</option>
                      <option value="promo">Promo</option>
                      <option value="license">License</option>
                    </select>
                    <input v-else class="input-text w-100 text-light-gray" v-model="value.voucher_type" readonly/>
                  </label>
                </div>
              </div>

              <div class="main-container my-1">
                <div class="form-group w-100">
                  <label>
                    <span class="org-label form-label">Use</span>
                    <select v-if="actionType !== 'edit' && actionType !== 'add'" name="type" class="input-select"
                            v-model="voucher.voucher_use">
                      <option value="" disabled>Select use</option>
                      <option value="single-use">Single-use</option>
                      <option value="multiple-use">Multiple-use</option>
                    </select>
                    <input v-else class="input-text w-100 text-light-gray" v-model="value.voucher_use" readonly/>
                  </label>
                </div>
              </div>

              <!-- Number of vouchers-->

              <div class="main-container my-2" v-if="actionType !== 'edit'">
                <div class="flex-display flex-column">
                  <label class="form-label pb-2">Number of vouchers</label>
                  <div class="flex-display flex-row align-center">
                    <input v-model="voucher.number_of_vouchers"
                           class="input-text w-100px"
                           placeholder="Number">
                    <div class="toggle-switch ml-2" v-if="actionType !== 'add'">
                      <label class="org-label light-label switch-label">Unlimited &nbsp;</label>
                      <label class="switch">
                        <input type="checkbox" v-model="voucher.is_unlimited" true-value="1" false-value="0">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Duration -->

              <div class="my-3">
                <div class="w-100">
                  <div class="flex-row flex-display justify-start align-center">
                    <label class="form-label w-215px">Duration from activation </label>
                    <label class="form-label pl-3 pr-2">Duration </label>
                    <template v-if="actionType !== 'edit'  && actionType !== 'add'">
                      <input v-model="voucher.activation_duration.number" class="input-text w-100px"
                             placeholder="Number">
                      <select name="type" class="input-select w-215px ml-2" v-model="voucher.activation_duration.unit">
                        <option value="" disabled>days, weeks, months</option>
                        <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option>
                      </select>
                    </template>
                    <template v-else>
                      <input class="input-text w-100px text-light-gray" :value="voucher.activation_duration.number"
                             readonly/>
                      <input class="input-text w-215px ml-2 text-light-gray" :value="voucher.activation_duration.unit"
                             readonly/>
                    </template>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="w-100">
                  <div class="flex-row flex-display justify-start align-center">
                    <label class="form-label w-215px">Duration from assignment </label>
                    <label class="form-label pl-3 pr-2">Duration </label>
                    <template v-if="actionType !== 'edit' && actionType !== 'add'">
                      <input v-model="voucher.assignment_duration.number" class="input-text w-100px"
                             placeholder="Number">
                      <select name="type" class="input-select w-215px ml-2" v-model="voucher.assignment_duration.unit">
                        <option value="" disabled>days, weeks, months</option>
                        <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option>
                      </select>
                    </template>
                    <template v-else>
                      <input class="input-text w-100px text-light-gray" :value="voucher.assignment_duration.number"
                             readonly/>
                      <input class="input-text w-215px ml-2 text-light-gray" :value="voucher.assignment_duration.unit"
                             readonly/>
                    </template>
                  </div>
                </div>
              </div>

              <div class="mb-4">
                <div class="w-100">
                  <div class="flex-row flex-display justify-start align-center">
                    <label class="form-label w-215px">Duration from creation </label>
                    <label class="form-label pl-3 pr-2">Duration </label>
                    <template v-if="actionType !== 'edit' && actionType !== 'add'">
                      <input v-model="voucher.creation_duration.number" class="input-text w-100px" placeholder="Number">
                      <select name="type" class="input-select w-215px ml-2" v-model="voucher.creation_duration.unit">
                        <option value="" disabled>days, weeks, months</option>
                        <option value="days">days</option>
                        <option value="weeks">weeks</option>
                        <option value="months">months</option>
                      </select>
                    </template>
                    <template v-else>
                      <input class="input-text w-100px text-light-gray" :value="voucher.creation_duration.number"
                             readonly/>
                      <input class="input-text w-215px ml-2 text-light-gray" :value="voucher.creation_duration.unit"
                             readonly/>
                    </template>
                  </div>
                </div>
              </div>

              <!-- If the Admin edits a voucher code -->

              <template v-if="actionType === 'edit'">
                <div class="main-container mt-4 mb-3">
                  <div class="w-100">
                    <label class="w-100">
                      <span class="org-label form-label">Membership number</span>
                      <input class="input-text w-100" v-model="voucher.user_membership_id"
                             placeholder="Enter membership number"/>
                    </label>
                  </div>
                </div>

                <!--Claim number -->
                <div class="main-container mb-3">
                  <div class="w-100">
                    <label class="w-100">
                      <span class="org-label form-label">Claim Number</span>
                      <input class="input-text w-100" placeholder="Enter Claim Number" v-model="voucher.claim_number" />
                    </label>
                  </div>
                </div>


                <!-- DOB -->
                <div class="main-container mb-3">
                  <div class="w-100">
                    <label class="w-100">
                      <span class="org-label form-label">DOB</span>
                      <date-picker class="p-0 w-100" :date="voucher.date_of_birth" ref="dob"
                                   :placeholder="'Enter date of birth'" :org="true"
                                   v-on:valueUpdated="updateDateOfBirth"></date-picker>
                    </label>
                  </div>
                </div>

                <!-- Assignment Date -->
                <div class="main-container mb-3">
                  <div class="w-100">
                    <label class="w-100">
                      <span class="org-label form-label">Assignment date</span>
                      <date-picker class="p-0 w-100" :date="voucher.assignment_date" ref="assignment_date"
                                   :placeholder="'Enter assignment date'" :org="true"
                                   v-on:valueUpdated="updateAssignmentDate"></date-picker>
                    </label>
                  </div>
                </div>

              </template>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" :class="[actionType === 'edit' ? 'w-215px': '']"
                @click="createVouchers">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import regexHelper from "@/helpers/regexHelper";
import DatePicker from "@/components/common/DatePicker";

export default {
  name: "addVoucher",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      default: "create",
    },
    value: null,
  },
  components: {
    DatePicker,
  },
  data: () => ({
    modalTitle: 'Create vouchers',
    actionButtonTitle: 'Create vouchers',
    voucher: {
      plan_id: "",
      org_id: "",
      voucher_type: "",
      voucher_use: "",
      number_of_vouchers: 0,
      is_unlimited: 0,
      activation_duration: {
        number: 0,
        unit: ""
      },
      assignment_duration: {
        number: 0,
        unit: ""
      },
      creation_duration: {
        number: 0,
        unit: ""
      },
      user_membership_id: "",
      date_of_birth: null,
      assignment_date: null,
      claim_number: ""
    },
    plans: [],
    orgs: [],
    disable: false,
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },

  },
  created() {
    if (this.value) {
      this.getVoucherDetails();
    }
    this.getCreateVoucherData();
    console.log(this.actionType);
  },
  methods: {
    getVoucherDetails() {
      let loader = this.$loading.show();
      this.$http.get(this.url + 'partner_voucher?id=' + this.value.id).then(response => {
        this.voucher = response.data.voucher;
        if (this.actionType === 'add') {
          this.voucher.number_of_vouchers = 0;
        } else if (this.actionType === 'edit') {
          this.modalTitle = 'Edit voucher ' + this.voucher.voucher_title;
          this.actionButtonTitle = 'Add membership details';
        }
        loader.hide();
      }).catch(error => {
        loader.hide();
      })
    },
    getCreateVoucherData() {
      let loader = this.$loading.show();
      this.$http.get(this.url + 'create_voucher_data/get').then(response => {
        this.plans = response.data.plans;
        this.orgs = response.data.orgs;
        loader.hide();
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });

    },
    hide() {
      this.$emit('hide');
    },
    createVouchers() {
      if (this.validateSubmitRequest()) {
        let loader = this.$loading.show();
        this.voucher.action = this.actionType;
        if (this.actionType === 'edit') {
          this.url = this.url + 'add_membership_details';
        } else {
          this.url = this.url + 'partner_vouchers';
        }
        this.$http.put(this.url, this.voucher).then(response => {
          loader.hide();
          this.$toastr('success', response.data.message, '');
          this.$events.fire('vuetable:reload');
          this.hide();
        }).catch(error => {
          loader.hide();
          this.$toastr('error', error.response.data.message, '');
        });
      }
    },
    updateAssignmentDate(date) {
      this.voucher.assignment_date = date;
    },
    updateDateOfBirth(date) {
      this.voucher.date_of_birth = date;
    },
    validateSubmitRequest() {
      if (this.actionType === 'edit') {
        if (!this.voucher.user_membership_id &&
            !this.voucher.date_of_birth &&
            !this.voucher.assignment_date
        ) {
          this.showAlert('Empty form cannot be submitted.');
          return false;
        } else if (this.voucher.user_membership_id && !this.voucher.date_of_birth) {
          this.showAlert('Please select date of birth.');
          return false;
        } else if (this.voucher.date_of_birth && !this.voucher.user_membership_id) {
          this.showAlert('Please enter membership number.');
          return false;
        } else if (this.voucher.date_of_birth && this.voucher.user_membership_id) {
          return true;
        }
      }
      if (this.voucher.plan_id === "") {
        this.showAlert('Please select a plan.');
        return false;
      }
      if (this.voucher.org_id === "") {
        this.showAlert('Please select an organization.');
        return false;
      }
      return true;
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    },
  },
  watch: {
    'voucher.number_of_vouchers': function (value) {
      if (this.actionType !== 'edit') {
        this.voucher.number_of_vouchers = regexHelper.filterNumericOnly(value);
      }
    },
    'voucher.duration_numbers': function (value) {
      this.voucher.duration_numbers = regexHelper.filterNumericOnly(value);
    },
  }
}
</script>
