<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading pt-4 pl-4">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="closeIcon" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text class="pt-0">
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="courseForm"
            >
              <!-- Featured Image -->
              <div class="form-group w-100">
                <label>
                  <span class="form-label">Featured Image</span>
                  <input class="input-text"
                         type="text"
                         name="featured_image"
                         v-model="course.featured_image"
                         placeholder="Enter Image Link"
                  />
                </label>
              </div>

              <!-- Course title -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Enter Title</span>
                  <input class="input-text"
                         type="text"
                         name="title"
                         v-model="course.title"
                         placeholder="Enter Title"
                  />
                </label>
              </div>

              <!-- Goal Description -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Description (90 character max)</span>
                  <input class="input-text"
                         type="text"
                         name="description"
                         v-model="course.description"
                         placeholder="Enter Description"
                         maxlength=90
                  />
                </label>
              </div>

              <!--Intro audio title-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio title</span>
                  <input class="input-text"
                         type="text"
                         name="audio_title"
                         v-model="course.intro.title"
                         placeholder="Enter audio title"
                  />
                </label>
              </div>

              <!--Intro audio description-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio description</span>
                  <input class="input-text"
                         type="text"
                         name="audio_description"
                         v-model="course.intro.bottom_desc"
                         placeholder="Enter audio description"
                  />
                </label>
              </div>

              <!--Intro audio (portrait) -->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio (portrait)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="course.intro.portrait_url"
                         placeholder="Enter audio link"
                  />
                </label>
              </div>

              <!-- Intro audio (landscape)-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Intro audio (landscape)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="course.intro.landscape_url"
                         placeholder="Enter audio link"
                  />
                </label>
              </div>

              <!--Intro video title-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video title</span>
                  <input class="input-text"
                         type="text"
                         name="video_title"
                         v-model="course.how_to.title"
                         placeholder="Enter video title"
                  />
                </label>
              </div>

              <!--Intro video description-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video description</span>
                  <input class="input-text"
                         type="text"
                         name="video_description"
                         v-model="course.how_to.bottom_desc"
                         placeholder="Enter video description"
                  />
                </label>
              </div>

              <!--Intro video thumbnail-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video thumbnail link</span>
                  <input class="input-text"
                         type="text"
                         name="video_thumbnail_link"
                         v-model="course.how_to.thumbnail_url"
                         placeholder="Enter video Thumbnail link"
                  />
                </label>
              </div>

              <!--video (landscape)-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">‘How to’ video (landscape)</span>
                  <input class="input-text"
                         type="text"
                         name="audio"
                         v-model="course.how_to.video_url"
                         placeholder="Enter video link"
                  />
                </label>
              </div>

              <!--Keywords-->
              <div class="form-group w-48">
                <label>
                  <span class="form-label">Keywords</span>
                  <keywords-component :keywords="course.keywords"
                                      v-on:update="updateKeywords"
                  ></keywords-component>
                </label>
              </div>
              <!--Course modalities-->
              <!--              <div class="form-group w-48">-->
              <!--                <label>-->
              <!--                  <span class="form-label">Course modalities</span>-->
              <!--                  <input class="input-text"-->
              <!--                         type="text"-->
              <!--                         name="audio"-->
              <!--                         v-model="course.modality"-->
              <!--                         placeholder="Select modalities"-->
              <!--                  />-->
              <!--                </label>-->
              <!--              </div>-->

              <!-- Add Module Title -->
              <div class="w-100">
                <div class="form-group w-48 left">
                  <label class="form-label fs-16 lh-16 bold-font self-course-title">Add a module
                  </label>
                </div>

                <!-- Add Module Button-->

                <div class="flex-display flex-row flex-justify-end mb-3 w-48 right">
                  <a class="right add-module-btn font-weight-bold fs-14" @click="addNewModule()"><em
                      class="v-icon mdi mdi-plus width-15 height-20"></em> Add module
                  </a>
                </div>
              </div>

              <draggable  :list="course.modules" class="w-100" @end="onEnd">
                <add-module
                    v-for="(module, index ) in course.modules"
                    :key="module.id"
                    :index="index"
                    :module="module"
                    :modules="course.modules"
                    :exercises="exercises"
                    @removeModule="deleteModule"
                    @refresh-module="refreshModule"

                >
                </add-module>
              </draggable>

              <!-- Status Toggle -->
              <div class="mt-4 w-48">
                <span class="form-label pt-2 pb-2">Status</span>
                <label class="switch-label font-weight-bold pr-3 fs-13 lh-13">Inactive/Active</label>
                <label class="switch m-l-93">
                  <input type="checkbox"
                         name="status"
                         v-model="course.status"
                         :true-value="'active'"
                         :false-value="'inactive'"
                  />
                  <span class="slider round"></span>
                </label>
              </div>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row m-t-25">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button width-118 height-41" @click="submitCourse">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MultiSelect from "vue-multiselect"
import KeywordsComponent from "../common/KeywordsComponent"
import draggable from "vuedraggable"
import AddModule from "./addModule"
import routeHelper from '../../helpers/app/routeHelper'

export default {
  name: "addCourse",
  components: {
    MultiSelect,
    KeywordsComponent,
    draggable,
    AddModule
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    actionType: {
      required: true,
      type: String
    },
    courses: {
      required: true,
      type: Array
    },
    exercises: {
      required: true,
      type: Array
    },
    value: null
  },
  data: () => ({
    modelHeader: "",
    modalTitle: "",
    actionButtonTitle: "",
    panel: [true, false],
    course: {
      featured_image: "",
      title: "",
      description: "",
      intro: {
        title:"",
        bottom_desc:"",
        portrait_url: "",
        landscape_url: "",
      },
      how_to: {
        title:"",
        bottom_desc:"",
        video_url: "",
        thumbnail_url: "",
      },
      keywords: [],
      modality: "",
      modules: [],
      status: "inactive",
      visible: 0,
    }
  }),
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit("close")
        }
      },
    }
  },
  created () {
    if (this.actionType === "add") {
      this.modalTitle = "Add Course"
      this.actionButtonTitle = "Save"
    } else {
      this.modalTitle = "Update Course"
      this.actionButtonTitle = "Update"
    }
    if (this.value.id) {
      this.course = {
        ...this.value,
      }
    }

  },
  methods: {
    deleteModule (i,type) {
      let modules = this.course.modules
      if(type === "index"){
        modules.splice(i,1);
      }else{
        modules=modules.filter(module=>module.id!==i);
      }
      this.course={
        ...this.course,
        modules
      }

    },
    hide () {
      this.$emit("hide")
    },
    submitCourse () {
      if (!this.validateSubmitRequest()) {
        return false;
      }
      let modules = this.course.modules.map(module=> {
        let exercise_ids = [];
        module.exercises.map(ex=>{
          exercise_ids.push(ex.id)

        })
        return {...module,exercise_ids}
      })
      let loader = this.$loading.show();
      this.$http.post(this.url + 'self_care/course', {
        ...this.course,
        modules

      }).then(response => {
        loader.hide();
        this.$toastr('success', response.data.message, '');
        this.$emit('submitCourse', response.data.course);
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });
    },
    validateSubmitRequest () {
      if (this.course.featured_image === "") {
        this.showAlert("Please enter Featured Image URL.")
        return false
      }

      if (this.course.title === "") {
        this.showAlert("Please enter title.")
        return false
      }

      if (this.course.description === "") {
        this.showAlert("Please enter description.")
        return false
      }

      if (this.course.status === "") {
        this.showAlert("Please select status.")
        return false
      }

      return true
    },
    showAlert (msg) {
      this.$toastr("error", msg)
    },
    updateKeywords (keywords) {
      this.course.keywords = keywords
    },
    addNewModule () {
      this.course.modules.push({
        title: "",
        description: "",
        exercises: [],
      })
    },
    refreshModule() {
      this.$emit("refresh-course");

    },
    onEnd (evt) {
      this.course.modules.map((module, index) => {
        module.order = index + 1
      })
      this.$http.put(this.url + routeHelper.sortModuleApi, {
        sorted_module: this.course.modules
      }).then(response => {
        this.$toastr('success', response.data.message, '')
      })
    },
  }
}
</script>
<style scoped>
.self-course-title {
  color: #283D47 !important;
}
.add-module-btn {
  height: 35px;
  width: 127px;
  border: 1px solid #959595;
  border-radius: 2px;
  box-sizing: border-box;
  color: #959595 !important;
  padding-top: 4px !important;
  padding-left: 13px !important;
}
.v-expansion-panel {
  box-shadow: none !important;
}
.v-expansion-panel__header {
  padding: 0 !important;
  border-bottom: 1px solid #E4E4E4;
}
.v-expansion-panel__container {
  border-top: none;
}

.btn.btn-module {
  width: auto;
  color: #959595 !important;
  background-color: #ffffff !important;
  border: 1px solid #959595 !important;
  border-radius: 2px;
}

</style>
