let countryHelper = {
    getCountryNameFromCountryCode(code, countries) {
        let countryName = code;
        countries.forEach((item) => {
            let name = item.text === 'United Kingdom of Great Britain and Northern Ireland' ? 'United Kingdom' : item.text;
            let value = item.text === 'United Kingdom of Great Britain and Northern Ireland' ? 'GB' : item.value;
            if (code.toLowerCase() === value.toLowerCase()) {
                countryName = name;
            }
        });
        return countryName;
    }
};

export default countryHelper;