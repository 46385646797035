<template>
  <div class="filters">
    <multi-select class="w-100"
                  v-model="selected"
                  :options="filters"
                  :multiple=true
                  label="name"
                  track-by="id"
                  :placeholder="placeholder">
    </multi-select>
  </div>
</template>

<script>
import MultiSelect from "vue-multiselect";

export default {
  components: {MultiSelect},
  props: {
    filterId: {
      required: true
    },
    filters: {
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select filter'
    },
    operator: {
      type: String,
      default: 'in'
    },
    column:{
      type: String,
      required: true
    },
    queryType: {
      default: 'text'
    },
    relationShipName: {
       default: ''
    },
    relationShipType: {
      default: ''
    }
  },
  data() {
    return {
      selected: [],
      selectedFilterData: {
        filter_id: '',
        constraints: {
          column: '',
          operator: 'all',
          value1: '',
          type: 'all',
          relationship_name: '',
          relationship_type: this.relationShipType
        },
      }
    }
  },
  methods: {
    selectFilter() {
      let values = [];
      this.selected.forEach(filter => {
        if(filter.constraints.type === "all") {
           values = [];
           return;
        } else {
          if (filter.filter_id === 'issues_filter' || filter.filter_id === 'treatments_filter' ) {
            values.push(filter.name)
          } else {
            values.push(filter.id)
          }
        }
      });

      if (values.length > 0) {
        this.selectedFilterData.filter_id = this.filterId;
        this.selectedFilterData.constraints.column = this.column;
        this.selectedFilterData.constraints.operator = this.operator;
        this.selectedFilterData.constraints.value1 = values.join(',');
        this.selectedFilterData.constraints.type = this.queryType;
        this.selectedFilterData.constraints.relationship_name =  this.relationShipName;
        this.selectedFilterData.constraints.relationship_type = this.relationShipType;
      } else {
        this.selectedFilterData.filter_id = this.filterId;
        this.selectedFilterData.constraints.column = '';
        this.selectedFilterData.constraints.operator = 'all';
        this.selectedFilterData.constraints.value1 = '';
        this.selectedFilterData.constraints.type = 'all';
      }
      this.reloadTable();
    },
    reloadTable() {
      this.$events.fire('filter-set', this.selectedFilterData);
    }
  },
  watch: {
    'selected': function () {
      this.selectFilter();
    }
  }
}
</script>
<style scoped>
.multiselect__tags {
  min-width: 200px;
  border: none !important;
}

.multiselect__content-wrapper {
  min-width: 200px;
}
</style>
