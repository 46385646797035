<template>
  <v-app>

    <template v-if="!$route.meta.requiresAuth">
      <router-view></router-view>
    </template>

    <template v-if="$route.meta.requiresAuth">
      <!--Top navBar -->
      <core-toolbar />
      <!-- Top sidebar -->
      <core-drawer v-if="this.$router.currentRoute.path !== '/expired-password-change'"/>
      <!-- main layout -->
      <core-view />

    </template>

  </v-app>
</template>

<script>
  export default {
    name: "App"
  }
</script>

<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
