<template>
  <recipient
    :default-user-image="defaultUserImage"
    :user_id="user_id"
    :recipient="channel"
    :type="'client'"
    :profile_image="isEmpty(channelUser.profileUrl) ? defaultUserImage : parseProfileImageUrl(channelUser.profileUrl)"
    :profile_header="!isEmpty(channelUser.nickname) ? channelUser.nickname : 'MOT User'"
    :header_time="channel.lastMessage ? channel.lastMessage.createdAt : ''"
    :profile_sub_header="channel.lastMessage ? channel.lastMessage.message : ''"
    :profile_text="''"
    :unread-message="channel.unreadMessageCount > 0"
    :remove_border="false"
    @openConversation="openConversation"
  />
</template>

<script>

    import recipient from './recipient'
    export default {
        components: {
            recipient
        },
        props: {
            'user_id': {
                required: true
            },
            'defaultUserImage': {
                required: true
            },
            'channel': {
                required: true
            }
        },
        data: function () {
            return {
                channelUser: null
            }
        },
      watch: {
        channel () {
          this.getAndSetChannelConnectedUser()
        }
      },
        created () {
            this.getAndSetChannelConnectedUser()
        },
        methods: {
            parseProfileImageUrl (url) {
              let parseResult = new DOMParser().parseFromString(url, 'text/html')
              return parseResult.documentElement.textContent
            },
            getAndSetChannelConnectedUser () {
                const self = this
                let members = self.channel.members
                self.channelUser = null
                let filteredMembers = members.filter(
                    member => member.userId !== self.environmentPrefix + self.user_id
                )
                self.channelUser = filteredMembers.length > 0
                    ? filteredMembers[0]
                    : {
                        ...members,
                        nickname: 'Deleted User',
                        profileUrl: ''
                    }
            },
            openConversation () {
                this.$emit('openConversation', this.channel)
            }
        }
    }
</script>
