<template>
  <div v-if="isReady">
  <div class="bookingFlow__BoxWidth bookingFlow__w-80 ma-auto">
    <div class="bookingFlow__boxShadow flex-column  justify-start bg-white bookingFlow__padding">
        <client-info-header
        :client="client"
        ></client-info-header>
        <divider></divider>
        <review-payment-method
            :planType="planType"
            :client="clientObj"
            :name="paymentMethodName"
            :description="paymentMethodDescription"
        ></review-payment-method>
        <alert-box v-if="showCardAlertBanner" :type="isCardInformationMissing ? 'error' : 'info'"
                   :msg-text="cardDetailText"></alert-box>
        <alert-box v-if="showInsuranceAlertBanner" :type="isInsuranceInformationMissing ? 'error' : 'info'"
                   :msg-text="insuranceDetailText"></alert-box>
        <divider></divider>
        <active-payment-plan
            :planType="planType"
            :activePlan="plan"
        ></active-payment-plan>
      </div>
      <div class="ma-auto m-t-80">
        <button
            class="d-inline-block white--text bg-blue-500  w-100 height-44 bold-font fs-16"
            :disabled="disabled" @click="continueBtn" :class="disabled ? 'opacity-5': 'bg-blue-500'">
          Confirm payment method
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClientInfoHeader from "../components/clientInfoHeader";
import ReviewPaymentMethod from "./reviewPaymentMethod";
import ActivePaymentPlan from "./activePaymentPlan";
import Divider from "../components/divider";
import AlertBox from "../components/alertBox";
import PopUp from "@/components/main/addBooking/components/popUp";
import NoPaymentDetailsDialogue from "@/components/main/addBooking/components/NoPaymentDetailsDialogue";
import TherapyHelper from "@/helpers/app/therapyHelper";

export default {
  name: "paymentMethod",
  components: {
    NoPaymentDetailsDialogue,
    PopUp,
    AlertBox,
    Divider,
    ActivePaymentPlan,
    ReviewPaymentMethod,
    ClientInfoHeader
  },
  props: {
    client: {
      type: Object,
      required: true
    },
    therapist: {
      required: true
    },
  },
  data() {
    return {
      isReady: false,
      disabled: false,
      cardDetailsRequired: false,
      showCardAlertBanner: false,
      isCardInformationMissing: false,
      insuranceDetailsRequired: false,
      showInsuranceAlertBanner: false,
      isInsuranceInformationMissing: false,
      insuranceDetailText : "",
      cardDetailText : "Card details are saved in case the client owes money due to a late cancellation, missed session or excess.",
      planTypeSelfPay: TherapyHelper.planTypeSelfPay,
      planTypeSelfPayDiscounted: TherapyHelper.planTypeSelfPayDiscounted,
      planType:"",
      paymentMethodName: "",
      paymentMethodDescription: "",
      plan:[],
      card:[],
      insurance:[],
      clientObj:[],
    }
  },
  created() {
    this.getClientPlanDetails();
  },
  methods: {
    getClientPlanDetails(){
      let loader = this.$loading.show();
      this.$http.get(this.url+'therapy/client-plan-details', {
        params: {
          client_id: this.client.id,
          user_id: this.therapist.id,
          timezone: "europe/london",
          device_type: this.deviceType
        }
      }).then(response => {
        this.planType = response.data.plan_type;
        this.insuranceDetailsRequired = response.data.insurance_details_required;
        this.cardDetailsRequired = response.data.card_details_required;
        this.insurance = response.data.insurance;
        this.plan = response.data.plan;
        this.card = response.data.card;
        this.clientObj = response.data.client;
        this.isReady = true;
        this.$emit("getPlanDetail",response.data);
        this.noPaymentMethod();
        this.paymentDetailData();
        this.warningInfoBox();
        this.isDisabled();
        loader.hide();
      }).catch(error =>{
        loader.hide();
        console.log(error)
        this.$toastr("error", error.response.data.message)
      });
    },
    continueBtn() {
      this.$emit("increment");
    },
    warningInfoBox() {
      if ([TherapyHelper.planTypeNonInsurance, TherapyHelper.planTypeInsurance].includes(this.planType)) {
        this.showCardAlertBanner = !(!this.cardDetailsRequired && this.insuranceDetailsRequired);
        if (this.cardDetailsRequired) {
          this.cardDetailText = this.client.first_name + " needs to add card details within their account before the booking can be made. This is required in case of a missed session or excess.";
          this.isCardInformationMissing = true;
        }
        if (this.insuranceDetailsRequired) {
          this.showInsuranceAlertBanner = true;
          this.isInsuranceInformationMissing = true;
          this.insuranceDetailText = "It is a requirement for " + this.client.first_name + " to add all their insurance details into their account before the booking can be made.";
        }
      }
    },
    isDisabled() {
      if (TherapyHelper.planTypePackage === this.planType && this.cardDetailsRequired) {
        this.disabled = false;
      } else {
        this.disabled = this.cardDetailsRequired || this.insuranceDetailsRequired;
      }
    },
    noPaymentMethod() {
      if([TherapyHelper.planTypeSelfPay, TherapyHelper.planTypeSelfPayDiscounted].includes(this.planType) && this.cardDetailsRequired){
        this.$emit("paymentMethodDialogue");
      }
    },
    paymentDetailData() {
      if (this.card && [TherapyHelper.planTypeSelfPay, TherapyHelper.planTypeSelfPayDiscounted].includes(this.planType)) {
        this.paymentMethodName =  this.card.brand.charAt(0).toUpperCase() + this.card.brand.slice(1);
        this.paymentMethodDescription = "..." + this.card.last4;
      } else {
        this.paymentMethodName = this.plan.heading;
        this.paymentMethodDescription = this.plan.sub_heading;
      }
    }
  }
}
</script>
