<!--
  1. Component to link the client profile
  2. Main purpose of it that when admin clicks on the client name in the booking table, it should
     redirect to client profile.
 -->
<template>
    <div class="item-actions">
        <a :href="'/client/details/'+rowData.client.id" target="_blank">{{rowData.client.full_name}}</a>
    </div>
</template>

<script>
    export default {
        name: "ClientProfileLink",
        props: {
            rowData: {
                type: Object,
                required: true
            },
        },
    }
</script>

<style scoped>

</style>