let numberHelper = {
    isEmpty(value) {
        return value == 0 || value === null || value === '';
    },
    allowUptoTwoDecimalPoint(val) {
        let num = val;
        if (val.includes('.')) {
            num = val.split('.')[0] + '.' + val.split('.')[1].slice(0, 2)
        }
        return num;
    },
    isNumber: (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
            return evt.preventDefault();
        }
        return true;
    },
    removeCharactorAtFirstPlace: (input, char) => {
        let s = input;
        while (s.charAt(0) === char) {
            s = s.substr(1);
        }
        return s;
    },

    //Function to get array of numbers between min and max values included.
    range: (min, max) => {
        let array = [],
            j = 0;
        for (let i = min; i <= max; i++) {
            array[j] = i;
            j++;
        }
        return array;
    }
};

export default numberHelper;
