<template>
  <div class="m-t-70 flex-display flex-column">
    <p class="bold-font blue-100 fs-17">
      Payout workflow
    </p>

    <p class="detail-item m-t-10">
      <label class="item-label">Payout status: </label>
      <span class="item-value"> {{ claim.payment_status }}</span>
    </p>
    <p class="detail-item m-t-8">
      <label class="item-label">Payment id: </label>
      <span class="item-value"> {{ claim.payment_id }}</span>
    </p>
    <p class="detail-item m-t-8">
      <label class="item-label">Fianlise date: </label>
      <span class="item-value"> {{ claim.finalise_date }}</span>
    </p>
    <p class="detail-item m-t-8">
      <label class="item-label">Sent date: </label>
      <span class="item-value"> {{ claim.sent_date }}</span>
    </p>
    <p class="detail-item m-t-8">
      <label class="item-label">Due date: </label>
      <span class="item-value"> {{ claim.due_date }}</span>
    </p>
    <p class="detail-item m-t-8">
      <label class="item-label">Date paid: </label>
      <span class="item-value"> {{ claim.paid_date }}</span>
    </p>
  </div>
</template>

<script>
export default {
    name: "PayoutWorkFlow",
    props: {
        claim: {
            required: true
        }
    }
}
</script>

<style scoped>
.item-label {
    color: #949494;
    font-family: MetropolisRegular;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
}

</style>
