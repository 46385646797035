<template>
    <div class="filters">
        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Actions
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item"
                   v-for="(action,index) in actions"
                   :key="index"
                   @click="selectAction(action)" >{{action.title}}
                </a>
            </div>
        </div>

        <confirmation v-if="showConfirmation" :visible="showConfirmation">
            <template slot="close-icon">
                <img class="close-model" src="/icons/close_ic.svg" @click="hideConfirmation"/>
            </template>
            <template slot="title">
                Are you sure?
            </template>
            <template slot="desc">
                {{deleteConfirmationMessage}}
            </template>
            <template slot="actions">
                <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                <button class="btn modal-action-button confirmation-delete" @click="performAction">Done</button>
            </template>
        </confirmation>
    </div>
</template>

<script>
    import Confirmation from "../../Confirmation";
    export default {
        components: {Confirmation},
        data () {
            return {
                showConfirmation: false,
                action:{},
                selectedData: []
            }
        },
        props: {
            actions: {
                required: true,
                type: Array
            },
            deleteConfirmationMessage: {
                type: String,
                default: 'This action cannot be undone.'
            }
        },
        created(){

        },
        methods: {
            selectAction(action) {
                this.showConfirmation = true;
                this.action = action;
                this.$events.fire('get-table-ref'); // get table reference, that reference will be used
            },
            performAction(){
                if( this.selectedData.length <=0 ) {
                        this.hideConfirmation();
                        alert('Please select data');
                        return;
                }
                let self = this;
                let loader = this.$loading.show();
                this.$http({
                    method: 'put',
                    url: this.url+this.action.url,
                    data: {
                        user_ids: this.selectedData,
                        value: this.action.value
                    }
                }).then(function (response) {
                    self.$toastr('success', response.data.message,'');
                    self.hideConfirmation();
                    self.$events.fire('vuetable:reload');
                    loader.hide();
                }).catch(function (error) {
                    self.$toastr('error', error.response.data.message,'');
                    loader.hide();
                });
            },
            hideConfirmation(){
                this.showConfirmation = false;
            }
        },
        events:{
            'get-selected-data': function (data) {
                if(data) {
                    this.selectedData = data;
                }
            }
        }
    }
</script>
<style>
    .dt-options-item {
        padding-left: 20px !important;
    }
    .selected-filter {
        left: 5px;
    }
</style>
