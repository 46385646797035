<template>
    <div class="filters">

        <create-custom-filters v-if="showCustomFilters"
                               :visible="showCustomFilters"
                               :fields="fields"
                               :table-name="tableName"
                               v-on:hide="hideCustomFiltersModal"
        ></create-custom-filters>

        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{selected}}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item create-new-item"
                   @click="showCustomFiltersModal()" >
                    <img src="icons/filters/plus.svg" width="14" alt="myonline therapy"/>
                    Create New Filter
                </a>
                <a class="dropdown-item"
                   v-for="(filter,index) in filters"
                   @click="selectFilter(filter)"
                   :key="index">
                    {{filter.name}}
                    <img v-if="filter.id" v-on:click.stop="deleteCustomFilter(filter)"
                         class="dropdown-item__img"
                         src="icons/delete_payout_ic.svg"
                         alt="myonline therapy"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateCustomFilters from "@/components/common/dataTable/filters/CreateCustomFilters";
    export default {
        name: "CustomFilters",
        components: {
            CreateCustomFilters
        },
        props: {
            tableName: {
                required: true,
                type: String
            },
            fields: [String, Array, Object],
            filters: {
                required: true,
                type: Array
            },
        },
        data () {
            return {
                showCustomFilters: false,
                selected:'Custom Options'
            }
        },
        created(){

        },
        methods: {
            selectFilter(filter) {
                this.selected = filter.name;
                this.$events.fire('filter-set', filter);
            },
            showCustomFiltersModal() {
                this.showCustomFilters = true;
            },
            hideCustomFiltersModal() {
                this.showCustomFilters = false;
            },
            deleteCustomFilter(filter){
               this.$events.fire('delete-filter', filter);
            }
        },
        events: {
            'close-custom-filter-modal': function() {
                this.showCustomFilters = false;
            },
            'apply-custom-filter': function(name){
                this.selected = name
            }
        }
    }
</script>

<style scoped>
   .dropdown-item {
       position: relative;
   }
   .dropdown-item__img{
       position: absolute;
       right: 0;
       cursor: pointer;
   }
</style>