export default {
    sortCollectionApi: 'self_care/collections/sort',
    sortCourseApi: 'self_care/course/sort',
    sortModuleApi: 'self_care/course/module/sort',
    deleteModuleApi: 'self_care/course/module/delete',
    cancelSessionApi: 'session/cancel',
    rescheduleSessionApi: 'session/reschedule',
    sessionStatusUpdateApi: 'session/status_update',
    availableTherapistsApi: 'dashboard/therapists/available_therapists'
}
