<template>
  <div class="flex-display flex-row flex-wrap flex-justify-between w-100">
    <div class="w-48 m-b-30">
      <label>
        <span class="form-label">Number of sessions requested</span>
        <input class="input-text disabled-input form-group__input"
               type="text"
               name="name"
               placeholder="Enter additional sessions"
               v-model="sessionRequest.requested_sessions"
               v-popover:requestSessionInputs.right
               @input="requestStatusChange(sessionRequest, false)"
               :readonly="!isFieldEditable('requested_sessions')"
        />
      </label>
    </div>
    <div class="w-48 m-b-30">
      <label>
        <span class="form-label">Date requested</span>
        <p class="height-41 neutral-dark-blue bold-font fs-13 lh-13 mb-0">{{sessionRequest.request_date}}</p>
      </label>
    </div>

    <div class="w-48 m-b-30">
      <label>
        <span class="form-label">Recommendation</span>
        <textarea class="input-textArea disabled-input form-group__input"
                  type="text"
                  name="name"
                  rows="4"
                  v-popover:requestSessionInputs.right
                  v-model="sessionRequest.request_detail"
                  @input="requestStatusChange(sessionRequest, false)"
                  :readonly="!isFieldEditable('recommendation')"
        />
      </label>
    </div>
    <div class="w-48 m-b-30">
      <label>
        <span class="form-label">Status of request</span>
        <select class="input-select"
                :class="{'error-border': request_status === requestPending && sessionRequest.status === requestPending}"
                name="status"
                required
                v-popover:requestStatusInputs.right
                v-model="sessionRequest.status"
                :disabled="!isFieldEditable('status')"
                @change="requestStatusChange(sessionRequest, true)"
        >
          <option value="" class="grey-600">Select request status</option>
          <option value="N/A">Not required</option>
          <option :value="requestPending">{{ requestPending }}</option>
          <option :value="requestSubmitted">{{ requestSubmitted }}</option>
          <option :value="requestApproved">{{ requestApproved }}</option>
          <option :value="requestPartiallyApproved">{{ requestPartiallyApproved }}</option>
          <option :value="requestRejected">{{ requestRejected }}</option>
        </select>
      </label>

      <div class="flex-display flex-row m-t-12" v-if="request_status === requestPending && sessionRequest.status === requestPending">
        <img src="/assets/images/red-info.png" width="16" height="16" alt="MyOnlineTherapy"/>
        <p class="red-300 m-l-8 fs-13">Submit request to Vitality and update status</p>
      </div>

      <div class="m-t-15" v-if="sessionRequest.status === requestPartiallyApproved">
        <label>
          <span class="form-label">Number of sessions approved by Vitality</span>
          <input class="input-text disabled-input form-group__input"
                 :class="{'error-border' : !sessionRequest.approved_sessions}"
                 type="text"
                 name="name"
                 v-model="sessionRequest.approved_sessions"
                 @input="requestStatusChange(sessionRequest, false)"
                 :readonly="!isFieldEditable('approved_sessions')"
          />
        </label>
        <div class="flex-display flex-row m-t-12" v-if="isEmpty(sessionRequest.approved_sessions)">
          <img src="/assets/images/red-info.png" width="16" height="16" alt="MyOnlineTherapy"/>
          <p class="red-300 m-l-8 fs-13">Add number of sessions approved by Vitality</p>
        </div>
      </div>
    </div>

    <div class="w-48">

    </div>
    <hr class="w-100 m-b-30">
      <popover name="requestSessionInputs" v-if="!additional_request_allowed || request_status === requestSubmitted" event="hover">
          <div class="flex-column">
              <p class="blue-500 fs-14 mb-0">
                  {{popoverText}}
              </p>
          </div>
      </popover>
      <popover name="requestStatusInputs" v-if="!additional_request_allowed" event="hover">
        <div class="flex-column">
          <p class="blue-500 fs-14 mb-0">
            {{popoverText}}
          </p>
        </div>
      </popover>
  </div>
</template>

<script>
import regexHelper from "@/helpers/regexHelper";
import stringHelper from "@/helpers/stringHelper";
import planHelper from "@/helpers/app/partner/planHelper";

export default {
  name: 'Details',
  components: {},
  props: {
    session_request: {},
    request_action: {},
    request_status: {},
    additional_request_allowed: {
        default: true
    },
    additional_request_trigger_session: {
        default: ""
    }
  },
  data() {
    return {
      requestPending: planHelper.requestPending,
      requestSubmitted: planHelper.requestSubmitted,
      requestApproved: planHelper.requestApproved,
      requestPartiallyApproved: planHelper.requestPartiallyApproved,
      requestRejected: planHelper.requestRejected,
      sessionRequest: null,
      popoverText: "",
    }
  },
  created() {
    this.sessionRequest = this.session_request;
    if (!this.additional_request_allowed) {
        this.popoverText = "Additional session requests are not possible until client completes "+ this.additional_request_trigger_session +" session and a treatment plan has been entered";
    } else if (this.request_status === this.requestSubmitted) {
        this.popoverText = "Cannot edit a request once it has been submitted";
    }
  },
  methods: {
    isEmpty(value) {
      return stringHelper.empty(value)
    },
    requestStatusChange(sessionRequest, isFromStatus) {
      this.$emit('requestUpdated', sessionRequest, isFromStatus);
    },
    isFieldEditable(field) {
      if (this.sessionRequest.is_locked) {
        return false;
      }
      let editable = this.sessionRequest.is_editable;
      if (field === 'status' && this.request_action === 'add') {
        editable = false;
      }
      let lockStatuses = [
        planHelper.requestSubmitted,
        planHelper.requestApproved,
        planHelper.requestPartiallyApproved,
        planHelper.requestRejected
      ]

      if (lockStatuses.includes(this.request_status) && field !== 'status') {
        editable = false;
      }
      if (this.request_status !== planHelper.requestPartiallyApproved && field === 'approved_sessions') {
        editable = true;
      }
      return editable;
    }
  },
  watch: {
    'sessionRequest.requested_sessions': function (value) {
      this.sessionRequest.requested_sessions = regexHelper.filterNumericOnly(value)
    },
    'sessionRequest.approved_sessions': function (value) {
      this.sessionRequest.approved_sessions = regexHelper.filterNumericOnly(value)
    }
  }
}
</script>