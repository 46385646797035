<template>
    <div class="root">
        <div class="container p-5" v-if="isReady">
            <div class="flex-column align-center bookingFlow__flex justify-center">
                <h1 class="font-medium fs-20 grey-900 mb-5">Select date and time</h1>
                <div class="bookingFlow__flex justify-center align-center w-100">
                    <div class="row bookingFlow__slotWidth">
                        <div class="col-md-5 col-sm-12 text-center">
                            <v-date-picker
                                    :event-color="date => availability.includes(date) ? '#30AD63' : '#EA85D4'"
                                    :events="availabilitySlotsArray"
                                    :min="minimum_date"
                                    v-model="current_date"
                                    :reactive="reactive">
                            </v-date-picker>
                          <div class="m-t-24 mb-1 bookingFlow__flex flex-row justify-center">
                            <div class="bookingFlow__flex flex-row">
                              <p class="width-14 height-14 bookingFlow__rounded-full mt-2 m-r-12 mb-0 ml-0 bookingFlow__bgAvailableSlot"></p>
                              <p class="fs-13 height-16 font-medium pt-1">Slots available</p>
                            </div>
                            <div class="bookingFlow__flex flex-row ml-5">
                              <p class="width-14 height-14 bookingFlow__rounded-full mt-2 m-r-12 mb-0 ml-0 bookingFlow__bgSlotOnLeave"></p>
                              <p class="fs-13 height-16 font-medium pt-1">On Leave</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 offset-md-1 col-sm-12 bookingFlow__flex flex-column bookingFlow__slots justify-space-between">
                            <div v-if="slot.slots && slot.slots.length > 0">
                                <p class="timeSlot pointer bookingFlow__slotsList font-medium ls-3"
                                   v-for="s in slot.slots"
                                   :id="'slot_'+s.slot_id"
                                   @click="selectSlot(s)"
                                >
                                    {{s.formatted_start_time}}
                                </p>
                            </div>
                            <div class="align-center bookingFlow__flex height-239 justify-center ma-auto position-relative width-274 bookingFlow__z-10" v-else>
                                <p class="fs-12 position-relative text-center width-266 bold-font">You have not added availability for this date.</p>
                            </div>

                            <div class="bookingFlow__flex align-center flex-column justify-center p-l-24">
                                <div class="bookingFlow__flex tw-th-my-7 pa-3 bg-blue-100">
                                    <div>
                                        <img class="mb-1" :src="alertInfoImg" alt="mot">
                                    </div>
                                    <div class="ml-2">
                                        <p class="blue-500 fs-12 mb-0">Time slots are automatically adjusted to your local time zone.</p>
                                    </div>
                                </div>
                                <div class="w-100 ma-auto mt-3">
                                  <button
                                      class="d-inline-block white--text bg-blue-500  w-100 height-44 bold-font fs-16"
                                      :disabled="disable" @click="continueBtn"
                                      :class="disable ? 'opacity-5': 'bg-blue-500'">
                                    Next
                                  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
export default {
  name: "Slots",
  props: {
    session_type: {
      required: true,
    },
    therapist: {
      required: true
    },
  },
  data() {
    return {
      current_date: null,
      minimum_date: new Date().toISOString().slice(0, 10),
      slot: {},
      selected_slot: null,
      session_type_id: this.session_type,
      reactive: true,
      is_first_date: true,
      disable: true,
      isReady: true,
      availability: [],
      availabilitySlotsArray: [],
      alertInfoImg: "https://assets.myonlinetherapy.com/images/icons/booking/booking/info_blue_ic.svg",
    }
  },
  created() {
    this.getTherapistSlots();
  },
  methods: {
    getTherapistSlots() {
      let loader = this.$loading.show();
      this.$http.get(this.url + "therapy/therapist-slots", {
        params: {
          user_id: this.therapist.id,
          session_type_id: this.session_type_id,
          is_first_date: this.is_first_date,
          date: this.current_date,
          device_type: "web",
          timezone: "europe/london",

        }
      }).then(response => {
        loader.hide();
        this.slot = response.data;
        this.current_date = response.data.date;
        this.availability = response.data.availability;
        this.leave_duration = response.data.therapist_leave_period;
        this.isReady = true;
        this.availabilitySlotsArray = this.availability.concat(this.leave_duration);
      }).catch(error =>  {
        loader.hide();
        this.isReady = false;
        this.$toastr("error", error.response.data.message);
      })
    },
    selectSlot(slot) {
      $('.timeSlot').removeClass("active");
      if (this.selected_slot && this.selected_slot.slot_id === slot.slot_id) {
        this.selected_slot = null;
      } else {
        this.selected_slot = slot;
        $("#slot_" + slot.slot_id).addClass("active");
      }
    },
    continueBtn() {
      this.$emit("increment");
      this.$emit("getSelectedSlot", this.selected_slot);
    }
  },
  watch: {
    current_date(val, oldval) {
      this.is_first_date = false;
      if (oldval !== null) {
        this.selected_slot = null;
        this.getTherapistSlots();
      }
    },
    selected_slot() {
      this.disable = this.selected_slot === null;
      if (this.selected_slot === null) {
        $(".timeSlot").removeClass("active");
      }
    }
  }

}
</script>

<style scoped>
.timeSlot.active {
  background: #2493C4 !important;
  color: white !important;
}
</style>
