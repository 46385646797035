<template>
  <div class="therapy-plan-actions">
    <hr class="m-0">
    <form class="flex-display flex-row flex-wrap flex-justify-between w-100"
          id="skillForm"
    >
      <p class="bold-font w-100 height-41 flex-display align-center m-t-37 m-b-35 neutral-black">Insurance cover details</p>

      <div class="w-48 m-b-40">
          <label>
              <span class="form-label">Plan name</span>
              <p class="height-41 neutral-dark-blue bold-font fs-13 lh-13 mb-0">{{plan.plan_name}}</p>
          </label>
      </div>
      <div class="w-48 m-b-40" v-if="pageReference !== 'client_detail'">
          <label>
              <span class="form-label">Claim number</span>
              <input v-model="plan.claim_number"
                     class="input-text form-group__input"
                     type="text"
                     name="name"
                     placeholder="Enter claim number"
              />
          </label>
      </div>

      <div class="w-48 m-b-40" v-if="pageReference !== 'client_detail'">
          <label>
              <span class="form-label">Membership number</span>
              <input v-model="plan.membership_number"
                     class="input-text form-group__input"
                     type="text"
                     name="name"
                     placeholder="Enter membership number"
              />
          </label>
      </div>
      <div class="w-48 m-b-40" v-if="pageReference !== 'client_detail'">
          <label>
              <span class="form-label">Member email</span> &nbsp;
              <p class="height-41 neutral-dark-blue bold-font fs-13 lh-13 mb-0">{{plan.member_email}}</p>
          </label>
      </div>
      <div class="w-48 m-b-40">
        <label>
          <span class="form-label">
            Plan Status
            <template v-if="defaultPlan">
              (This is the default MOT Plan)
            </template>
          </span>
          <select :disabled="(defaultPlan || !availableSession || isPlanExpired)" @change="changePlanStatus" class="input-select" name="status" required v-model="plan.user_plans.status">
            <option value="" class="grey-600">Select therapy status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>
        <p v-if="showChangeReasonCodeWarning" class="red-300">Change Reason code</p>
      </div>
      <div class="w-48 m-b-40">
        <label>
          <span class="form-label">Reason Code</span>
          <select :disabled="plan.user_plans.status === 'active'" class="input-select" name="status" required v-model="plan.therapy_status">
            <option value="" class="grey-600">Select therapy status</option>
            <option value="active">Active</option>
            <option value="voucher_expired">Expired</option>
            <option value="suspended">Suspended</option>
            <option value="plan_change">Plan change</option>
            <option value="complete">Complete</option>
            <option value="dropped_out">Dropped out</option>
            <option value="coverage_expired">Coverage Expired</option>
          </select>
        </label>
      </div>
      <div class="w-48 m-b-40">
          <label>
              <span class="form-label">MOT voucher code</span>
              <p class="height-41 neutral-dark-blue bold-font fs-13 lh-13 mb-0">{{plan.voucher_number}}</p>
          </label>
      </div>


      <div class="w-48 m-b-40 popover__wrapper" id="authorized-session">
          <label>
              <span class="form-label">Total number of authorized sessions</span>
              <input v-model="plan.authorized_sessions"
                     class="input-text form-group__input"
                     type="text"
                     name="name"
                     placeholder="Enter authorized sessions"
              />
          </label>

          <div class="popover__content" :class="{'popover-visible' : showAuthPopover}">
              <div class="flex-column">
                  <p class="blue-500 fs-14 mb-0">The number of authorized sessions has been updated to include the approved request.</p>
                  <div class="flex-display flex-row justify-end w-100">
                      <a class="bold-font blue-500 fs-14" @click="showAuthPopover=false">Got it</a>
                  </div>
              </div>
          </div>

      </div>
      <div class="w-48"></div>
      <div class="w-48 m-b-40">
        <label v-if="pageReference === 'client_detail'" >
          <span class="form-label">Complete Sessions</span>
          <input v-model="usedSessions" :disabled="true"
                 class="input-text form-group__input"
                 type="text"
                 name="name"
                 placeholder="Enter authorized sessions"
          />
        </label>
      </div>
        <div class="w-48 m-b-40">
        <label v-if="pageReference === 'client_detail'">
          <span class="form-label">Remaining Sessions</span>
          <input v-model="remainingSessions"
                 :disabled="true"
                 class="input-text form-group__input"
                 type="text"
                 name="name"
                 placeholder="Enter authorized sessions"
          />
        </label>
      </div>
      <div class="w-48"></div>
      <template v-if="plan.additional_request_enabled">
        <p class="bold-font height-41 neutral-black w-100 mb-1 flex-display align-center">Requests for additional sessions</p>
        <template v-if="plan.additional_session_requests.length > 0">
          <template v-for="sessionRequest in plan.additional_session_requests">
            <additional-session-request :session_request="sessionRequest"
                                        :request_action="plan.request_action"
                                        :request_status="request_status"
                                        :additional_request_allowed="plan.additional_request_allowed"
                                        :additional_request_trigger_session="plan.additional_request_trigger_session"
                                        v-on:requestUpdated="requestUpdated"
            ></additional-session-request>
          </template>
        </template>
        <template v-else-if="activeRequest">
          <additional-session-request :session_request="activeRequest"
                                      :request_action="plan.request_action"
                                      :request_status="request_status"
                                      :additional_request_allowed="plan.additional_request_allowed"
                                      :additional_request_trigger_session="plan.additional_request_trigger_session"
                                      v-on:requestUpdated="requestUpdated"
          ></additional-session-request>
        </template>
        <div class="w-100" v-if="showCreateRequestButton">
          <div class="width-290">
              <span v-if="disableCreateRequestButton"
                    v-popover:requestSessions.right
                    :id="disableCreateRequestButton ? 'requestSessions' : ''">
                <a class="height-46 width-271 flex-display justify-center align-center therapy-plan-actions__btn primary-blue-2 bg-blue-100">Make a request for more sessions</a>
              </span>

            <span v-else>
                <a class="height-46 width-271 flex-display justify-center align-center therapy-plan-actions__btn" @click="createNewRequest()">Make a request for more sessions</a>
              </span>

            <popover name="requestSessions" event="hover">
              <div class="flex-column">
                <p class="blue-500 fs-14 mb-0">
                  As part of our Vitality partnership, if a request has been rejected, you cannot submit any further requests.
                </p>
              </div>
            </popover>
          </div>
        </div>
      </template>

    </form>
  </div>
</template>

<script>
    import stringHelper from "@/helpers/stringHelper";
    import planHelper from "@/helpers/app/partner/planHelper";
    import AdditionalSessionRequest from "@/components/main/therapyPlanAction/AdditionalSessionRequest";

    export default {
        name: 'TherapyPlanDetails',
        components: {
          AdditionalSessionRequest
        },
        props: {
          plan_details: {},
          defaultPlan: {
            type: Boolean,
            default: false
          },
          availableSession: {
            type: Boolean,
            default: false
          },
          isPlanExpired: {
            type: Boolean,
            default: false
          },
          pageReference: {
            type: String
          },
          usedSessions: {
            type: Number,
          },
          remainingSessions: {
            type: Number
          }

        },
        data () {
            return {
              plan: null,
              totalAuthorizedSessions : 0,
              showAuthPopover: false,
              activeRequest: null,
              request_status: '',
              showCreateRequestButton: false,
              disableCreateRequestButton: false,
              showChangeReasonCodeWarning: false,
              oldReasonCode: "",
              oldPlanStatus: "",
            }
        },
        created () {
          this.plan = {
            ...this.plan_details
          }
          this.totalAuthorizedSessions = this.plan.authorized_sessions;
          if (this.plan.additional_request_enabled) {
            if (this.plan.additional_session_requests.length > 0) {
              let lastRequest = this.plan.additional_session_requests[this.plan.additional_session_requests.length - 1];
              this.request_status = lastRequest.status;

              // manage create new request button
              if (this.plan.additional_request_allowed) {
                let activeRequestStatuses = [planHelper.requestPending, planHelper.requestSubmitted];
                if (!activeRequestStatuses.includes(lastRequest.status)) {
                  this.showCreateRequestButton = true;
                  if (lastRequest.status === planHelper.requestRejected) {
                    this.disableCreateRequestButton = true;
                  }
                }
              }
            } else {
              // add empty fields when no request
              this.activeRequest = {
                request_id: null,
                requested_sessions: null,
                approved_sessions: null,
                created_at: '',
                request_detail: '',
                status: null,
                is_editable: this.plan.additional_request_allowed,
              }
            }
          }
          this.reasonCode = false;
          this.planStatus = false;
          this.oldReasonCode= this.plan_details.therapy_status;
          this.oldPlanStatus= this.plan_details.user_plans.status;
          if(this.plan_details.authorized_sessions === null){
            this.remainingSessions = null;
          }
        },
      methods: {
        showReasonCodeWarning() {
          this.showChangeReasonCodeWarning = true;
          setTimeout(() => {
            this.showChangeReasonCodeWarning = false;
          }, 5000);
        },
        changePlanStatus() {
          if(this.plan.user_plans.status === 'inactive'){
            this.plan.therapy_status = 'plan_change'
          }else if(this.plan.user_plans.status === 'active'){
            this.plan.therapy_status = 'active'
          }
            let loader = this.$loading.show();
            this.$http.put(this.url + "update-therapy-plan-status", {
              id: this.plan.id,
              reason_code: this.plan.therapy_status,
              plan_status: this.plan.user_plans.status,

            }).then(response => {
              loader.hide();
              this.$toastr("success", response.data.message, "");
              this.$events.fire('vuetable:reload');
              this.$emit('hide');
            }).catch(error => {
              loader.hide();
              this.$toastr("error", error.response.data.message, "");
            });
        },
        isEmpty(value) {
          return stringHelper.empty(value);
        },
        requestUpdated(sessionRequest, isFromStatus) {
          if (sessionRequest.status === planHelper.requestApproved) {
            this.plan.authorized_sessions = this.totalAuthorizedSessions + parseInt(sessionRequest.requested_sessions);
            if (isFromStatus) {
              this.showAuthPopover = true;
            }
          } else if (sessionRequest.status === planHelper.requestPartiallyApproved && !this.isEmpty(sessionRequest.approved_sessions)) {
            this.plan.authorized_sessions = this.totalAuthorizedSessions + parseInt(sessionRequest.approved_sessions);
            if (!isFromStatus) {
              this.showAuthPopover = true;
            }
          } else {
            this.plan.authorized_sessions = this.totalAuthorizedSessions;
          }
          this.updatePlanRequest(sessionRequest);
        },
        updatePlanRequest(sessionRequest) {
          this.plan = {
            ...this.plan,
            request_action: this.plan.request_action === 'add' ? 'add' : 'update',
            request_id: sessionRequest.id,
            requested_sessions: sessionRequest.requested_sessions,
            approved_sessions: sessionRequest.approved_sessions,
            request_detail: sessionRequest.request_detail,
            status: sessionRequest.status
          }
        },
        showRequestMoreButton() {
            if (this.plan.additional_session_requests.length > 0) {
                return (this.plan.additional_session_requests[0].status === "Approved" ||
                        this.plan.additional_session_requests[0].status === "Partially Approved" ||
                        this.plan.additional_session_requests[0].status === "Rejected");
            }
            return false;
        },
        createNewRequest() {
          let lastIndex = this.plan.additional_session_requests.length - 1
          this.plan.additional_session_requests[lastIndex].is_locked = true;
          this.request_status = '';
          this.plan.additional_session_requests.push({
            request_id: null,
            requested_sessions: null,
            approved_sessions: null,
            created_at: '',
            request_detail: '',
            status: null,
            is_editable: true,
          });
          this.plan.request_action = 'add';
          this.showCreateRequestButton = false;
        },
      },
        watch: {
          'plan': {
            deep: true,
            handler: function () {
              this.$emit('updated', this.plan);
            }
          },
        }
    }
</script>
<style scoped>
  .form-group {
    margin: 20px 20px 0 0 !important;
  }
  .error {
    background: none !important;
  }
</style>
