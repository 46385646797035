<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Results
            </p>
        </div>
        <div class="content">
            <div id="configuration">
                <v-tabs>
                    <v-tab class="w-50" v-for="tab in tabs" :key="tab.key">{{tab.text}}</v-tab>
                    <v-tab-item key="questionnaire">
                        <div class="questionnaire p-3" v-if="questionnaire.length > 0">
                            <p class="question" v-for="question in questionnaire">
                                <span class="question-title">{{question.question_title}}</span>:
                                <span class="answer-answer">{{question.answer}}</span>
                            </p>
                        </div>
                        <p class="m-3" v-else>Questionnaire is not filled yet.</p>
                    </v-tab-item>
                    <v-tab-item key="review">
                        <div class="questionnaire p-3" v-if="review">
                            <p class="question">
                                <span class="question-title">Issues</span>:
                                <span class="answer-answer">{{formatIssues(review.issues)}}</span>
                            </p>
                            <p class="question">
                                <span class="question-title">Severity</span>:
                                <span class="answer-answer">{{capitalizeText(review.severity)}}</span>
                            </p>
                            <p class="question wrap_text">
                                <span class="question-title">Notes</span>:
                                <span class="answer-answer">
                                    <template v-if="review.notes && review.notes !== ''">
                                              {{review.notes}}
                                    </template>
                                    <template v-else>
                                        <span class="font-weight-light font-italic question-title">Not added</span>
                                    </template>
                                </span>
                            </p>
                            <button class="btn btn-review" @click="openAssessmentForm" v-if="isReviewAllowed">Edit</button>
                        </div>
                        <div class="m-3" v-else>
                            <p>No initial review added yet.</p>
                            <button class="btn btn-review" @click="openAssessmentForm" v-if="isReviewAllowed">Add</button>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Questionnaire',
        props: {
            questionnaire: {
                type: Array,
                required: true
            },
            review: {
                type: Object,
            },
            isReviewAllowed: {
                type: Boolean
            }
        },
        data() {
            return {
                tabs: [
                    {'key':'questionnaire','text':'Questionnaire'},
                    {'key':'review','text':'Initial Review'},
                ],
            }
        },
        methods: {
            openAssessmentForm() {
                this.$emit('openAssessmentForm');
            },
            formatIssues(issues) {
                const self = this;
                let formattedIssues = [];
                let issuesArray = issues.split(',');
                issuesArray.forEach(function(issue) {
                    formattedIssues.push(self.capitalizeText(issue));
                });
                return  formattedIssues.join(', ');
            },
            capitalizeText(text) {
                if (typeof text !== 'string') return '';
                return text.charAt(0).toUpperCase() + text.slice(1)
            }
        }
    }
</script>

<style scoped>
    .btn-review {
        box-sizing: border-box;
        color: #308BC3;
        background: #ffffff !important;
        height: 30px;
        width: 57px;
        border: 1px solid #308BC3;
        border-radius: 3px !important;
    }
    .wrap_text {
        text-overflow: ellipsis;
        word-wrap: break-word;
        max-width: 100%;
    }
</style>