<template>
  <div class="user-progress">
    <div class="item-actions">
      <label class="switch">
        <input type="checkbox" name="status" v-on:change="editRecord($event)" v-model="rowData.active" true-value="true" false-value="false">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  methods: {
    editRecord(event) {
      this.rowData.active = event.target.checked  ? 1 : 0;
      this.$events.fire('disable-record', this.rowData)
    }
  }
}
</script>
