<template>
    <div class="user-progress">
        <div class="item-actions">
            <button @click="openDetails" class="btn-edit">Details</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            rowData: {
                type: Object,
                required: true
            },
            rowIndex: {
                type: Number
            }
        },
        data() {
          return {
              detailEvent: 'open-details'
          }
        },
        created() {
            if ( this.rowData.detailEvent ) {
                this.detailEvent = this.rowData.detailEvent;
            }
        },
        methods: {
            openDetails() {
                this.$events.fire(this.detailEvent, this.rowData)
            }
        }
    }
</script>
