<template>
  <div class="datatable-wrapper w-100">
    <!--Search Table-->
    <div class="datatable-header">
      <div v-if="showGrayHeader" class="flex-display flex-row align-center flex-justify-end w-100">
        <slot name="top-header"></slot>
      </div>
      <div class="flex-display flex-row align-center flex-justify-between w-100">
        <div class="flex-display flex-column flex-justify-between" :class="{'w-100' : showGrayHeader}">
          <h1 :class="[tableDescription ? 'mb-2': 'mb-0']" class="page-title">
            {{ tableName }}
            <span class="fs-12">{{subTableName}}</span>
          </h1>
          <p class="mb-0" v-if="tableDescription">{{ tableDescription }}</p>
        </div>
        <template v-if="!showGrayHeader">
          <slot name="top-header"></slot>
        </template>
        <template v-else>
          <div class="d-flex">
            <button @click="$emit('import')" v-if="isImport" class="btn btn-import" :class="{'btn-export' : btnImportWhiteBg}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Import
            </button>
            <li class="dropdown mx-3" v-if="isExport">
              <button class="btn" :class="{'btn-export' : btnExportWhiteBg}" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                {{ exportBtnText }}
              </button>

              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="exportDropdown">
                <a v-if="isCSV" class="dropdown-item" name="csvExport" id="csvExport" @click="exportTable('csv')">CSV</a>
                <a class="dropdown-item" name="xlsxExport" id="xlsxExport" @click="exportTable('xlsx')">Excel</a>
              </div>
            </li>
          </div>
        </template>
      </div>

      <div v-if="showGrayHeader" class="border-bottom-2px w-100 my-4"></div>

      <div class="w-100 flex-display flex-row justify-start">
        <slot name="header"></slot>
      </div>

      <div class="mb-4 w-100 flex-display flex-row justify-start">
        <slot name="tabs"></slot>
      </div>

      <template v-if="!showGrayHeader">
        <div class="flex-display flex-row flex-justify-between mb-3 w-100">
          <div class="d-flex">
            <input v-if="search.searchAble"
                   class="search-container"
                   type="text"
                   :placeholder="search.placeholder"
                   v-model="searchQuery"
                   v-on:keyup.enter="filterDataTable"
            >
            <input v-if="tableName === 'Invoices' && search2.searchAble"
                   class="search-container width-275 ml-3"
                   type="text"
                   :placeholder="search2.placeholder"
                   v-model="searchQuery2"
                   v-on:keyup.enter="filterDataTable('secondSearch')"
            >
          </div>
          <div class="d-flex">

            <button @click="$emit('CsvEmail')" v-if="isCSVEmail" class="btn btn-import mx-2 finalize-btn">Finalise draft items</button>
            <button @click="$emit('import')" v-if="isImport" class="btn btn-import" :class="{'btn-export' : btnImportWhiteBg}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Import
            </button>

            <li class="dropdown ml-3" v-if="isExportTable">
              <button class="btn" :class="{'btn-export dropdown-toggle' : btnExportWhiteBg}" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                Table Export
              </button>

              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="exportDropdown">
                <a v-if="isCSV" class="dropdown-item" id="csvExportTable" @click="exportTable('csv',false)">CSV</a>
                <a class="dropdown-item" id="xlsxExportTable" @click="exportTable('xlsx',false)">Excel</a>
              </div>
            </li>
            <li class="dropdown mx-3" v-if="isExport">
              <button class="btn" :class="{'btn-export dropdown-toggle' : btnExportWhiteBg}" role="button" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                {{ exportBtnText }}
              </button>

              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="exportDropdown">
                <a v-if="isCSV" class="dropdown-item" id="csvExport" @click="exportTable('csv')">CSV</a>
                <a class="dropdown-item" id="xlsxExport" @click="exportTable('xlsx')">Excel</a>
              </div>
            </li>
            <slot name="datatable-actions"></slot>
          </div>

        </div>
      </template>
      <template v-else>
        <div id="grey-header" class="w-100 flex-display flex-row align-center flex-justify-between bg-grey-200 height-72 border-gray-400 px-3">
          <div class="w-100 flex-display flex-row align-center justify-start">
            <button @click="$emit('CsvEmail')"
                    v-if="isCSVEmail"
                    class="btn btn-import mr-2 width-246 finalize-btn">
              Finalise draft items
            </button>
            <slot name="actions"></slot>
            <custom-filters v-if="isCustomFilter"
                            :table-name="tableName"
                            :filters="customFilters"
                            :fields="fields"
            >
            </custom-filters>
            <slot name="filters"></slot>
          </div>
          <div>
            <input v-if="search.searchAble"
                   class="search-container bg-grey-200 border-gray-400 w-auto"
                   type="text"
                   :placeholder="search.placeholder"
                   v-model="searchQuery"
                   v-on:keyup.enter="filterDataTable"
            >
          </div>
        </div>
      </template>
    </div>

    <div class="mb-2">
      <div class="mt-1 flex-display flex-row flex-wrap" v-if="!showGrayHeader">
        <slot name="actions"></slot>
        <custom-filters v-if="isCustomFilter"
                        :table-name="tableName"
                        :filters="customFilters"
                        :fields="fields"
        >
        </custom-filters>
        <slot name="filters"></slot>
      </div>
      <div>
        <slot name="stats"></slot>
      </div>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.paginationCss"
      ></vuetable-pagination>
      <vuetable-pagination-info
          ref="paginationInfo"
          info-template="{from}-{to} of {total}"
      ></vuetable-pagination-info>
      <div class="clearfix"></div>
    </div>
    <vue-table ref="vuetable"
               :key="tableName"
               :api-url="apiurl"
               :http-options="httpOptions"
               :css="css.table"
               :fields="fields"
               :sort-order="sortOrder"
               pagination-path=""
               @vuetable:load-success="onLoadSuccess"
               @vuetable:pagination-data="onPaginationData"
               @vuetable:loading="loadingData"
               @vuetable:loaded="loaded"
               :append-params="queryParams"
    ></vue-table>
  </div>


</template>

<script>
    import VueTable from 'vuetable-2/src/components/Vuetable'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import CustomFilters from "@/components/common/dataTable/filters/CustomFilters";
    import SubscriptionTransactions from "./SubscriptionTransactions";
    import moment from 'moment'
    import RecordDetails from './RecordDetails';
    import RecordEdit from './RecordEdit';
    import RecordDisable from '@/components/common/dataTable/RecordDisable'
    import RecordDelete from './RecordDelete';
    import ClientProfileLink from "./ClientProfileLink";
    import DraftDetailLink from "./DraftDetailLink";
    import InvoiceDetailLink from "./InvoiceDetailLink";
    import BillDetailLink from "@/components/common/dataTable/BillDetailLink";
    import ClientDetail from "./users/ClientDetail";
    import RecordAdd from "./RecordAdd";
    import RecordOpen from "@/components/common/dataTable/RecordOpen";
    import ClientActionsBadge from "@/components/common/dataTable/users/ClientActionsBadge";
    import TherapistActionsBadge from "@/components/common/dataTable/users/TherapistActionsBadge";
    import BookingSessionStatus from './BookingSessionStatus'
    import stringHelper from "@/helpers/stringHelper";

import Vue from 'vue'


Vue.component('record-open', RecordOpen);
Vue.component('record-details', RecordDetails);
Vue.component('record-add', RecordAdd);
Vue.component('subscription-transactions', SubscriptionTransactions);
Vue.component('record-edit', RecordEdit);
Vue.component('record-disable', RecordDisable);
Vue.component('record-delete', RecordDelete);
Vue.component('client-profile-link', ClientProfileLink);
Vue.component('invoice-detail-link', InvoiceDetailLink);
Vue.component('draft-detail-link', DraftDetailLink);
Vue.component('bill-detail-link', BillDetailLink);
Vue.component('client-detail', ClientDetail);
Vue.component('client-actions-badge', ClientActionsBadge);
Vue.component('therapist-actions-badge', TherapistActionsBadge);
Vue.component('booking-session-status', BookingSessionStatus);

export default {
  name: "DataTable",
  props: {
    tableName: {
      type: String,
      required: true
    },
    subTableName: {
      type: String,
      required: false
    },
    tableDescription: {
      type: String,
      required: false
    },
    apiEndPoint: {
      type: String,
      required: true
    },
    isCustomFilter: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Object,
      required: true
    },
    search2: {
      type: Object,
    },
    sortOrder: {
      type: Array
    },
    PreSelectedFilters: {
      type: Array,
    },
    isExport: {
      type: Boolean,
      default: false
    },
    btnExportWhiteBg: {
      default: true,
    },
    btnImportWhiteBg: {
      default: false,
    },
    exportBtnText: {
      default: "Export"
    },
    isCSV: {
      type: Boolean,
      default: true
    },
    isImport: {
      type: Boolean,
      default: false
    },
    isCSVEmail: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    isSubscriptionTransaction: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    isId: {
      type: Boolean,
      default: false
    },
    exportUrl: {
        default: ""
    },
    exportParam: {
        default: "",
    },
    showGrayHeader: {
      default: false
    },
    isExportTable: {
      type: Boolean,
      default: false
    },
    exportTableUrl: {
      default: ""
    },
    forOrgConsole: {
      default: false
    }
  },
  data() {
    return {
      httpOptions: {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      },
      fields: [],
      customFilters: [],
      selectedFilters: [],
      queryParams: {},
      loader: null,// data loader indication
      css: {
        table: {
          tableClass: 'table',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down'
        },
        paginationCss: {
          wrapperClass: 'pagination floated right',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'item',
          linkClass: 'icon item',
          paginationClass: 'ui bottom attached segment grid',
          paginationInfoClass: 'left floated left aligned six wide column',
          dropdownClass: 'ui search dropdown',
          icons: {
            prev: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
          }
        },
      },
      searchQuery: '',
      searchQuery2: '',
      apiurl: '',
      allPossibleFormats: [
        'D MMMM YYYY',
        'D MMMM YYYY HH:mm',
        'DD-MM-YY',
        'DD-MM-YYYY',
        'DD.MM.YYYY',
        'DD.MM.YYYY HH:mm',
        'DD/MM/YY',
        'DD/MM/YYYY',
        'DD/MM/YYYY HH:mm:ss',
        'HH:mm:ss',
        'M/D/YYYY',
        'D/M/YYYY',
        'MM-DD-YY',
        'MM-DD-YYYY',
        'MM-DD-YYYY HH:mm:ss',
        'MM/DD/YY',
        'MM/DD/YYYY',
        'MM/DD/YYYY HH:mm:ss',
        'MMM D YYYY',
        'MMM D YYYY LT',
        'MMMM Do YYYY',
        'MMMM Do YYYY LT',
        'YYYY-DD-MM HH:mm:ss',
        'YYYY-MM',
        'YYYY-MM-DD',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD LT',
        'YYYY-MM-DD h:mm:ss A',
        'YYYY-MM-DDTHH:mm:ssZ',
        'ddd, MMM D YYYY LT',
        'dddd D MMMM YYYY HH:mm',
        'dddd, MMMM Do YYYY LT'
      ]
    };
  },
  components: {
    CustomFilters,
    VueTable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  mounted() {
    this.$events.listen('filter-set', e => this.onFilterSet(e));
    this.$events.listen('pagination-set', e => this.setPagination(e));
    this.$events.$on('vuetable:reload', e => this.$refs.vuetable.reload());
  },
  computed: {
    fullQuery: function () {

      const filters = this.queryParams.filters;
      const search = this.queryParams.search;
      const id = this.$route.params.id;
      let query = '';
      if (id && this.isId) {
        query = 'id=' + id;
        if (this.sortOrder) {
            query = query + `&sort=${this.sortOrder[0]['sortField']}%7C${this.sortOrder[0]['direction']}`;
        }
      } else {
        query = this.sortOrder ? `sort=${this.sortOrder[0]['sortField']}%7C${this.sortOrder[0]['direction']}` : ``;
      }


      if (search) {
        query += `&search=${search}`
      }
      if (this.searchQuery2 !== "") {
        query += `&second_search=true`
      }

      if (filters) {
        filters.map(function (filter) {
          query += `&filters[]=${JSON.stringify(filter)}`
        });
      }

      query += `&timezone=${moment.tz.guess()}`;


      return query;
    }
  },

  created() {
    this.fetchFields();
    this.fetchFilters();

    if (this.search.data) {
      this.searchQuery = this.search.data;
    }
    this.selectedFilters = this.PreSelectedFilters;
    this.queryParams = {
      'search': this.searchQuery,
      'filters': this.selectedFilters,
      'timezone': moment.tz.guess(),
    };
    this.apiurl = this.url + this.apiEndPoint;
    this.refreshData();
    this.refreshSelfData();
  },

  methods: {
    onLoadSuccess(data) {
      this.$emit('onLoadSuccessData', data);
    },
    setPagination(paginationData) {
      this.onPaginationData(paginationData, true);
    },
    onPaginationData(paginationData, manualPagination = false) {
      if (
          (this.forOrgConsole && manualPagination) ||
          (!this.forOrgConsole && !manualPagination)
      ) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      }
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    loadingData() {
      this.loader = this.$loading.show();
    },
    loaded() {
      this.loader.hide();
    },
    filterDataTable(searchFlag) {
      if (searchFlag === 'secondSearch'){
        this.queryParams = {
          'search': this.searchQuery2,
          'filters': this.selectedFilters,
          'second_search': true
        }
      } else {
        this.queryParams = {
          'search': this.searchQuery,
          'filters': this.selectedFilters
        }
      }


      this.$nextTick(() => this.$refs.vuetable.refresh());
      this.refreshData();
      this.refreshSelfData();
    },
    formatId(id, prefix) {
      return prefix + id;
    },
    formatDate(time, format) {
      if (stringHelper.empty(time)) {
        return '';
      } else {
        if (time.indexOf(' ') !== -1) {
          const utcTime = time.replace(' ', 'T') + 'Z'
          return moment(utcTime).tz(moment.tz.guess()).format(format)
        } else {
          return moment(time).tz(moment.tz.guess()).format(format)
        }
      }
    },
    formatDateString(time, format) {
      if (time === null) {
        return '';
      } else {
        if (!moment(time,this.allPossibleFormats, true).isValid()) {
          return time;
        }
        if (time.indexOf(' ') !== -1) {
          const utcTime = time.replace(' ', 'T') + 'Z'
          return moment(utcTime).tz(moment.tz.guess()).format(format)
        } else {
          return moment(time).tz(moment.tz.guess()).format(format)
        }
      }
    },
    formatBookingStatus(status) {
      if (status === "" || status === null) {
        return "N/A";
      }
      return status;
    },
    formatSessionDate(time, format) {
      if (time === "" || time === null) {
        return "N/A";
      }
      return this.formatDate(time, format);
    },
    formatStatus(status) {
      status = status.split('|');
      if (status[0].toLowerCase() === 'reschedule request' && status[1].toLowerCase() === 'therapist') {
        return '<span class="text-warning"><span class="slickdot slickdot-nav-unread-message m-0"></span> ' + status[0] + '</span>';
      }
      return status[0];
    },
    formatStatusColor(status) {
      if (status.toLowerCase() === 'transferred') {
        return '<span class="text-success">' + status + '</span>';
      }
      if (status.toLowerCase() === 'reversed' || status.toLowerCase() === 'cancelled') {
        return '<span class="text-danger">' + status + '</span>';
      }
      if (status.toLowerCase() === 'pending') {
        return '<span class="text-warning">' + status + '</span>';
      }
      return status;
    },
    arrayToString(array, property = null) {
      try {
        const parsedArray = JSON.parse(array)
        let convertedArray = []

        if (property === 'isDevice') {
          convertedArray = parsedArray.map((value) => {
            return this.deviceName(value)
          });

          return convertedArray.join(', ')
        }

        if (property) {
          for (let i = 0; i < parsedArray.length; i++) {
            convertedArray.push(parsedArray[i][property]);
          }

          return convertedArray.join(', ')
        }
        return parsedArray.join(', ')
      } catch (e) {
        return array;
      }
    },
    deviceName(name) {
      if (!name) {
        return '';
      }
      switch (name.toLowerCase()) {
        case 'ios':
          return 'iOS';
        case 'android':
          return 'Android';
        case 'web':
          return 'Web';
        default:
          return '';
      }
    },
    formatConsent (value) {
      if (value === 1 || value === 2) {
        return 'Yes'
      }
      return 'No'
    },
    removeBooked(parameter) {
      if (parameter === null || parameter === '') {
        return '';
      }
      let frags = parameter.split('_')[0];
      if (frags === 'booked') {
        frags = parameter.substring(parameter.indexOf("_") + 1);
      }
      return frags;
    },
    unparameterize(parameter) {
      if (parameter === null || parameter === '') {
        return '';
      }
      let frags = parameter.split('_');
      for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    },
    removeUnderscore(parameter) {
      return parameter.replace(/_/g, ' ', s => s.slice(-1).toUpperCase());
    },
    removeNullValue(parameter) {
        return parameter !== null ? 'P-' + parameter : '';
    },
    convertBoolToString(parameter) {
      return parameter === 0 ? 'NO' : 'YES';
    },
    fetchFields() {
      this.$http.get(this.url + 'fields/' + this.apiEndPoint).then((res) => {
        if (res.data) {
          this.fields = res.data;
        }

        if (this.isDetail) {
          this.fields.push({
            name: '__component:record-details',
            titleClass: 'title-class',
            dataClass: 'data-class'
          })
        }

        if (this.isSubscriptionTransaction) {
          this.fields.push({
            name: '__component:subscription-transactions',
            titleClass: 'title-class',
            dataClass: 'data-class'
          })
        }

        if (this.isEdit) {
          this.fields.push({
            name: '__component:record-edit',
            titleClass: 'title-class',
            dataClass: 'data-class',
          })
        }

        if (this.isDisabled) {
          this.fields.push({
            name: '__component:record-disable',
            titleClass: 'title-class',
            dataClass: 'data-class',
          })
        }

        if (this.isDelete) {
          this.fields.push({
            name: '__component:record-delete',
            titleClass: 'title-class',
            dataClass: 'data-class'
          })
        }

        if (this.isAdd) {
          this.fields.push({
            name: '__component:record-add',
            titleClass: 'title-class',
            dataClass: 'data-class',
          })
        }
        this.$nextTick(() => {
          this.$refs.vuetable.normalizeFields();
        });
      });
    },
    fetchFilters() {
      this.$http.get(this.url + 'filters/custom?type=' + this.tableName).then((res) => {
        if (res.data) {
          this.customFilters = res.data;
        }
      });
    },
    onFilterSet(param) {
      let index = this.selectedFilters.findIndex(x => x.filter_id.toLowerCase() === param.filter_id.toLowerCase());
      if (index >= 0) {
        this.selectedFilters.splice(index, 1);
      }
      this.selectedFilters.push(param);
      this.filterDataTable();
    },
    removeCustomFilter(obj) {
      let x;
      let i = 0;
      for (x in this.selectedFilters) {
        if (this.selectedFilters.hasOwnProperty(x) && this.selectedFilters[x] === obj) {
          this.selectedFilters.splice(i, 1);
          this.$events.fire('apply-custom-filter', 'Custom Options');
          this.filterDataTable();
        }
        i++;
      }

    },
    exportTable(format , MainExportCheck = true) {
      let self = this;
      let exportUrl = '';
      let tableName = '';
      if (this.isExportTable && !stringHelper.empty(this.exportTableUrl) && !MainExportCheck) {
        exportUrl = this.exportTableUrl.toLowerCase();
        tableName = stringHelper.parseForUrl(exportUrl);
      } else {
        exportUrl = stringHelper.empty(this.exportUrl) ? this.tableName.toLowerCase() : this.exportUrl.toLowerCase();
        tableName = stringHelper.parseForUrl(exportUrl);
      }

      let url = `${self.url}export/${tableName}?format=${format}&${this.fullQuery}${this.exportParam}`;
      this.$http({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = 'MOT - ' + self.tableName + '.' + format;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    refreshData() {
      this.$emit('refreshData', this.fullQuery);
    },
    refreshSelfData() {
      this.$emit('refreshSelfData', this.fullQuery);
    }
  },
  events: {
    'filters-updated': function (newFilter) {
      this.customFilters.push(newFilter);
      this.onFilterSet(newFilter);
    },
    'api-url-updated': function (newApiUrl) {
      this.apiurl = this.url + newApiUrl;
      this.refreshData();
      this.refreshSelfData()
    },
    'delete-filter': function (filter) {
      let self = this;
      this.$http.delete(this.url + 'filters/delete', {
        params: {
          id: filter.id
        }
      }).then(function (response) {
        self.fetchFilters();
        self.$toastr('success', response.data.message, '');
        self.removeCustomFilter(filter);
      }).catch(function (error) {
        const errorMessage = error.response.data.error ? error.response.data.error : 'an error occured, please try again'
        self.$toastr('error', errorMessage, '');
      });
    },
    'refresh-table': function () {
      this.filterDataTable();
    }
  }

}
</script>

<style scoped>
  .btn-export {
    color: #308BC3 !important;
    background-color: #ffffff !important;
    border: 1px solid #308BC3 !important;
  }
  .border-gray-400 {
    border: 1px solid #D4D4D4;
  }
  .finalize-btn:hover {
    color: white !important;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .second-search{
    margin-left: -34px !important;
  }
</style>
