<template>

  <!-- Select Skills for the course -->

  <v-expansion-panel v-model="panel">
    <div class="w-100">

      <v-expansion-panel-content>
          <template v-slot:actions>
            <a class="delete-module mr-3" @click="deleteModule(index,module.id,$event)">Delete</a>
            <v-icon color="white" >mdi-menu-down</v-icon>
          </template>

          <template v-slot:header class="d-flex">
            <div class="position-absolute d-flex justify-center align-center flex-column" @click="preventDefaultEvent" >
              <span class="d-inline-flex bold-font">{{ index + 1 }}</span>
              <em class="fa fa-arrows cursor-move position-absolute d-inline-flex ml-5 mr-2"></em>
            </div>

            <div class="form-group w-48 pl-4">

              <label class="ml-3">
                <span class="form-label">Enter Title</span>
                <input class="input-text width-253 height-40"
                       type="text"
                       name="name"
                       v-model="module.title"
                       placeholder="Enter Title"
                       @click="preventDefaultEvent"
                />
              </label>
            </div>
          </template>
          <article class="m-l-40">
            <!--Module Description-->
            <div class="form-group width-255  pt-4">
              <label>
                <span class="form-label">Description (90 character max)</span>
                <input class="input-text"
                       type="text"
                       name="description"
                       v-model="module.description"
                       placeholder="Enter Description"
                       maxlength=90
                />
              </label>
            </div>

            <span class="form-label pt-2 pb-2">Sessions</span>
            <label class="neutral-dark-blue fs-14 lh-14 font-weight-bold pb-2 w-100">Simple select / dropdown</label>
            <div class="form-group width-255 ">
              <multi-select v-model="module.exercises"
                            :options="exercises"
                            :allow-empty="false"
                            :multiple="true"
                            :taggable="false"
                            :min="1"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Select exercise"
                            label="name"
                            track-by="id"
                            class="w-100">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                  <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{
                                      values.length
                                    }} options selected</span></template>
              </multi-select>
            </div>
            <draggable class="w-48"
                       :list="module.exercises">
              <div class="keyword w-100" v-for="(skill,index) in module.exercises" :key="skill.id">
                {{ index + 1 }}
                <em class="fa fa-arrows handle pt-2 pb-2"></em>
                {{ skill.name }}
              </div>
            </draggable>
          </article>
        </v-expansion-panel-content>
    </div>
  </v-expansion-panel>


</template>


<script>
import MultiSelect from 'vue-multiselect'
import KeywordsComponent from '../common/KeywordsComponent'
import draggable from 'vuedraggable'
import routeHelper from '@/helpers/app/routeHelper'

export default {
  name: 'addModule',
  components: {
    MultiSelect,
    KeywordsComponent,
    draggable,
  },
  props: {
    exercises: {
      required: true,
      type: Array
    },
    module: {
      type: Object,
    },
    index: {
      default: 0,
    },
    modules: {
      type: Array
    },
    value: null
  },
  data: () => ({
    panel: [true, false],
  }),
  created () {

  },
  methods: {
    deleteModule (index, module, e) {
      e.stopPropagation();
      if (this.module.id !== undefined) {

        this.$http.put(this.url + routeHelper.deleteModuleApi, {
          module_id: this.module.id
        }).then(response => {
          this.$emit('removeModule',this.module.id,"module");
          this.$toastr('success', response.data.message, '')
          this.$emit('refresh-module')
        })
      } else {
        this.$emit('removeModule',index,"index");
      }
    },
    preventDefaultEvent: function (event) {
      event.stopPropagation();
    },
  }
}
</script>
<style scoped>
.v-expansion-panel {
  box-shadow: none !important;
}

.v-expansion-panel__header {
  padding: 0 !important;
  border-bottom: 1px solid #E4E4E4;
}

.v-expansion-panel__container {
  border-top: none;
}

.btn.btn-module {
  width: auto;
  color: #959595 !important;
  background-color: #ffffff !important;
  border: 1px solid #959595 !important;
  border-radius: 2px;
}

.delete-module {
  border: 1px solid #FB6161;
  border-radius: 2px;
  color: #FB6161 !important;
  font-size: 14px;
  line-height: 16px;
  padding: 7px;
}
.bottom-270 {
  bottom: 270px !important;
}
</style>
