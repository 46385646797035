<template>
    <v-dialog v-model="show" persistent max-width="500px" min-height="300px">
        <v-card>
            <v-card-title>
                <slot name="close-icon"></slot>
            </v-card-title>

            <v-card-text>
                <div class="container text-xl-center">
                    <h1 class="confirmation-model-title">
                        <slot name="title"></slot>
                    </h1>
                    <p class="confirmation-model-desc mt-3">
                        <slot name="desc"></slot>
                    </p>
                </div>
            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "Confirmation",
        props: {
            visible: {
                required: true,
                type: Boolean
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{

        }
    }
</script>
<style scoped>

</style>