<template>
    <div class="details-section">
        <div class="header">
            <p class="title">
                Treatments
            </p>
            <a class="create-new" href="#" @click="showTreatmentModal()">New Treatment</a>
        </div>
        <div class="content">
            <div id="configuration">
                <div class="item-container border-0"
                     v-for="(treatment, index) in selectedTreatments"
                     :key="index"
                >
                    <p class="item-text">{{ treatment }}</p>
                    <div class="item-actions">
                        <img class="cursor-pointer" src="/icons/delete_payout_ic.svg" @click="deleteConfirmation(treatment)"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Treatment -->
        <add-treatment v-if="addTreatmentDialog"
                   :visible="addTreatmentDialog"
                   :treatments="treatments"
                   :selectedTreatments="selectedTreatments"
                   v-on:addTreatment="addTreatment"
                   v-on:hide="hideTreatmentModal"
        >
        </add-treatment>

        <!-- delete dialog -->
        <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
                Are you sure?
            </template>
            <template slot="desc">
                This Treatment will be deleted. This action cannot be undone.
            </template>
            <template slot="actions">
                <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                <button class="btn modal-action-button confirmation-delete" @click="deleteTreatment">Delete</button>
            </template>
        </confirmation>
    </div>
</template>

<script>
    import AddTreatment from './actions/AddTreatment.vue';
    import Confirmation from '../common/Confirmation.vue';
    export default {
        name: 'Treatments',
        components: {
            AddTreatment,
            Confirmation
        },
        props: {
            user_id: {
                type: [Number, String],
                required: true
            },
            treatments: {
                type: Array,
                required: true
            },
            selectedTreatments: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                addTreatmentDialog: false,
                deleteDialog: false,
                allSelectedTreatment: [],
                activeTreatment: ''
            }
        },
        created() {
            this.allSelectedTreatments = this.selectedTreatments;
        },
        methods: {
            deleteConfirmation(treatment) {
                this.deleteDialog = true;
                this.activeTreatment = treatment;
            },
            hideConfirmation () {
                this.deleteDialog = false;
                this.activeTreatment = '';
            },
            deleteTreatment(){
                this.allSelectedTreatments = this.removeArrayValue(this.activeTreatment, this.allSelectedTreatments);
                this.updateTreatments();
                this.deleteDialog = false;
            },
            showTreatmentModal(){
                this.addTreatmentDialog = true;
            },
            hideTreatmentModal(){
                this.addTreatmentDialog  = false;
            },
            addTreatment(treatments){
                this.allSelectedTreatments = treatments;
                this.updateTreatments();
            },
            updateTreatments() {
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+'update_user_treatments', {
                    user_id: this.user_id,
                    treatments: this.allSelectedTreatments.join(','),
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success',response.data.message,'');
                    self.$emit('updateTreatments', response.data.user.treatment);
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message,'');
                })
            },
            removeArrayValue(value, array) {
                const index = array.indexOf(value);
                if (index > -1) {
                    array.splice(index, 1);
                }
                return array;
            }
        }
    }
</script>