<template>
  <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
    <v-card>
      <v-card-title>
        <h1 class="model-heading">{{modal_title}}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
      </v-card-title>

      <v-card-text>

        <div>
          <form>
            <!-- Filter Name -->
            <div class="form-group">
              <label class="form-label">Filter Name</label>
              <input type="text" class="input-text" name="filterName" v-model="filterName" placeholder="Enter Filter Name"/>
            </div>

            <!-- Column -->
            <div class="form-group">
              <label class="form-label">Column</label>
              <select name="role" class="input-select" v-model="selectedColumnIndex" v-on:change="selectColumn()">
                <option value="" disabled selected>Select column</option>
                <template v-for="(column, index) in columns">
                  <option v-if="column.customField === false" :value="index" :key="index">{{ column.title }}</option>
                </template>
              </select>
            </div>

            <!-- Operator -->
            <div class="form-group">
              <label class="form-label">Operator</label>
              <select name="role" class="input-select" v-model="selectedOperatorIndex" v-on:change="selectOperator()">
                <option value="" disabled selected>Select operator</option>
                <template v-for="(operator, index) in operators">
                  <option :value="index" :key="index">{{ operator.title }}</option>
                </template>
              </select>
            </div>

            <!-- Value -->
            <div class="form-group">
              <label class="form-label">Value</label>
              <!-- For Within Filter -->
              <div class="d-flex justify-space-between" v-if="this.selectedOperator.name.includes('within')">
                <div class="col-6">
                  <input type="number" class="input-text" name="filterName" v-model="unitValue" placeholder="Enter Value"/>
                </div>
                <div class="col-6">
                  <select name="role" class="input-select" v-model="selectedUnit">
                    <option value="Select Duration" disabled selected>Select Duration</option>
                    <template v-for="(unit, index) in selectedOperator.units">
                      <option :value="unit" @click="selectUnit(unit)" :key="index">{{ unit }}</option>
                    </template>
                  </select>
                </div>
              </div>

              <!-- Between Filter | multi-value -->
              <template v-else-if="this.selectedOperator.multiValue">
                <!-- Value 2 for date -->
                <div class="d-flex" v-if="this.selectedColumn.type === 'Date'">
                  <div class="col-6"><date-picker class="p-0" :date="value1" v-on:valueUpdated="updateValue1"></date-picker></div>
                  <div class="col-6"><date-picker class="p-0" :date="value2" v-on:valueUpdated="updateValue2"></date-picker></div>
                </div>
                <!-- Value 2 for input -->
                <div class="d-flex" v-else>
                  <div class="col-6"><input type="text" class="input-text" name="filterName" v-model="value1" placeholder="Enter Value"/></div>
                  <div class="col-6"><input type="text" class="input-text" name="filterName" v-model="value2" placeholder="Enter Value"/></div>
                </div>
              </template>

              <!-- Other Filters | single-value -->
              <template v-else>
                <!-- Value 1 for date -->
                <div v-if="this.selectedColumn.type === 'Date'">
                  <date-picker :date="value1" v-on:valueUpdated="updateValue1"></date-picker>
                </div>
                <!-- Value 1 for input -->
                <div v-else>
                  <input type="text" class="input-text" name="filterName" v-model="value1" placeholder="Enter Value"/>
                </div>
              </template>
            </div>

            <div class="form-group">
              <label class="form-label">
                <input type="checkbox" v-model="saveForLater" id="save_for_later" name="save_for_later"/>
                Save for later use
              </label>
            </div>


          </form>
        </div>

      </v-card-text>

      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" :disabled="!allowSubmit" :class="allowSubmit ? '':'disabled'" @click="createFilter" type="button" name="create-filter">{{actionButtonTitle}}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
    import DatePicker from "../../DatePicker";
    export default {
        components: {DatePicker},
        name: "CustomFilters",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            fields: {
                required: true
            },
            tableName: {
                required: true
            }
        },
        data: () => ({
            modal_title : 'Create Custom Filter',
            actionButtonTitle:'Apply Filter',
            selectedColumnIndex: '',
            selectedColumn: {
                title: 'Column',
                name: ''
            },
            selectedOperatorIndex: '',
            selectedOperator: {
                title: 'Operator',
                name: ''
            },
            operatorsList: [
                {
                    title: 'Equal to',
                    name: 'equal_to',
                    forTypes: ['String', 'Number', 'Date', 'Datetime'],
                    multiValue: false,
                },
                {
                    title: 'Less than',
                    name: 'less_than',
                    forTypes: ['Number', 'Date', 'Datetime'],
                    multiValue: false,
                },
                {
                    title: 'Greater than',
                    name: 'greater_than',
                    forTypes: ['Number', 'Date', 'Datetime'],
                    multiValue: false,
                },
                {
                    title: 'Contains',
                    name: 'contains',
                    forTypes: ['String'],
                    multiValue: false,
                },
                {
                    title: 'Between',
                    name: 'between',
                    forTypes: ['Number', 'Date', 'Datetime'],
                    multiValue: true,
                },
                {
                    title: 'Within',
                    name: 'within',
                    forTypes: ['Date', 'Datetime'],
                    multiValue: false,
                    units: ['Days', 'Weeks', 'Months', 'Years']
                },
                {
                    title: 'Not Within',
                    name: 'not_within',
                    forTypes: ['Date', 'Datetime'],
                    multiValue: false,
                    units: ['Days', 'Weeks', 'Months', 'Years']
                }
            ],
            value1: '',
            value2: '',
            filterName: '',
            selectedUnit: 'Select Duration',
            unitValue: 0,
            saveForLater: false,
            date: '',
        }),
        created() {

        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
            columns: function () {
                return this.fields.filter((field) => {
                    return !field.name.includes('__')
                })
            },
            operators: function() {
                if (this.showOperators) {
                    return this.operatorsList.filter((operator) => {
                        return operator.forTypes.indexOf(this.selectedColumn.type) !== -1
                    })
                } else {
                    return []
                }
            },
            showOperators: function() {
                return this.selectedColumn.name !== ''
            },
            showValueField: function() {
                return this.selectedOperator.name !== ''
            },
            showTitle: function() {
                return this.selectedColumn.title !== '' &&
                    this.selectedOperator.name !== '' &&
                    this.value1 !== ''
            },
            allowSubmit: function() {
                return this.selectedColumn.name !== '' &&
                    this.selectedOperator.name !== '' &&
                    this.value1 !== '' &&
                    this.filterName !== ''
            }
        },
        methods:{
            hide(){
                this.$emit('hide');
            },
            selectColumn() {
                let index = this.selectedColumnIndex;
                this.selectedColumn = this.columns[index];
            },
            selectOperator() {
                let index = this.selectedOperatorIndex;
                this.selectedOperator = this.operators[index];
            },
            selectUnit(unit) {
                this.selectedUnit = unit
                this.updateValue()
            },
            updateValue() {
                if (this.selectedUnit !== 'Select Duration' && this.unitValue) {
                    this.value1 = `${this.unitValue} ${this.selectedUnit}`
                }
            },
            reset() {
                this.selectedColumn = {
                    title: 'Column',
                    name: ''
                }
                this.selectedOperator = {
                    title: 'Operator',
                    name: ''
                }
                this.value1 = '';
                this.value2 = '';
                this.selectedUnit = 'Select Duration';
                this.unitValue = 0;
                this.filterName = '';
                this.showTools = false;
            },
            createFilter() {
                var self = this;
                if (!this.allowSubmit) {
                    return false
                }

                this.$http.put(this.url+'filters/create', {
                    filter_json: {
                        column: this.selectedColumn.column[0],
                        operator: this.selectedOperator.name,
                        value1: this.value1,
                        value2: this.value2,
                        type: this.selectedColumn.type,
                        is_multiple:true
                    },
                    filter_type: this.tableName.toUpperCase(),
                    filter_name: this.filterName,
                    save_for_later: this.saveForLater
                }).then((response) => {
                    self.$toastr('success',response.data.message,'');
                    this.reset();
                    this.$events.fire('filters-updated', response.data.filter);
                    this.$events.fire('apply-custom-filter', response.data.filter.name);
                    this.closeModal()
                }).catch( (error) => {
                    const errorMessage = error.response.data.error ? error.response.data.error : 'an error occured, please try again'
                    self.$toastr('error', errorMessage,'');
                })
            },
            closeModal: function() {
                this.$events.fire('close-custom-filter-modal');
            },
            updateValue1(value) {
                this.value1 = value;
            },
            updateValue2(value) {
                this.value2 = value;
            }
        }
    }
</script>
<style>
  .v-picker__body .v-btn {
    color: #212121 !important;
    padding: 0;
    border-radius: 50%;
  }
  .v-date-picker-table .v-btn--active {
    color: #000 !important;
  }
  .v-btn.v-btn--icon {
    height: 32px !important;
    width: 32px !important;
  }
  .v-text-field {
    padding: 0 !important;
  }
</style>