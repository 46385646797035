<template>
  <div>
    <div class="w-100">
      <hr class="mt-0">
      <p class="font-weight-bold">Outcome measure</p>
      <hr>
      <div class="form-group flex-display w-70">
        <span class="font-weight-bold w-35">Client defined goal</span>
        <span class="w-50">{{ plan.client_goal }}</span>
      </div>
      <hr>
      <div class="w-100 mt-4">
        <div class="w-70 flex-display">
          <span class="w-35"></span>
          <span class="text-center w-35">
              <span class="slickdot slickdot-nav-unread-message bg-danger-admin" v-if="plan.plan_actions.initial_outcome"></span>
              &nbsp; Initial assessment
          </span>
          <span class="text-center w-35">
              <span class="slickdot slickdot-nav-unread-message bg-danger-admin" v-if="plan.plan_actions.final_outcome"></span>
              &nbsp; Final assessment
          </span>
        </div>
        <hr class="w-70 mt-2">
        <div class="w-70 flex-display">
          <span class="font-weight-bold w-35">GAD</span>
          <span class="text-center font-weight-bold w-35">{{ plan.gad_score.initial_score }}</span>
          <span class="text-center w-35">{{ plan.gad_score.final_score }}</span>
        </div>
        <hr class="w-70">
        <div class="w-70 flex-display">
          <span class="font-weight-bold w-35">PHQ</span>
          <span class="text-center font-weight-bold w-35">{{ plan.phq_score.initial_score }}</span>
          <span class="text-center w-35">{{ plan.phq_score.final_score }}</span>
        </div>
        <hr class="w-70">
        <div class="w-70 flex-display">
          <span class="font-weight-bold w-35">Goal progress</span>
          <span class="text-center font-weight-bold w-35">{{ plan.goal_progress.initial_score }}</span>
          <span class="text-center w-35">{{ plan.goal_progress.final_score }}</span>
        </div>
      </div>
      <hr>
      <div class="w-100">
        <div class="w-70 flex-display">
          <span class="font-weight-bold w-35">
              <span class="slickdot slickdot-nav-unread-message bg-danger-admin" v-if="plan.plan_actions.nps_rating"></span>
              &nbsp; NPS score
          </span>
          <span class="text-center font-weight-bold w-35">{{ plan.nps_score }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Details',
        components: {  },
        props: {
          plan_details: {}
        },
        data () {
            return {
              plan: null
            }
        },
        created () {
            this.plan = {
                ...this.plan_details
            }
        }
    }
</script>
<style scoped>
  span {
    font-size: 14px !important;
  }
</style>
