<template>
  <div class="details-section">
    <div class="header">
      <p class="title">
        {{headerTitle}}
      </p>
      <slot name="data-table-actions"></slot>
    </div>
    <div class="content">

      <data-table v-if="areTherapyPlanLoaded"
                  :isDetail="isDetail"
                  :fields="therapyPlans.fields"
                  :data="therapyPlans.data"
                  :doSwitchPlans="true"

      ></data-table>
    </div>
    <plan-detail v-if="therapyPlanDetailDialog"
                 :visible="therapyPlanDetailDialog"
                 :rowData="selectedPlan"
                 :user_id="user_id"
                 v-on:hide="hideDetailModal"
    >
    </plan-detail>

    <!-- confirmation dialog -->
    <confirmation v-if="switchPlanDialog" :visible="switchPlanDialog">
      <template slot="title">
        Are you sure?
      </template>
      <template slot="desc">
        The user will be switched to <p>{{ planName }} .</p>
      </template>
      <template slot="actions">
        <a class="cancel-model" @click="hideConfirmation">Cancel</a>
        <button class="btn modal-action-button confirmation-delete" @click="switchPlan">Switch Plan</button>
      </template>
    </confirmation>

  </div>
</template>

<script>
import DataTable from "@/components/common/dataTable/DataTableDataMode";
import TherapyPlanDetail from "@/components/main/therapyPlanAction/TherapyPlanDetail";
import Confirmation from '@/components/common/Confirmation';
import PlanDetail from "@/components/main/planDetails/PlanDetail";

export default {
  name: "TherapyPlan",
  components: {
    PlanDetail,
    TherapyPlanDetail,
    DataTable,
    Confirmation
  },
  props: {
    headerTitle: {
      type: String,
      required: true
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    user_id: {
      required: true,
    },

  },
  data() {
    return {
      therapyPlans: {
        fields: [],
        data: []
      },
      areTherapyPlanLoaded: false,
      therapyPlanUrl: "client/therapy-plans",
      therapyPlanDetailDialog: false,
      selectedPlan: null,
      selectedTab: 0,
      switchPlanDialog: false,
      switchToPlan: '',
      planName: ''
    }
  },
  created () {
    this.getTherapyPlans()
  },

  methods: {
    hideDetailModal() {
      this.therapyPlanDetailDialog = false;
    },
    getTherapyPlans() {
      this.$http.get(this.url + this.therapyPlanUrl, {
        params: {
          user_id: this.user_id,
          timezone: this.timezone,
        }
      }).then(response => {
        this.therapyPlans.fields = response.data.therapy_plans.fields;
        if (response.data.therapy_plans.data.length > 0) {
          this.therapyPlans.data.push(...response.data.therapy_plans.data);
        }
        this.areTherapyPlanLoaded = true;
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "Error")
      })
    },
    switchPlan() {
      let loader = this.$loading.show();
      this.$http.put(this.url + 'client/switch-plan', {
        partner_plan_id: this.selectedPlan.partner_plan_id,
        user_voucher_id: this.selectedPlan.id,
        user_plan_id: this.selectedPlan.user_plan_id,
        client_id: this.user_id,
        provider: this.selectedPlan.provider,
        switch_to: this.switchToPlan,
      }).then(response => {
        loader.hide();
        this.$toastr('success', response.data.message, '');
        this.switchPlanDialog= false;
        this.$router.go();
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
        this.switchPlanDialog= false;
      });
    },
    hideConfirmation() {
      this.switchPlanDialog= false;
    }
  },
  events: {
    "open-details": function (data, tab) {
      this.selectedPlan = data;
      this.selectedTab = tab;
      this.therapyPlanDetailDialog = true;
    },
    "switch-plan": function (data, switchPlan) {
      this.selectedPlan = data;
      this.switchPlanDialog = true;
      this.switchToPlan = switchPlan
      this.planName = switchPlan === 'vitality-health-standard-plan' ? 'Vitality Mental Health Plan' : 'Vitality Core Plan'
    },
  },

}
</script>
