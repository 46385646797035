<template>
  <v-dialog v-model="show" persistent max-width="750px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title class="pb-0 pt-3 pl-4">
        <h1 class="model-heading pl-3">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" @click="hide" alt="myonlinetherapy"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text class="pt-0">
        <v-container fluid class="pt-0" id="plan-form">
          <v-layout wrap align-center class="justify-space-between">
            <form v-if="plan" class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100"
                  id="organisationForm"
            >

              <div class="main-container w-100 mt-3">
                <!-- Plan title -->
                <div class="form-group w-100 flex-display flex-row">
                  <label class="org-label form-label pt-0 w-auto white-space-pre">
                    Plan title:
                  </label>
                  <input class="input-text m-l-12"
                         :class="{'error-border' : showPlanTitleError}"
                         type="text"
                         v-model="planTitle"
                         placeholder="Enter plan title"/>
                </div>
              </div>
              <div class="main-container w-100">
                <!-- Plan description -->
                <div class="form-group w-100 flex-display flex-row">
                  <label class="org-label form-label pt-0 w-auto white-space-pre">
                    Plan description:
                  </label>
                  <textarea class="input-text m-l-12 h-100"
                            :class="{'error-border' : showPlanDescriptionError}"
                            rows="3"
                            v-model="planDescription"
                            placeholder="Enter plan description"/>
                </div>
              </div>

              <div class="main-container mb-3">
                <div class="row mx-0">
                  <div class="col-xs-6">
                    <label class="org-label switch-label pr-3 ml-5">Plan type:</label>
                  </div>
                  <div class="col-xs-6">
                    <select class="input-select" name="plan_type" v-model="planType">
                      <option value="self-care">Self-care</option>
                      <option value="therapy">Therapy</option>
                    </select>
                  </div>
                </div>
              </div>

              <self-care-plan v-if="planType === 'self-care' && plans"
                              :self-care-plan="plan"
                              :plans="plans"
                              @planChanged="planChanged">
              </self-care-plan>

              <therapy-plan v-else-if="planType === 'therapy' && plans"
                            :action-type="actionType"
                            :therapy-plan="plan"
                            :plans="plans"
                            :gaia-plan-list="plansList"
                            :show-diagnosis-errors="showDiagnosisErrors"
                            :show-service-charges-errors="showServiceChargesErrors"
                            :planPricingModelError="planPricingModelError"
                            @planChanged="planChanged">
              </therapy-plan>
            </form>
            <div v-else class="w-100 flex-display flex-row justify-center align-center height-100">
              <p>Loading...</p>
            </div>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="addPlan">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import numberHelper from '@/helpers/numberHelper'
import selfCarePlan from './selfCarePlan'
import therapyPlan from './therapyPlan'

export default {
  name: 'Index',

  components: {
    selfCarePlan,
    therapyPlan
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    actionType: {
      required: true,
      type: String,
      default: 'add',
    },
    value: {
      default: null
    },
  },

  data: () => ({
    icd10Codes: [],
    isEverythingReady: true,
    modalTitle: 'Add a Plan',
    actionButtonTitle: 'Add a plan',
    plan: null,
    blankPlan: null,
    organisations: [],
    loadingMultiSelects: false,
    selfCareActive: false,
    duration_number: null,
    clinical_numbers_options: [],
    outcome_numbers_options: [],
    subscriptionProducts: [],
    selectedDiscountedProduct: null,
    showDiagnosisErrors: false,
    showInsuranceCoverErrors: false,
    plansList: [],
    showServiceChargesErrors: false,
    planTitle: "",
    planDescription: "",
    planType: "",
    planTitleError: false,
    planDescriptionError: false,
    planPricingModelError: false,
    plans: null,
    partnerPlan: null
  }),

  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },

    showDiagnosisError () {
      return this.plan.is_diagnosis && this.isEmpty(this.plan.type_of_diagnosis) && this.showDiagnosisErrors
    },

    showPlanTitleError () {
      return this.isEmpty(this.planTitle) && this.planTitleError
    },

    showPlanDescriptionError () {
      return this.isEmpty(this.planDescription) && this.planDescriptionError
    },
  },
  created () {
    // get plans for Gaia drop down
    this.getGaiaPlansList()

    // Get plan initial data
    this.getPlanData(this.value);

    if (this.value) {
      this.getPlanDetails(this.value.id)
    }

    if (this.actionType === 'add') {
      this.modalTitle = 'Add a Plan'
      this.actionButtonTitle = 'Add a plan'
    } else {
      this.modalTitle = 'Edit a plan'
      this.actionButtonTitle = 'Save'
    }

  },
  methods: {
    getPlanData (val) {
      this.$http.get(this.url + 'plan/data').then(response => {
        this.plans = response.data.plans
        if (!val) {
          this.blankPlan = this.getBlankPlan()
          this.plan = {
            ...this.blankPlan
          }
          this.partnerPlan = {
            ...this.plan
          }
          if (this.actionType === 'add') {
            if (this.plan.serviceCharges.length < 1) {
              this.createNewServiceCharge()
            }
          }
        }
      }).catch(error => {
        // nothing requires here
      })
    },
    planChanged (plan) {
      this.partnerPlan = { ...plan }
    },

    getGaiaPlansList () {
      this.$http.get(this.url + 'gaia/plans').then(response => {
        this.plansList = response.data
      }).catch(error => {
        // nothing requires here
      })
    },

    isEmpty (value) {
      return numberHelper.isEmpty(value)
    },

    hide () {
      this.$emit('hide')
    },

    getPlanDetails (plan_id) {
      let loader = this.$loading.show()
      this.$http.get(this.url + 'org-console/plan?plan_id=' + plan_id).then(response => {
        this.plan = response.data.plan
        if (this.plan.serviceCharges.length < 1) {
          this.createNewServiceCharge()
        }
        this.partnerPlan = { ...this.plan }
        loader.hide()
      }).catch(error => {
        loader.hide()
      })
    },

    addPlan () {
      if (this.validateSubmitRequest()) {
        let loader = this.$loading.show()
        let data = this.getDataToSubmit()
        this.$http.put(this.url + 'org-console/plan', data).then(response => {
          loader.hide()
          this.$emit('submit')
          this.$toastr('success', response.data.message, '')
        }).catch(error => {
          loader.hide()
          this.$toastr('error', error.response.data.message, '')
        })
      }
    },

    getDataToSubmit () {
      return {
        ...this.partnerPlan,
        title: this.planTitle,
        description: this.planDescription,
        plan_type: this.planType,
        enable_therapy: this.parseBooleanToString(this.partnerPlan.enable_therapy),
        undefined_sessions: this.parseBooleanToString(this.partnerPlan.undefined_sessions),
        paid_by_user: this.parseBooleanToString(this.partnerPlan.paid_by_user),
        paid_by_org: this.parseBooleanToString(this.partnerPlan.paid_by_org),
        prepay: this.parseBooleanToString(this.partnerPlan.prepay),
        is_gaia: this.parseBooleanToString(this.partnerPlan.is_gaia),
        gaia_configuration: {
          ...this.partnerPlan.gaia_configuration,
          deprexis: this.parseBooleanToString(this.partnerPlan.gaia_configuration.deprexis),
          deprexis_plan_id: this.partnerPlan.gaia_configuration.deprexis_plan_id,
          vorvida: this.parseBooleanToString(this.partnerPlan.gaia_configuration.vorvida),
          vorvida_plan_id: this.partnerPlan.gaia_configuration.vorvida_plan_id,
          velibra: this.parseBooleanToString(this.partnerPlan.gaia_configuration.velibra),
          velibra_plan_id: this.partnerPlan.gaia_configuration.velibra_plan_id,
        },
        enable_additional_sessions_request: this.parseBooleanToString(this.partnerPlan.enable_additional_sessions_request),
        enable_outcome_measures: this.parseBooleanToString(this.partnerPlan.enable_outcome_measures),
        enable_bulk_invoicing: this.parseBooleanToString(this.partnerPlan.enable_bulk_invoicing),
        enable_nps: this.parseBooleanToString(this.partnerPlan.enable_nps),
        card_details_required: this.parseBooleanToString(this.partnerPlan.card_details_required),
        disable: this.parseBooleanToString(this.partnerPlan.disable),
        is_diagnosis: this.parseBooleanToString(this.partnerPlan.is_diagnosis),
        type_of_diagnosis: this.partnerPlan.type_of_diagnosis ? this.partnerPlan.type_of_diagnosis : '',
        session_types: this.partnerPlan.session_types,
        enable_service_charges: this.parseBooleanToString(this.partnerPlan.enable_service_charges),
      }
    },
    parseBooleanToString (value) {
      return value ? 'true' : 'false'
    },
    validateSubmitRequest () {
      if (this.partnerPlan.is_diagnosis) {
        this.showDiagnosisErrors = true
      }

      if (this.partnerPlan.enable_service_charges) {
        this.showServiceChargesErrors = true
      }

      return this.fieldsValidated()
    },

    fieldsValidated () {
      let allChecksPass = true
      allChecksPass = this.generalFieldsValidation(allChecksPass)
      allChecksPass = this.therapyPlanFieldsValidation(allChecksPass)
      allChecksPass = this.selfCarePlanFieldsValidation(allChecksPass);

      return allChecksPass
    },

    generalFieldsValidation (allChecksPass) {
      if (this.planTitle === '') {
        this.planTitleError = true
        this.showAlert('Please enter title.')
        allChecksPass = false
      }

      if (this.planDescription === '') {
        this.planDescriptionError = true
        this.showAlert('Please enter plan description.')
        allChecksPass = false
      }

      if (this.planType === '') {
        this.showAlert('Please select a plan type.')
        allChecksPass = false
      }

      return allChecksPass
    },

    therapyPlanFieldsValidation (allChecksPass) {
      if (this.planType === 'therapy') {
        if (!this.partnerPlan.pricing_id) {
          this.planPricingModelError = true
          this.showAlert('Please select pricing model.')
          allChecksPass = false
        }
        if (this.partnerPlan.is_diagnosis && this.isEmpty(this.partnerPlan.type_of_diagnosis)) {
          this.showAlert('Please Choose Type of diagnosis.')
          allChecksPass = false
        }
        if (!this.partnerPlan.undefined_sessions && this.isEmpty(this.partnerPlan.allowed_sessions)) {
          this.showAlert('Please enter valid allowed sessions which should be greater than 0')
          allChecksPass = false
        }
        if (this.partnerPlan.enable_nps && this.isEmpty(this.partnerPlan.nps_trigger_number)) {
          this.showAlert('Please enter valid NPS trigger which should be greater than 0')
          allChecksPass = false
        }

        allChecksPass = this.validatePaidByOrg(allChecksPass);
        allChecksPass = this.validateGaiaPlanFields(allChecksPass);

        if (!this.validateSessionTypes()) {
          this.showAlert('Please select at least one session type.')
          allChecksPass = false
        }
      }
      return allChecksPass
    },

    selfCarePlanFieldsValidation (allChecksPass) {
      if (this.planType === 'self-care' && this.partnerPlan.billing_type === 'subscription' && !this.partnerPlan.pricing_id) {
        this.showAlert('Please select a subscription.')
        allChecksPass = false
      }
      return allChecksPass;
    },
    validatePaidByOrg (allChecksPass) {
      if (this.partnerPlan.enable_additional_sessions_request && this.isEmpty(this.partnerPlan.additional_session_request_trigger)) {
        this.showAlert('Please enter valid session completion trigger which should be greater than 0')
        allChecksPass = false
      }
      if (this.validateServiceChargesFields()) {
        this.showAlert('Please fill all the service charges.')
        allChecksPass = false
      }
      return allChecksPass;
    },
    validateSessionTypes () {
      let sessionTypesAdded = false;
      for (let sessionTypeMain in this.partnerPlan.session_types) {
        this.partnerPlan.session_types[sessionTypeMain].session_types.forEach((sessionTypeIndividual) => {
          if (sessionTypeIndividual.status) {
            sessionTypesAdded = true
          }
        })
      }
      return sessionTypesAdded
    },
    validateGaiaPlanFields (allChecksPass) {
      if (
          this.partnerPlan.is_gaia &&
          !this.partnerPlan.gaia_configuration.deprexis &&
          !this.partnerPlan.gaia_configuration.vorvida &&
          !this.partnerPlan.gaia_configuration.velibra
      ) {
        this.showAlert('Please Select at least one Gaia plan')
        allChecksPass = false
      }
      if (this.partnerPlan.is_gaia && this.partnerPlan.gaia_configuration.deprexis && this.isEmpty(this.partnerPlan.gaia_configuration.deprexis_plan_id)) {
        this.showAlert('Please Select Plan for Deprexis')
        allChecksPass = false
      }
      if (this.partnerPlan.is_gaia && this.partnerPlan.gaia_configuration.vorvida && this.isEmpty(this.partnerPlan.gaia_configuration.velibra_plan_id)) {
        this.showAlert('Please Select Plan for Vorvida')
        allChecksPass = false
      }
      if (this.partnerPlan.is_gaia && this.partnerPlan.gaia_configuration.velibra && this.isEmpty(this.partnerPlan.gaia_configuration.vorvida_plan_id)) {
        this.showAlert('Please Select Plan for Velibra')
        allChecksPass = false
      }
      return allChecksPass
    },

    showAlert (msg) {
      this.$toastr('error', msg)
    },

    validateServiceChargesFields () {
      let isFieldEmpty = false
      this.partnerPlan.serviceCharges.forEach((charge) => {
        if (
            (
                this.isEmpty(charge.price) ||
                this.isEmpty(charge.name)
            ) &&
            !isFieldEmpty &&
            this.partnerPlan.enable_service_charges &&
            this.partnerPlan.paid_by_org
        ) {
          isFieldEmpty = true
        }
      })
      return isFieldEmpty
    },

    createNewServiceCharge () {
      this.plan.serviceCharges.push({
        name: '',
        price: '',
        plan_id: this.plan.id
      })
    },

    getBlankPlan () {
      return {
        title: '',
        description: '',
        pricing_id: 0,
        is_diagnosis: false,
        enable_therapy: false,
        allowed_sessions: '',
        undefined_sessions: true,
        paid_by_user: true,
        paid_by_org: false,
        enable_additional_sessions_request: false,
        additional_session_request_trigger: null,
        enable_outcome_measures: false,
        enable_service_charges: false,
        enable_bulk_invoicing: false,
        enable_nps: false,
        nps_trigger_number: null,
        card_details_required: false,
        type_of_diagnosis: '',
        is_gaia: false,
        gaia_configuration: {
          deprexis: false,
          deprexis_plan_id: '',
          vorvida: false,
          vorvida_plan_id: '',
          velibra: false,
          velibra_plan_id: '',
        },
        serviceCharges: [],
        disable: false,
        billing_type: 'license',
      }
    }
  },
  watch: {
    'plan.title': function (value) {
      this.planTitle = value;
    },
    'plan.description': function (value) {
      this.planDescription = value;
    },
    'plan.plan_type': function (value) {
      this.planType = value;
    },
  }
}

</script>
