<template>
  <div class="flex-display flex-row justify-end w-100 align-items-center">
    <div class="flex-display flex-row justify-end w-100 align-items-center">
      <div class="flex-row flex-display justify-content-end w-100 align-items-center filters">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{selected}}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item"
               v-for="(individualPaidType,index) in insurancePaidTypes"
               :key="index"
               @click="updateProfile(individualPaidType)" >{{individualPaidType.name}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TherapistInsurancePaid",
  props: {
    paidType: {
      type: String,
    },
    activeInsuranceProvider: {
      required: true,
    },
    user_id: {
      required: true
    },
    paidTypes: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selected: 'Select paid type',
      insurancePaidType: this.paidType,
      insurancePaidTypes: [],
    }
  },
  mounted() {
    this.paidTypes.forEach((paidType) => {
      this.insurancePaidTypes.push({
        name: paidType.name,
        value: paidType.value
      });
    });

    if (this.paidType) {
      this.selected = this.paidType;
    }
  },
  methods: {
    updateProfile(paidType) {
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/insurance-paid-type/update", {
        therapist_id: this.user_id,
        therapist_insurance_provider_id: this.activeInsuranceProvider,
        paid: paidType.value,
        device_type: "web"
      }).then((response) => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.$emit("updateInsuranceProviders", response.data);
        this.insurancePaidType = paidType.name;
        this.selected = this.insurancePaidType;
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
  },
  watch: {
    paidType() {
      if (this.paidType) {
        this.selected = this.paidType;
      } else {
        this.selected = "Select paid type";
      }
    }
  }
}
</script>