<template>
  <v-dialog v-model="show" persistent max-width="900px" min-height="500px">
    <v-card v-if="isEverythingReady">
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" slot="mot" alt="close" @click="hideModal"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <div class="w-100">
              <v-tabs v-model="selectedTab">
                <v-tab v-for="tab of tabs"
                       class="w-50"
                       :class="tab.key"
                       :key="tab.key"
                >
                  <span class="slickdot slickdot-nav-unread-message bg-danger-admin"
                        v-if="
                            (tab.key === 'plan_details' && (latestRequest && latestRequest.status === requestPending)) ||
                            (tab.key === 'client_actions' && (plan.plan_actions.initial_outcome || plan.plan_actions.final_outcome || plan.plan_actions.nps_rating)) ||
                            (tab.key === 'therapist_actions' && (plan.plan_actions.icd_10_code || plan.plan_actions.therapist_plan))
                        "
                  >&nbsp;</span>
                  &nbsp; {{tab.text}}
                </v-tab>
                <v-tab-item key="plan_details">
                  <plan-detail :plan_details="plan"
                               :default-plan="defaultPlan"
                               :isPlanExpired="isPlanExpired"
                               :pageReference="pageReference"
                               :remainingSessions="remainingSessions"
                               :availableSession="availableSession"
                               :usedSessions="usedSessions"
                               v-on:updated="updatePlanData"
                               v-on:hide="hideModal"></plan-detail>
                </v-tab-item>
                <v-tab-item key="therapist_actions">
                  <therapist-actions :plan_details="plan" :icd_10_codes="icd10Codes" v-on:updated="updatePlanData"></therapist-actions>
                </v-tab-item>
                <v-tab-item key="client_actions">
                  <client-actions  :plan_details="plan"></client-actions>
                </v-tab-item>
              </v-tabs>
            </div>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hideModal">Cancel</a>
        <button class="btn modal-action-button" @click="updateDetails">
          {{ actionButtonTitle }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import planHelper from "@/helpers/app/partner/planHelper";
import PlanDetail from "@/components/main/therapyPlanAction/PlanDetail";
import ClientActions from "@/components/main/therapyPlanAction/ClientActions";
import TherapistActions from "@/components/main/therapyPlanAction/TherapistActions";
import stringHelper from "@/helpers/stringHelper";

export default {
  name: "PlanDetails",
  components: {
    PlanDetail,
    ClientActions,
    TherapistActions
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    rowData: {},
    selectedTab: {},
    reference: {
      type: String,
      default: "therapy"
    }
  },
  data: () => ({
    modalTitle: '',
    actionButtonTitle: 'Save',
    tabs: [
      {'key': 'plan_details', 'text': 'PLAN DETAILS'},
      {'key': 'therapist_actions', 'text': 'TREATMENT OVERVIEW'},
      {'key': 'client_actions', 'text': 'OUTCOME MEASURES'},
    ],
    plan: '',
    icd10Codes: [],
    isEverythingReady: true,
    latestRequest: null,
    requestPending: planHelper.requestPending,
    defaultPlan: false,
    availableSession: false,
    isPlanExpired: false,
    pageReference: "",
    remainingSessions: null,
    usedSessions: null,
  }),
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    }
  },
  created() {
    this.pageReference = this.reference;
    if(this.pageReference === 'client_detail') {
      this.tabs = [
        {'key': 'plan_details', 'text': 'PLAN DETAILS'},
      ]
    }
    this.getPlanDetail();
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    updateDetails() {
      if (this.plan.additional_sessions_request_status === 'Approved' &&
          stringHelper.empty(this.plan.approved_additional_sessions)
      ) {
        this.$toastr('error', 'Authorized additional sessions field is required when request is approved.', 'Error');
        return false;
      }

      if (this.plan.additional_sessions_request &&
          stringHelper.empty(this.plan.requested_additional_sessions)
      ) {
        this.$toastr('error', 'Additional sessions field is required when "on client behalf" is "Yes".', 'Error');
        return false;
      }

      if (this.plan.additional_sessions_request &&
          stringHelper.empty(this.plan.request_detail)
      ) {
        this.$toastr('error', 'Additional details field is required when "on client behalf" is "Yes".', 'Error');
        return false;
      }

      let loader = this.$loading.show()
      this.$http.put(this.url+'therapy_plan_actions', {
        ...this.plan,
        additional_sessions_request: this.plan.additional_sessions_request ? "yes" : "no"
      }).then(response => {
        loader.hide();
        this.$events.fire('vuetable:reload');
        this.$toastr('success',response.data.message,'');
        this.hideModal();
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message,'');
      });
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    },
    getPlanDetail () {
      let loader = this.$loading.show()
      this.isEverythingReady = false
      this.$http.get(this.url + 'therapy_plan_actions/details', {
        params: {
          voucher_id: this.rowData.id,
          timezone: this.timezone
        }
      }).then(response  => {
        loader.hide();
        this.icd10Codes = response.data.icd_10_codes;
        this.plan = {
          ...response.data.plan_details,
          request_action: 'none'
        };
        if (this.plan.additional_session_requests.length > 0) {
          this.latestRequest = this.plan.additional_session_requests[this.plan.additional_session_requests.length - 1];
        }
        if (this.plan.additional_request_enabled && this.plan.additional_request_allowed && this.plan.additional_session_requests.length === 0) {
          this.plan.request_action = 'add'
        }

        this.defaultPlan = response.data.default_plan;
        this.availableSession = response.data.available_session;
        this.usedSessions = response.data.used_sessions;
        this.remainingSessions = response.data.remaining_sessions;
        this.availableSession = response.data.available_session;
        this.isPlanExpired = response.data.is_expired;

        this.isEverythingReady = true;

      }).catch(error => {
        loader.hide()
        this.isEverythingReady = true
        this.hideModal();
        this.$toastr('error', error.response.data.message, 'Error')
      })
    },
    updatePlanData(plan) {
      this.plan = plan;
    }
  }
}
</script>
<style>
.theme--light.v-sheet{
  overflow-x: hidden !important;
}
</style>
