<template>
  <v-dialog v-model="show" persistent max-width="700px" min-height="500px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h1 class="model-heading">{{ headerTitle }}</h1>
        <img class="close-model" src="/icons/close_ic.svg" alt="MyOnlineTherapy" @click="hide"/>
      </v-card-title>

      <!-- Content -->
      <v-card-text>
        <v-container fluid>
          <v-layout wrap align-center class="justify-space-between">
            <form class="w-100 flex-display flex-row flex-wrap flex-justify-between " id="pricingForm">

              <!-- Discount title -->
              <div class="w-100">
                <label class="w-100">
                  <span class="form-label">Title</span>
                  <input type="text" name="discount_title" v-model="form.discount_title" class="input-text" placeholder="Enter title"/>
                </label>
              </div>



              <div class="form-group w-48">
                <label class="form-label">Pricing</label>
                <select name="use" :disabled="action === 'add'" class="input-select" v-model="form.pricing_id">
                  <option value="" disabled selected>Select Pricing</option>
                  <template v-for="p in pricing">
                    <option :value="p.id">{{ p.title }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Currency</label>
                <select name="use" class="input-select" v-model="form.currency_id">
                  <option value="" disabled selected>Select Currency</option>
                  <template v-for="currency in currencies">
                    <option :value="currency.id">{{ currency.title }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Session Type</label>
                <select name="use" class="input-select" v-model="form.session_type_id">
                  <option value="" disabled selected>Select Session Type</option>
                  <template v-for="sessionType in sessionTypes">
                    <option :value="sessionType.id">{{ sessionType.formatted_title }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Session Stage</label>
                <select name="use" class="input-select" v-model="form.session_stage">
                  <option value="" disabled selected>Select Session Stage</option>
                  <option value="initial">Initial</option>
                  <option value="ongoing">Ongoing</option>
                </select>
              </div>

              <div class="form-group w-48">
                <label class="form-label">Actual Price</label>
                <input name="price" type="number" v-model="form.discount_price" class="input-text" placeholder="e.g. 50"/>
              </div>

              <div class="main-container my-3 w-100">
                <div class="toggle-switch">
                  <label class="org-label switch-label pr-3">Discount: &nbsp;</label>
                  <label class="switch">
                    <input type="checkbox"
                           v-model="form.discount_applied"
                           :true-value="true"
                           :false-value="false">
                    <span class="slider"></span>
                  </label>
                </div>
              </div>

              <template v-if="form.discount_applied">
                <!-- Discount Description -->
                <div class="form-group w-48">
                  <label class="w-100">
                    <span class="form-label">Discount Description</span>
                    <textarea class="input-textArea form-group__input form-group__input--inputTextArea"
                              name="description"
                              v-model="form.discount_description"
                              placeholder="Enter Description..."
                              rows="4"
                    ></textarea>
                  </label>
                </div>

                <div class="form-group w-48">
                  <label class="form-label">Full Price</label>
                  <input name="price" type="number" v-model="form.price" class="input-text" placeholder="e.g. 50"/>
                </div>
              </template>

            </form>
          </v-layout>
        </v-container>
      </v-card-text>

      <!-- Actions -->
      <v-card-actions class="flex-display flex-row">
        <v-spacer></v-spacer>
        <a class="cancel-model" @click="hide">Cancel</a>
        <button class="btn modal-action-button" @click="submitTherapyPricingItem">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddTherapyPricingItems",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    action: {
      required: true,
      default: "add"
    },
    value: {
      type: Object,
      default: null
    },
    preSelectedPricingId: {
      default: 0
    },
  },
  data () {
    return {
      isReady: false,
      pricing: null,
      sessionTypes: null,
      currencies: null,
      headerTitle: 'Add Therapy Pricing Item',
      actionButtonTitle: 'Add',
      form: {
        id: null,
        pricing_id: "",
        currency_id: "",
        session_type_id: "",
        session_stage: "",
        discount_applied: false,
        discount_title: "",
        discount_description: "",
        price: 0.00,
        discount_price: 0.00,
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  created () {
    this.getTherapyPricingItemFormData();
    if ( this.action === 'update' ) {
      this.headerTitle =  'Update Therapy Pricing Item';
      this.actionButtonTitle = 'Update';
      this.form = {...this.value};
    }
  },
  methods: {
    getTherapyPricingItemFormData() {
      let loader = this.$loading.show();
      this.disableActions = true;
      this.$http.get(this.url+'data/therapy-pricing-items', {
      }).then((response) => {
        loader.hide();
        this.pricing = response.data.pricing;
        this.sessionTypes = response.data.session_types;
        this.currencies = response.data.currencies;
        this.form.pricing_id = this.preSelectedPricingId;
        this.isReady = true;
      }).catch((error) => {
        loader.hide();
        this.isReady = true;
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },
    hide() {
      this.$emit('hide');
    },
    validateSubmitRequest() {
      if (!this.form.pricing_id) {
        this.showAlert('Please select a pricing.');
        return false;
      }
      if (!this.form.currency_id) {
        this.showAlert('Please select a currency.');
        return false;
      }
      if (!this.form.session_type_id) {
        this.showAlert('Please select a session type.');
        return false;
      }
      if (this.form.discount_title === '') {
        this.showAlert('Please enter discount title.');
        return false;
      }
      if (this.form.discount_applied && this.form.discount_description === '') {
        this.showAlert('Please enter a discount description.');
        return false;
      }
      if (this.form.discount_applied && this.form.price <= 0) {
        this.showAlert('Please enter price greater than zero.');
        return false;
      }
      if (this.form.discount_price <= 0) {
        this.showAlert('Please enter discount price greater than zero.');
        return false;
      }
      return true;
    },
    submitTherapyPricingItem() {
      if (this.validateSubmitRequest()) {
        this.$emit('submitTherapyPricingItem', this.form, this.action);
      }
    },
    showAlert(msg) {
      this.$toastr('error', msg);
    }
  }
}
</script>
