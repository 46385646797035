<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>

                <div class="booking-details">
                    <div class="detail-item" v-if="rowData.is_booking_exist">
                        <label class="item-label">Booking Id:</label>
                        <span class="item-value"> {{ rowData.booking_id }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Client Name:</label>
                        <span class="item-value"> {{ rowData.client_name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Psychologist:</label>
                        <span class="item-value"> {{ rowData.therapist_name }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Transaction date & time:</label>
                        <span class="item-value"> {{ customDateFormat(rowData.transaction_date, 'DD/MM/YYYY hh:mm A') }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Session Type:</label>
                        <span class="item-value"> {{ rowData.session_type }}</span>
                    </div>

                    <div class="detail-item" v-if="rowData.is_booking_exist">
                        <label class="item-label">Session date & time:</label>
                        <span class="item-value"> {{ customDateFormat(rowData.session_time, 'DD/MM/YYYY hh:mm A') }}</span>
                    </div>

                    <div class="detail-item" v-if="rowData.is_booking_exist">
                        <label class="item-label">Session Status:</label>
                        <span class="item-value" :class="{'highlighted': rowData.session_status === 'reschedule request'}"> {{ rowData.session_status }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Amount:</label>
                        <span class="item-value"> {{ rowData.amount }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Payment method:</label>
                        <span class="item-value"> {{ rowData.payment_method }}</span>
                    </div>

                    <div class="detail-item">
                        <label class="item-label">Status:</label>
                        <span class="item-value"> {{ rowData.status }}</span>
                    </div>
                </div>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "TransactionDetail",
        components: {},
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            rowData: {
                type: Object,
                required: true
            },
        },
        data () {
            return {
                modal_title : 'Transaction Detail',
                actionButtonTitle:'Save',
                editUrl: '',
            }
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide(){
                this.$emit('hide')
            }
        },
        watch: {

        },
    }
</script>
<style scoped>

</style>
