<template>
  <!-- details modal -->
  <v-dialog v-model="show" persistent max-width="566px" min-height="569px" v-else>
    <v-card>
      <!-- Title -->
      <v-card-title class="pl-5 pt-5 pb-0 pr-0">
        <h1 class="model-heading">{{ modalTitle }}</h1>
        <img alt="close" class="close-model pt-4 pr-4" src="/icons/close_ic.svg" @click.stop="hide"/>
        <div class="divider"></div>
      </v-card-title>
      <!-- Content -->
      <v-card-text class="pl-5 pt-3 pr-5 pb-0">
        <p class="fs-16 lh-24 leave-text mb-0 pb-0">
          Scheduling leave can constitute anything from holidays to abscence due to illness,
          or other commitments. Saving a period of leave will:
        </p>
        <p class="fs-16 lh-24 mb-1 pt-4 leave-text">
          1. Block any clients from booking a session with you during the stated leave period
        </p>
        <p class="fs-16 lh-24 mb-1 leave-text">
          2. Still allow new clients to connect with you whilst you’re on leave
        </p>
        <p class="fs-16 lh-24  mb-1 leave-text">
          3. Still allow you to conduct / book sessions during the stated period of leave if necessary
        </p>
        <form class="self-care-form flex-display flex-row flex-wrap flex-justify-between w-100 pt-4"
        >
          <div class="form-group w-33">
            <label class="form-label leave-text fs-14 lh-20 ">
              Choose Leave Period.
            </label>
          </div>
          <label class="form-label fs-14 lh-20">From</label>

          <div class="form-group width-103 start-date">
            <v-menu v-model="startDate"
                    offset-y
                    :nudge-right="-180"
                    transition="scale-transition"
                    lazy
            >
              <template v-slot:activator="{on}">
                <v-text-field
                    class="input"
                    v-model="therapistLeaveObject.start_date"
                    :placeholder="inputPlaceHolder"
                    v-on="on"
                    name="start-date"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="therapistLeaveObject.start_date" :min="nowDate" :max="getEndDate" no-title
                             @input></v-date-picker>
            </v-menu>

          </div>
          <label class="form-label fs-14 lh-20
            ">to</label>
          <div class="form-group width-103 end-date">
            <v-menu v-model="endDate"
                    :nudge-right="-180"
                    transition="scale-transition"
                    offset-y
                    lazy
                    class="calendar-row"
            >
              <template v-slot:activator="{on}">
                <v-text-field
                    v-model="therapistLeaveObject.end_date"
                    :placeholder="inputPlaceHolder"
                    v-on="on"
                    name="end-date"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="therapistLeaveObject.end_date" :min="nowDate" :max="getEndDate" no-title
                             @input></v-date-picker>
            </v-menu>
          </div>
        </form>
      </v-card-text>
      <v-card-actions class="flex-display flex-row pb-5 pr-4">
        <v-spacer></v-spacer>
        <button class="leave-delete mr-4 fs-13 lh-20"
                v-if="therapistLeaveObject.start_date && therapistLeaveObject.end_date" @click="deleteLeave">{{
            deleteButtonTitle
          }}
        </button>
        <button class="btn btn-save  pt-12 pr-12 mr-3" @click.stop="hide">{{ cancelButtonTitle }}</button>
        <button class="btn leave-submit" :disabled="isDisabled" @click="submitLeave">{{ actionButtonTitle }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'TherapistLeaveModal',
  props: {
    userId: {
      required: true,
    },
    visible: {
      required: true,
      type: Boolean,
    },
    therapistLeave: {
      required: true,
      default: null
    }
  },
  mounted() {
    if (this.therapistLeave) {
      this.therapistLeaveObject = this.therapistLeave
    }
  },
  data: () => ({
    modalTitle: 'Schedule leave',
    actionButtonTitle: 'Save',
    cancelButtonTitle: 'Cancel',
    deleteButtonTitle: 'Delete Leave',
    startDate: false,
    endDate: false,
    disabled: false,
    nowDate: new Date().toISOString().slice(0, 10),
    date: new Date(),
    picker: new Date().toISOString().substr(0, 10),
    therapistLeaveObject: {
      therapist_id: null,
      start_date: null,
      end_date: null,
    },
    inputPlaceHolder: "        /       /    ",
    disabledDates: {
      to: new Date(Date.now() - 8640000)
    }
  }),
  computed: {
    isDisabled() {
      return !(this.therapistLeaveObject.start_date && this.therapistLeaveObject.end_date)
    },
    getEndDate() {
      let endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 12, 10);
      return endDate.toISOString().slice(0, 10)
    },

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    submitLeave() {
      let loader = this.$loading.show();
      this.$http.put(this.url + "therapist/leave/add", {
        therapist_id: this.userId,
        start_date: this.therapistLeaveObject.start_date,
        end_date: this.therapistLeaveObject.end_date,
      }).then(response => {
        this.$toastr("success", response.data.message, "");
        this.$emit("refresh-leave");
        loader.hide();
      }).catch(error => {
        this.$toastr("error", error.response.data.message, "");
        loader.hide();
      })
    },

    deleteLeave() {

      if (!this.therapistLeave) {
         this.resetLeaveObject();
         return;
      }

      let self = this;
      let loader = this.$loading.show();
      this.$http.delete(this.url+"therapist/leave/delete", {
        data: {
            leave_id: this.therapistLeaveObject.id,
            therapist_id: this.therapistLeaveObject.therapist_id,
        }
      }).then(response => {
        loader.hide();
        self.$toastr("success", response.data.message);
        self.$emit("refresh-leave");
      }).catch(error => {
        loader.hide();
        self.$toastr("error",error.response.data.message);
      })
    },

    resetLeaveObject() {
      this.therapistLeaveObject.start_date = this.therapistLeaveObject.end_date = null;
    },

    hide() {
      this.$emit('hide')
    }
  },
}
</script>
<style scoped>
.btn.btn-save {
  width: auto;
  color: #308BC3 !important;
  background-color: #ffffff !important;
  border: 1px solid #308BC3 !important;
}

.btn.leave-submit {
  padding: 12px 24px;
  width: auto;
  height: auto;
  background: #2493C4;
}

.divider {
  position: static;
  width: 486px;
  height: 1px;
  left: 0;
  top: 48px;
  background: #F1F1F1;
  margin: 16px 0;
}

.leave-delete {
  color: #C50909;
  font-weight: 700;
}

.leave-text {
  color: #4A4A4A !important;
}

.v-text-field input {
  border: 1px solid #4A4A4A !important;
  padding: 0 6px !important;
  outline: none !important;
}

::placeholder {
  color: #4A4A4A !important;
}

.v-menu__content--fixed {
  top: 490px !important;
}

td button:disabled {
  background-color: #fff !important;
}
</style>
