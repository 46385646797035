<template>
  <div class="keywords-input pl-2 pt-1 pr-0 pb-0">
    <span class="keyword" v-for="(keyword, index) in selectedKeywords">
      {{ keyword }}
      <i class="multiselect__tag-icon"
         tabindex="1"
         aria-hidden="true"
         @click="removeKeyword(index)"
      ></i>
    </span>
    <input class="input-text form-group__input"
           type="text"
           name="therapy"
           v-model="newKeyword"
           :placeholder="placeholder"
           @keyup="newKeywordAdded"
    />
  </div>
</template>

<script>
export default {
  name: 'keywordsComponent',
  props: {
    keywords: {
      type: Array
    },
    placeholder: {
      type: String,
      default: 'Enter Keyword'
    }
  },
  data() {
    return {
      selectedKeywords: [],
      newKeyword: ''
    }
  },
  created() {
    this.selectedKeywords = [
      ...this.keywords
    ]
  },
  methods: {
    newKeywordAdded(e) {
      let keyword = this.newKeyword;
      if (e.keyCode === 13) {
        this.selectedKeywords.push(keyword);
        this.newKeyword = '';
        this.$emit('update', this.selectedKeywords)
      }
    },
    removeKeyword(index) {
      this.selectedKeywords.splice(index, 1)
      this.$emit('update', this.selectedKeywords)
    }
  }
}
</script>

<style scoped>

</style>