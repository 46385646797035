<template>
    <v-dialog v-model="show" persistent max-width="400px" min-height="263px">
        <v-card>
            <v-card-title>
                <h1 class="model-heading">{{modal_title}}</h1>
                <img class="close-model" src="/icons/close_ic.svg" @click.stop="hide"/>
            </v-card-title>

            <v-card-text>

                <div class="mt-4 scroll_auto issue-treatment-modal_height">
                    <div v-for="t in treatments" class="man-cell field">
                        <label>
                            <input type="checkbox" :value="t.name.toLowerCase()" v-model="allSelectedTreatments">
                            <div class="man-circle">
                                <img class="circle-check" src="/img/whitecheckmark.png">
                            </div>
                        </label>
                        <span class="text">{{t.name}}</span>
                    </div>
                </div>

            </v-card-text>

            <v-card-actions class="flex-display flex-row">
                <v-spacer></v-spacer>
                <a class="cancel-model" @click="hide">Cancel</a>
                <button :disabled="allSelectedTreatments.length === 0" class="btn modal-action-button" @click="addTreatment">{{actionButtonTitle}}</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "addTreatment",
        props: {
            visible: {
                required: true,
                type: Boolean
            },
            treatments: {
                type: Array,
                required: true
            },
            selectedTreatments: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            modal_title : 'Add a Treatment',
            actionButtonTitle:'Apply',
            allSelectedTreatments: []
        }),
        created(){
            this.allSelectedTreatments = this.selectedTreatments;
        },
        computed: {
            show: {
                get () {
                    return this.visible
                },
                set (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                },
            },
        },
        methods:{
            hide(){
                this.$emit('hide')
            },
            addTreatment(){
                this.$emit('addTreatment', this.allSelectedTreatments);
                this.$emit('hide');
            }
        }
    }
</script>
<style scoped>

</style>